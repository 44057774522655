<div class="payment" [class.payment_grey]="grey">
  <div class="payment__title">{{ title }}</div>

  <div class="payment__item">
    <div class="payment__label">Total Payment Remaining</div>
    <div class="payment__value">$ {{ payment | mask: 'comma_separator.2' }}</div>
  </div>
  <div class="payment__item">
    <div class="payment__label">Total Interest</div>
    <div class="payment__value">$ {{ interest | mask: 'comma_separator.2' }}</div>
  </div>
  <div class="payment__item">
    <div class="payment__label">Remaining # of Payments</div>
    <div class="payment__value">{{ payments }}</div>
  </div>
</div>
