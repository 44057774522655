import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ITab } from 'src/app/shared/components/tabs/tabs.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  public copyright = `© ApexLend ${new Date()
    .getFullYear()
    .toString()} Company, Inc.`;
  public links: ITab[] = [
    {
      label: 'Privacy policy',
      path: '/privacy'
    },
    {
      label: 'Terms of Use',
      path: '/content/terms'
    },
    {
      label: 'License',
      path: '/license'
    }
  ];
}
