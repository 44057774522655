<mat-progress-bar mode="indeterminate" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<app-information-confirm-form
  [customer]="customer$ | async"
  [withoutAuth]="true"
  [isProfilePage]="true"
  (submitted)="onSubmitted($event)"
></app-information-confirm-form>

<div class="controls">
  <app-control
    class="controls__control"
    label="Payment Notifications"
    type="radio"
    [checked]="paymentNotificationFlag"
    icon="/assets/img/icons/notifications.svg"
    (changed)="onChangedNotificationsControl($event)"
  ></app-control>
  <app-control
    class="controls__control"
    label="Turn on Two-factor Authentication"
    icon="/assets/img/icons/lock.svg"
    type="radio"
    [checked]="isTwoFactorOn$ | async"
    (changed)="onChangedTwoFactorAuthControl($event)"
  ></app-control>
</div>

<app-button class="reset-password-btn" (pressed)="onPressedChangePassword()">Change password</app-button>
