<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <app-close-button class="close" (pressed)="dialogRef.close()"></app-close-button>
    <div class="al-modal-title">
      {{!otpflag ? 'Sign in' : ''}} 
      <p *ngIf="otpflag" class="otp_title">
        We have sent your <br>One Time Passcode (OTP) to your<br> email - {{username}}
      </p>
      <!-- <img 
          class="otp_lock"
          *ngIf="otpflag"
          src="assets/img/icons/lock.svg"
        > -->
    </div>
    <form [formGroup]="form" class="form">
      <div [hidden]="otpflag">
        <app-input
          class="username-input"
          formControlName="username"
          label="Email Address"
          [forceError]="error"
          ngDefaultControl
          [id]="'email_id_input'"        
        ></app-input>
        <app-input
          inputType="password"
          class="password-input"
          formControlName="password"
          label="Password"
          type="password"
          [forceError]="error"
          ngDefaultControl
          [id]="'password_input'"
          [warning]="isCaps"
        ></app-input>
        <ngx-recaptcha2        
          *ngIf="data?.withRecaptcha"
          class="recaptcha"
          #captchaElem
          [siteKey]="googleCaptchaKey"
          [useGlobalDomain]="false"
          formControlName="recaptcha"
        >
        </ngx-recaptcha2>
        <app-button 
          class="submit-btn"
          type="submit"
          [id]="'sign_in_model_btn'"
          [isDisabled]="form.invalid"
          [pending]="pending"
          (pressed)="onSubmit(form.value)">
          Sign in
        </app-button>
      </div>
      <div [hidden]="!otpflag" class="otp-wrapper">
        <app-input
        class="otp-input"
        formControlName="otp"
        label="Please enter the OTP from the message"
        [forceError]="error"
        ngDefaultControl
        [id]="'otp_input'"
      ></app-input>
      <span class="error" *ngIf="error">{{error}}</span>
        <app-button
          class="submit-btn"
          type="submit"
          [id]="'verify_otp'"
          (pressed)="onVerifyOTP(form.value)">
          Verify One Time Passcode(OTP)
        </app-button>
        <div class="link-container" >
          <ng-container>
            <span class="link-container__passcode" *ngIf="(timeRemaining$ | async) !=0">One Time Passcode (OTP) will expire in 
              {{timeRemaining$ | async | date:'mm:ss'}} minutes
            </span>
          </ng-container>
          <span class="link-container__link" *ngIf="(timeRemaining$ | async) == 0" (click)="resentOTP(form.value)">Resend One Time Passcode (OTP)</span>
        </div>
      </div>
    </form>
    <div [hidden]="otpflag" [class]="!otpflag ? 'link-container' : ''" >
      <span class="link-container__link" (click)="clickedPasswordRecovery()">I forgot my password</span>
      <span class="link-container__link" (click)="clickedResetOffer()">I forgot my email</span>
    </div>
  </div>       
  <!-- <span *ngIf="isCaps">Caps lock is on!</span> -->
</div>
