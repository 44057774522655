<div
  class="checkbox"
  [class.checkbox_loading]="loading"
  [class.checkbox_align-left]="alignCheckbox === 'left'"
  [class.checkbox_align-right]="alignCheckbox === 'right'"
>
  <mat-checkbox
    class="checkbox__input"
    [hidden]="hidden"
    [ngModel]="model"
    (ngModelChange)="writeValue($event)"
    [disabled]="isDisabled || loading">
    <div class="checkbox__label">
      <ng-content></ng-content>
    </div>
  </mat-checkbox>
  <div *ngIf="!loading && forceWarning" class="checkbox__warning-message">
    {{ warningMessage }}
  </div>
</div>
