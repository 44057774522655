import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DocuSignService } from 'src/app/shared/services/docusign/docusign.service';

export interface IDocuSignAuthHash {
  access_token: string;
  expires_in: string;
  token_type: string;
}

@Component({
  template: '',
  selector: 'app-docu-sign-window',
  styleUrls: ['./docu-sign-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocuSignWindowComponent implements OnInit {

  constructor(
    private $docusign: DocuSignService
  ) { }

  ngOnInit() {
    if (!window.location.hash) {
      this.$docusign.goToSignIn();
      return;
    }
    const authMap = <IDocuSignAuthHash>this.parseHash(window.location.hash);
    this.$docusign.accessToken = authMap.access_token;
  }

  private parseHash(hash: string): Object {
    return hash.replace('#', '').split('&').reduce((obj, str) => {
      const [key, value] = str.split('=');
      obj[key] = value;
      return obj;
    }, {});
  }

}
