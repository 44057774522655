<app-base-input
  [id]="id"
  class="input"
  [hidden]="isPreview || hidden"
  [label]="label"
  [errorMessage]="errorMessage"
  [model]="model"
  [placeholder]="placeholder"
  [type]="type"
  [isDisabled]="locked"
  [loading]="loading"
  [hasHiddenInput]="hasHiddenInput"
  [ngxMask]="ngxMaskDelayed$ | async"
  [prefix]="prefix"
  [suffix]="suffix"
  [maxLength]="maxLength"
  [setTouched]="setTouched"
  [forceError]="forceError"
  [warning]="warning"
  [validateErrors]="validateErrors"
  [hasHint]="hasHint"
  [isRequired]="isRequired"
  [isPreview]="isPreview"
  [ngxDropSpecialCharacters]="ngxDropSpecialCharacters"
></app-base-input>
<app-button
  class="button"
  *ngIf="locked"
  [loading]="loading"
  (pressed)="onEditClick()"
  [id]="'edit_btn'"
>Edit</app-button>