import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/user/services/user/user.service';
import { environment } from '../../../../environments/environment';
import { of, concat, timer, Observable } from 'rxjs';
import { map, combineAll, toArray, delay, takeWhile } from 'rxjs/operators';
import { ILoginResponse, IProfiles } from 'src/app/user/models/user.models';
import { GoogleAnalyticsService } from '../../services/google-api/google-analytics.service'
import { CustomerService } from '../../services/customer/customer.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss']
})
export class SignInModalComponent implements OnInit {
  form: FormGroup;
  error: boolean;
  public googleCaptchaKey: string = environment.googleCaptchaKey;
  public pending = false;
  public isCaps: string;
  public otpflag = false;
  public token: any;
  public customer: any;
  public username: string;
  public counter: number = 300;
  public timeRemaining$: Observable<any>;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SignInModalComponent>,
    private userService: UserService,
    private customerService: CustomerService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { withRecaptcha: boolean }
  ) {}

  @HostListener('window:keydown', ['$event'])
  public onKeydown(event: KeyboardEvent): void {
    this.isCaps = typeof event.getModifierState === 'function' && event.getModifierState('CapsLock') && 'Caps Lock is on!';
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(60)]],
      password: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(60)]],
      otp: ['', [Validators.minLength(6), Validators.maxLength(6)]]
    });

    if (this.data && this.data.withRecaptcha) {
      this.form.addControl('recaptcha', new FormControl('', Validators.required));
    }
  }

  public onSubmit({ username, password }) {
    if (this.form.invalid) {
      return;
    }
    this.googleAnalyticsService.eventEmitter("loginPage", "signIn", " login", 1);
    this.pending = true;
    concat(
      this.userService.login({ username, password }),
      this.userService.profiles(),
    ).pipe(toArray())
      .subscribe(
        ([login, profiles]: [ILoginResponse, IProfiles]) => {
          this.token = login.token;
          this.customer = profiles.profiles.customer;
          if (this.token) {
            this.error = false;
            this.customerService.getUsersSetting(this.userService.customerId).subscribe(res => {
              if (res[0].twoFactorAuthentication){
                this.otpflag = true;
                this.username = username;
                this.timeRemaining$ = timer(0, 1000).pipe(
                  map(n => (this.counter - n) * 1000),
                  takeWhile(n => n >= 0),
                );
              } else{
                this.otpflag = false;
                const token = login.token;
                const customer = profiles.profiles.customer;
                this.dialogRef.close({ token, customer });
              }
            })
          } else {
            this.error = true;
            this.pending = false;
          }
        },
        res => {
          this.error = res.message;
          this.pending = false;
        }
      );
  }

  public onVerifyOTP({otp, username}) {
    console.log(otp)
    const token = this.token;
    const customer = this.customer;
    this.userService.verifyEmailOTP({otp, username}).subscribe(
      (response: any) => {
      console.log(response)
      this.dialogRef.close({ token, customer });
    },  
    (err) => {
      this.error = err.error.message;
      console.log(err.error.message)
    });
  }

  public resentOTP({ username }) {
    this.userService.resendOTP({ username }).subscribe(
      (res: any) => {   
        this._snackBar.open(res.message, 'X')
        this.timeRemaining$ = timer(0, 1000).pipe(
          map(n => (this.counter - n) * 1000),
          takeWhile(n => n >= 0),
        );
      },
      (err) => {
        this._snackBar.open(err.error.message, 'X')
      });
  }

  public clickedPasswordRecovery() {
    this.dialogRef.close({ action: 'passwordRecovery' });
  }

  public clickedResetOffer() {
    this.dialogRef.close({ action: 'resetOffer' });
  }
}
