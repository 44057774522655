import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OfferCodeGuard } from './shared/guards/offer-code.guard';
import { AuthForStepsGuard } from './shared/guards/auth-for-steps.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { environment } from 'src/environments/environment';
import { HoldsAcceptComponent } from './independent/holds-accept/holds-accept.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'final',
    loadChildren: () => import('./final-steps/final-steps.module').then(m => m.FinalStepsModule),
    canActivate: [AuthForStepsGuard]
  },
  {
    path: 'steps',
    loadChildren: () => import('./steps/steps.module').then(m => m.StepsModule),
    canActivate: [OfferCodeGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'holds/accept',
    component: HoldsAcceptComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

// if (environment.development) {
//   routes.forEach(route => delete route.canActivate);
// }

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
