<div class="footer">
  <div class="container container-footer p-relative">
    <div class="footer-logo"></div>
    <div class="footer-sunwest"></div>
    <div class="footer-aws"></div>
    <div class="footer-equal"></div>
    <div class="footer-copyright">
      {{ copyright }}
    </div>
    <div class="footer-links">
      <a *ngFor="let link of links" class="footer-link" [routerLink]="link?.path">
        {{ link?.label }}
      </a>
    </div>
    <div class="footer-disclaimer">
      Although Sun West Mortgage is approved to conduct business in the state of New York, this website has not yet been approved by the State of New York Department of Financial Services. For properties located in the State of New York, this website can not be used to upload an application, please visit <a href="https://swmc.com/" target="_blank">SWMC.com</a> to upload an application.
    </div>
  </div>
</div>
