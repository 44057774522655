import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IApplicationTerm } from 'src/app/user/models/user.models';

@Component({
  selector: 'app-terms-table',
  templateUrl: './terms-table.component.html',
  styleUrls: ['./terms-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsTableComponent implements OnChanges {
  @Input() loading: boolean;
  @Input() terms: IApplicationTerm[] = [];

  @Output() termSelect = new EventEmitter<number>();

  public selectedTerm = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.terms && changes.terms.currentValue && changes.terms.currentValue.length) {
      this.selectedTerm = this.terms[0].term;
      this.termSelect.emit(this.selectedTerm);
    }
  }

  onChange() {
    this.termSelect.emit(this.selectedTerm);
  }
}
