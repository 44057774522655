import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ILoanPayment } from '../../../shared/services/customer/customer.service';
import { parse } from 'json2csv';
import * as FileSaver from 'file-saver';
import { ThousandsSeparatorsPipe } from 'src/app/shared/pipes/thousands-separators.pipe';
import { DatePipe } from '@angular/common';
import { ITableSchema } from '../loans-table/loans-table.component';
import { ILoan } from 'src/app/shared/models/customer.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
@Component({
  selector: 'app-loan-payment-history',
  templateUrl: './loan-payment-history.component.html',
  styleUrls: ['./loan-payment-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanPaymentHistoryComponent {
  @Input() payments: ILoanPayment[];
  @Input() paymentHistoryStatus: boolean;
  @Input() loan: ILoan;
  public selectedData: any[];

  @ViewChild('htmlData') htmlData: ElementRef;

  public tableSchema: ITableSchema[] = [
    // { name: 'Starting Balance ($)', key: 'starting_balance', pipe: ThousandsSeparatorsPipe },
    { name: 'Payment Date', key: 'created', pipe: DatePipe, pipeArgs: ['MM/dd/yyyy'] },
    { name: 'Type', key: 'type' },
    { name: 'Paid Amount ($)', key: 'payment', pipe: ThousandsSeparatorsPipe },
    { name: 'Interest ($)', key: 'interest', pipe: ThousandsSeparatorsPipe },
    { name: 'Principal ($)', key: 'principal', pipe: ThousandsSeparatorsPipe },
    // { name: 'Ending balance ($)', key: 'ending_balance', pipe: ThousandsSeparatorsPipe },
    // { name: 'Due Date', key: 'due_date', pipe: DatePipe, pipeArgs: ['MM/dd/yyyy'] },
    { name: 'Payment Notes', key: 'notes' },
    { name: 'Status', key: 'status' },
    { name: 'Statement', key: 'statement', checkbox: true, classCell: ['payment-checkbox'] }
  ];

  public controls = [
    {
      label: 'Download History in CSV',
      action: () => {
        const fields = this.tableSchema.map(item => ({
          label: item.name,
          value: item.key,
          default: 'NULL'
        }));
        if (this.payments && this.payments.length) {
          const csv = parse(this.payments, { fields });
          const blob = new Blob([csv], {type: 'text/csv;charset=utf-8' });
          FileSaver.saveAs(blob, "payment-history.csv");
        }
      },
    },
    {
      label: 'Download Statements',
      action: (data: ILoanPayment[], checkboxList: boolean[]) => {
        console.log(data, checkboxList);
        this.selectedData = data;
        setTimeout(() => {          
          this.openPDF();
        }, 500);
      }
    }
  ];

  public async openPDF() {
    const pdfTable = this.htmlData.nativeElement;
    let html = htmlToPdfmake(pdfTable.innerHTML);
    const URL = window.location.origin + "/assets/img/logo-header/logo-header.png";
    const documentDefinition = {
      header: '© ApexLend 2023 Company, Inc.',
      content: [
        {
          image: await this.getBase64ImageFromURL(URL),
          width: 150
        },
        {
          text: 'Credit Card Statement',
          fontSize: 16,
          alignment: 'center',
          color: '#047886',
          margin: [40, 0 , 0, 0] 
        },
        html,
      ],
      footer: '© ApexLend 2023 Company, Inc.'
    };
    pdfMake.createPdf(documentDefinition).download(`Payment-Statment-${+new Date()}.pdf`);
  };

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => { 
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        let dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }
}
