import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { ILoan } from '../../models/customer.model';
import { ILoanPayment } from '../../services/customer/customer.service';

@Component({
  selector: 'app-delay-payment-modal',
  templateUrl: './delay-payment-modal.component.html',
  styleUrls: ['./delay-payment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DelayPaymentModalComponent implements OnInit {
  public form: FormGroup;
  public error: boolean;
  public minPaymentDate: Date;
  public maxPaymentDelayDate: Date;
  public workDayFilter = (d: moment.Moment): boolean => {
    const day = d.day();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  constructor(
    public dialogRef: MatDialogRef<DelayPaymentModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { loan: ILoan, nextPayment: ILoanPayment },
  ) {}

  ngOnInit() {
    this.initForm();
    const paymentDate = new Date(this.data && this.data.nextPayment ? this.data.nextPayment.due_date : null);
    this.minPaymentDate = new Date(this.data && this.data.nextPayment ? this.data.nextPayment.due_date : null);
    // Add 3 days when all 3 days ahead of paymentDate are work days.
    // Add 5 days when 3 days ahead of paymentDate include weekend days.
    const newDate = paymentDate.getDay() > 2
      ? paymentDate.getDate() + 5
      : paymentDate.getDate() + 3;
    this.maxPaymentDelayDate = new Date(paymentDate.setDate(newDate));
  }

  initForm() {
    this.form = this.formBuilder.group({
      date: ['', [Validators.required]],
    });
  }

  onSubmit({ date }) {
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close(date);
  }
}
