<div
  class="select"
  [class.select_has-error]="isControlError"
  [class.select_loading]="loading"
>
  <div class="select__label" *ngIf="label">
    <span class="select__title">{{ label }}</span>
    <span *ngIf="isRequired && !isPreview" class="select__required">*</span>
    <img 
      *ngIf="hasHint"
      class="select__hint"
      src="assets/img/icons/info--blue.svg"
      (click)="hintClick.emit()"
    >
  </div>

  <div class="select__input" [hidden]="isPreview">
    <select
      [ngModel]="model"
      (ngModelChange)="writeValue($event)"
      (focusout)="onTouched()"
      [disabled]="isDisabled || loading"
      [id]="id"
    >
      <option
        *ngFor="let option of optionsOutput"
        [value]="option.key"
        [disabled]="option.disabled"
        >{{ option.value }}</option
      >
    </select>
  </div>
  <span
    *ngIf="isPreview"
    class="select__preview"
    [class.select__preview_loading]="loading"
  >{{ options[model] }}</span>

  <div *ngIf="isControlError && !loading" class="select__error-message">
    {{ errorMessage }}
  </div>
</div>
