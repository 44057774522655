/**
 * Parses string with format `00/00` to Date object.
 */
export function parseExpirationDate(str: string): Date {
  const [month, year] = str.split('/');
  const monthNum = Number(month);
  if (monthNum < 1 || monthNum > 12) {
    return new Date(NaN);
  }
  return new Date(
    Number(`20${year}`),
    monthNum - 1
  );
}