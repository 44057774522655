<div class="table table--2cols table--striped facts-table">
  <div style="order:1;" class="table-cell title-line title-cell">FACTS</div>
  <div style="order:2;" class="table-cell title-cell">WHY?</div>
  <div style="order:3;" class="table-cell title-cell">WHAT?</div>
  <div style="order:4;" class="table-cell title-cell">HOW?</div>

  <div style="order:1;" class="table-cell title-line title-line-default">
    WHAT DOES APEXLEND LLC ("ApexLend") DO WITH YOUR PERSONAL INFORMATION?
  </div>
  <div *ngFor="let fact of content?.facts; let i = index" [style.order]="i + 2" class="table-cell">
    <div>{{ fact.header }}</div>
    <ul *ngIf="fact?.list?.length">
      <li *ngFor="let listItem of fact.list">{{ listItem }}</li>
    </ul>
  </div>
</div>

<div class="table table--3cols reasons-table">
  <div style="order:1;" class="table-cell title-line">Reasons we can share your personal information</div>
  <div
    *ngFor="let reason of content?.reasons; let i = index"
    [style.order]="i + 2"
    class="table-cell"
    [innerHTML]="reason.title | safeHtml"
  ></div>
  <div style="order:1;" class="table-cell title-line title-line-default answer-cell">
    Does ApexLend Share?
  </div>
  <div *ngFor="let reason of content?.reasons; let i = index" [style.order]="i + 2" class="table-cell answer-cell">
    {{ reason.doesApexShare ? 'YES' : 'NO' }}
  </div>
  <div style="order:1;" class="table-cell title-line answer-cell">Can you limit this sharing?</div>
  <div *ngFor="let reason of content?.reasons; let i = index" [style.order]="i + 2" class="table-cell answer-cell">
    <app-privacy-policy-radio
      *ngIf="isCheckboxConfiguration(reason.canYouLimit)"
      [label]="reason?.canYouLimit?.label"
      [defaultValue]="reason?.canYouLimit?.defaultValue"
      (changed)="onChangedRadioButton($event)"
    ></app-privacy-policy-radio>
    <ng-container *ngIf="!isCheckboxConfiguration(reason.canYouLimit)">{{
      reason.canYouLimit ? 'YES' : 'NO'
    }}</ng-container>
  </div>
</div>

<div *ngFor="let question of content?.questions" class="table table--1cols">
  <div style="order:1;" class="table-cell title-line">{{ question.title }}</div>
  <div style="order:2;" class="table-cell">
    <ng-container *ngFor="let questionContent of question.content">
      <div *ngIf="questionContent.title">{{ questionContent.title }}</div>
      <div *ngIf="questionContent.header" class="question-header">{{ questionContent.header }}</div>
      <div *ngIf="questionContent.description" [innerHTML]="questionContent?.description | safeHtml"></div>
      <ul *ngIf="questionContent.list">
        <li *ngFor="let listItem of questionContent.list" [innerHTML]="listItem | safeHtml"></li>
      </ul>
      <div *ngIf="questionContent.listDescription">{{ questionContent.listDescription }}</div>
    </ng-container>
  </div>
</div>

<div *ngIf="content?.contacts?.length" class="table table--2cols contacts-table">
  <div *ngFor="let contact of content?.contacts; let i = index" [style.order]="i + 1" class="table-cell title-cell">
    {{ contact.title }}
  </div>

  <div
    *ngFor="let contact of content?.contacts; let i = index"
    [style.order]="i + 1"
    class="table-cell"
    [innerHTML]="contact?.content | safeHtml"
  ></div>
</div>
