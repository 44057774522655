<ng-container *ngIf="!(submitRequest.isSuccess | async); else success">
  <div class="title">
    Method to make payments
  </div>
  
  <div class="modal-content" mat-dialog-content>
    <div class="description description--bottom">
      Please note - Your payment information will not be stored until you agree to and finalize your loan document.
    </div>

    <div class="description description--subtitle">
      By submitting your account information, you agree to the One-Time Automated Payments <a (click)="openTermsOfUse()">terms of use</a>
    </div>
  
    <form [formGroup]="form" class="form" [class.loading]="loading$ | async">
      <div class="left-column">
        <ng-container formGroupName="primary">
          <app-input
            formControlName="routing_number"
            label="Bank Routing Number"
            class="input"
            errorMessage="Incorrect Bank Routing Number"
            ngxMask="000000000"
            placeholder="9 digits"
            [loading]="loading$ | async"
            ngDefaultControl
            [id]="'routing_number'"
          ></app-input>
          <app-input
            formControlName="account_number"
            label="Bank Account Number"
            class="input"
            errorMessage="Incorrect Bank Account Number"
            ngxMask="0*"
            placeholder="{{accountNumberMinLength}} to {{accountNumberMaxLength}} digits"
            [maxLength]="accountNumberMaxLength"
            [validateErrors]="[form.get('primary').get('account_number').getError('length'), form.get('primary').getError('reenter')]"
            [loading]="loading$ | async"
            ngDefaultControl
            [id]="'account_number'"
          ></app-input>
          <app-input
            formControlName="account_number_reenter"
            label="Re-enter Bank Account Number"
            class="input"
            errorMessage="Incorrect Bank Account Number"
            ngxMask="0*"
            placeholder="{{accountNumberMinLength}} to {{accountNumberMaxLength}} digits"
            [maxLength]="accountNumberMaxLength"
            [validateErrors]="[form.get('primary').get('account_number_reenter').getError('length'), form.get('primary').getError('reenter')]"
            [disablePaste]="true"
            [loading]="loading$ | async"
            ngDefaultControl
            [id]="'account_number_reenter'"
          ></app-input>
          <app-select
            formControlName="type"
            label="Bank Account Type"
            class="input"
            [options]="bankAccountTypeOptions"
            [loading]="loading$ | async"
            ngDefaultControl
            [id]="'bankAccountType'"
          ></app-select>
          <app-input
            formControlName="institution"
            label="Institution"
            class="input"
            errorMessage="Incorrect Institution"
            [isDisabled]="true"
            [loading]="loading$ | async"
            ngDefaultControl
            [id]="'institution'"
          ></app-input>
        </ng-container>
      </div>
      <div class="right-column">
        <ng-container formGroupName="secondary">
          <app-input
            formControlName="routing_number"
            label="Alternate Bank Routing Number"
            class="input"
            errorMessage="Incorrect Alternate Bank Routing Number"
            ngxMask="000000000"
            placeholder="9 digits"
            [loading]="loading$ | async"
            [forceError]="form.getError('linkRoutingNumber')"
            ngDefaultControl
            [id]="'linkRoutingNumber'"
          ></app-input>
          <app-input
            formControlName="account_number"
            label="Alternate Bank Account Number"
            class="input"
            errorMessage="Incorrect Alternate Bank Account Number"
            ngxMask="0*"
            placeholder="{{accountNumberMinLength}} to {{accountNumberMaxLength}} digits"
            [maxLength]="accountNumberMaxLength"
            [loading]="loading$ | async"
            [validateErrors]="[form.get('secondary').get('account_number').getError('length'), form.get('secondary').getError('reenter')]"
            [forceError]="form.getError('linkAccountNumber')"
            ngDefaultControl
            [id]="'linkAccountNumber'"
          ></app-input>
          <app-input
            formControlName="account_number_reenter"
            label="Re-enter Bank Account Number"
            class="input"
            errorMessage="Incorrect Bank Account Number"
            ngxMask="0*"
            placeholder="{{accountNumberMinLength}} to {{accountNumberMaxLength}} digits"
            [maxLength]="accountNumberMaxLength"
            [validateErrors]="[form.get('secondary').get('account_number_reenter').getError('length'), form.get('secondary').getError('reenter')]"
            [disablePaste]="true"
            [loading]="loading$ | async"
            ngDefaultControl
            [id]="'linkAccountNumberReenter'"
          ></app-input>
          <app-select
            class="input"
            label="Bank Account Type"
            formControlName="type"
            [options]="bankAccountTypeOptions"
            [loading]="loading$ | async"
            ngDefaultControl
            [id]="'linkAccountType'"
          ></app-select>
          <app-input
            formControlName="institution"
            label="Alternate Institution"
            class="input"
            errorMessage="Incorrect Alternate Institution"
            [loading]="loading$ | async"
            [isDisabled]="true"
            ngDefaultControl
            [id]="'linkInstituion'"
          ></app-input>
        </ng-container>
      </div>
      <div class="button-list">
        <app-button
          class="button-list__button button-list__button--cancel"
          [color]="'white'"
          (pressed)="onCancel()"
          [id]="'bank_cancel_btn'"
        >Cancel
        </app-button>
        <app-button
          class="button-list__button button-list__button--submit"
          [pending]="submitRequest.isPending | async"
          (pressed)="onSubmit()"
          [id]="'bank_submit_btn'"
        >Submit
        </app-button>
      </div>
    </form>
  
    <img class="bank-check" src="/assets/img/bank_check.png"/>
  </div>
</ng-container>
<ng-template #success>
  <div class="title">
    Bank Account set up success
  </div>
  <div class="bank-info bank-info--primary">
    <span class="bank-info__row bank-info__row--title">Primary:</span>
    <span class="bank-info__row">{{ form.value.primary.institution }}</span>
    <span class="bank-info__row">{{ form.value.primary.routing_number }}</span>
    <span class="bank-info__row">{{ form.value.primary.account_number }}</span>
    <span class="bank-info__row">{{ form.value.primary.type }}</span>
  </div>
  <div *ngIf="form.value.secondary.institution" class="bank-info bank-info--secondary">
    <span class="bank-info__row bank-info__row--title">Secondary:</span>
    <span class="bank-info__row">{{ form.value.secondary.institution }}</span>
    <span class="bank-info__row">{{ form.value.secondary.routing_number }}</span>
    <span class="bank-info__row">{{ form.value.secondary.account_number }}</span>
    <span class="bank-info__row">{{ form.value.secondary.type }}</span>
  </div>
  <app-button
    class="bank-info-button"
    [pending]="submitRequest.isPending | async"
    (pressed)="dialogRef.close(true)"
    [id]="'bank_ok_btn'"
    >OK
  </app-button>
</ng-template>