import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteListComponent } from './components/route-list/route-list.component';
import { RouterModule } from '@angular/router';

const entryComponents = [RouteListComponent];

@NgModule({
  declarations: [...entryComponents],
  entryComponents,
  imports: [
    CommonModule, RouterModule
  ],
  exports: [...entryComponents]
})
export class DebugModule { }
