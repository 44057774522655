import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPageComponent {
  @Input() statusCode: '404' | '500';

  constructor(private router: Router) {}

  onPressed(): void {
    switch (this.statusCode) {
      case '404': {
        this.router.navigate(['/']);
        break;
      }
      case '500': {
        break;
      }
    }
  }
}
