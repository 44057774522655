import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ILoan, IHoldCard } from 'src/app/shared/models/customer.model';
import { CustomerService, ILoanPayment } from 'src/app/shared/services/customer/customer.service';
import { UserService } from 'src/app/user/services/user/user.service';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IApplication } from '../../../user/models/user.models';
import { shareReplay, delay } from 'rxjs/operators';
import { CARD_TYPE_SHORT } from 'src/app/shared/constants/select-data.constants';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { FaqPaymentCycleModalComponent } from 'src/app/shared/entry/faq-payment-cycle-modal/faq-payment-cycle-modal.component';
import { PrivacyPolicyService } from 'src/app/user/services/privacy-policy/privacy-policy.service';
import { EConsent } from 'src/app/shared/models/enums/consent.enum';

declare const videojs: any;

@Component({
  selector: 'app-final-approved-container',
  templateUrl: './final-approved-container.component.html',
  styleUrls: ['./final-approved-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalApprovedContainerComponent implements OnInit {
  @ViewChild('video', { static: true }) videoElement: ElementRef;
  public paymentSchedule$: Observable<ILoanPayment[]>;
  public loan$: Observable<ILoan>;
  public progressBar: boolean = true;
  @Input() loading: boolean;
  @Input() spinner: boolean;
  public requestPending = false;

  public holdCard: IHoldCard;
  public holdCardType: string;
  public holdCardLastFour: string;
  public paymentAmount: number;
  public balanceAmount: number;
  public endedVideo$ = new BehaviorSubject<boolean>(false);
  public loading$ = new BehaviorSubject<boolean>(true);
  public emailVerified$ = new BehaviorSubject<boolean>(false);
  public emailVerificationLoading$ = new BehaviorSubject<boolean>(false);
  public emailVerificationLinkSent$ = new BehaviorSubject<boolean>(false);
  public emailVerificationFailedOnce$ = new BehaviorSubject<boolean>(false);

  public holdCardAgreement = new FormControl(false);
  public loanAgreement = new FormControl(false);
  public agreed = new FormControl(false);
  public email: string;

  private videoComponent: any;
  private supposedCurrentTime = 0;
  private application: IApplication;
  private username: string;

  public holdAmount: number;
  public dateAfter5days: any;
  public dateAfter1days: any;
  public listDateObj: any;
  
  constructor(
    public customerService: CustomerService,
    private router: Router,
    private userService: UserService,
    private modalService: ModalService,
    private privacyPolicyService: PrivacyPolicyService
  ) {}

  ngOnInit() {
    const appId = this.userService.applicationId;

    this.loan$ = this.customerService.getApplicationLoan(appId).pipe(shareReplay(1));
    this.paymentSchedule$ = this.customerService.getPaymentSchedule(appId).pipe(shareReplay(1));

    forkJoin({
      loan: this.loan$,
      payments: this.paymentSchedule$,
      holdCard: this.customerService.getHoldCard(appId),
      app: this.userService.getApplication(appId),
      user: this.userService.profiles(),
      dateAfter5days: this.customerService.getPaymentDateAfter(appId, 5),
      dateAfter1days: this.customerService.getPaymentDateAfter(appId, 1)
    }).subscribe(({payments, holdCard, app, user, dateAfter5days, dateAfter1days}) => {
      this.loading$.next(false);
      this.paymentAmount = payments[0].payment;
      this.balanceAmount = payments[1].starting_balance;
      this.application = app;
      this.emailVerified$.next(user.email_verified);
      this.username = user.username;
      this.email = user.email;
      this.holdCard = holdCard;
      this.holdCardType = CARD_TYPE_SHORT[holdCard.card_type];
      this.holdCardLastFour = holdCard.number.slice(-4);
      this.progressBar = false;
      this.holdAmount = this.application && this.application.amount > this.application.available_cash_advance ? this.application.available_cash_advance : this.application.amount;
      this.listDateObj = dateAfter5days;
      this.listDateObj.push(dateAfter1days[0]);
    });

    const videoOptions = {
      width: '100%'
    };
    this.videoComponent = videojs(this.videoElement.nativeElement, videoOptions);
    this.videoComponent.on('timeupdate', () => {
      if (!this.videoComponent.seeking()) {
        this.supposedCurrentTime = this.videoComponent.currentTime();
      }
    });
    // prevent user from seeking
    this.videoComponent.on('seeking', () => {
      const delta = this.videoComponent.currentTime() - this.supposedCurrentTime;
      if (Math.abs(delta) > 0.01) {
        this.videoComponent.currentTime(this.supposedCurrentTime);
      }
    });
    // delete the following event handler if rewind is not required
    this.videoComponent.on('ended', () => {
      // reset state in order to allow for rewind
      this.endedVideo$.next(true) ;
      this.supposedCurrentTime = 0;
    });
    // TODO: remove in prod
    this.endedVideo$.next(true);
  }

  get allAgreementChecked(): boolean {
    return this.holdCardAgreement.value && this.loanAgreement.value && this.agreed.value;
  }

  public resendLink() {
    this.emailVerificationLinkSent$.next(true);
    return this.userService.change({ username: this.username }).subscribe();
  }

  public checkVerification() {
    this.emailVerificationLoading$.next(true);
    this.userService.profiles().pipe(delay(1200))
    .subscribe(user => {
      if (!user.email_verified) {
        this.emailVerificationFailedOnce$.next(true);
      }
      this.emailVerified$.next(user.email_verified);
      this.emailVerificationLoading$.next(false);
    }, err => console.log('http error', err)
    );
  }

  public onSubmitted(): void {
    this.requestPending = true
    this.spinner = true;
    forkJoin([
      this.userService.processApplication({ ...this.application, step: 12 }),
      this.userService.saveCurrentStepNumber(12, this.application.id),
      this.privacyPolicyService.saveConsent(EConsent.Hold),
      this.privacyPolicyService.saveConsent(EConsent.UsaEpay),
      this.privacyPolicyService.saveConsent(EConsent.CreditPull)
    ]).subscribe(() => {
      this.router.navigateByUrl('final/document');
    }, err => console.log('http error', err)
    );
  }

  public openFaq() {
    this.modalService.open(FaqPaymentCycleModalComponent);
  }
}
