import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private dialog: MatDialog) {}

  private _error(error: any, operationName: string): void {
    alert(
      `Error: ${operationName}
${JSON.stringify(error, null, 4)}`
    );
  }

  codeError(error: any, operationName: string): void {
    this._error(error, operationName);
  }

  serverOperationSuccess(message: string): void {
    alert(message);
  }

  serverOperationError(error: any, operationName: string): void {
    this._error(error, operationName);
  }
}
