import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { IInfoModalData, InfoModalComponent } from '../../entry/info-modal/info-modal.component';
import { IBankAccount, IHoldCard } from '../../models/customer.model';
import { PasswordRecoveryModalComponent } from '../../entry/password-recovery-modal/password-recovery-modal.component';
import { SignInModalComponent } from '../../entry/sign-in-modal/sign-in-modal.component';
import { ValidateSSNModalComponent } from '../../entry/validate-ssn-modal/validate-ssn-modal.component';
import { ChangePasswordModalComponent } from '../../entry/change-password-modal/change-password-modal.component';
import { AddBankAccountModalComponent } from '../../entry/add-bank-account-modal/add-bank-account-modal.component';
import { DelayPaymentModalComponent } from '../../entry/delay-payment-modal/delay-payment-modal.component';
import { ChangeAchModalComponent, IChangeAchModalOutput } from '../../entry/change-ach-modal/change-ach-modal.component';
import { ChangeCreditCardModalComponent, IChangeCreditCardModalOutput } from '../../entry/change-credit-card-modal/change-credit-card-modal.component';
import { ResetOfferCodeModalComponent } from '../../entry/reset-offer-code-modal/reset-offer-code-modal.component';
import { ContactsModalComponent } from '../../entry/contacts-modal/contacts-modal.component';
import { AddCreditCardModalComponent } from '../../entry/add-credit-card-modal/add-credit-card-modal.component';
import { TwoFactorModalComponent } from '../../entry/two-factor-modal/two-factor-modal.component';
import { EditCreditCardModalComponent } from '../../entry/edit-credit-card-modal/edit-credit-card-modal.component';
import { IConfirmModalData, ConfirmModalComponent } from '../../entry/confirm-modal/confirm-modal.component';
import { ApexpayTermsComponent } from '../../entry/apexpay-terms/apexpay-terms.component';
import { ConfirmManualLinkModalComponent } from '../../entry/confirm-manual-link-modal/confirm-manual-link-modal.component';
import { ComponentType } from '@angular/cdk/portal';

export interface ISignInResult {
  token?: string;
  action?: string;
  customer?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  open<T = any>(component: ComponentType<any>, data: any = null, config?: MatDialogConfig): Observable<T> {
    const dialogRef = this.dialog.open(component, { data, ...config });
    return dialogRef.afterClosed();
  }

  openSignIn(withRecaptcha: boolean): Observable<ISignInResult> {
    const dialogRef = this.dialog.open(SignInModalComponent, { data: { withRecaptcha } });
    return dialogRef.afterClosed();
  }

  openRecoveryPassword(): Observable<boolean> {
    const dialogRef = this.dialog.open(PasswordRecoveryModalComponent);
    return dialogRef.afterClosed();
  }

  openInfo(data: IInfoModalData): Observable<any> {
    const dialogRef = this.dialog.open(InfoModalComponent, { data });
    return dialogRef.afterClosed();
  }

  openChangePassword() {
    const dialogRef = this.dialog.open(ChangePasswordModalComponent);
    return dialogRef.afterClosed();
  }

  openValidateSsnModal(customerId: number) {
    const dialogRef = this.dialog.open(ValidateSSNModalComponent, {
      data: { customerId }
    });
    return dialogRef.afterClosed();
  }

  openAddNewCreditCard(): Observable<IHoldCard> {
    const dialogRef = this.dialog.open(AddCreditCardModalComponent);
    return dialogRef.afterClosed();
  }

  openDelayPayment(data: any): Observable<moment.Moment> {
    const dialogRef = this.dialog.open(DelayPaymentModalComponent, { data });
    return dialogRef.afterClosed();
  }

  openChangeAch(): Observable<IChangeAchModalOutput> {
    const dialogRef = this.dialog.open(ChangeAchModalComponent);
    return dialogRef.afterClosed();
  }

  openChangeCreditCard(): Observable<IChangeCreditCardModalOutput> {
    const dialogRef = this.dialog.open(ChangeCreditCardModalComponent);
    return dialogRef.afterClosed();
  }

  openResetOfferCode(): Observable<any> {
    const dialogRef = this.dialog.open(ResetOfferCodeModalComponent);
    return dialogRef.afterClosed();
  }

  openContacts(): Observable<any> {
    const dialogRef = this.dialog.open(ContactsModalComponent);
    return dialogRef.afterClosed();
  }

  openTwoFactor(phoneNumber: string): Observable<any> {
    const dialogRef = this.dialog.open(TwoFactorModalComponent, {
      data: { phoneNumber },
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openEditCreditCard(data?): Observable<any> {
    const dialogRef = this.dialog.open(EditCreditCardModalComponent, { data });
    return dialogRef.afterClosed();
  }

  openConfirm(data: IConfirmModalData): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmModalComponent,  { data });
    return dialogRef.afterClosed();
  }

  openApexpayTerms(): Observable<any> {
    const dialogRef = this.dialog.open(ApexpayTermsComponent);
    return dialogRef.afterClosed();
  }

  openConfirmManualPayments(): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmManualLinkModalComponent);
    return dialogRef.afterClosed();
  }
}
