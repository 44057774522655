<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <div class="al-modal-title">
      Two-factor Auth
    </div>
    <form
      [formGroup]="form"
      class="form"
      [class.form_loading]="loading$ | async"
    >
      <app-radio-group
        label=" "
        formControlName="option"
        direction="column"
        [loading]="loading$ | async"
        [options]="options"
      >
      </app-radio-group>
    </form>
    <div class="form__bottom-controls">
      <app-button
        class="form__bottom-btn"
        [isDisabled]="form.invalid"
        (pressed)="onSubmit()"
      >Submit</app-button>
      <app-button
        class="form__bottom-btn"
        color="white"
        (pressed)="dialogRef.close()"
      >Cancel</app-button>
    </div>
  </div>
</div>
