import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocuSignService {

  accessToken: string;
  rootUrl = 'https://account-d.docusign.com';
  integrationId = 'f750b3f8-1c80-4b45-bcd2-e476ecee711b';
  redirectUrl = 'http://localhost:4200/docusign';

  constructor() {}

  goToSignIn() {
    const href = `${this.rootUrl}/oauth/auth?response_type=token&scope=signature&client_id=${this.integrationId}&redirect_uri=${this.redirectUrl}`;
    window.location.href = href;
  }
}
