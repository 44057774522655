<app-header></app-header>
<div class="content">
  <div *ngIf="canGoBack" class="button-wrapper">
    <app-button class="button-wrapper__button" [routerLink]="['/final/payments']">Go back</app-button>
  </div>
  <h1><i>ApexPay</i>/Plaid Terms of Use</h1>
  <p>
    ApexLend uses Plaid, a third-party provider, to link to your bank account. 
    The link provides us with your bank account number, routing number, account balance, and personally identifying information such as name, 
    address, email, and phone number. 
    By signing up for <i>ApexPay</i> you grant ApexLend and Plaid the right, power, 
    and authority to access and transmit such personal and financial information from your linked designated account. 
    You acknowledge and agree that ApexLend may access this information from time to time to the extent necessary to enable functionality of <i>ApexPay</i> 
    and other features on our website, or for marketing, research, and analysis. 
    We will handle this information in accordance with our <a [routerLink]="['/privacy']">Privacy Policy</a>. 
    ApexLend will access the name and contact information on the account for identity checks, research, and analysis. 
    ApexLend will also access your bank account balance just prior to debiting your scheduled payment to confirm sufficient funds exist at the time we check. 
    ApexLend will never store or have access to your bank sign-in credentials and any and all data and information is sent
    and stored in accordance with our <a [routerLink]="['/privacy']">Privacy Policy</a>.  
  </p>

  <h2><i>ApexPay</i> Preauthorized Automated Payment Plan:</h2>
  <p>
    This plan simplifies the repayment of your loan. 
    With <i>ApexPay</i> preauthorized automatic payments, we deduct your loan payments electronically from your designated checking or savings* 
    account so we receive your payment timely. 
    By using <i>ApexPay</i> automatic payments, and by maintaining sufficient collected funds in your designated bank account to make those payments, 
    you receive these benefits as long as you maintain <i>ApexPay</i>:
  </p>
  <ul class="list">
    <li class="list__item">Your payments are always timely without having to take any further action.</li>
    <li class="list__item">You’ll either never incur a late charge, or ApexLend will waive them.</li>
    <li class="list__item">You’ll either never incur a returned payment fee from ApexLend, or ApexLend will waive them.</li>
    <li class="list__item">As an added bonus, you’ll receive a 0.25% interest rate “discount” described below.</li>
  </ul>

  <p>
    We apply your interest rate “discount” with each automatic payment as a credit directly against your then outstanding principal loan balance. 
    This will reduce your outstanding principal balance by the amount of the discount and reduce the amount of interest paid over the life of the loan. 
    However, if <i>ApexPay</i> automatic payments are canceled at any time after repayment begins, 
    the corresponding interest rate “discount” will be discontinued until <i>ApexPay</i> automatic payments are reestablished. 
    The interest rate “discount” is effective the day after the first payment is made using <i>ApexPay</i> automatic payments during the repayment period. 
    If you have questions about how the interest rate “discount” will impact an individual loan, you can contact us at 1-800-____, 
    Monday through Friday, ____ a.m. to ____ p.m., PDT or PST, or you can view your payment schedule on our website with and without <i>ApexPay</i>.
  </p>
  <p>
    Usually around 3:00 p.m., PDT or PST, the day before initiating each installment payment electronic debit on the day before it is due, 
    we check your designated account balance to determine that you have sufficient collected funds available for 
    that payment (a “Designated Account Balance Check”). If there is a collected funds insufficiency, we will not initiate the debit. 
    If an <i>ApexPay</i> automatic electronic debit is returned, or if we had to cancel it, because of insufficient collected funds, 
    it will be resubmitted up to two more times in an attempt to process the payment. If we are still unable to process the automatic payment, 
    and the loan becomes past due, we will notify you of your designated account insufficiency by email and text message, 
    but may also continue to perform Designated Account Balance Checks and if sufficient funds become available, we will process an electronic debit. 
    Once notified of a funds insufficiency, you will then have three options to cure the insufficiency (i) make a curing cash deposit 
    or a funds transfer from another of your accounts into your designated account; (ii) go to the ApexLend website 
    and authorize us to initiate a one-time electronic debit from another of your bank accounts other than your designated account; 
    or (iii) direct us to charge the unpaid installment payment to your Credit Card Account. 
    The loan will need to be brought current using one of these payment options in order for you to reauthorize 
    and resume automatic payments, thereby reinstating your interest rate “discount.” 
    If the loan remains past due for more than 5 banking <u>days after the due date</u>, your credit card will be charged for the payment due. 
    If your credit card is charged for three (3) consecutive payments, ApexLend reserves the right to cancel the <i>ApexPay</i> automatic payments, 
    and to charge the remaining unpaid balance of your loan to your Credit Card Account.
  </p>
  <p>
    * Excess Activity Fees may apply if you exceed these federally mandated transaction limits for savings accounts: 
    Three withdrawals in a month by check, draft, point-of-sale purchase, check card, 
    or other similar withdrawal payable to a third party. A total of six transfers and/or withdrawals in a month including: 
    <i>ApexPay</i> automatic payments, pre-authorized or automated transfers (including overdraft protection and payments to third parties), 
    and transfers made online or by telephone. For more information, please refer to your account agreement with your financial institution.
  </p>
  <h2>
    Authorization for the <i>ApexPay</i> Automated Payment Plan
  </h2>
  <p>
    You agree to make your regular scheduled loan payments, 
    you authorize ApexLend to initiate automated clearing house (ACH) electronic debits to the checking or savings account you specify prior to, 
    on, or after the scheduled payment due dates for your loan (or the next business day if the then due date is not on a bank business day). 
    You understand, acknowledge, and agree that this authorization will remain in effect until your cancel it on the ApexLend website by turning off 
    <i>ApexPay</i> or ApexLend terminates at its discretion or as otherwise provided in your Loan Agreement or 
    Supplemental Agreement and Disclosures. 
    You also acknowledge that you must turn off <i>ApexPay</i> at least 3 banking days prior to the next payment due date for the termination to be in effect. 
    You also authorize ApexLend to initiate credit entry (disbursement) transactions into the account you specify when and if requested by you. 
    If any debit entry transaction is rejected for any reason, you acknowledge and agree that such rejection does not cancel your 
    authorization for recurring debit entry transactions. ApexLend may in its discretion continue to process transactions during 
    the intervals thereafter in accordance with the provisions herein.  Because this is an electronic transaction, funds may be 
    withdrawn from your account prior to, on, or after the scheduled payment date. You acknowledge and agree that ApexLend 
    shall bear no liability for any losses that you may incur as a result of any delay in the actual date your account is debited. 
    In the case of the payment being rejected for Non-Sufficient Funds (NSF), you acknowledge that ApexLend may at its discretion 
    attempt to process the charge again. You acknowledge that the origination of ACH transactions to and from your account must 
    comply with the provisions of U.S. law and that you will not dispute ApexLend’s ACH debits with your bank or the automated clearing 
    house processing such debits, provided the transaction corresponds to the terms indicated in this Authorization, your Loan Agreement, 
    and the Supplemental Agreement and Disclosures. 
    Because all of your regular installment payments except the final payment are 
    being made by preauthorized ACH debits in the same amount, you agree that no prior notification will be provided to you before those payments are made. 
    However, because your final loan payment will be a different amount from all of the others, 
    you will receive notice from us at least 10 days prior to that payment being collected.
  </p>
  <p>
    You further agree to be bound by NACHA rules and regulations and the rules 
    set by your financial institution for authorized electronic fund transfers. 
    You are responsible for any and all fees charged by your financial institution associated with these transactions. 
    To cancel a transaction, you need to notify ApexLend at least three (3) banking days prior to the scheduled date of payment 
    by logging into your account and cancelling such payment by cancelling <i>ApexPay</i>.  ApexLend bears no liability for any losses 
    that you may incur as a result of any delay in the actual date your account is debited. 
  </p>
  <p>
    You agree to update all changes to your bank account by logging into your ApexLend account 
    and making such changes for both one-time and recurring transfers/payments. 
    ApexLend reserves the right to change these terms or terminate the <i>ApexPay</i> automated recurring payment program at any time. 
    Notice may be given on the website, by email, or other methods.
  </p>
  <div *ngIf="canGoBack" class="button-wrapper button-wrapper--bottom">
    <app-button class="button-wrapper__button" [routerLink]="['/final/payments']">Go back</app-button>
  </div>
</div>
<app-footer></app-footer>