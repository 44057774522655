import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IPrivacyContent } from '../../models/privacy.model';
import { STATES } from '../../../shared/constants/select-data.constants';
import { UserService } from './../../../user/services/user/user.service';
import { PrivacyPolicyService } from './../../services/privacy-policy/privacy-policy.service';
import { californiaPrivacyContent } from './california-privacy';
import { nonStatePrivacyContent } from './non-state-privacy';
import { texasPrivacyContent } from './texas-privacy';
import { vermontPrivacyContent } from './vermont-privacy';
import { CustomerService } from '../../../shared/services/customer/customer.service';
import { NavigationService } from '../../services/navigation.service';
import { EConsent } from 'src/app/shared/models/enums/consent.enum';

@Component({
  selector: 'app-privacy-policy-container',
  templateUrl: './privacy-policy-container.component.html',
  styleUrls: ['./privacy-policy-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyContainerComponent implements OnInit {
  public allRadioButtonsChecked = false;
  public STATES = STATES;
  public selectedState: string;
  public selectedStateContent: IPrivacyContent;
  public consentsReady$ = this.privacyPolicyService.consentsReady$;

  private texasPrivacyContent: IPrivacyContent = texasPrivacyContent;
  private vermontPrivacyContent: IPrivacyContent = vermontPrivacyContent;
  private nonStatePrivacyContent: IPrivacyContent = nonStatePrivacyContent;
  private californiaPrivacyContent: IPrivacyContent = californiaPrivacyContent;
  private dtiRoute = ['steps', 'dti'];

  constructor(
    private privacyPolicyService: PrivacyPolicyService,
    private router: Router,
    public userService: UserService,
    public customerService: CustomerService
  ) {}

  ngOnInit() {
    this.selectedStateContent = this.texasPrivacyContent;
    if (!this.userService.isLoggedInForSteps) {
      this.userService.logout();
    }
    this.selectedState = this.customerService.customerState;
  }

  public onChangedState(state: string) {
    this.allRadioButtonsChecked = false;
    switch (state) {
      case 'TX':
        this.selectedStateContent = this.texasPrivacyContent;
        break;
      case 'VT':
        this.selectedStateContent = this.vermontPrivacyContent;
        break;
      case 'CA':
        this.selectedStateContent = this.californiaPrivacyContent;
        break;
      default:
        this.selectedStateContent = this.nonStatePrivacyContent;
    }
  }

  public onSavePdf() {
    window.open(this.selectedStateContent.pagePdf);
  }

  public onSaveMailInForm() {
    window.open(this.selectedStateContent.mailInFormPdf);
  }

  public onChangedRadioButtons(allChecked: boolean) {
    this.allRadioButtonsChecked = allChecked;
  }

  public onAccepted() {
    this.privacyPolicyService.saveConsent(EConsent.Privacy).subscribe(() => {
      this.router.navigate(this.dtiRoute);
    });
  }
}
