import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/user/services/user/user.service';
import { tap } from 'rxjs/operators';
import { ModalService } from '../services/modal/modal.service';

@Injectable()
export class RateLimitingInterceptor implements HttpInterceptor {
  constructor(
    private modalService: ModalService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 429) {
              this.modalService.openInfo({
                title: 'Error',
                description: 'Too many request. Try later.',
                buttonText: 'OK',
              });
              return Observable.throw(err);
            }
          }
        }
      )
    );
  }
}
