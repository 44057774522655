<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<div class="form-title" [class.loading]="loading$ | async">
  <span>Debt to Income</span>
  <img 
    class="form-title--hint"
    src="assets/img/icons/info--blue.svg"
    (click)="onTitleHintClick()"
  >
</div>

<div class="table table--2cols" [class.loading]="loading$ | async">
  <div *ngIf="(offer$ | async)?.dti > 0" style="order:1; font-weight:500;" class="table-cell">At Mortgage Closing on {{ (offer$ | async)?.asofDt | date:'MM-yyyy'}}</div>
  <div style="order:2; font-weight:500;" class="table-cell">Current</div>
  <div *ngIf="(offer$ | async)?.dti > 0" style="order:3; font-weight:500;" class="table-cell">Change</div>

  <div *ngIf="(offer$ | async)?.dti > 0" style="order:1;" class="table-cell value-cell">{{ (offer$ | async)?.dti | number: '1.0-2' }}%</div>
  <div style="order:2;" class="table-cell value-cell">{{ dtiCurrent$ | async | number: '1.0-2' }}%</div>
  <div *ngIf="(offer$ | async)?.dti > 0" style="order:3;" class="table-cell value-cell">{{ (dtiCurrent$ | async) - (offer$ | async)?.dti | number: '1.0-2' }}%</div>
</div>

<div class="warning" *ngIf="((dtiCurrent$ | async) - (offer$ | async)?.dti) > 10 && !(loading$ | async)">Change is greater than 10%. Are you sure?</div>

<div
  class="checkbox"
  [class.checkbox_loading]="loading$ | async"
>
  <mat-checkbox
    class="checkbox__agreed"
    [checked]="agreed$ | async"
    (change)="toggleAgreed($event.checked)"
    [disabled]="!requiredAccepted || (loading$ | async)"
    id="termsAndCondition"
  >
    <div class="checkbox__agreed__label"
    >To continue you need to agree with all 3 below:</div>
  </mat-checkbox>

  <div class="checkbox__links">
    <div class="checkbox__link">
      <a class="checkbox__link__text" id="privacyPolicy" routerLink="/privacy">ApexLend Privacy Policy</a>
      <div class="checkbox__link__icon" *ngIf="(privacyConsent$ | async)?.accepted"></div>
    </div>
    <div class="checkbox__link">
      <a class="checkbox__link__text" id="termsOfUse" routerLink="/content/terms">Terms Of Use</a>
      <div class="checkbox__link__icon" *ngIf="(termsConsent$ | async)?.accepted"></div>
    </div>
    <div class="checkbox__link">
      <a class="checkbox__link__text" id="esignActConsent" routerLink="/content/transactions">ESIGN Act Consent</a>
      <div class="checkbox__link__icon" *ngIf="(esignConsent$ | async)?.accepted"></div>
    </div>
  </div>
</div>

<p
  class="warning"
  [class.warning_loading]="loading$ | async"
>
  Please make sure all your information is correct.
  Once you agree and see your rate on the next page,
  you will not be able to go back. You can use the step bubble and the previous step link
  at the top to go back and make corrections before you go on to the next page
</p>

<app-button 
class="submit-btn" 
(pressed)="onSubmitted()" 
[isDisabled]="!(agreed$ | async)" 
[loading]="loading$ | async"
[pending]="requestPending"
[spinner]="spinner"
[id]="'debtIncomeSubmit'"
>
  {{ (agreed$ | async) ? 'Submit' : 'Agree and see your rate' }}
</app-button>
