<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<app-form-title [loading]="loading$ | async">Credit Card Payoff and Hold Information</app-form-title>
<form [formGroup]="form" class="form">
  <app-base-form-layout class="form-layout form-layout--top">
    <app-select
      formControlName="desired_payoff_account"
      label="Pay Off Credit Card Type"
      class="input"
      errorMessage="Incorrect Pay Off Credit Card Type"
      [options]="cardTypesPayOff"
      [loading]="loading$ | async"
      [hasHint]="true"
      (hintClick)="onHintClick('pay-off-credit-card')"
      leftColumn
      [id]="'desired_payoff_account'"
      ngDefaultControl
    ></app-select>
    <app-input
      formControlName="amount"
      label="Amount to Pay Off"
      class="input"
      type="tel"
      [setTouched]="payoffAmountTouched$ | async"
      [errorMessage]="getCardError('payoff')"
      [dropSpecialCharacters]="true"
      prefix="$"
      ngxMask="comma_separator.2"
      [loading]="loading$ | async"
      rightColumn
      [id]="'amount'"
      ngDefaultControl
    ></app-input>
  </app-base-form-layout>
  <p class="help-text help-text--hold">
    Please provide the following information about the<br>
    credit card that you have selected for hold
  </p>
  <app-base-form-layout class="form-layout">
    <mat-checkbox
      class="checkbox checkbox--same-card"
      [class.checkbox_loading]="loading$ | async"
      [checked]="samePayOffCard$ | async"
      (change)="toggleSamePayOffCard($event.checked)"
      [disabled]="(loading$ | async) || isPayoffCardIncompatibleForHold"
      leftColumn
      [id]="'isPayoffCardIncompatibleForHold'"
    >
      <div class="checkbox__label"
      >Same as Pay Off Credit Card</div>
    </mat-checkbox>
    <app-input
      formControlName="total_credit_limit"
      label="Total Credit Limit"
      class="input"
      prefix="$"
      ngxMask="comma_separator.2"
      [loading]="loading$ | async"
      [dropSpecialCharacters]="true"
      [id]="'total_credit_limit'"
      [validateErrors]="[
        form.get('total_credit_limit').errors?.parseMax && 'Maximum value is $1,000,000.',
        form.errors?.totalCreditLimitLessThenPayoff && 'Total Credit Limit should be equal or more than Amount to Pay Off.',
        form.errors?.totalCreditLimitLessThenHoldCardLimit && 'Total Credit Limit should be equal or more than Available Credit.'
      ]"
      leftColumn
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="hold_card_limit"
      label="Available Credit"
      class="input"
      type="tel"
      [errorMessage]="getCardError('hold')"
      prefix="$"
      ngxMask="comma_separator.2"
      [setTouched]="availableCreditTouched$ | async"
      [loading]="loading$ | async"
      [isDisabled]="samePayOffCard$ | async"
      [hasHint]="true"
      [dropSpecialCharacters]="true"
      (hintClick)="onHintClick('available-credit')"
      leftColumn
      [id]="'hold_card_limit'"
      ngDefaultControl
    ></app-input>
    <app-select
      formControlName="desired_hold_card"
      label="Hold Credit Card Type"
      class="input"
      errorMessage="Incorrect Hold Credit Card Type"
      [options]="cardTypesHold"
      [isDisabled]="samePayOffCard$ | async"
      [loading]="loading$ | async"
      [hasHint]="true"
      (hintClick)="onHintClick('hold-credit-card')"
      rightColumn
      [id]="'desired_hold_card'"
      ngDefaultControl
    ></app-select>
    <app-input
      formControlName="cash_advance_limit"
      label="Cash Advance Limit"
      class="input"
      prefix="$"
      ngxMask="comma_separator.2"
      [loading]="loading$ | async"
      [id]="'cash_advance_limit'"
      [dropSpecialCharacters]="true"
      [validateErrors]="[
        form.get('cash_advance_limit').errors?.parseMax && 'Maximum value is $1,000,000.',
        form.errors?.cashAdvanceLimit20Percent && 'Cash Advance Limit should be at least 20% of the Amount to Pay Off.',
        form.errors?.cashAdvanceLimitLessThenAvailableCash && 'Cash Advance Limit should be equal or more than Available Cash Advance.',
        form.errors?.cashAdvanceLimitLessThenTotalCreditLimit && 'Cash Advance Limit should be less or equal to Total Credit Limit.'
      ]"
      rightColumn
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="available_cash_advance"
      label="Available Cash Advance"
      class="input"
      prefix="$"
      ngxMask="comma_separator.2"
      [loading]="loading$ | async"
      [hasHint]="true"
      [id]="'available_cash_advance'"
      (hintClick)="onHintClick('available-cash-advance')"
      [dropSpecialCharacters]="true"
      [validateErrors]="[
        form.get('available_cash_advance').errors?.parseMax && 'Maximum value is $1,000,000.',
        form.errors?.availableCash20Percent && 'Available Cash Advance should be at least 20% of the Amount to Pay Off.',
        form.errors?.availableCashAdvanceLessThenTotalCreditLimit && 'Available Cash Advance should be less or equal to Total Credit Limit.'
      ]"
      rightColumn
      ngDefaultControl
    ></app-input>
  </app-base-form-layout>
</form>

<app-button
  styleType="form-submit"
  (pressed)="onSubmit()"
  [loading]="loading$ | async"
  [pending]="requestPending"
  [spinner]="spinner"
  [id]="'submit_btn'"
>Submit</app-button>
