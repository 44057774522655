<app-header>
  <app-button
    class="header-button"
    link="/profile/loans"
    icon="loans"
    iconPosition="left"
    color="white"
    [hideTextOnTablet]="true"
    [matTooltip]="'Loan'"
    >Loans</app-button
  >
  <app-button
    class="header-button"
    link="/profile/information"
    icon="account"
    iconPosition="left"
    color="white"
    [hideTextOnTablet]="true"
    [matTooltip]="'Account'"
    >Account</app-button
  >
  <app-button
    class="header-button"
    link="/profile/support"
    icon="headphones"
    iconPosition="left"
    color="white"
    [hideTextOnTablet]="true"
    [matTooltip]="'Help Center'"
    >Help Center</app-button>

  <app-button
    class="header-button"
    icon="exit-blue"
    iconPosition="left"
    color="white"
    (pressed)="onLoggedOut()"
    [hideTextOnTablet]="true"
    [matTooltip]="'Log Out'"
    >Log Out</app-button
  >
</app-header>
<div class="page-container container">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
