import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssn'
})
export class SsnPipe implements PipeTransform {
  transform(value: string, showMask: boolean): string {
    // if (value && value.length === 9 && showMask) {
    //   return `•••-••-${value.substr(5)}`;
    // }
    return value;
  }
}
