<div class="error-modal">
  <app-close-button class="close-button" (pressed)="onClose()"></app-close-button>
  <div *ngIf="data?.title" class="error-modal__title">
    {{ data?.title }}
  </div>
  <div class="error-modal__description">
    <div *ngFor="let row of description" class="error-modal__description-row">
      {{ row }}
    </div>
  </div>
  <button *ngIf="data?.buttonText" type="button" class="error-modal__btn al-button al-button-blue" [mat-dialog-close]>
    {{ data?.buttonText }}
  </button>
  <div *ngIf="data?.imageUrl" class="error-modal__image" [style.backgroundImage]="'url(' + data?.imageUrl + ')'"></div>
</div>
