<div class="select-input-wrapper" (clickOutside)="onClickOutside()" clickOutsideEvents="mousedown">
  <app-input
    [isDisabled]="loading || isDisabled"
    [forceError]="forceError"
    [placeholder]="placeholder"
    [label]="label"
    [hidden]="isPreview || hidden"
    [maxLength]="maxLength"
    [errorMessage]="errorMessage"
    [prefix]="prefix"
    [ngxMask]="ngxMask"
    [type]="type"
    [suffix]="suffix"
    [withIndicator]="withIndicator"
    [withToggleSecret]="withToggleSecret"
    [groupLabel]="groupLabel"
    [collapsed]="collapsed"
    [warning]="warning"
    [isPreview]="isPreview"
    [isSecretPreview]="isSecretPreview"
    [loading]="loading"
    [isSecurityCodeInfo]="isSecurityCodeInfo"
    [setTouched]="setTouched"
    [model]="model"
    [ngxDropSpecialCharacters]="false"
    [isRequired]="isRequired"
    (changed)="changed.emit($event)"
    (keyPressed)="keyPressed.emit($event)"
    (focused)="onFocused($event)"
    (focusOuted)="onFocusedOut()"
    [id]="id"
  ></app-input>

  <div
    *ngIf="options && options.length && isOptionsVisible"
    class="select-input-wrapper__options"
  >
    <div
      *ngFor="let option of options"
      class="select-input-wrapper__option"
      (mousedown)="selectOption(option)"
    >
      {{ option.label || option }}
    </div>
  </div>
</div>
