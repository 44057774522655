<button (click)="toggle()">Toggle</button>
<div class="links" [ngStyle]="{'display': !visible ? 'none' : ''}">
  <a
  class="link"
  *ngFor="let route of routes"
  [routerLink]="route.path" 
  [routerLinkActive]="route.path && 'link--active'"
  >
    {{ route.path }}
  </a>
</div>
