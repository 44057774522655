import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MakePaymentContainerComponent } from './containers/make-payment-container/make-payment-container.component';
import { MyLoansContainerComponent } from './containers/my-loans-container/my-loans-container.component';
import { NewHoldsAuthorizationContainerComponent } from './containers/new-holds-authorization-container/new-holds-authorization-container.component';
import { PaymentInfoContainerComponent } from './containers/payment-info-container/payment-info-container.component';
import { PaymentPersonalInfoContainerComponent } from './containers/payment-personal-info-container/payment-personal-info-container.component';
import { PersonalInfoContainerComponent } from './containers/personal-info-container/personal-info-container.component';
import { SupportContainerComponent } from './containers/support-container/support-container.component';
import { ProfileComponent } from './profile.component';
import {LoadGuard} from "../shared/guards/loan.guard";

export const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    canActivate: [LoadGuard],
    children: [
      {
        path: '',
        redirectTo: 'information',
        pathMatch: 'full'
      },
      {
        path: 'information',
        component: PaymentPersonalInfoContainerComponent,
        children: [
          {
            path: '',
            redirectTo: 'personal',
            pathMatch: 'full'
          },
          {
            path: 'personal',
            component: PersonalInfoContainerComponent
          },
          {
            path: 'payment',
            component: PaymentInfoContainerComponent
          }
        ]
      },
      {
        path: 'loans',
        component: MyLoansContainerComponent
      },
      {
        path: 'new-holds-authorization',
        component: NewHoldsAuthorizationContainerComponent
      },
      {
        path: 'make-payment',
        component: MakePaymentContainerComponent
      },
      {
        path: 'support',
        component: SupportContainerComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule {}
