import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/user/services/user/user.service';
import { Router } from '@angular/router';
import { Observable, concat, BehaviorSubject, forkJoin } from 'rxjs';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { CustomerService, ILoanPayment } from 'src/app/shared/services/customer/customer.service';
import { toArray } from 'rxjs/operators';
import { shareReplay, delay } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IHoldCard, ILoan, ILoanDocument, ISignedDocumentRequest } from 'src/app/shared/models/customer.model';
import { IApplication } from 'src/app/user/models/user.models';
import { CARD_TYPE_SHORT } from 'src/app/shared/constants/select-data.constants';

export interface IDocumentConsents {
  programDisclosure: boolean;
  saleAgreement: boolean;
}

@Component({
  selector: 'app-final-docu-sign',
  templateUrl: './final-docu-sign.component.html',
  styleUrls: ['./final-docu-sign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalDocuSignComponent implements OnInit {
  @Input() spinner: boolean;
  public requestPending = false;
  public progressBar: boolean = true;
  public username$: Observable<string>;
  public documents$ = new BehaviorSubject<ILoanDocument[]>([]);
  public paymentSchedule$: Observable<ILoanPayment[]>;
  public loan$: Observable<ILoan>;
  public documentUrls: SafeResourceUrl[];
  public formArray: FormArrayTyped<boolean> = new FormArray([]);
  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const appId = this.userService.applicationId;
    this.username$ = this.userService.getUsername();
    this.loan$ = this.customerService.getApplicationLoan(appId).pipe(shareReplay(1));
    this.paymentSchedule$ = this.customerService.getPaymentSchedule(appId).pipe(shareReplay(1));
    this.customerService.getLoanDocument().subscribe(resp => {
      this.documentUrls = resp.documents.map(i => this.sanitizer.bypassSecurityTrustResourceUrl(i.url));
      const controls = resp.documents.map(i => new FormControl(i.signed, Validators.requiredTrue));
      controls.forEach(c => this.formArray.push(c));
      this.documents$.next(resp.documents);
      this.progressBar = false;
    }, err => console.log('http error', err));
    
    this.loading$.next(false);
  }

  onLoggedOut() {
    this.userService.logout();
    this.router.navigateByUrl('/');
  }

  onSubmit() {
    this.requestPending = true;
    this.spinner = true;
    const signedDocs: ISignedDocumentRequest[] = this.documents$.value
      .filter(i => !i.signed)
      .map(i => ({document: i.document_id}));

    concat(
      this.customerService.postSignedDocument(signedDocs),
      this.userService.saveCurrentStepNumber(13, this.userService.applicationId)
    ).pipe(toArray())
     .subscribe(() => {
      this.router.navigateByUrl('/final/complete');
    }, (err) => {
      this.spinner = false;
      console.log('http error', err)
    });
  }
}
