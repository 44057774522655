import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from 'src/app/user/services/user/user.service';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StepsGuard implements CanActivate {

  constructor(
    private $user: UserService,
    private router: Router
  ) {}
  
  canActivate(route: ActivatedRouteSnapshot) {
    if (environment.debug && environment.debug.disableGuards) { return true; }
    return this.getLastStep().pipe(
      map(step => this.checkRoute(route, step)),
      tap(bool => { 
        if (!bool) {
          this.$user.resetSteps();
          this.router.navigate(['']); 
        }
      }));
  }

  checkRoute(route: ActivatedRouteSnapshot, currentStep: number): boolean {
    const routeStep = route.data && route.data.step;
    if (!routeStep) { return false; }
    if (currentStep >= 8 && routeStep < 8) { return false; }
    return true;
  }

  getLastStep(): Observable<number> {
    if (this.$user.maxStep) {
      return of(this.$user.maxStep);
    } else {
      return this.$user.getApplication().pipe(map(app => app.step));
    }
  }
}
