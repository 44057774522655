<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <app-close-button class="close" (pressed)="dialogRef.close()"></app-close-button>
    <div class="al-modal-title">
      <i>ApexPay</i>/Plaid Terms of Use
    </div>
    <div class="modal-content" mat-dialog-content>
      <p class="description">
        ApexLend uses Plaid a third-party provider for linking to your bank account.
        The link provides us with your bank account number, routing number, account balance,
        and personally-identifying information such as name, address, email, and phone number.
        By signing up for <i>ApexPay</i> you grant ApexLend and Plaid the right, power, and authority to
        access and transmit such personal and financial information from the account your link herein.
        You acknowledge and agree that ApexLend may access this information from time to time to the
        extent necessary to enable functionality of <i>ApexPay</i> and other features on our website, or for marketing,
        research, and analysis. We will handle this information in accordance with our Privacy Policy.
        ApexLend will access the name and contact information on the account for identity checks, research, and analysis.
        ApexLend will also access your bank account balance just prior to debiting your scheduled payment to confirm
        sufficient funds at the time of the check. ApexLend will never store or have access to your bank sign-in
        credentials and any and all data and information is sent and stored in accordance with our Privacy Policy.
      </p>
    </div>
  </div>

</div>

