import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StepsService } from 'src/app/steps/services/steps/steps.service';
import { InjectingLogicFunctionMethods } from '../../../shared/models/injecting-logic-function';
import { UserService } from '../../services/user/user.service';
import { ModalService } from './../../../shared/services/modal/modal.service';
import { IOffer } from '../../models/api/response/login-response';
import { BehaviorSubject } from 'rxjs';
import {environment} from "../../../../environments/environment";

declare const videojs: any;

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainContainerComponent
  implements OnInit, OnDestroy, InjectingLogicFunctionMethods<'onEnterOfferCodeSubmit' | 'onSubscribeSubmit'> {
  @ViewChild('video', { static: true }) videoElement: ElementRef;
  private videoComponent: any;
  pausedOnDocumentVisibilityChange: boolean;
  playedOnDocumentVisibilityChange: boolean;
  videoStarted: boolean;
  videoPlaying$ = new BehaviorSubject(false);
  private playOnSwitchToAppBrowserTab = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private stepsService: StepsService
  ) {}

  ngOnInit(): void {
    const action = this.route.snapshot.queryParamMap.get('action');
    if (action === 'final') {
      // this.openFinalModal();
    }

    // Video.js library component not initialized at this time, so setTimeout used.
    const videoOptions = {
      width: '100%'
    };
    this.videoComponent = videojs(this.videoElement.nativeElement, videoOptions);

    const self = this;
    this.videoComponent.on('play', function() {
      if (self.playedOnDocumentVisibilityChange) {
        self.playedOnDocumentVisibilityChange = false;
      } else {
        self.videoStarted = true;
        self.videoPlaying$.next(true);
      }
    });
    this.videoComponent.on('pause', function() {
      if (self.pausedOnDocumentVisibilityChange) {
        self.pausedOnDocumentVisibilityChange = false;
      } else {
        self.videoStarted = false;
      }
    });
    this.videoComponent.on('ended', function() {
      self.videoPlaying$.next(false);
    });
  }

  ngOnDestroy(): void {
    this.videoComponent.off();
  }

  openFindCodeModal(): void {
    this.modalService.openInfo({ imageUrl: '/assets/img/find-code/find-code.png' }).subscribe(_ => null);
  }

  onDocumentVisibilityChange(): void {
    if (this.videoStarted) {
      if (document.hidden) {
        this.pausedOnDocumentVisibilityChange = true;
        this.videoComponent.pause();
      } else if (this.playOnSwitchToAppBrowserTab) {
        this.playedOnDocumentVisibilityChange = true;
        this.videoComponent.play();
      }
    }
  }

  onEnterOfferCodeSubmit(offerCode: string): void {
    if (offerCode) {
      this.userService.logout();
      this.userService.findMailOffer(true, offerCode).subscribe(
        (result) => {
          // TODO: check step if profile already filled
          if (result.step <= 3) {
            this.userService.customerId = result.customer;
            this.stepsService._goToStep(2);
          } else {
            this.openSignInModal(result, offerCode);
          }
        },
        () => this.modalService.openInfo({
          title: 'Invalid Offer Code',
          description: 'Offer Codes are 4 characters, alpha numeric, and case sensitive',
          buttonText: 'OK' })
      );
    }
  }

  openSignInModal(offer: IOffer, offerCode: string) {
    this.modalService
      .openSignIn(environment.withRecaptcha)
      .subscribe((res) => {
        if (!res) return;
        const matchProfile = offer.customer === this.userService.customerId;
        if (matchProfile) {
          if (offer.step === 99) {
            this.userService.setToken(res.token);
          }
          this.stepsService._goToStep(offer.step);
        } else {
          if (res.action === 'passwordRecovery') {
            this.modalService.openRecoveryPassword();
          } else if (res.action === 'resetOffer') {
            this.modalService.openResetOfferCode().subscribe(result => {
              if (!result) {
                return;
              }
              this.stepsService._goToStep(3);
            });
          } else {
            this.userService.logout();
            this.modalService.openInfo({
              title: 'Error',
              description: `The offer code ${offerCode} does not match login`,
              buttonText: 'OK'
            });
          }
        }
      });
  }

  onSubscribeSubmit(email: string): void {
    this.userService.subscribe({ email }).subscribe(
      (): void => {
        this.modalService.openInfo({
          title: 'Success',
          description: 'Thank you for signing up, we’ll keep you posted!',
          buttonText: 'Ok'
        });
      },
      (): void => {
        this.modalService.openInfo({
          title: 'We are sorry',
          description: 'You are already subscribed',
          buttonText: 'Ok'
        });
      }
    );
  }
}
