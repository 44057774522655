import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RouteListComponent } from '../components/route-list/route-list.component';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  constructor(private $dialog: MatDialog) {
    if (environment.debug && environment.debug.enableToggle) {
      this.$dialog.open(RouteListComponent, {
        hasBackdrop: false,
        closeOnNavigation: false,
        disableClose: false,
        position: {left: '0px', bottom: '0px'}
      });
    }
  }
}
