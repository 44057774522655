<div id="pdf-wrapper">
  <app-header></app-header>
  <div class="container page-container">
    <div class="controls">
      <div class="logotype">
        <div class="logotype__source"></div>
        <div class="logotype__text">
          <div class="logotype__title">ApexLend LLC</div>
          <div class="logotype__description">State Licenses</div>
        </div>
      </div>
      <app-button class="save-button" (pressed)="onSavePdf()">Save in PDF</app-button>
    </div>

    <div class="table table--2cols table--striped states-table">
      <div style="order:1;" class="table-cell title-cell title-line">STATE</div>
      <div style="order:2;" class="table-cell title-cell">California</div>
      <div style="order:3;" class="table-cell title-cell">Florida</div>
      <div style="order:4;" class="table-cell title-cell">Florida</div>
      <div style="order:5;" class="table-cell title-cell">North Carolina</div>
      <div style="order:6;" class="table-cell title-cell">North Carolina</div>
      <div style="order:7;" class="table-cell title-cell">Texas</div>
      <div style="order:8;" class="table-cell title-cell">Texas</div>
      <div style="order:9;" class="table-cell title-cell">Puerto Rico</div>
      <div style="order:10;" class="table-cell title-cell">Puerto Rico</div>

      <div style="order:1;" class="table-cell title-line">License Description</div>
      <div style="order:2;" class="table-cell">California Finance Lender (CFL) License #TBD</div>
      <div style="order:3;" class="table-cell">Florida Consumer Finance Company License CF9901358</div>
      <div style="order:4;" class="table-cell">Florida Chapter 687 – NO LICENSE REQUIRED</div>
      <div style="order:5;" class="table-cell">North Carolina Consumer Finance Act (CFA) License #TBD</div>
      <div style="order:6;" class="table-cell">
        North Carolina Interest Chapter Authority Loans – NO LICENSE REQUIRED
      </div>
      <div style="order:7;" class="table-cell">Texas Regulated Lender (Chapter 342) License #TBD</div>
      <div style="order:8;" class="table-cell">Texas Chapter 302 – NO LICENSE REQUIRED</div>
      <div style="order:9;" class="table-cell">Puerto Rico Small Loans License #TBD</div>
      <div style="order:10;" class="table-cell">Puerto Rico Financial Intermediary License #TBD</div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
