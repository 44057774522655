import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResponsiveText, ResponsiveTextNonResponsive } from '../../models/responsive-text';
import { UserService } from '../../services/user/user.service';
import { SignInService } from '../../services/sign-in.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationBarComponent {
  items: { text: ResponsiveText; url: string }[] = [
    {
      text: new ResponsiveTextNonResponsive('We Are Apex'),
      url: '/about'
    },
    {
      text: new ResponsiveTextNonResponsive('Help Center'),
      url: '/help'
    },
    {
      text: new ResponsiveTextNonResponsive('Contact Us'),
      url: '/contacts'
    }
  ];

  constructor(
    private signInService: SignInService,
    private userService: UserService,    
  ) {}

  public onSignIn() {
    this.userService.logout();
    this.signInService.initSignIn();
  }
}
