import { Component, OnInit, ChangeDetectionStrategy, ElementRef, Renderer2, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-faq-credit-card-modal',
  templateUrl: './faq-credit-card-modal.component.html',
  styleUrls: ['./faq-credit-card-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqCreditCardModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public linkId: string,
    private elRef: ElementRef,
    private render: Renderer2
  ) {}

  ngOnInit() {
    if (this.linkId) { this.scrollTo(this.linkId); }
  }

  scrollTo(linkId: string) {
    const element: HTMLElement = this.elRef.nativeElement;
    const linkElement = element.querySelector(`[link='${linkId}']`);
    if (linkElement) {
      linkElement.scrollIntoView({block: "center", behavior: "smooth"});
      this.render.addClass(linkElement, 'line--highlight');
    }
  }

}
