<div id="pdf-wrapper">
  <app-header></app-header>
  <div class="container">
    <div class="headline">
      <div class="title">Security and Privacy Policy</div>
      <div class="controls">
        <app-button
          class="controls__button"
          fadedIfActive="true"
          (pressed)="onAccepted()"
          *ngIf="(consentsReady$ | async) && (userService.isLoggedInForSteps || userService.isLoggedIn)"
        >Accept</app-button>
        <app-button class="controls__button" (pressed)="onSavePdf()">Save in PDF</app-button>
      </div>
    </div>

    <div class="select">
      <app-select
        class="select__control"
        [label]="userService.isLoggedInForSteps || userService.isLoggedIn ? 'Your State' : 'Choose Your State'"
        [options]="STATES"
        [initialValue]="selectedState || 'CA'"
        (changed)="onChangedState($event)"
        [isDisabled]="!!selectedState"
      ></app-select>
      <div
        class="select__warning"
        *ngIf="!selectedState"
      >Please change State here or your residence state. The selected state should match your state of residence.</div>
    </div>

    <app-privacy-policy
      [content]="selectedStateContent"
      (changedRadioButtons)="onChangedRadioButtons($event)"
    ></app-privacy-policy>

    <app-button (pressed)="onSaveMailInForm()" class="submit-btn">Download Mail-in Forms</app-button>
  </div>
  <app-footer></app-footer>
</div>
