<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<ng-container *ngIf="isLoanAvailable$ | async">
  <div class="form-title" [class.loading]="loading$ | async">Your Loan Offers</div>

  <div class="description" [class.loading]="loading$ | async">
    We could potentially offer you loan options with the annual interest rates below<br />
    <span class="description--black">(Your mailed offer code 3-year loan rate was {{ (offer$ | async)?.interestRatePct | number: '1.0-2' }}%).</span>
  </div>

  <app-terms-table
    class="terms-table"
    [loading]="loading$ | async"
    [terms]="terms$ | async"
    (termSelect)="onTermSelect($event)"
  ></app-terms-table>

  <div class="controls">
    <app-button 
      class="controls__btn" 
      (pressed)="onSubmitted()" 
      [loading]="loading$ | async"
      [pending]="requestPending"
      [spinner]="spinner"
      [id]="'continue'"
      >Continue</app-button>
    <app-button class="controls__btn" color="white" routerLink="/" [id]="'Cancel'" [loading]="loading$ | async">Cancel</app-button>
  </div>
</ng-container>
<ng-container *ngIf="isLoanRejected$ | async">
  <div class="description">
    At the moment, we cannot offer a loan to you. You may want to check back with us in the future.
  </div>
</ng-container>
<ng-container *ngIf="isLoanUnderReview$ | async">
  <div class="description">
    Your loan is under review. We will be back with you shortly.
  </div>
</ng-container>