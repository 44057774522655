export abstract class ResponsiveText {}

export class ResponsiveTextNonResponsive extends ResponsiveText {
  text: string;

  constructor(text: string = null) {
    super();
    this.text = text;
  }
}

export class ResponsiveTextResponsive extends ResponsiveText {
  mobileText: string;
  tabletText: string;
  desktopText: string;

  constructor(mobileText: string = null, tabletText: string = null, desktopText: string = null) {
    super();
    this.mobileText = mobileText;
    this.tabletText = tabletText;
    this.desktopText = desktopText;
  }
}
