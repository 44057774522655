import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../../shared/services/modal/modal.service';
import { BehaviorSubject } from 'rxjs';
import { IOption } from '../../../shared/components/select-input/select-input.component';
import { faqOptions } from '../../../shared/constants/common.constants';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-faq-container',
  templateUrl: './faq-container.component.html',
  styleUrls: ['./faq-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqContainerComponent {
  @ViewChild('selectedQuestion') selectedQuestion: ElementRef;
  public questions: IOption[] = faqOptions.slice(0, 19);
  public searchTerm = new FormControl('');

  public autocompleteOptions$ = new BehaviorSubject<IOption[]>([]);
  public selectedQuestion$ = new BehaviorSubject<string>('');

  constructor(
    private modalService: ModalService,
    private router: Router,
  ) {}

  public openResetOfferCodeModal() {
    this.modalService.openResetOfferCode()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.router.navigateByUrl('steps/confirm');
      });
  }

  public filterOptions(term: string) {
    if (term) {
      this.autocompleteOptions$.next(this.questions.filter((question: IOption) => {
        return question.label.toLowerCase().includes(term.toLowerCase());
      }));
    } else {
      this.autocompleteOptions$.next([]);
      this.selectedQuestion$.next('');
    }
  }

  public onSelectedQuestion() {
    this.selectedQuestion$.next(this.searchTerm.value);
    setTimeout(() => {
      this.selectedQuestion.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    })
  }
}
