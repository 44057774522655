import {
  Input,
  Component,
  ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewChild
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-subject-accordion',
  templateUrl: './subject-accordion.component.html',
  styleUrls: ['./subject-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectAccordionComponent implements OnChanges {
  @Input() subjectTitle: string;
  @Input() forceExpand: boolean;
  @ViewChild('panel', { static: true }) panel: MatExpansionPanel;
  public opened$ = new BehaviorSubject<boolean>(false);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forceExpand && changes.forceExpand.currentValue) {
      this.panel.open();
    } else if (!changes.forceExpand.currentValue) {
      this.panel.close();
    }
  }

  switchExpansionPanel() {
    this.opened$.next(!this.opened$.value);
  }
}
