<div class="page">
  <div class="logo">
    <app-logotype mode="no-spacing"></app-logotype>
  </div>
  <div class="logo-mobile">
    <div class="header">
      <div class="header__content">
        <app-logotype></app-logotype>
      </div>
    </div>
  </div>

  <img class="not-found-logo" src="/assets/img/error-pages/error-{{ statusCode }}.svg" />
  <div class="description" [ngSwitch]="statusCode">
    <div *ngSwitchCase="404" class="description-text description-text-404">
      Sorry, the page you are trying to access does not exist
    </div>
    <div *ngSwitchCase="500" class="description-text description-text-500">
      Internal Server Error<br />
      Something went wrong, Try to refresh the page.
    </div>
  </div>
  <app-button class="button" [ngSwitch]="statusCode" (pressed)="onPressed()">
    <span *ngSwitchCase="404">
      Back to main page
    </span>
    <span *ngSwitchCase="500">
      Refresh
    </span>
  </app-button>

  <img class="top-image" src="/assets/img/error-pages/img-2.png" />
  <img class="bottom-image" src="/assets/img/error-pages/img-1.png" />
</div>
