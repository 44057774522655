import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IHoldCard, IPayOffAccount } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { UserService } from '../../../user/services/user/user.service';
import { BehaviorSubject, Observable, concat } from 'rxjs';
import { IApplication } from '../../../user/models/user.models';
import { shareReplay } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/services/modal/modal.service';

@Component({
  selector: 'app-final-container',
  templateUrl: './final-container.component.html',
  styleUrls: ['./final-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalContainerComponent implements OnInit {
  public username$: Observable<string>;
  public payOffAccount$: Observable<IPayOffAccount>;
  public application$: Observable<IApplication>;
  public holdCard$: Observable<IHoldCard>;

  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private userService: UserService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.username$ = this.userService.getUsername();
    this.payOffAccount$ = this.customerService.getPayOffAccount(this.userService.applicationId).pipe(shareReplay(1));
    this.payOffAccount$.subscribe((account: IPayOffAccount) => {
      if (account.content_object) {
        this.loading$.next(false);
      } else {
        this.getApplicationData();
      }
    })
  }

  public onSubmitted(payOffAccount: IPayOffAccount): void {
    const appId = this.userService.applicationId;

    this.userService.setToken();
    concat(
      this.customerService.addPayOffAccount(appId, payOffAccount),
      this.userService.saveCurrentStepNumber(99, appId)
    ).subscribe((res) => {
      if (res && res.content_type === "credit card"){
        this.modalService.openInfo({
          title: 'Congratulations!!',
          description: 'Congratulations on beginning your Debt Diet with ApexLend.',
          buttonText: 'Ok'
        }).subscribe(() => {
              this.router.navigateByUrl('/profile/information/personal');
        });
      }
    }, error => {
      if (error.status === 400 || error.status === 500) {
        this.modalService.openInfo({
          title: 'Error',
          description: 'Error while submitting.',
          buttonText: 'Ok'
        })
      }
    })
  }

  public onLoggedOut() {
    this.userService.logout();
    this.router.navigateByUrl('/');
  }

  private getApplicationData() {
    this.application$ = this.userService.getApplication().pipe(shareReplay(1));
    this.application$.subscribe((application: IApplication) => {
      if (!application.same_payoff_hold_account) {
        this.loading$.next(false);
      } else {
        this.getHoldCard(application.id);
      }
    }, err => console.log('http error', err)
    );
  }

  private getHoldCard(applicationId: number) {
    this.holdCard$ = this.customerService.getHoldCard(applicationId).pipe(shareReplay(1));
    this.holdCard$.subscribe(() => {
      this.loading$.next(false);
    }, err => console.log('http error', err));
  }
}
