<app-form-title class="title">Authorization for New Holds</app-form-title>
<app-form-description class="description"
  >When you authorize the new hold, ApexLend will cancel any previous hold before we use the new credit card hold
  authorization.</app-form-description
  >
  <br/>
  <mat-progress-bar mode="indeterminate" [ngStyle]="{'visibility': progressBarFlag ? 'visible' : 'hidden'}"></mat-progress-bar>
  <app-loans-table *ngIf='show'
  [data]="pendingHolds"
  [controls]="controls"
  [tableSchema]="tableSchema"
  [highlightFirst]="true"
></app-loans-table>

<app-form-description *ngIf='pendingHolds <= 0'>
 <strong> {{message}}</strong>
</app-form-description>

<!-- <form [formGroup]="form" class="form">
  <app-base-form-layout>
    <app-input formControlName="prevBalance" label="Previous Balance Hold Authorized" leftColumn></app-input>
    <app-input formControlName="prevPayment" label="Previous Payment Hold Authorized" leftColumn></app-input>
    <app-input formControlName="prevDate" label="Previous Due Date" leftColumn></app-input>
    <app-input
      formControlName="card"
      label="Credit Card"
      hasHiddenInput="true"
      ngxMask="XXXX XXXX XXXX 0000"
      leftColumn
    ></app-input>
    <app-input formControlName="newBalance" label="New Balance Hold Authorized" rightColumn></app-input>
    <app-input formControlName="newPayment" label="New Payment Hold Authorized" rightColumn></app-input>
    <app-input formControlName="newDate" label="New Due Date" rightColumn></app-input>
    <app-button class="form__btn" (pressed)="openChangeCardModal()" rightColumn>Use a different Credit Card</app-button>
  </app-base-form-layout>

  <app-checkbox
          formControlName="acceptTerms"
  >Agree to <a routerLink="/content/terms">Terms and Conditions</a>
  </app-checkbox>
</form> -->

<form [formGroup]='form' class="form" *ngIf='show'>
  <div class="controls">
    <app-checkbox
    formControlName="acceptTerms"
    >Agree to <a target="_blank" routerLink="/content/terms">Terms and Conditions</a>
  </app-checkbox>
  <!-- <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div> -->
</div>

<div class="controls">  
  <app-button class="controls__control" color="white">Do not Authorize</app-button>
  <app-button class="controls__control submit-btn" (pressed)="onAuthorize()" [isDisabled]="!submitted && f.acceptTerms.errors">
    
    {{ !submitted && !f.acceptTerms.errors ? 'Authorize' : 'Agree T & C to Authorize' }}
  </app-button>
</div>
</form>

<app-form-description>
  Please be assured that your information is being sent over a secure SSL server. We are in full compliance with <a target="_blank" [href]="pciLink">PCI DSS</a>.<br />
  Your privacy is important to us. Please see our Privacy Policy below for more information
</app-form-description>

<app-secure-logotypes></app-secure-logotypes>
