import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMPLOYMENT, INCOME_TYPES } from 'src/app/shared/constants/select-data.constants';
import { IEmployment, IEmploymentType, IIncome } from 'src/app/shared/models/customer.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-employment-information-form',
  templateUrl: './employment-information-form.component.html',
  styleUrls: ['./employment-information-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmploymentInformationFormComponent implements OnChanges {
  @Input() employmentTypesOptions: IEmploymentType[];
  @Input() employment: IEmployment;
  @Input() incomes: IIncome[];
  @Output() submitted = new EventEmitter<any>();
  @Output() changedEmploymentType = new EventEmitter<string>();
  @Input() spinner: boolean;
  public requestPending = false;
  public progressBar: boolean = true;  
  public form: FormGroup;
  public EMPLOYMENT = EMPLOYMENT;
  public loadingCustomer = true;
  public loadingIncomes = true;

  public isEmploymentTypeSelectedFromBackend$ = new BehaviorSubject<boolean>(true);
  public displayIncomeError$ = new BehaviorSubject<boolean>(false);
  public isEmployed$: Observable<boolean>;

  private filteredIncomes: IIncome[];

  constructor(private formBuilder: FormBuilder) {}

  get autocompleteOptions(): string[] {
    if (this.employmentTypesOptions) {
      return this.employmentTypesOptions.map((option: IEmploymentType) => option.matchTitle) || [];
    }
    return [];
  }
  
  private filterIncomes(): IIncome[] {
    if (!this.incomes) return [];
    const filtered = [];
    INCOME_TYPES.forEach((type) => {
      const income = this.incomes.find((i) => i._type === type);
      if (income) {
        filtered.push(income);
      }
    });
    return filtered;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      this.initForm();
    }

    if (changes.employment && changes.employment.currentValue) {
      const employment = changes.employment.currentValue;
      if (!employment._type) {
        this.isEmploymentTypeSelectedFromBackend$.next(true);
      } else {
        this.onChangeEmploymentType(employment._type);
      }
      this.form.controls.employment.patchValue({
        status: employment.status,
        yearsExperience: `${employment.yearsExperience || ''}`,
        _type: employment._type,
        employer: employment.employer,
      });
      this.loadingCustomer = false;
    }

    if (changes.incomes && changes.incomes.currentValue) {
      const incomeValues = {
        incomeBase: '0',
        incomeBonus: '0',
        incomeDividend: '0',
        incomeOvertime: '0',
        incomeCommission: '0',
        incomeOther: '0',
      };
      this.filteredIncomes = this.filterIncomes();

      this.filteredIncomes.forEach(({ _type, value }) => {
        incomeValues[`income${_type.charAt(0)}${_type.slice(1).toLowerCase()}`] = `${value}`;
      });

      this.form.patchValue(incomeValues);
      this.loadingIncomes = false;
      this.progressBar = false;
    }

    const options = changes.employmentTypesOptions;
    if (options && options.currentValue) {
      this.isEmploymentTypeSelectedFromBackend$.next(!!options.currentValue.find(opt => opt.matchTitle === this.form.value.employment._type));
    }    
  }

  public onSubmit() {
    this.displayIncomeError$.next(true);
    this.form.markAllAsTouched();
    if (!this.form.controls.employment.valid) { return; }

    if (this.form.valid) {
      this.requestPending = true;
      this.spinner = true;
      const formattedForm = { ...this.form.value };
      Object.keys(formattedForm).forEach((key) => {
        if (key.startsWith('income')) {
          const value = this.parseNumber(formattedForm[key]) || 0;
          const previous = this.filteredIncomes.find((i) => i._type === key.replace('income', '').toUpperCase());
          if ((!previous && !value) || (previous && previous.value === value)) {
            delete formattedForm[key];
          } else {
            formattedForm[key] = value;
          }
        } else if (key === 'employment') {
          const employment = formattedForm[key];
          if (
            employment.employer === this.employment.employer &&
            employment._type === this.employment._type &&
            employment.status === this.employment.status &&
            +employment.yearsExperience === +this.employment.yearsExperience
          ) {
            delete formattedForm[key];
          }
        }
      });
      this.submitted.emit(formattedForm);
    }
  }

  public onChangeEmploymentType(e: string) {
    this.changedEmploymentType.emit(e);
  }

  public onSelectedEmploymentType() {
    this.isEmploymentTypeSelectedFromBackend$.next(true);
  }

  public parseNumber(str: string): string {
    return parseFloat(str.replace(/[^0-9\.]/g, '')).toFixed(2);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      employment: this.formBuilder.group({
        status: ['', [Validators.required]],
        yearsExperience: ['', [Validators.required]],
        employer: ['', [Validators.required]],
        _type: ['', [Validators.required]]
      }),

      incomeBase: ['', []],
      incomeBonus: ['', []],
      incomeDividend: ['', []],
      incomeOvertime: ['', []],
      incomeCommission: ['', []],
      incomeOther: ['', []]
    },
    { validators: this.atLeastOneIncomeValidator() },
    );

    this.isEmployed$ = this.form.get('employment.status').valueChanges.pipe(
      map(value => [this.EMPLOYMENT.E, this.EMPLOYMENT.S].includes(this.EMPLOYMENT[value]))
    );

    this.isEmployed$.subscribe(bool => this.toggleEmployerValidators(bool));
  }

  private atLeastOneIncomeValidator() {
    return (group: FormGroup) => {
      const { employment, ...incomes } = group.controls;
      let errors: any = { atLeastOneIncome: true };
      Object.keys(incomes).forEach((key) => {
        if (this.parseNumber(incomes[key]['value'])) {
          errors = {};
        }
      });

      group.setErrors(errors);
      return errors;
    };
  }

  private toggleEmployerValidators(employed: boolean) {
    const employer = this.form.controls.employment['controls'].employer;
    if (!employed) {
      employer.clearValidators();
    } else {
      employer.setValidators([Validators.required]);
    }
    employer.updateValueAndValidity();
  }
}
