<mat-expansion-panel 
  class="card card--foldable"
  togglePosition="before"
  [class.card--foldable]="foldable"
  [disabled]="!foldable"
  [expanded]="expanded"
>
  <mat-expansion-panel-header
    class="card-header card-header--foldable"
    [class.card-header_loading]="loading"
    [class.card-header--foldable]="foldable"
  >
    <div class="card-header-mat-content">
      <div class="title-wrapper">
        <div class="title">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>
      <div 
        *ngIf="hasRemoveBtn" 
        class="remove-btn"
        (click)="onRemoveBtnClick($event)"
      ></div>
    <span *ngIf="priority" [ngClass]="{'primary': priority == 'primary', 'secondary': priority != 'primary'}">{{ priority }}</span>
    </div>
  </mat-expansion-panel-header>
  <div class="card-content">
    <ng-content></ng-content>
  </div>
</mat-expansion-panel>