import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IConfig, initialConfig } from 'ngx-mask';

@Component({
  selector: 'app-base-input',
  templateUrl: './base-input.component.html',
  styleUrls: ['./base-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseInputComponent),
      multi: true
    }
  ]
})
export class BaseInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() loading: boolean;
  @Input() errorMessage: string;
  @Input() warning: string;
  @Input() label: string;
  @Input() hidden: boolean;
  @Input() model: string;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() isDisabled: boolean;
  @Input() hasHiddenInput: boolean;
  @Input() ngxMask: string;
  @Input() ngxDropSpecialCharacters = false;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() maxLength: number;
  @Input() forceError: boolean;
  @Input() setTouched = false;
  @Input() autocomplete: 'off' | 'on' | 'new-password' = 'off';
  @Input() validateErrors: string | (string | boolean)[] = [];
  @Input() disablePaste = false;
  @Input() hasHint = false;
  @Input() isRequired = false;
  @Input() isPreview = false;
  @Input() id: string;
  @Output() keyPressed = new EventEmitter();
  @Output() focusOuted = new EventEmitter();
  @Output() focused = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() hintClick = new EventEmitter();
  @ViewChild('inputRef') inputRef: ElementRef;
  public customPatterns: IConfig['patterns'] = {
    ...initialConfig.patterns,
    'Z': {pattern: /[0-9*]/},
    'X': {pattern: /\d/, symbol: '•'},
    'N': {pattern: /[a-zA-Z\s]/ }
  };

  protected control: AbstractControl;
  private touched = false;
 
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    if (this.disablePaste) { e.preventDefault(); }
  }

  constructor(protected injector: Injector, protected cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.initFormControl();
  }

  public get hasValidateErrors(): boolean {
    const errors = [].concat(this.validateErrors);
    return Boolean(errors.find(Boolean));
  }

  public get validateErrorOutput(): string {
    const errors = [].concat(this.validateErrors);
    const firstError = errors.find(i => typeof i === 'string');
    return firstError || this.errorMessage;
  }

  get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  get isTouched(): boolean {
    return this.control ? this.setTouched || this.touched || this.control.touched : false;
  }

  private initFormControl() {
    if (this.injector) {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
        this.control.statusChanges.subscribe(() => this.cdRef.markForCheck());
        this.monkeyPatchTouched(this.control);
      } else {
        console.warn('Component is missing form control binding');
      }
    }
  }

  public onChange: (value: string) => void = (value: string) => {
    this.changed.emit(value);
  };

  public onTouched: () => void = () => {
    this.touched = true;
  };

  public writeValue(value: string) {
    if (typeof value === 'string' && this.model !== value) {
      this.model = value;
      this.onChange(value);

      if (this.control) {
        this.control.setValue(value);
      }

      return value;
    }
  }

  public registerOnChange(fn: () => {}) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }

  private monkeyPatchTouched(control: AbstractControl) {
    if (!control) { return; }
    const self = this;
    const origFunc = control.markAsTouched;
    control.markAsTouched = function () {
      origFunc.apply(this, arguments);
      self.cdRef.markForCheck();
    }
  }
}
