<mat-progress-bar mode="indeterminate" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<div class="title" [class.loading]="loading$ | async">
  Your loan has been approved
</div>

<app-loan-info
  [payments]="paymentSchedule$ | async"
  [loan]="loan$ | async"
  [loading]="loading$ | async"
></app-loan-info>

<div class="description" [class.loading]="loading$ | async">
  This is what your bi-weekly tentative loan schedule looks like. Scroll to the bottom to watch the video on how your
  loan works to proceed.
</div>
<app-loans-list
  [payments]="paymentSchedule$ | async"
  [loading]="loading$ | async"
></app-loans-list>

<div class="agreement">
  <ng-container>
    <app-checkbox
      class="checkbox"
      [formControl]="holdCardAgreement"
      [loading]="loading$ | async"
      [id]="'holdCardAgreement'"
    >
      <span>
        I {{ holdCard?.name }} authorize ApexLend to charge my {{ holdCardType }} Credit Card ending in {{ holdCardLastFour }} 
        for {{ (paymentAmount | currency) || '[$xxxx]' }} on {{listDateObj && listDateObj[0].due_date | date:'MM-dd-yyyy' : 'UTC' }}.
        I understand that ApexLend will place an authorization hold for {{ (paymentAmount | currency) || '[$xxxx]' }} right away.<br>
        <br>
        I also understand that ApexLend will not charge {{ (paymentAmount | currency) || '[$xxxx]' }} pursuant to this authorization 
        unless I fail to make my first regular payment by the due date. If I am charged {{ (paymentAmount | currency) || '[$xxxx]' }}, 
        it will go towards paying down my loan.
      </span>
    </app-checkbox>
    <app-checkbox
      class="checkbox"
      [formControl]="loanAgreement"
      [loading]="loading$ | async"
      [id]="'loanAgreement'"
    >
      <span>
        If I finalize my loan by signing the loan agreement on the next page, I, {{ holdCard?.name }} 
        authorize ApexLend to charge my {{ holdCardType }} Credit Card ending in {{ holdCardLastFour }} up to 
        ${{holdAmount}} on {{listDateObj && listDateObj[1].due_date | date:'MM-dd-yyyy' : 'UTC'}}.<br>
        <br>
        I understand that ApexLend, LLC may place an authorization hold for ${{holdAmount}}. 
        I also understand that ApexLend will only charge up to ${{holdAmount}} pursuant to this authorization 
        unless there is an acceleration event. If there is an acceleration event, I will be charged up to ${{holdAmount}} 
        but no more than the amount required to pay off my loan.
      </span>
    </app-checkbox>
    <app-checkbox
      class="checkbox"
      [formControl]="agreed"
      [loading]="loading$ | async"
      [id]="'agreed'"
    >
      <span>To finalize your loan submission, we need to request a full credit report. This may or may not affect your credit score.
        Do we have your permission to have a full credit report?</span><br />
      <br />
      <span>I agree to the credit report authorization</span>
    </app-checkbox>
  </ng-container>
</div>

<ng-container>
  <div class="description" [hidden]="(loading$ | async) || !allAgreementChecked">
    <p class="description__line">Please watch the entire video before proceeding.</p>
    <p class="description__line">For supplemental explanation please read the <a (click)="openFaq()">payment cycle example</a>.</p>
  </div>
</ng-container>
<div class="video-container" [hidden]="(loading$ | async) || !allAgreementChecked">
  <video
    #video
    class="video-js"
    id="main-video"
    [controls]="true"
    preload="auto"
    poster="/assets/video/poster.png"
    data-setup="{}"
  >
    <source src="/assets/video/ApexLend_Vid_2.mp4" type="video/mp4" />
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a web browser that
      <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
    </p>
  </video>
</div>

<ng-container *ngIf="endedVideo$ | async">
  <app-button 
    class="submit-btn" 
    type="submit"  
    *ngIf="(emailVerified$ | async) else verifyEmail"
    [isDisabled]="!allAgreementChecked"
    (pressed)="onSubmitted()"
    [loading]="loading"  
    [pending]="requestPending"
    [spinner]="spinner"
    [id]="'agree_finish_btn'"
    >Agree and Finish
  </app-button>
  <ng-template #verifyEmail>
    <div
      class="verify-email"
      [class.verify-email_loading]="loading$ | async"
    >
      <span 
        class="verify-email__message"
        *ngIf="!(emailVerificationLinkSent$ | async)"
        >Please verify your account before finalizing your loan
      </span>
      <div *ngIf="(emailVerificationLinkSent$ | async) && !(emailVerificationFailedOnce$ | async)">
        <span class="verify-email__message">{{ 'Your verification link has been sent to ' + email }}</span>
        <span class="verify-email__message">Please verify your account and click the Check Verification button</span>
      </div>
      <span
        *ngIf="emailVerificationFailedOnce$ | async"
        >Your email is not verified. Please check your email and try again or <a href="/contacts" target="_blank">Contact Us</a>
      </span>
      <app-button
        class="verify-email__button"
        [pending]="emailVerificationLoading$ | async"
        (pressed)="checkVerification()"
        [id]="'emailVerified'"
      >Check verification</app-button>
      <a
        class="verify-email__link"
        *ngIf="!(emailVerificationLinkSent$ | async)"
        (click)="resendLink()"
      >Resend verification link</a>
    </div>
  </ng-template>
</ng-container>
