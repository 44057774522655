<mat-sidenav-container class="example-container" [hasBackdrop]="true" fullscreen>
  <mat-sidenav #sidenav mode="over" position="end">
    <button type="button" class="sidebar-close" (click)="sidenav.close()"></button>
    <div class="sidebar-item-list">
      <div class="sidebar-item-outer" *ngFor="let item of items">
        <a class="sidebar-item" [routerLink]="item.url">
          <app-responsive-text [text]="item.text"></app-responsive-text>
        </a>
      </div>
    </div>
    <div class="sidebar-sign-in-btn-container">
      <app-button [color]="'white'" [id]="'sign-in-mobile-btn'" type="submit" [styleType]="'sign-in'" (pressed)="onSignIn()">
        Sign In
      </app-button>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <div class="content-wrapper">
      <div class="navigation-bar navigation-bar-desktop">
        <div class="container container-header">
          <app-logotype class="navigation-bar-logo"></app-logotype>
          <div class="navigation-bar-item-list">
            <a class="navigation-bar-item" *ngFor="let item of items" [routerLink]="item.url">
              <app-responsive-text [text]="item.text"></app-responsive-text>
            </a>
          </div>
          <app-button class="sign-in-btn" [id]="'sign-in-btn'" [noShadow]="true" (pressed)="onSignIn()">
            Sign In
          </app-button>
        </div>
      </div>
      <div class="navigation-bar navigation-bar-tablet-mobile">
        <div class="container container-header">
          <app-logotype class="navigation-bar-logo"></app-logotype>
          <button type="button" class="navigation-bar-menu-btn" (click)="sidenav.toggle()"></button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
