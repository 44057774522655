<div class="line">
  <img class="line__image" src="assets/img/payment-cycle-example.png">
  <p class="line__subtext">*FUNDING DATE : Date ApexLend Sends Money to pay down your credit card</p>
  <p class="line__subtext">**PAYMENT DUE DATE: Every 14th day from Funding Date</p>
  <p class="line__subtext">
    <span class="highlight--yellow">***CREDIT CARD AUTO PAY SINGLE PAYMENT DATE</span>: 5:00 PM PST 1 business days after the end of the regular payment window
  </p>
  <p class="line__subtext">
    <span class="highlight--pink">****CREDIT CARD AUTO PAY OFF IN FULL</span>: 1:00 PM PST 1 business day before next due date
  </p>
  <p class="line__subtext">How the ApexLend Loan Works</p><br>
  <p class="line__subtext">Congratulations! You are about to begin your debt diet with ApexLend.</p>
</div>

<div class="line">
  <p class="line__question">What happens during the REGULAR PAYMENT WINDOW with <i>ApexPay</i>?</p> 
  <p class="line__answer">
    On each payment due date, ApexLend will check your designated bank account balance:
  </p>
  <ul class="line__list">
    <li>If the available balance is greater than the scheduled payment, ApexLend will withdraw the money.  </li>
    <li>
      If the balance is less than the scheduled payment, ApexLend will send a message using either email or text 
      letting you know that there are insufficient funds in your designated account.  You can choose to do one of the following:
      <ul class="line__list">
        <li>
          Change/add the bank account you would like us to use for this loan by signing into your account and designating the new account 
          for use with <i>ApexPay</i>. ApexLend will then debit the payment as soon as possible using the new account. 
        </li>
        <li>
          Change/add the bank account you would like us to use for this loan by signing into your account and manually pay using this account. 
          ApexLend will then debit the payment on the date you choose. 
          As long as you do not turn off <i>ApexPay</i>, you will keep your <i>ApexPay</i> benefits even if you pay manually for a payment.
        </li>
        <li>
          Add funds into your designate <i>ApexPay</i> account. ApexLend will attempt to debit from 
          your designated <i>ApexPay</i> account 2 business days after your due date.
        </li>
      </ul>
    </li>
  </ul>
  <p class="line__answer">
    If the second attempt fails, ApexLend may attempt a third ACH debit payment on the 4th business day after your due date.  
    If all the ACH debit attempts fail, on the 5th business day after your due date, ApexLend will charge the scheduled payment 
    to your designated credit card unless you contact us prior to the end of the Auto Balance Check & Debit Window.
  </p>
</div>

<div class="line">
  <p class="line__question">What happens during REGULAR PAYMENT WINDOW without <i>ApexPay</i>?</p> 
  <p class="line__answer">
    Begins one day prior to each Payment Due Date and Ends at 8:00 PM EST 5 Business Days from the Payment Due Date. 
    You may log into the system or call us to make your payment anytime during the Manual Payment Window. 
    If you do not make a manual payment and you do not have <i>ApexPay</i> on, 
    ApexLend will charge the scheduled payment to your designated credit card.
  </p>
</div>

<div class="line">
  <span class="line__bold">New Credit Card Authorization Window after payment:</span>
  <span>
    Begins on each Payment Due Date after you make your scheduled payment and ends at 8:00 PM 
    one Business Day prior to your next Payment Due Date.
  </span>
</div>

<div class="line">
  <p class="line__question">What happens if I do not authorize a new credit card authorization after each payment?</p> 
  <p class="line__answer">
    As per our agreement, if you do not authorize a new credit card authorization after each payment, 
    your loan will be accelerated and full payment of interest and principal will be required. 
    If you have made arrangements to pay via a bank account prior to the end of the New Credit Card Authorization Window, 
    ApexLend will debit payment from that bank account.  If not, ApexLend will charge your designated credit card for the full balance.
  </p>
</div>