import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResponsiveText, ResponsiveTextNonResponsive, ResponsiveTextResponsive } from '../../models/responsive-text';

@Component({
  selector: 'app-responsive-text',
  templateUrl: './responsive-text.component.html',
  styleUrls: ['./responsive-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponsiveTextComponent {
  @Input() text: ResponsiveText;

  get isNonResponsive(): boolean {
    return this.text && this.text instanceof ResponsiveTextNonResponsive;
  }

  get isResponsive(): boolean {
    return this.text && this.text instanceof ResponsiveTextResponsive;
  }
}
