import { BehaviorSubject, Subject, Observable } from "rxjs";
import { share, takeUntil } from "rxjs/operators";

export class RequestObserver<T = any> {

  public isDone = new BehaviorSubject(false);
  public isPending = new BehaviorSubject(false);
  public isError = new BehaviorSubject(false);
  public isSuccess = new BehaviorSubject(false);

  private requestSource: Observable<T>;
  private reset$ = new Subject();

  public setSource(source: Observable<T>): Observable<T> {
    this.reset();
    this.isPending.next(true);
    this.requestSource = source.pipe(takeUntil(this.reset$), share());
    this.requestSource.subscribe({
      complete: () => {
        this.isDone.next(true);
        this.isPending.next(false);
        this.isSuccess.next(true);
      },
      error: error => {
        this.isDone.next(true);
        this.isPending.next(false);
        this.isError.next(true);
      }
    });
    return this.requestSource;
  }

  private reset() {
    const subjects = [this.isPending, this.isError, this.isSuccess, this.isDone];
    subjects.forEach(item => item.next(false));
    this.reset$.next();
  }
}