import { IOption } from '../components/select-input/select-input.component';

export const PCI_DSS_LINK = 'http://bit.ly/pci_security';

export const faqOptions: IOption[] = [
  {
    label: 'How do I get an ApexLend personal loan?',
    value: 'How do I get an ApexLend personal loan?',
  },
  {
    label: 'What is an offer code?',
    value: 'What is an offer code?',
  },
  {
    label: 'Where do I find my offer code?',
    value: 'Where do I find my offer code?',
  },
  {
    label: 'How do I get an offer code if I did not receive a letter or e-mail?',
    value: 'How do I get an offer code if I did not receive a letter or e-mail?',
  },
  {
    label: 'Will the rate on the offer flyer be my rate if I apply?',
    value: 'Will the rate on the offer flyer be my rate if I apply?',
  },
  {
    label: 'How long does it take to get a loan?',
    value: 'How long does it take to get a loan?',
  },
  {
    label: 'What if I entered the wrong Email address/Username?',
    value: 'What if I entered the wrong Email address/Username?',
  },
  {
    label: 'What does Reset Offer Code do?',
    value: 'What does Reset Offer Code do?',
  },
  {
    label: 'What are the income requirements to qualify for a loan?',
    value: 'What are the income requirements to qualify for a loan?',
  },
  {
    label: 'What are ApexLend personal loan rates?',
    value: 'What are ApexLend personal loan rates?',
  },
  {
    label: 'What are origination fees for ApexLend personal loans?',
    value: 'What are origination fees for ApexLend personal loans?',
  },
  {
    label: 'Do you have prepayment fees?',
    value: 'Do you have prepayment fees?',
  },
  {
    label: 'Do you charge a fee to file an application?',
    value: 'Do you charge a fee to file an application?',
  },
  {
    label: 'What is a Truth in Lending disclosure?',
    value: 'What is a Truth in Lending disclosure?',
  },
  {
    label: 'Will checking my rate affect my credit score?',
    value: 'Will checking my rate affect my credit score?',
  },
  {
    label: 'How will I receive my funds?',
    value: 'How will I receive my funds?',
  },
  {
    label: 'How often are payments due?',
    value: 'How often are payments due?',
  },
  {
    label: 'Can I cancel my loan?',
    value: 'Can I cancel my loan?',
  },
  {
    label: 'Can I receive Military Lending Act (MLA) benefits?',
    value: 'Can I receive Military Lending Act (MLA) benefits?',
  },
  {
    label: 'Can I take out an additional loans?',
    value: 'Can I take out an additional loans?',
  },
  {
    label: 'How do I change my password?',
    value: 'How do I change my password?',
  },
  {
    label: 'What if I forgot my password?',
    value: 'What if I forgot my password?',
  },
  {
    label: 'Can I turn on two-step authentication?',
    value: 'Can I turn on two-step authentication?',
  },
  {
    label: 'How can I change my profile information (address, phone number, email, etc)?',
    value: 'How can I change my profile information (address, phone number, email, etc)?',
  },
  {
    label: 'Changing your bank account?',
    value: 'Changing your bank account?',
  },
  {
    label: 'Changing your credit card?',
    value: 'Changing your credit card?',
  },
  {
    label: 'Automated Payments',
    value: 'Automated Payments',
  },
  {
    label: 'Manual Payments - Regular Payments',
    value: 'Manual Payments - Regular Payments',
  },
  {
    label: 'How do I see how my loan will change if I make Additional Principal Paydown?',
    value: 'How do I see how my loan will change if I make Additional Principal Paydown?',
  },
  {
    label: 'When will Additional Principal Paydowns be applied?',
    value: 'When will Additional Principal Paydowns be applied?',
  },
  {
    label: 'How do I turn on or turn off notifications?',
    value: 'How do I turn on or turn off notifications?',
  },
  {
    label: 'Adding ApexLend to your address book',
    value: 'Adding ApexLend to your address book',
  },
  {
    label: 'How do I view my payment history?',
    value: 'How do I view my payment history?',
  },
  {
    label: 'How do I view future payments due?',
    value: 'How do I view future payments due?',
  },
  {
    label: 'How to Close your Account',
    value: 'How to Close your Account',
  },
  {
    label: 'What happens if I enter active military duty?',
    value: 'What happens if I enter active military duty?',
  }
];
