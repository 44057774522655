<div class="title">Edit Credit Card</div>
<form [formGroup]="form" class="form">
  <app-input
    class="input"
    label="Credit Card Number"
    formControlName="number"
    errorMessage="Incorrect Credit Card Number"
    [isDisabled]="true"
    ngDefaultControl
  ></app-input>
  <app-input
    class="input"
    label="Expiration Date"
    formControlName="expiration_date"
    errorMessage="Incorrect Expiration Date"
    ngxMask="00/00"
    placeholder="MM/YY"
    ngDefaultControl
  ></app-input>
  <app-input
    class="input"
    label="Security Code"
    formControlName="security_code"
    errorMessage="Incorrect Security Code"
    ngxMask="0000"
    ngDefaultControl
  ></app-input>
</form>
<div class="bottom">
  <app-button
    class="bottom__btn"
    color="white"
    (pressed)="onClose()"
  >Back</app-button>
  <app-button
    class="bottom__btn"
    (pressed)="onSubmit()"
  >Submit</app-button>
</div>