import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-apex-pay-terms-container',
  templateUrl: './apex-pay-terms-container.component.html',
  styleUrls: ['./apex-pay-terms-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApexPayTermsContainerComponent implements OnInit {
  canGoBackTo = ['final', 'payments'];
  canGoBack = false;

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {
    const previousUrl = this.navigationService.previousUrl;
    if (previousUrl && previousUrl.includes(this.canGoBackTo.join('/'))) {
      this.canGoBack = true;
    }
  }
}
