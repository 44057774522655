import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IHoldCardResponse } from '../../models/customer.model';
import { hideDataString } from '../../helpers/hide-data-string';
import { CustomerService } from '../../services/customer/customer.service';
import { parseExpirationDate } from '../../helpers/parse-expiration-date';
import { formatDate } from '@angular/common';
import { CARD_TYPE_SHORT } from '../../constants/select-data.constants';

@Component({
  selector: 'app-edit-credit-card-modal',
  templateUrl: './edit-credit-card-modal.component.html',
  styleUrls: ['./edit-credit-card-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCreditCardModalComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private $api: CustomerService,
    private dialogRef: MatDialogRef<EditCreditCardModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { card: IHoldCardResponse },
  ) {}

  ngOnInit() {
    this.initForm();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid) { return; }

    const { expiration_date, security_code } = this.form.value;
    const formattedDate = formatDate(parseExpirationDate(expiration_date), 'yyyy-MM-dd', 'en-GB');

    this.$api.patchHoldCard(this.data.card.application, {
      expiration_date: formattedDate,
      security_code
    }).subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  private initForm() {
    const cardNumber = hideDataString(this.data.card && this.data.card.credit_card && this.data.card.credit_card.number ? this.data.card.credit_card.number : null, 4, 16);
    const card_type = this.data && this.data.card && this.data.card.credit_card ? this.data.card.credit_card.card_type : null;
    this.form = this.fb.group({
      number: [cardNumber],
      expiration_date: ['', [Validators.required, Validators.minLength(5)]],
      security_code: ['', [Validators.required, this.getSecurityCodeValidator(card_type)]]
    })
  }

  // TODO: Move to custom validators or helpers
  private getSecurityCodeValidator(cardType: string) {
    // Probably you should add another constant with short values
    const length = cardType === CARD_TYPE_SHORT.A.charAt(0) ? 4 : 3;
    return Validators.compose([Validators.minLength(length), Validators.maxLength(length)]);
  }
}
