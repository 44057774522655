<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<app-form-title [loading]="loading">Customer information</app-form-title>
<form [formGroup]="form" (keyup.enter)="onSubmit()">
  <app-base-form-layout>
    <app-input
      formControlName="firstName"
      [isDisabled]="isDisabled"
      label="First Name"
      errorMessage="Incorrect first name"
      [loading]="loading"
      leftColumn
      [id]="'firstName'"
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="lastName"
      [isDisabled]="isDisabled"
      label="Last Name"
      errorMessage="Incorrect last name"
      [loading]="loading"
      leftColumn
      [id]="'lastName'"
      ngDefaultControl
    ></app-input>
    <app-datepicker
      formControlName="dateOfBirth"
      [isDisabled]="isDisabled"
      label="Date of Birth"
      errorMessage="Incorrect date of birth"
      [loading]="loading"
      leftColumn
      [id]="'dateOfBirth'"
      ngDefaultControl
    ></app-datepicker>
    <app-input
      formControlName="email"
      [isDisabled]="isDisabled"
      label="Email Address"
      errorMessage="Incorrect email"
      [loading]="loading"
      leftColumn
      [id]="'email'"
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="alternateEmail"
      [isDisabled]="isDisabled"
      label="Alternate Email Address"
      errorMessage="Incorrect alternative email"
      [loading]="loading"
      leftColumn
      [id]="'alternateEmail'"
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="address1"
      [isDisabled]="isDisabled"
      label="Residence Address 1"
      errorMessage="Incorrect residence address 1"
      [loading]="loading"
      rightColumn
      [id]="'address1'"
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="address2"
      [isDisabled]="isDisabled"
      label="Residence Address 2"
      errorMessage="Incorrect residence address 2"
      [loading]="loading"
      rightColumn
      [id]="'address2'"
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="homePhone"
      [isDisabled]="isDisabled"
      label="Home Phone"
      errorMessage="Incorrect home phone"
      type="tel"
      [loading]="loading"
      rightColumn
      [id]="'homePhone'"
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="cellPhone"
      [isDisabled]="isDisabled"
      label="Cell Phone"
      errorMessage="Incorrect cell phone"
      type="tel"
      [loading]="loading"
      rightColumn
      [id]="'cellPhone'"
      ngDefaultControl
    ></app-input>
  </app-base-form-layout>
  <app-form-description [loading]="loading">
    To help protect your identity, please enter the last four digits of your social security number and 
    the zip code for the home mortgaged with SunWest Mortgage Company.
  </app-form-description>
  <app-base-form-layout>
    <app-secret-input
      formControlName="ssn"
      label="Last 4 Digits of SSN"
      [forceError]="hasErrors"
      ngxMask="0000"
      [loading]="loading"
      leftColumn
      [id]="'ssn'"
      ngDefaultControl
    ></app-secret-input>
    <app-input
      formControlName="zipcode"
      label="Zip Code"
      [forceError]="hasErrors"
      ngxMask="00000"
      [loading]="loading"
      rightColumn
      [id]="'zipcode'"
      ngDefaultControl
    ></app-input>
  </app-base-form-layout>
</form>

<app-button
  styleType="form-submit"
  (pressed)="onSubmit()"
  [isDisabled]="!form.valid || loading"
  [loading]="loading"
  [id]="'stepSubmit'"
>
  <div class="form-submit__text">
    Submit
    <span
      *ngIf="hasErrors"
      class="form-submit__error">
      Incorrect SSN or Zip Code
    </span>
  </div>
</app-button>
