import { Injectable } from '@angular/core';

export enum EStorageTypes {
  LocalStorage = 'local', SessionStorage = 'session'
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  prefix = 'apex_';
  local = window.localStorage;
  session = window.sessionStorage;

  set(key: string, value: any, destination = EStorageTypes.LocalStorage) {
    const storage = destination === EStorageTypes.LocalStorage ? this.local : this.session;
    const storageKey = `${this.prefix}${key}`;
    storage.setItem(storageKey, JSON.stringify(value));
  }

  get(key: string, destination = EStorageTypes.LocalStorage): any {
    const storage = destination === EStorageTypes.LocalStorage ? this.local : this.session;
    const storageKey = `${this.prefix}${key}`;
    return JSON.parse(storage.getItem(storageKey));
  }

  remove(key: string, destination = EStorageTypes.LocalStorage): any {
    const storage = destination === EStorageTypes.LocalStorage ? this.local : this.session;
    const storageKey = `${this.prefix}${key}`;
    storage.removeItem(storageKey);
  }

  clearAll() {
    const localKeys = Object.keys(this.local);
    const sessionKeys = Object.keys(this.session);
    localKeys
      .filter(key => key.startsWith(this.prefix))
      .forEach(key => this.local.removeItem(key));
    sessionKeys
      .filter(key => key.startsWith(this.prefix))
      .forEach(key => this.local.removeItem(key));
  }
}
