import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ITab } from 'src/app/shared/components/tabs/tabs.component';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { UserService } from 'src/app/user/services/user/user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICustomer } from 'src/app/shared/models/customer.model';

@Component({
  selector: 'app-payment-personal-info-container',
  templateUrl: './payment-personal-info-container.component.html',
  styleUrls: ['./payment-personal-info-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentPersonalInfoContainerComponent implements OnInit {
  public tabs: ITab[] = [
    { label: 'Your personal info', path: '/profile/information/personal' },
    { label: 'Payment information', path: '/profile/information/payment' }
  ];
  public greeting: string;
  public customer$: Observable<ICustomer>;
  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private customerService: CustomerService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.customer$ = this.customerService.get(this.userService.customerId);
    this.customer$.subscribe((res) => {
      this.greeting = `Hi, ${res.firstName} ${res.lastName}`;
      this.loading$.next(false);
    });
  }
}
