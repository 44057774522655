<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<app-form-title [loading]="loadingCustomer">

  <div class="description">
    Below is your employment and income to the best of our knowledge,<br />
    please change where appropriate
  </div>

  <div class="title">Customer Employment Information</div>

</app-form-title>
<form [formGroup]="form" class="form">
  <app-base-form-layout>
    <app-select
      [formControl]="(form?.controls)['employment']['controls'].status"
      label="Employment Status"
      class="input"
      errorMessage="Missing Employment Status"
      [options]="EMPLOYMENT"
      [initialValue]="employment?.status"
      [loading]="loadingCustomer"
      [isRequired]="true"
      leftColumn
      [id]="'employmentStatus'"
      ngDefaultControl
    ></app-select>
    <app-input
      [formControl]="(form?.controls)['employment']['controls'].yearsExperience"
      label="Years of Experience"
      class="input"
      type="tel"
      errorMessage="Missing Years of Experience"
      [warning]="form?.value.employment.yearsExperience > 80 ? 'Are you sure?' : ''"
      ngxMask="000"
      [loading]="loadingCustomer"
      [isRequired]="true"
      leftColumn
      [id]="'yearsExperience'"
      ngDefaultControl
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['employment']['controls'].employer"
      label="Employer Name"
      class="input"
      errorMessage="Missing Employer Name"
      [loading]="loadingCustomer"
      [isRequired]="isEmployed$ | async"
      rightColumn
      [id]="'employment'"
      ngDefaultControl
    ></app-input>
    <app-select-input
      [formControl]="(form?.controls)['employment']['controls']._type"
      label="Job Title"
      class="input"
      [options]="autocompleteOptions"
      errorMessage="Missing Employment Type"
      (changed)="onChangeEmploymentType($event)"
      (selectedOption)="onSelectedEmploymentType()"
      [loading]="loadingCustomer"
      [warning]="!(isEmploymentTypeSelectedFromBackend$ | async) ? 'Are you sure?' : ''"
      [isRequired]="true"
      rightColumn
      [id]="'employmentType'"
      ngDefaultControl
    ></app-select-input>
    <p class="info-required" leftColumn>
      <span class="info-required__star">*</span> Required
    </p>
  </app-base-form-layout>

  <app-form-title [loading]="loadingIncomes">
    Yearly Income
  </app-form-title>

  <app-base-form-layout>
    <app-input
      formControlName="incomeBase"
      label="Base"
      class="input"
      type="tel"
      errorMessage="Incorrect Base"
      prefix="$"
      ngxMask="comma_separator.2"
      [warning]="form?.value?.incomeBase.length < 6 ? 'Are you sure this is your yearly base?' : ''"
      [loading]="loadingIncomes"
      leftColumn
      ngDefaultControl
      [id]="'incomeBase'"
    ></app-input>
    <app-input
      formControlName="incomeBonus"
      label="Bonuses"
      class="input"
      type="tel"
      errorMessage="Incorrect Bonuses"
      prefix="$"
      ngxMask="comma_separator.2"
      [warning]="parseNumber(form.value.incomeBonus) > parseNumber(form.value.incomeBase) ? 'Are you sure?' : ''"
      [loading]="loadingIncomes"
      leftColumn
      [id]="'incomeBonus'"
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="incomeDividend"
      label="Dividends"
      class="input"
      type="tel"
      errorMessage="Incorrect Dividends"
      prefix="$"
      ngxMask="comma_separator.2"
      [warning]="parseNumber(form.value.incomeDividend) > parseNumber(form.value.incomeBase) ? 'Are you sure?' : ''"
      [loading]="loadingIncomes"
      leftColumn
      [id]="'incomeDividend'"
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="incomeOvertime"
      label="Overtime"
      class="input"
      type="tel"
      errorMessage="Incorrect Overtime"
      prefix="$"
      ngxMask="comma_separator.2"
      [warning]="parseNumber(form.value.incomeOvertime) > parseNumber(form.value.incomeBase) ? 'Are you sure?' : ''"
      [loading]="loadingIncomes"
      rightColumn
      [id]="'incomeOvertime'"
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="incomeCommission"
      label="Commissions"
      class="input"
      type="tel"
      errorMessage="Incorrect Commissions"
      prefix="$"
      ngxMask="comma_separator.2"
      [warning]="parseNumber(form.value.incomeCommission) > parseNumber(form.value.incomeBase) ? 'Are you sure?' : ''"
      [loading]="loadingIncomes"
      rightColumn
      [id]="'incomeCommission'"
      ngDefaultControl
    ></app-input>
    <app-input
      formControlName="incomeOther"
      label="Other (i.e. Alimony, Social Security, etc.)"
      class="input"
      type="tel"
      prefix="$"
      ngxMask="comma_separator.2"
      [warning]="parseNumber(form.value.incomeOther) > parseNumber(form.value.incomeBase) ? 'Are you sure?' : ''"
      [loading]="loadingIncomes"
      rightColumn
      [id]="'incomeOther'"
      ngDefaultControl
    ></app-input>
  </app-base-form-layout>
</form>

<app-button
  styleType="form-submit"
  (pressed)="onSubmit()"
  [loading]="loadingIncomes || loadingCustomer"
  [pending]="requestPending"
  [spinner]="spinner"
  [id]="'emp_Submit'"
>
  <div class="button-content">
    Submit
    <span
      *ngIf="form?.errors?.atLeastOneIncome && (displayIncomeError$ | async)"
      class="button-error"
    >You must have income in order to get a loan</span>
  </div>
</app-button>
