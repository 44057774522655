import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../shared/services/modal/modal.service';
import { PCI_DSS_LINK } from 'src/app/shared/constants/common.constants';
import { ThousandsSeparatorsPipe } from 'src/app/shared/pipes/thousands-separators.pipe';
import { DatePipe } from '@angular/common';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { HoldsService, IPendingHolds } from 'src/app/shared/services/holds/holds.service';
import { BehaviorSubject } from 'rxjs';
import { ITableSchema } from '../../components/loans-table/loans-table.component';
import { Router } from '@angular/router';
import { shareReplay, map } from 'rxjs/operators';

@Component({
  selector: 'app-new-holds-authorization-container',
  templateUrl: './new-holds-authorization-container.component.html',
  styleUrls: ['./new-holds-authorization-container.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewHoldsAuthorizationContainerComponent implements OnInit, OnChanges {
  public form: FormGroup;
  submitted = false;
  public pciLink = PCI_DSS_LINK;
  public pendingHolds: any;
  public show: boolean;
  public progressBarFlag: boolean = true;
  public message: string = "Currently You have no holds to authorize. Thank You!";
  public tableSchema: ITableSchema[] = [
    { name: 'Hold Amount ($)', key: 'amount', pipe: ThousandsSeparatorsPipe },
    { name: 'Period', key: 'period' },
    { name: 'Submitted Date', key: 'submitted', pipe: DatePipe, pipeArgs: ['MM/dd/yyyy'] },
    { name: 'Payment Date', key: 'payment_date', pipe: DatePipe, pipeArgs: ['MM/dd/yyyy'] },
    { name: 'Loan', key: 'loan' },
    { name: 'Status', key: 'status' }
    // { name: 'Action', key: 'action', checkbox: true, classCell: ['payment-checkbox'] }
  ];

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private holdsService: HoldsService,
  ) {}

  ngOnInit() {
    this.initForm();    
    this.getPendingHolds();
  }

  ngOnChanges() {
    if (this.pendingHolds <= 0) {
      this.progressBarFlag = false;
      this.show = false
    }
  }

  get f() { return this.form.controls}

  getPendingHolds() {
    this.show = false;   
    this.holdsService.getPendingHolds()
    .subscribe((holds: IPendingHolds[]) => {
      holds.length > 0 ? this.show = true : this.show = false;
      this.progressBarFlag = false;
      this.pendingHolds = holds;
    },
    (err) => {
      console.log(err)
      this.progressBarFlag = false;
      this.message = "Ooops something went wrong!!"
    })
  }
  
  private initForm() {
    // this.form = this.fb.group({
    //   prevBalance: [''],
    //   prevPayment: [''],
    //   prevDate: [''],
    //   card: [''],
    //   newBalance: [''],
    //   newPayment: [''],
    //   newDate: [''],
    //   acceptTerms: [false]
    // });
    this.form = this.fb.group({
      acceptTerms: [false, Validators.requiredTrue]
    })
    
  }

  openChangeCardModal() {
    this.modalService.openChangeCreditCard();
  }

  onAuthorize() {
    this.submitted = true;
    
    if (this.form.invalid) { return; }
    this.holdsService.acceptHolds(this.pendingHolds[0].loan).subscribe(
      res => {
        this.modalService.openInfo({
          title: 'Information',
          description: [`${res}`],
          buttonText: 'OK'
        }).subscribe(() => {
          this.getPendingHolds();
          this.onBack();
        });
        this.submitted = false;
      },
      error => {
        if (error.status === 401) {
          this.modalService.openInfo({
            title: 'Unauthorized',
            description: [
              error.error.detail
            ],
            buttonText: 'OK'
          }).subscribe(() => {
            this.onBack();
          });
        }
      }
    );
  }

  public onBack(): void {
    this.router.navigate(['profile', 'new-holds-authorization']);
  }
}
