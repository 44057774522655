<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <div class="al-modal-title">
      Are you sure you do not want the benefits of <i>ApexPay</i>?
    </div>
    <ul class="list">
      <li>Receive 0.25% off the rate you pay</li>
      <li>No late fees</li>
      <li>No returned payment fees</li>
    </ul>
    <div class="controls">
      <app-button
        class="controls__btn"
        color="white"
        (pressed)="dialogRef.close(true)"
      >Yes</app-button>
      <app-button
        class="controls__btn"
        (pressed)="dialogRef.close(false)"
      >No</app-button>
    </div>
  </div>
</div>
