import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Injector,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  Output,
  EventEmitter, forwardRef
} from '@angular/core';
import { NgControl, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOptions, ISmartOption, ISmartOptionShort } from '../select/select.component';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    }
  ]
})
export class RadioGroupComponent implements AfterViewInit, OnChanges {
  @Input() label: string;
  @Input() loading: boolean;
  @Input() options : IOptions | (ISmartOption | ISmartOptionShort)[];
  @Input() isError: boolean;
  @Input() correctValue: string;
  @Input() direction: 'row' | 'column' = 'row';
  @Input() isPreview: boolean;
  @Output() changed = new EventEmitter();
  private control: FormControl;
  public optionsOutput = [];
  public model;

  public onTouched: () => void = () => {};
  public onChange: (value: string) => void = (value: string) => {
    this.changed.emit(value);
  };

  constructor(private injector: Injector) {}

  ngAfterViewInit() {
    this.initFormControl();
  }

  ngOnChanges({options}: SimpleChanges) {
    if (options && this.options) {
      if (Array.isArray(this.options) && this.options.length > 0) {
        const first = this.options.find(Boolean);
        if (Array.isArray(first)) {
          this.optionsOutput = this.options.map((item) => {
            const [key, value] = item as ISmartOptionShort;
            return { key, value };
          });
        } else {
          this.optionsOutput = <ISmartOption[]>this.options;
        }
      } else {
        const entries = Object.entries(this.options);
        this.optionsOutput = entries.map(([key, value]) => ({ key, value }));
      }
    }
  }

  public writeValue(value: string) {
    if (this.model !== value) {
      this.model = value;
      this.onChange(value);

      if (this.control) {
        this.control.setValue(value);
      }

      return value;
    }
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouched = fn;
  }

  private initFormControl() {
    if (this.injector) {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      } else {
        console.warn('Component is missing form control binding');
      }
    }
  }

  get isControlError(): boolean {
    if (!this.control) {
      return false;
    }

    if (this.correctValue) {
      return this.control.value !== this.correctValue;
    }

    return this.control.touched && (!this.control.valid || this.isError);
  }
}
