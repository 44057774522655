import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-privacy-policy-radio',
  templateUrl: './privacy-policy-radio.component.html',
  styleUrls: ['./privacy-policy-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyRadioComponent implements OnChanges {
  @Input() label = 'You must approve for us to share';
  @Input() defaultValue = false;
  @Output() changed = new EventEmitter<boolean>();
  public model = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.defaultValue) {
      this.model = changes.defaultValue.currentValue;
    }
  }

  public onChanged(event: MatRadioChange) {
    this.changed.emit(event.value);
  }
}
