<app-navigation-bar>
  <div class="head-touch-screen">
    <app-slideshow class="head-touch-screen__slideshow"></app-slideshow>
    <div class="head-touch-screen__title">
      Your Path To Becoming <br />
      Debt-Free Begins Here
    </div>
  </div>
  <app-send-input-form
    class="input-form input-form--offer input-form--offer-mobile"
    [minLength]="4"
    [maxLength]="4"
    [showLabel]="true"
    [labelText]="'You’ve been pre-qualified for a personal loan'"
    [inputPlaceholder]="'offer code'"
    [submitButtonMode]="'blue'"
    [submitButtonText]="'Continue'"
    [showHelpLink]="true"
    [helpLinkText]="'Where do I find my code?'"
    [helpLinkUrl]="'#'"
    (helpLinkClick)="openFindCodeModal()"
    (submitted)="onEnterOfferCodeSubmit($event)"
  ></app-send-input-form>
  <div class="container container-main-page">
    <app-send-input-form
      class="input-form input-form--offer input-form--offer-desktop"
      [minLength]="4"
      [maxLength]="4"
      [showLabel]="true"
      [labelText]="'You’ve been pre-qualified for a personal loan'"
      [inputPlaceholder]="'Enter your offer code'"
      [submitButtonMode]="'blue'"
      [submitButtonText]="'Continue'"
      [showHelpLink]="true"
      [helpLinkText]="'Where do I find my code?'"
      [helpLinkUrl]="'#'"
      (helpLinkClick)="openFindCodeModal()"
      (submitted)="onEnterOfferCodeSubmit($event)"
    ></app-send-input-form>
    <app-send-input-form
      class="input-form input-form--email"
      [showLabel]="true"
      [labelText]="'Sign Up for future offer codes'"
      [inputPlaceholder]="'E-mail'"
      [submitButtonMode]="'white'"
      [submitButtonText]="'Sign Up'"
      (submitted)="onSubscribeSubmit($event)"
      type="email"
    ></app-send-input-form>
  </div>
  <div class="separator"></div>
  <div class="video-section">
    <app-slideshow 
      class="video-section__slideshow"
      [disableAutoScroll]="videoPlaying$ | async"
    ></app-slideshow>
    <div
      class="container container-video"
      [class.container-video--centered]="videoPlaying$ | async"
      [class.container-video--disabled]="!(videoPlaying$ | async)"
    >
      <div class="video-container-inner">
        <video
          #video
          class="video-js"
          id="main-video"
          controls
          preload="auto"
          poster="/assets/video/poster-main.jpg"
          data-setup="{}"
        >
          <source src="/assets/video/main-video.mp4" type="video/mp4" />
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
          </p>
        </video>
      </div>
    </div>
  </div>
  <app-footer class="footer"></app-footer>
</app-navigation-bar>
