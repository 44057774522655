<div class="terms" [class.loading]="loading">
  <div class="terms__header">
    <div class="terms__cell">Loan Term</div>
    <div class="terms__cell">Balance</div>
    <div class="terms__cell">Interest Rate</div>
    <div class="terms__cell">APR</div>
    <div class="terms__cell">Bi-Weekly Payment</div>
    <div class="terms__cell terms__cell_input"></div>
  </div>
  <div *ngFor="let term of terms" class="terms__term">
    <div class="terms__cell">
      {{ term?.term }} month
    </div>
    <div class="terms__cell">
      <span class="terms__label">Balance</span>
      $ {{ term?.amount | round }}
    </div>
    <div class="terms__cell">
      <span class="terms__label">Interest Rate</span>
      {{ term?.interest_rate | round }}%
    </div>
    <div class="terms__cell">
      <span class="terms__label">APR</span>
      {{ term?.apr | round }}%
    </div>
    <div class="terms__cell">
      <span class="terms__label">Bi-Weekly Payment</span>
      <!-- TODO: ask about weekly payments -->
      $ {{ term?.pmt | round }}
    </div>
    <div class="terms__cell terms__cell_input">
      <input type="radio" [value]="term?.term" [id]="term?.term" [(ngModel)]="selectedTerm" (change)="onChange()"/>
    </div>
  </div>
</div>
