<div class="information" [class.loading]="loading">
  <div class="information__column">
    <div class="information__column__line">
      <span class="information__column__line__title">Loan Amount: </span>
      <span class="information__column__line__value">$ {{ loan?.amount | thousandsSeparators }}</span>
    </div>
    <div class="information__column__line">
      <span class="information__column__line__title">Origination Fee: </span>
      <span class="information__column__line__value">$ {{ loan?.originationFeePct | thousandsSeparators }}</span>
    </div>
    <div class="information__column__line">
      <span class="information__column__line__title">Proceeds Sent to Credit Card: </span>
      <span class="information__column__line__value">$ {{ proceeds | thousandsSeparators }}</span>
    </div>
  </div>

  <div class="information__column">
    <div class="information__column__line">
      <span class="information__column__line__title">Interest Rate per Annum: </span>
      <span class="information__column__line__value">{{ loan?.interestRatePct | round }}%</span>
    </div>
    <div class="information__column__line">
      <span class="information__column__line__title">APR: </span>
      <span class="information__column__line__value">{{ loan?.apr | round }}%</span>
    </div>
    <div class="information__column__line">
      <span class="information__column__line__title">Bi-weekly Payment: </span>
      <span class="information__column__line__value">$ {{ loan?.payment | thousandsSeparators }}</span>
    </div>
  </div>

  <div class="information__column">
    <div class="information__column__line">
      <span class="information__column__line__title">Closing Date: </span>
      <span class="information__column__line__value">{{ loan?.closingDate }}</span>
    </div>
    <div class="information__column__line">
      <span class="information__column__line__title">First Payment Date: </span>
      <span class="information__column__line__value">{{ firstPaymentDate | date:'yyyy-MM-dd' : 'UTC' }}</span>
    </div>
    <div class="information__column__line">
      <span class="information__column__line__title">Last Payment Date: </span>
      <span class="information__column__line__value">{{ lastPaymentDate | date:'yyyy-MM-dd' : 'UTC' }}</span>
    </div>
  </div>
</div>
