import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PrivacyPolicyService, IConsent } from 'src/app/user/services/privacy-policy/privacy-policy.service';
import { IOffer } from 'src/app/user/models/api/response/login-response';
import { UserService } from 'src/app/user/services/user/user.service';
import { shareReplay } from 'rxjs/operators';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { FaqDtiModalComponent } from 'src/app/shared/entry/faq-dti-modal/faq-dti-modal.component';
import { EConsent } from 'src/app/shared/models/enums/consent.enum';

@Component({
  selector: 'app-debt-to-income-comparison-container',
  templateUrl: './debt-to-income-comparison-container.component.html',
  styleUrls: ['./debt-to-income-comparison-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebtToIncomeComparisonContainerComponent implements OnInit {
  @Input() spinner: boolean;
  public requestPending = false;
  public requiredAccepted = false;
  public progressBar: boolean = true;

  public loading$ = new BehaviorSubject<boolean>(true);
  public agreed$ = new BehaviorSubject<boolean>(false);

  public offer$: Observable<IOffer>;
  public consent$: Observable<any>;
  public dtiCurrent$: Observable<number>;

  public privacyConsent$: Observable<IConsent>;
  public termsConsent$: Observable<IConsent>;
  public esignConsent$: Observable<IConsent>;

  private requiredConsentTypes = [EConsent.Privacy, EConsent.Terms, EConsent.ESign];

  constructor(
    private router: Router,
    private userService: UserService,
    private customerService: CustomerService,
    private modalService: ModalService,
    public privacyPolicyService: PrivacyPolicyService
  ) {}

  ngOnInit() {
    this.offer$ = this.userService.findMailOffer(false, this.userService.offerCode).pipe(shareReplay(1));
    this.dtiCurrent$ = this.customerService.getCustomerDti();
    this.privacyConsent$ = this.privacyPolicyService.getConsent(EConsent.Privacy);
    this.termsConsent$ = this.privacyPolicyService.getConsent(EConsent.Terms);
    this.esignConsent$ = this.privacyPolicyService.getConsent(EConsent.ESign);

    forkJoin([
      this.privacyPolicyService.requestConsents(),
      this.dtiCurrent$,
      this.offer$
    ]).subscribe(([consents]) => {
      const required = this.requiredConsentTypes.map(t => consents.find(i => i.consent.name === t));
      this.requiredAccepted = required.every(i => i && i.accepted);
      this.agreed$.next(this.requiredAccepted);
      this.loading$.next(false);
      this.progressBar = false;
    });
  }

  public onSubmitted(): void {
    this.requestPending = true;
    this.spinner = true;
    this.router.navigateByUrl('steps/loan-options');
  }

  public onTitleHintClick() {
    this.modalService.open(FaqDtiModalComponent);
  }

  public toggleAgreed(value: boolean) {
    this.agreed$.next(value);
  }

  get currentRoute(): string {
    return this.router.url;
  }
}
