import { EPlaidEnvironment } from "src/app/shared/models/enums/plaid.enum";

export const environment: any = {
    production: false, // Debug mode adds DebugModule with its helpers
    debug: {
      enableToggle: true,
      disableGuards: true,
      disableLogoutOnTimeOut: true,
      saveLoginTokenOnSteps: true,
    },
    log: true,
    withRecaptcha: false,
    apiUrl: 'http://127.0.0.1:8000',
    googleCaptchaKey: '6LfUYaMUAAAAAFRNoXUFvFjouHSekHrz83q9kI0u',
    plaidEnv: EPlaidEnvironment.Development, //Sandbox,
    plaidPublicKey: '36e037bcdc57e933074e39383e60b2',
    purechatToken: '2e117b7d-76ae-4c28-a568-5a348c8e2a34',
    artibotToken: '2f4d4e71-c228-402b-bf38-e8bdcfa683f6',
    googleAnalyticsToken: 'UA-123742411-1'
 }