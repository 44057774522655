export enum ELoanPaymentRequestType {
  Payment = 'PAYMENT', 
  Principal = 'PRINCIPAL'
}

export enum ELoanPaymentRequestStatus {
  Submitted = 'SUBMITTED', 
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED'
}