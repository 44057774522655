import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ContactService } from '../../services/contact.service'

// export interface IContacts {
//   helpEmail?: string;
//   ContactNumber?: number;
//   tollFreeNumber?: number;
//   name?: string;
//   address?: {
//     address1?: string;
//     address2?: string;
//     city?: string;
//     state?: string;
//     zipCode?: number;
//   };
// }
@Component({
  selector: 'app-contacts-container',
  templateUrl: './contacts-container.component.html',
  styleUrls: ['./contacts-container.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsContainerComponent implements OnInit {
  public contact: any;
  constructor(private contactService : ContactService) {
    this.contact = {};
  }

  ngOnInit() {
    this.contactService.getContactInfo().subscribe( res => {
      this.contact = res[0];
    })
  }
}
