import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { forkJoin, of, BehaviorSubject } from 'rxjs';
import { timeout, catchError, first, skip } from 'rxjs/operators';

declare const window: Window & { purechatApi: any; artibotApi: any; };
declare const PCWidget: any;
declare const ArtiBot: any;

export interface IVisitorInfo {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  question: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  purechatToken = environment.purechatToken;
  artibotToken = environment.artibotToken;
  purechatLoaded$ = new BehaviorSubject<boolean>(false);
  artibotLoaded$ = new BehaviorSubject<boolean>(false);

  get purechatApi() {
    return window.purechatApi;
  }
  get artibotApi() {
    return window.artibotApi;
  }

  init() {
    // will uncomment this when we ready to deploy/release to production
    
    // this.initArtibot();
    // this.initPurechat();
    // this.configureWidgets();
  }

  initPurechat() {
    window.purechatApi = {
       l: [],
       t: [],
       on: function () { this.l.push(arguments); }
    };
    let done = false;
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript';
    document.getElementsByTagName('HEAD').item(0).appendChild(script);
    script.onload = () => {
      if (!done) {
        const w = new PCWidget({c: this.purechatToken, f: true });
        done = true;
      }
    };
  }

  initArtibot() {
    window.artibotApi = {
      l: [],
      t: [],
      on: function() { this.l.push(arguments) },
      trigger: function(){ this.t.push(arguments) }
    };
    let done = false;
    const script = document.createElement('script');
    script.async = true; 
    script.type = 'text/javascript';
    script.src = 'https://app.artibot.ai/loader.js';
    document.getElementsByTagName('head').item(0).appendChild(script);
    script.onload = () => {
      if (!done) { 
        const w = new ArtiBot({i: this.artibotToken});
        done = true;
      }
    }
  }

  configureWidgets() {
    this.artibotApi.trigger('bot:hide');

    this.artibotApi.on('bot:ready', () => this.artibotLoaded$.next(true));
    this.purechatApi.on('chatbox:ready', () => {
      this.purechatApi.set('chatbox.unavailableBehavior', 'hide');
      this.purechatApi.set('chatbox.visible', false);
      const available = this.purechatApi.get('chatbox.available');
      if (available) { 
        this.purechatLoaded$.next(true);
      } else {
        this.purechatLoaded$.next(false);
      }
    });

    forkJoin({
      chatReady: this.purechatLoaded$.pipe(skip(1), first(), timeout(6000), catchError(() => of(false))),
      botReady: this.artibotLoaded$.pipe(skip(1), first(), timeout(3000), catchError(() => of(false)))
    }).subscribe(({chatReady, botReady}) => {
      if (chatReady) {
        this.purechatApi.set('chatbox.visible', true);
      } else if (botReady) {
        this.artibotApi.trigger('bot:show');
      }
    });
  }

  setUserInfo(info: Partial<IVisitorInfo>) {
    if (this.purechatLoaded$.getValue()) {
      Object.entries(info).forEach(([key, value]) => {
        window.purechatApi.set(`visitor.${key}`, value);
      });
    }
  }
}