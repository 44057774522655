<div class="header-container">
  <app-header [isFinalSteps]="true">
    <div
      class="header__username"
    >Welcome, {{ username$ | async }}</div>
    <app-button
      class="header__logout"
      icon="logout"
      (pressed)="onLoggedOut()"
    >Log Out</app-button>
  </app-header>

  <div class="page-title">
    <div
      *ngIf="currentStep === 9"
      class="page-title__back-link"
      (click)="goBackToPreviousSteps()"
    >Back to rate fields</div>
    <div class="page-title__title">Final steps</div>
  </div>

  <div class="stepper-container">
    <app-stepper [isFinal]="true" [steps]="steps" (selected)="onSelectedStep($event)"></app-stepper>
  </div>
</div>

<div class="container page-container">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
