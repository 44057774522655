<app-header></app-header>
<div class="container page-container">
  <h1>About Us</h1>
  <p>
    We are about you.<br />
    About helping you manage your debt.
  </p>
  <p>
    When used appropriately, debt can be a healthy part of your long-term financial future, and ApexLend wants
    to help you take charge.
  </p>
  <p>
    We sweat the small stuff, like matching your loan payment frequency to your pay check frequency so you don’t have
    to. What’s more, we pay your credit card directly, saving you money, freeing up more time and helping you take
    control. Our loans are designed to be repaid with interest rates as low as 5% and a 5% APR. Whether it’s $4000 or
    $40,000 you need, to get your debt under control, let ApexLend lead you comfortably towards financial independence.
  </p>
  <p>
    ApexLend<br />
    Helping you manage your debt
  </p>
  <div class="disclaimer">
    *Rates, APRs, Fees, and Loan Amounts may vary based on your state of residence
  </div>
</div>
<app-footer></app-footer>
