import { ISmartOption } from "../components/select/select.component";

export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const COUNTRIES = {
  Afghanistan: 'Afghanistan',
  Albania: 'Albania',
  Algeria: 'Algeria',
  Andorra: 'Andorra',
  Angola: 'Angola',
  Anguilla: 'Anguilla',
  // 'Antigua & Barbuda': 'Antigua & Barbuda',
  Argentina: 'Argentina',
  Armenia: 'Armenia',
  Australia: 'Australia',
  Austria: 'Austria',
  Azerbaijan: 'Azerbaijan',
  Bahamas: 'Bahamas',
  Bahrain: 'Bahrain',
  Bangladesh: 'Bangladesh',
  Barbados: 'Barbados',
  Belarus: 'Belarus',
  Belgium: 'Belgium',
  Belize: 'Belize',
  Benin: 'Benin',
  Bermuda: 'Bermuda',
  Bhutan: 'Bhutan',
  Bolivia: 'Bolivia',
  'Bosnia & Herzegovina': 'Bosnia & Herzegovina',
  Botswana: 'Botswana',
  Brazil: 'Brazil',
  'Brunei Darussalam': 'Brunei Darussalam',
  Bulgaria: 'Bulgaria',
  'Burkina Faso': 'Burkina Faso',
  'Myanmar/Burma': 'Myanmar/Burma',
  Burundi: 'Burundi',
  Cambodia: 'Cambodia',
  Cameroon: 'Cameroon',
  Canada: 'Canada',
  'Cape Verde': 'Cape Verde',
  'Cayman Islands': 'Cayman Islands',
  'Central African Republic': 'Central African Republic',
  Chad: 'Chad',
  Chile: 'Chile',
  China: 'China',
  Colombia: 'Colombia',
  Comoros: 'Comoros',
  Congo: 'Congo',
  'Costa Rica': 'Costa Rica',
  Croatia: 'Croatia',
  Cuba: 'Cuba',
  Cyprus: 'Cyprus',
  'Czech Republic': 'Czech Republic',
  Denmark: 'Denmark',
  Djibouti: 'Djibouti',
  'Dominican Republic': 'Dominican Republic',
  Dominica: 'Dominica',
  Ecuador: 'Ecuador',
  Egypt: 'Egypt',
  'El Salvador': 'El Salvador',
  'Equatorial Guinea': 'Equatorial Guinea',
  Eritrea: 'Eritrea',
  Estonia: 'Estonia',
  Ethiopia: 'Ethiopia',
  Fiji: 'Fiji',
  Finland: 'Finland',
  France: 'France',
  'French Guiana': 'French Guiana',
  Gabon: 'Gabon',
  Gambia: 'Gambia',
  Georgia: 'Georgia',
  Germany: 'Germany',
  Ghana: 'Ghana',
  'Great Britain': 'Great Britain',
  Greece: 'Greece',
  Grenada: 'Grenada',
  Guadeloupe: 'Guadeloupe',
  Guatemala: 'Guatemala',
  Guinea: 'Guinea',
  'Guinea-Bissau': 'Guinea-Bissau',
  Guyana: 'Guyana',
  Haiti: 'Haiti',
  Honduras: 'Honduras',
  Hungary: 'Hungary',
  Iceland: 'Iceland',
  India: 'India',
  Indonesia: 'Indonesia',
  Iran: 'Iran',
  Iraq: 'Iraq',
  Israel: 'Israel',
  Italy: 'Italy',
  'Ivory Coast': 'Ivory Coast',
  Jamaica: 'Jamaica',
  Japan: 'Japan',
  Jordan: 'Jordan',
  Kazakhstan: 'Kazakhstan',
  Kenya: 'Kenya',
  Kosovo: 'Kosovo',
  Kuwait: 'Kuwait',
  Kyrgyzstan: 'Kyrgyzstan',
  Laos: 'Laos',
  Latvia: 'Latvia',
  Lebanon: 'Lebanon',
  Lesotho: 'Lesotho',
  Liberia: 'Liberia',
  Libya: 'Libya',
  Liechtenstein: 'Liechtenstein',
  Lithuania: 'Lithuania',
  Luxembourg: 'Luxembourg',
  Macedonia: 'Macedonia',
  Madagascar: 'Madagascar',
  Malawi: 'Malawi',
  Malaysia: 'Malaysia',
  Maldives: 'Maldives',
  Mali: 'Mali',
  Malta: 'Malta',
  Martinique: 'Martinique',
  Mauritania: 'Mauritania',
  Mauritius: 'Mauritius',
  Mayotte: 'Mayotte',
  Mexico: 'Mexico',
  Moldova: 'Moldova',
  Monaco: 'Monaco',
  Mongolia: 'Mongolia',
  Montenegro: 'Montenegro',
  Montserrat: 'Montserrat',
  Morocco: 'Morocco',
  Mozambique: 'Mozambique',
  Namibia: 'Namibia',
  Nepal: 'Nepal',
  Netherlands: 'Netherlands',
  'New Zealand': 'New Zealand',
  Nicaragua: 'Nicaragua',
  Niger: 'Niger',
  Nigeria: 'Nigeria',
  'North Korea': 'North Korea',
  Norway: 'Norway',
  Oman: 'Oman',
  'Pacific Islands': 'Pacific Islands',
  Pakistan: 'Pakistan',
  Panama: 'Panama',
  'Papua New Guinea': 'Papua New Guinea',
  Paraguay: 'Paraguay',
  Peru: 'Peru',
  Philippines: 'Philippines',
  Poland: 'Poland',
  Portugal: 'Portugal',
  'Puerto Rico': 'Puerto Rico',
  Qatar: 'Qatar',
  Reunion: 'Reunion',
  Romania: 'Romania',
  'Russian Federation': 'Russian Federation',
  Rwanda: 'Rwanda',
  'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
  'Saint Lucia': 'Saint Lucia',
  'Saint Vincents & Grenadines': 'Saint Vincents & Grenadines',
  Samoa: 'Samoa',
  'Sao Tome and Principe': 'Sao Tome and Principe',
  'Saudi Arabia': 'Saudi Arabia',
  Senegal: 'Senegal',
  Serbia: 'Serbia',
  Seychelles: 'Seychelles',
  'Sierra Leone': 'Sierra Leone',
  Singapore: 'Singapore',
  'Slovak Republic': 'Slovak Republic',
  Slovenia: 'Slovenia',
  'Solomon Islands': 'Solomon Islands',
  Somalia: 'Somalia',
  'South Africa': 'South Africa',
  'South Korea': 'South Korea',
  'South Sudan': 'South Sudan',
  Spain: 'Spain',
  'Sri Lanka': 'Sri Lanka',
  Sudan: 'Sudan',
  Suriname: 'Suriname',
  Swaziland: 'Swaziland',
  Sweden: 'Sweden',
  Switzerland: 'Switzerland',
  Syria: 'Syria',
  Tajikistan: 'Tajikistan',
  Tanzania: 'Tanzania',
  Thailand: 'Thailand',
  'Timor Leste': 'Timor Leste',
  Togo: 'Togo',
  'Trinidad & Tobago': 'Trinidad & Tobago',
  Tunisia: 'Tunisia',
  Turkey: 'Turkey',
  Turkmenistan: 'Turkmenistan',
  'Turks & Caicos': 'Turks & Caicos',
  Uganda: 'Uganda',
  Ukraine: 'Ukraine',
  'United Arab Emirates': 'United Arab Emirates',
  'United States of America': 'United States of America',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Uzbekistan',
  Venezuela: 'Venezuela',
  Vietnam: 'Vietnam',
  Yemen: 'Yemen',
  Zambia: 'Zambia',
  Zimbabwe: 'Zimbabwe'
};

export const EMPLOYMENT = {
  E: 'Employed',
  U: 'Unemployed',
  S: 'Self-employed',
  R: 'Retired'
};

export const CITIZENSHIP = {
  U: 'US Citizen',
  P: 'Permanent Resident',
  N: 'Non-US Citizen'
};

export const HOME_OWNERSHIP = {
  M: 'Mortgage',
  O: 'Own',
  R: 'Rent',
  T: 'Other'
};

export const INCOME_VERIFICATION = {
  N: 'Not Verified',
  S: 'Source Verified',
  V: 'Verified'
};

export const INCOME = {
  1: 'Less than 10,000',
  2: '10,000-50,000',
  3: '50,000-100,000',
  4: 'Over 100,000'
};

export const PURPOSE = {
  1: 'Credit Card Refinancing',
  2: 'Debt Consolidation',
  3: 'Home Improvement',
  4: 'Major Purchase',
  5: 'Home Buying',
  6: 'Car Financing',
  7: 'Green Loan',
  8: 'Business',
  9: 'Vacation',
  10: 'Moving or Relocation',
  11: 'Medical Expenses',
  12: 'Other'
};

export const CARD_TYPE_SHORT = {
  V: 'Visa',
  M: 'MasterCard',
  A: 'American Express',
  D: 'Discover'
};

export type CardTypeShortKeys = keyof typeof CARD_TYPE_SHORT;

export const CARD_TYPE = {
  Visa: 'Visa',
  MasterCard: 'MasterCard',
  AmericanExpress: 'American Express',
  Discover: 'Discover'
};

export type CardTypeKeys = keyof typeof CARD_TYPE;

export const INCOME_TYPES = [
  'BASE', 'OVERTIME', 'BONUS', 'COMMISSION', 'DIVIDEND', 'OTHER',
];

export const DEBT_TYPES = [
  'HOME', 'AUTO', 'INSTALLMENT', 'REVOLVING', 'STUDENT', 'OTHER'
];

export const disabledCardTypesHold: CardTypeKeys[] = ['AmericanExpress'];

export function createCardTypesForHold(disabled = disabledCardTypesHold): ISmartOption[] {
  return Object.entries(CARD_TYPE).map(([key, value]) => {
    const isDisabled = disabled.includes(<CardTypeKeys>key);
    return {
      value: `${value}${isDisabled ? ' (not available)' : ''}`,
      key,
      disabled: isDisabled
    }
  }).reverse().sort((a, b) => Number(a.disabled) - Number(b.disabled));
}