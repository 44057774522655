import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import {
  IBankAccount,
  ICustomer,
  IEmploymentType,
  ILoan,
  IZipCode,
  IZipCodeResponse,
  IIncome,
  IBalanceDebt,
  IPeriodicDebt,
  IEmployment,
  IBankRouting,
  IHoldCard,
  IPayOffAccount,
  IPayee,
  IHoldCardResponse,
  ILoanPaymentRequest,
  ILoanDocument,
  ISignedDocumentRequest,
  ISignedDocument,
  IUsersSetting
} from '../../models/customer.model';
import { ApiService } from '../api/api.service';
import { IApplication, IApplicationTerm } from 'src/app/user/models/user.models';
import { UserService } from 'src/app/user/services/user/user.service';
import { ELoanPaymentRequestType } from '../../models/enums/loan-payment-request.enum';
import { ExcludeReadonly } from '../../types/exclude-readonly.type';
import { parseDate } from '../../helpers/parse-date';
import { ESignedDocumentStatus } from '../../models/enums/signed-document-status.enum';
import { ILinkToken } from '../plaid/plaid.service';

export interface ILoanPayment {
  accrual_days?: number,
  consent_date?: string,
  due_date: string,
  starting_balance: number,
  ending_balance?: number,
  payment: number,
  principal?: number,
  interest?: number,
}

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  // public cachedCards: { holdCard: ICustomerCard; payoffCard: ICustomerCard };
  private bankRoutingCache: { [routing: string]: IBankRouting } = {};
  private cache: any = {};
  private dti: number = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {
    this.userService.logoutEvent.subscribe(() => {
      this.bankRoutingCache = {};
      this.cache = {};
      this.dti = null;
      this.customerState = null;
    });
  }

  get customerState(): string {
    const state = localStorage.getItem('state');
    return state ? state : null;
  }

  set customerState(state: string) {
    state ? localStorage.setItem('state', `${state}`) : localStorage.removeItem('state');
  }

  getCustomerDti(): Observable<number> {
    if (this.dti) {
      return of(this.dti);
    }
    return this.get(this.userService.customerId).pipe(
      shareReplay(1),
      map(({ debt_to_income }) => debt_to_income),
      tap(dti => this.dti = dti)
    );
  }

  get(id: number): Observable<ICustomer> {
    const url = `customers/${id}`;
    return this.apiService.get<ICustomer[] | ICustomer>(url).pipe(
      map(res => (Array.isArray(res) ? res[0] : res)),
      tap(res => this.customerState = res.address ? res.address.state : null),
    );
  }

  patch(customer: ICustomer, id: number): Observable<ICustomer> {
    if (customer.address && customer.address.state) {
      this.customerState = customer.address.state;
    }
    const url = `customers/${id}`;
    return this.apiService.patch<ICustomer>(url, customer);
  }

  getEmployments(customerId: number): Observable<IEmployment[]> {
    if (this.cache['employments']) {
      return of(this.cache['employments']);
    }
    return this.apiService.get(`customers/${customerId}/employment`)
      .pipe(
        tap((employments: IEmployment[]) => {
          this.cache['employments'] = employments;
        })
      );
  }

  postEmployment(customerId: number, employment: IEmployment) {
    delete this.cache['employments'];
    return this.apiService.post(`customers/${customerId}/employment`, employment);
  }

  getUsersSetting(customerId: number): Observable<IUsersSetting[]> {
    if (this.cache['usersSetting']) {
      return of(this.cache['usersSetting']);
    }
    return this.apiService.get(`customers/${customerId}/users-setting`)
      .pipe(
        tap((usersSetting: IUsersSetting[]) => {
          this.cache['usersSetting'] = usersSetting;
        })
      );
  }

  patchUsersSetting(customerId: number, userSettingID: number, userSetting: any) {
    delete this.cache['usersSetting'];
    return this.apiService.patch(`customers/${customerId}/users-setting/${userSettingID}`, userSetting);
  }

  postUsersSetting(customerId: number, userSetting: IUsersSetting) {
    delete this.cache['usersSetting'];
    return this.apiService.post(`customers/${customerId}/users-setting`, userSetting);
  }

  getBalanceDebts(customerId: number): Observable<IBalanceDebt[]> {
    if (this.cache['balanceDebts']) {
      return of(this.cache['balanceDebts']);
    }
    return this.apiService.get(`customers/${customerId}/balance-debt`)
      .pipe(
        tap((debts: IBalanceDebt[]) => {
          this.cache['balanceDebts'] = debts;
        }),
      );
  }

  postBalanceDebt(customerId: number, debt: IBalanceDebt) {
    delete this.cache['balanceDebts'];
    this.dti = null;
    return this.apiService.post(`customers/${customerId}/balance-debt`, debt);
  }

  getIncomes(customerId: number): Observable<IIncome[]> {
    if (this.cache['incomes']) {
      return of(this.cache['incomes']);
    }
    return this.apiService.get(`customers/${customerId}/income`)
      .pipe(
        tap((incomes: IIncome[]) => {
          this.cache['incomes'] = incomes;
        }),
      );
  }

  postIncome(customerId: number, income: IIncome) {
    delete this.cache['incomes'];
    this.dti = null;
    return this.apiService.post(`customers/${customerId}/income`, income);
  }

  getPeriodicDebts(customerId: number): Observable<IPeriodicDebt[]> {
    if (this.cache['periodicDebts']) {
      return of(this.cache['periodicDebts']);
    }
    return this.apiService.get(`customers/${customerId}/periodic-debt`)
      .pipe(
        tap((debts: IPeriodicDebt[]) => {
          this.cache['periodicDebts'] = debts;
        }),
      );
  }

  postPeriodicDebt(customerId: number, debt: IPeriodicDebt) {
    delete this.cache['periodicDebts'];
    this.dti = null;
    return this.apiService.post(`customers/${customerId}/periodic-debt`, debt);
  }

  getApplications(customerId: number): Observable<IApplication[]> {
    return this.apiService.get(`customers/${customerId}/applications`);
  }

  getApplicationLoan(applicationId: number): Observable<ILoan> {
    return this.apiService.get(`applications/${applicationId}/loan`);
  }

  getApplicationTerms(applicationId: number): Observable<IApplicationTerm[]> {
    return this.apiService.get(`applications/${applicationId}/offered-terms`);
  }

  postApplicationTerms(applicationId: number, term: number) {
    return this.apiService.post(`applications/${applicationId}/offered-terms`, {term: term});
  }

  getPaymentSchedule(applicationId: number): Observable<ILoanPayment[]> {
    return this.apiService.get(`applications/${applicationId}/payment-schedule`);
  }

  patchApplication(applicationId: number, application: any): Observable<IApplication> {
    if (application.step) {
      this.userService.step = application.step;
    }
    return this.apiService.patch(`applications/${applicationId}`, application);
  }

  /*getCreditReports(customerId: number): Observable<ICustomerCreditScore[]> {
    if (this.cache['creditReports']) {
      return of(this.cache['creditReports']);
    }
    return this.apiService.get(`customers/${customerId}/credit-reports`)
      .pipe(
        tap((reports: ICustomerCreditScore[]) => {
          this.cache['creditReports'] = reports;
        })
      );
  }*/

  getHoldCardsList(applicationId: number): Observable<IHoldCardResponse[]> {
    return this.apiService.get(`applications/${applicationId}/hold-cards`);
  }

  getHoldCard(applicationId: number): Observable<IHoldCard> {
    return this.apiService.get(`applications/${applicationId}/hold-card`);
  }

  addHoldCard(applicationId: number, card: IHoldCard): Observable<IHoldCard> {
    return this.apiService.post(`applications/${applicationId}/hold-card`, card);
  }

  patchHoldCard(applicationId: number, card: Partial<IHoldCard>): Observable<IHoldCard> {
    return this.apiService.patch(`applications/${applicationId}/hold-card`, card);
  }

  deleteHoldCard(applicationId: number, cardId: number) {
    return this.apiService.delete(`applications/${applicationId}/hold-cards/${cardId}`);
  }

  getPayOffAccount(applicationId: number): Observable<IPayOffAccount> {
    return this.apiService.get(`applications/${applicationId}/payoff-account`);
  }

  addPayOffAccount(applicationId: number, account: IPayOffAccount): Observable<IPayOffAccount> {
    return this.apiService.post(`applications/${applicationId}/payoff-account`, account);
  }

  findPayees(searchTerm?: string): Observable<IPayee[]> {
    return this.apiService.get(`payees${searchTerm ? `?search=${searchTerm}` : ''}`);
  }

  getZipCode(code: string): Observable<IZipCode> {
    return this.apiService
      .get<IZipCodeResponse>(`zipcodes/search?zipCode=${code}`)
      .pipe(map(zip => ({ ...zip, area_codes: zip.area_codes.split(',') })));
  }

  getLoans(customerId: number): Observable<ILoan[]> {
    return this.apiService
      .get<ILoan[]>(`customers/${customerId}/loans`);
  }

  getLoanPaymentHistory(loanId: number): Observable<ILoanPayment[]> {
    return this.apiService.get(`loans/${loanId}/payments`);
  }

  getLoanFuturePayments(loanId: number): Observable<ILoanPayment[]> {
    return this.apiService.get(`loans/${loanId}/schedule`);
  }

  createPayment(loanId: number, type: ELoanPaymentRequestType, amount: number, payment_date: string): Observable<ILoanPaymentRequest> {
    return this.apiService.post(`loans/${loanId}/payments`, {type, amount, payment_date});
  }

  getBankAccounts(loanId?: number): Observable<IBankAccount[]> {
    if (!loanId) { loanId = this.userService.applicationId; }
    return this.apiService.get(`applications/${loanId}/bank-accounts`);
  }

  patchBankAccount(bankAccount: Partial<IBankAccount>, loanId?: number): Observable<IBankAccount> {
    if (!loanId) { loanId = this.userService.applicationId; }
    return this.apiService.patch(`applications/${loanId}/bank-accounts/${bankAccount.id}`, bankAccount);
  }

  postBankAccount(bankAccount: ExcludeReadonly<IBankAccount>, loanId?: number) {
    if (!loanId) { loanId = this.userService.applicationId; }
    return this.apiService.post(`applications/${loanId}/bank-accounts`, bankAccount);
  }

  findEmploymentType(title: string): Observable<IEmploymentType[]> {
    if (title && typeof title === 'string') {
      return this.apiService
        .get(`employmenttypes/search?matchTitle=${title}`)
        .pipe(map((res: any) => res.slice(0, 100)));
    }

    return of();
  }

  findFedwireAccount(routingNumber: string): Observable<IBankRouting> {
    if (this.bankRoutingCache[routingNumber]) {
      return of(this.bankRoutingCache[routingNumber]);
    }

    return this.apiService.get(`fedwires/search?routingNumber=${routingNumber}`).pipe(
      shareReplay(1),
      tap((routing: IBankRouting) => {
        this.bankRoutingCache[routingNumber] = routing;
      })
    );
  }

  getLoanDocument(loanId?: number): Observable<{ documents: ILoanDocument[] }> {
    if (!loanId) { loanId = this.userService.applicationId; }
    return this.apiService.get(`applications/${loanId}/loan-document`);
  }

  postSignedDocument(documents: ISignedDocumentRequest[]): Observable<ISignedDocument[]> {
    if (!documents.length) { return of([]); }
    const loanId = this.userService.applicationId;
    const body: ISignedDocumentRequest[] = documents.map(i => ({
      ...i,
      status: i.status || ESignedDocumentStatus.ACCEPTED,
      date: i.date || parseDate(new Date(), 'yyyy-MM-dd'),
      application: i.application || loanId
    }));
    return this.apiService.post('signed-document', body);
  }

  getLinkToken() {  
    return this.apiService.get<ILinkToken>(`generate_link_token`);
  }

  getPaymentDateAfter(id: number, period_offset: number) {
    return this.apiService.get(`next-payment/${id}/${period_offset}`);
  }
}
