<app-card
  title="Account ending in {{ endingDigits }}"
  [description]="loanId && 'Auto Paying Loan #' + loanId"
  [priority]="bankAccount?.account_priority"
  [foldable]="isCardFoldable"
  [expanded]="!isCardFoldable"
  [hasRemoveBtn]="isCardFoldable"
  [loading]="loading"
>
  <form [formGroup]="form" class="form">
    <app-input
      formControlName="routing_number"
      label="Bank Routing Number"
      class="form-field"
      [isDisabled]="true"
      [loading]="loading"
      ngDefaultControl
    ></app-input>
    <app-secret-input
      formControlName="account_number"
      label="Bank Account Number"
      class="form-field"
      [isDisabled]="true"
      [loading]="loading"
      ngDefaultControl
    ></app-secret-input>
    <app-input
      formControlName="institution"
      label="Institution"
      class="form-field"
      [isDisabled]="true"
      [loading]="loading || (fedwiresLoading$ | async)"
      ngDefaultControl
    ></app-input>
  </form>
</app-card>
