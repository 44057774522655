import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-manual-link-modal',
  templateUrl: './confirm-manual-link-modal.component.html',
  styleUrls: ['./confirm-manual-link-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmManualLinkModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmManualLinkModalComponent>
  ) {}
}
