/**
 * Hides part of the number/string.
 * @example
 * hideDataString(12345678, 4) // '••••5678';
 * hideDataString(1234, 1, 8) // '•••••••4';
 * hideDataString('abcd', 2, 5, 'x') // 'xxxcd';
 * 
 * @param dataString - input data;
 * @param visibleLength - length of visible part;
 * @param totalLength - total output length;
 * @param hiddenSymbol - symbol to replace hidden chars (default: '•');
 */
export function hideDataString(dataString: number | string, visibleLength: number, totalLength?: number, hiddenSymbol = '•'): string {
  if (totalLength < visibleLength) { visibleLength = totalLength; }
  const string = String(dataString);
  const visiblePart = visibleLength >= string.length ? string : string.slice(string.length - visibleLength);
  const length = totalLength || string.length;
  const output = new Array(length).fill(hiddenSymbol).join('');
  return output.slice(0, output.length - visibleLength) + visiblePart;
}