<div class="input">
  <app-base-input
    #baseInputRef
    class="input__control"
    [label]="label"
    [model]="model"
    [hidden]="isPreview"
    [ngxMask]="ngxMask"
    [ngxDropSpecialCharacters]="false"
    [loading]="loading"
    [errorMessage]="errorMessage"
    [prefix]="prefix"
    [hasHiddenInput]="hasHiddenInput"
    autocomplete="new-password"
    [type]="secret ? 'password' : 'tel'"
    [forceError]="forceError"
    [isDisabled]="isDisabled"
    [id]="id"
  ></app-base-input>
  <button
    class="input__toggle-secret-btn"
    [class.input__toggle-secret-btn_opened]="!secret"
    [class.input__toggle-secret-btn_loading]="loading"
    tabindex="-1"
    type="button"
    (click)="onToggleSecret($event)"
  ></button>

  <span
    *ngIf="isPreview"
    class="input__preview"
    [class.input__preview_loading]="loading"
  >{{ !secret ? model : '**********'}}</span>
</div>
<div *ngIf="!loading" class="tooltip">
  <ng-content></ng-content>
</div>
