<app-header>
  <div
    class="header__username"
    *ngIf="userService.isLoggedInForSteps"
  >Welcome, {{ username$ | async }}</div>
  <app-button
    *ngIf="userService.isLoggedInForSteps"
    class="header__logout"
    icon="logout"
    id="logout_btn"
    (pressed)="onLoggedOut()"
  >Log Out</app-button
  >
</app-header>

<div class="container page-container">
  <div class="page-title">
    <span class="previous" (click)="goBack()" *ngIf="currentStep < 8 && currentStep !== 3">
      Previous step
    </span>
  </div>
  <app-stepper 
    class="stepper" 
    [steps]="steps"
    [isLocked]="currentStepRoute?.data['lockStepper']"
    (selected)="onSelectedStep($event)"
  ></app-stepper>
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
