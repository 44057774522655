<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <app-close-button class="close" (pressed)="dialogRef.close()"></app-close-button>
    <div class="al-modal-title">
      Contacts
    </div>
    <p>
      You can contact us via email at <a href="mailto:help@apexlend.com">help@apexlend.com</a> or by calling us at (833)
      4-APEXLEND or (833) 427-3953.
    </p>
    <p>
      You may also reach us in writing at the following address:<br />
      ApexLend LLC<br />
      16192 Coastal Highway<br />
      Lewes, Delaware 19958 USA<br />
    </p>
    <app-button
      class="submit-btn"
      (pressed)="dialogRef.close()"
    >OK</app-button>
  </div>
</div>
