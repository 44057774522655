import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, pairwise, map } from 'rxjs/operators';
import { Event as NavigationEvent } from '@angular/router';
import { NavigationStart } from '@angular/router';
import { Subject } from 'rxjs';

const STORAGE_KEY = 'login-event-id';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private lastEvent: NavigationStart;
  private saveLoginEvent = false;

  public shouldLogOut$ = new Subject<boolean>();
  public previousUrl: string;

  private get loginEventId(): number | null {
    return +sessionStorage[STORAGE_KEY] || null ;
  }

  private set loginEventId(value: number) {
    sessionStorage[STORAGE_KEY] = value; 
  }

  constructor(
    private router: Router,
  ) {
    const navStart$ = this.router.events.pipe(
      filter((event: NavigationEvent) => {
        return event instanceof NavigationStart;
    }));
    const navStartPrev$ = navStart$.pipe(pairwise(), map(([prev, _]) => prev));
    navStart$.subscribe((event: NavigationStart) => {
      if (this.saveLoginEvent) {
        this.loginEventId = this.lastEvent.id;
        this.saveLoginEvent = false;
      }

      this.lastEvent = event;

      if (event.navigationTrigger === 'popstate' && event.restoredState.navigationId === this.loginEventId) {
        this.shouldLogOut$.next(true);
      }
    });
    navStartPrev$.subscribe((event: NavigationStart) => {
      this.previousUrl = event.url;
    });
  }

  public saveLoginNavigationEvent(): void {
    this.saveLoginEvent = true;
  }
}
