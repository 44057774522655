import { Injectable } from '@angular/core';
import { NgxPlaidLinkService } from 'ngx-plaid-link';
import { environment } from 'src/environments/environment';
import { EPlaidEnvironment } from '../../models/enums/plaid.enum';
import { Observable, from } from 'rxjs';
import { PlaidLinkHandler } from 'ngx-plaid-link/lib/ngx-plaid-link-handler';
import { first } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { 
  PlaidSuccessMetadata,
  PlaidAccountObject,
  PlaidErrorObject,
  PlaidErrorMetadata,
  PlaidEventMetadata
} from 'ngx-plaid-link';

export interface IPlaidSuccessMetadataNew extends PlaidSuccessMetadata {
  accounts: PlaidAccountObject[];
}

export interface IPlaidSuccessCallbackArguments {
  token: string;
  metadata: IPlaidSuccessMetadataNew;
}

export interface IPlaidExitCallbackArguments {
  error: PlaidErrorObject;
  metadata: PlaidErrorMetadata;
}

export interface ILinkToken {
  expiration: Date,
  link_token: string,
  request_id: string
}

export interface IPlaidEventCallbackArguments {
  eventName: string;
  metadata: PlaidEventMetadata;
}

export type PlaidSuccessCallback = (args: IPlaidSuccessCallbackArguments) => void;
export type PlaidExitCallback = (args: IPlaidExitCallbackArguments) => void;
export type PlaidEventCallback = (args: IPlaidEventCallbackArguments) => void;

@Injectable({
  providedIn: 'root'
})
export class PlaidService {

  plaidLinkHandler: PlaidLinkHandler;
  token: string;
  isLoggedIn = false;
  isModalOpened = false;

  private _isAutoPayOn = false;
  get isAutoPayOn(): boolean { return this._isAutoPayOn; }
  set isAutoPayOn(value: boolean) {
    if (!this.isLoggedIn) { return; }
    this._isAutoPayOn = value;
  }

  // Properties for plaid config
  env: EPlaidEnvironment = environment.plaidEnv;
  publicKey: string = environment.plaidPublicKey;

  private storageTokenKey = 'plaid_token';
  private storageLinkToken = 'plaid_link_token';

  constructor(private $plaidLink: NgxPlaidLinkService, private $storage: StorageService) {
    this.getLinkToken();
  }

  getLinkToken(){
    const plaidToken = this.$storage.get(this.storageLinkToken);
    if (plaidToken) {
      this.token = plaidToken.value;
      // this.isLoggedIn = true;
    }
  }

  get plaidConfig() {
    return {
      apiVersion: 'v2',
      clientName: 'ApexLend',
      env: this.env,
      token: this.token, //plaid link_token authorization
      product: ['auth'],
      countryCodes: ['US'],
      key: null // this.publicKey
    }
  }

  // Create and save plaid link handler, after saving open it
  createAndOpen(
    onSuccess?: PlaidSuccessCallback,
    onExit?: PlaidExitCallback,
    onEvent?: PlaidEventCallback
  ): Observable<PlaidLinkHandler> | null {
    if (this.isModalOpened) { return null; }
    this.isModalOpened = true;
    this.getLinkToken();
    const observable = this.createPlaidLink(onSuccess, onExit, onEvent);
    observable.subscribe(handler => {
      this.plaidLinkHandler = handler;
      this.open();
    });
    return observable;
  }

  // Open plaid window, if exists
  open() {
    if (this.plaidLinkHandler) {
      this.plaidLinkHandler.open();
    }
  }

  // Exit plaid window, if exists
  exit() {
    if (this.plaidLinkHandler) {
      this.plaidLinkHandler.exit();
    }
  }

  logout() {
    this.token = null;
    this.isLoggedIn = false;
  }

  // Create plaid link handler observable
  private createPlaidLink(
    onSuccess?: PlaidSuccessCallback,
    onExit?: PlaidExitCallback,
    onEvent?: PlaidEventCallback
  ): Observable<PlaidLinkHandler> {
    const plaidPromise = this.$plaidLink.createPlaid({
      ...this.plaidConfig,
      onSuccess: (token, metadata) => {
        // this.token = token;
        // this.$storage.set(this.storageTokenKey, token);
        this.isLoggedIn = true;
        this.isAutoPayOn = true;
        this.isModalOpened = false;
        if (onSuccess) { onSuccess({token, metadata}); }
      },
      onExit: (error, metadata) => {
        this.plaidLinkHandler = null;
        this.isModalOpened = false;
        if (onExit) { onExit({error, metadata}); }
      },
      onEvent: (eventName, metadata) => {
        if (onEvent) { onEvent({eventName, metadata}); }
      }
    });
    return from(plaidPromise).pipe(first());
  }
}
