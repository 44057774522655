<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="al-modal-title">
      Please enter your data for security purposes
    </div>
    <form [formGroup]="form" class="form" (keyup.enter)="onSubmit()">
      <app-secret-input
        formControlName="ssn"
        class="username-input"
        label="Last 4 Digits of SSN"
        [forceError]="loginError$ | async"
        ngxMask="0000"
        ngDefaultControl
      ></app-secret-input>
      <app-input
        formControlName="zipcode"
        class="password-input"
        label="Zip Code"
        [forceError]="loginError$ | async"
        ngxMask="00000"
        ngDefaultControl
      ></app-input>
    </form>
    <app-button class="submit-btn" (pressed)="onSubmit()">
      <div class="submit-btn__text">
        Submit
        <span
          *ngIf="loginError$ | async"
          class="submit-btn__error"
        >Incorrect SSN or Zip Code</span>
      </div>
    </app-button>
  </div>
</div>
