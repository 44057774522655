export const confirmSavingsBankAccountModalDescription = [
  'You chose to proceed with your savings account.', '',
  'Savings accounts are generally not used for automated debits. Your bank may place limits on the number of transactions and charge you additional fees for excess transactions in a savings account.', '',
  'Are you sure you would like to proceed with your savings account?'
];

export const authorizationForOneTimeAutomatedPayments = `
  You give ApexLend permission to initiate an electronic automated clearing house (ACH) debit entry transaction from 
  the checking or savings account you specify on or after the date you choose to make an ACH payment. 
  This gives us permission for a single ACH debit only and does not provide authorization for any additional unrelated debits 
  or credits to your account. The amount debited from your checking or savings account will be the amount you have specified on the ApexLend website. 
  You also authorize ApexLend to initiate credit entry (disbursement) transactions into the account you specify when and if requested by you. 
  You acknowledge that ApexLend may rely on the authorization herein and fulfill your instructions up to seven (7) days 
  after the date you selected to schedule the transfer. Because this is an electronic transaction, funds may be withdrawn from 
  your account prior to, on, or after the scheduled payment date. In the case of the payment being rejected for Non-Sufficient Funds (NSF), 
  you acknowledge that ApexLend may at its discretion attempt to process the charge again. 
  You acknowledge that the origination of ACH transactions to and from your account must comply with the provisions of U.S. law 
  and that you will not dispute ApexLend’s ACH debit with your bank or the automated clearing house processing such debits, 
  provided the transaction corresponds to the terms indicated in this Authorization and the One-Time Automated Payment Plan Agreement.
`;