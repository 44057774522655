import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ITab } from 'src/app/shared/components/tabs/tabs.component';
import { IBankAccount, ILoan, IHoldCardResponse } from '../../../shared/models/customer.model';
import { CustomerService, ILoanPayment } from '../../../shared/services/customer/customer.service';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

export enum LoanStatusEnum {
  success,
  error,
  warning,
  remark
}

@Component({
  selector: 'app-loan-accordion',
  templateUrl: './loan-accordion.component.html',
  styleUrls: ['./loan-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanAccordionComponent implements OnInit, OnChanges {
  @Input() status: LoanStatusEnum;
  @Input() loan: ILoan;
  @Input() bankAccounts: IBankAccount[];
  @Input() holdCards: IHoldCardResponse[];
  public paymentHistoryStatus : boolean = false;

  public loanStatusEnum = LoanStatusEnum;
  public tabs: ITab[] = [
    {
      label: 'Overview',
      active: true
    },
    {
      label: 'Payment History'
    },
    {
      label: 'Future Payments'
    }
  ];

  public linkedBankAccount: IBankAccount;
  public linkedHoldCard: IHoldCardResponse;

  public opened$ = new BehaviorSubject<boolean>(false);
  public paymentHistory$ = new BehaviorSubject<ILoanPayment[]>([]);
  public futurePayments$ = new BehaviorSubject<ILoanPayment[]>([]);

  public nextPayment$: Observable<ILoanPayment>;

  constructor(
    private customerService: CustomerService,
  ) {}

  ngOnInit() {
    this.nextPayment$ = this.futurePayments$
      .pipe(map(payments => payments.find(Boolean)));
  }

  ngOnChanges({ loan, bankAccounts, holdCards }: SimpleChanges): void {
    if (loan && this.loan) {
      forkJoin([
        this.customerService.getLoanPaymentHistory(this.loan.id),
        this.customerService.getLoanFuturePayments(this.loan.id)
      ]).subscribe(([history, future]) => {
        this.paymentHistory$.next(history);
        this.futurePayments$.next(future);
        this.paymentHistoryStatus = true 
      });
    }

    if (bankAccounts && this.bankAccounts) {
      this.linkedBankAccount = this.bankAccounts.find(item => item.account_priority === 'primary');
    }

    if (holdCards && this.holdCards) {
      this.linkedHoldCard = this.holdCards.find(item => item.active);
    }    
  }

  get selectedTabIndex(): number {
    return this.tabs.findIndex(tab => tab.active);
  }

  onSelectedTab(index: number) {
    this.tabs.forEach((tab, i) => (tab.active = i === index));
  }

  get opened(): boolean { return this.opened$.getValue() }

  set opened(value: boolean) { this.opened$.next(value) }
}
