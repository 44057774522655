<div *ngIf="label" class="label" [class.only-mobiles]="labelOnlyForMobiles" [class.loading]="loading">{{ label }}</div>
<div class="collapsed" (click)="opened = !opened" [class.loading]="loading">
  <div class="collapsed__icon">
    <span *ngIf="opened">-</span>
    <span *ngIf="!opened">+</span>
  </div>
  <div class="collapsed__label">{{ label }}</div>
</div>

<div class="content" [hidden]="!opened">
  <ng-content></ng-content>
</div>
