import {
  IPrivacyContact,
  IPrivacyContent,
  IPrivacyFact,
  IPrivacyQuestion,
  IPrivacyReason
} from '../../models/privacy.model';

export const facts: IPrivacyFact[] = [
  {
    header: `Financial companies choose how they share your personal information. Federal law gives
    consumers the right to limit some but not all sharing. Federal law also requires us to tell
    you how we collect, share, and protect your personal information. Please read this notice
    carefully to understand what we do.`
  },
  {
    header:
      'The types of personal information we collect and share depend on the product or service you have with us. This information can include:',
    list: [
      'Social Security number and income',
      'Account balances and payment history',
      'Credit scores and employment information'
    ]
  },
  {
    header: `All financial companies need to share customers’ personal information to run their everyday
    business. In the section below, we list the reasons financial companies can share their
    customers’ personal information; the reasons ApexLend chooses to share; and whether you
    can limit this sharing.`
  }
];

export const reasons: IPrivacyReason[] = [
  {
    title: `<b>For our everyday business purposes:</b> such as to process
    your transactions, maintain your account(s), respond to court
    orders and legal investigations, or report to credit bureaus`,
    doesApexShare: true,
    canYouLimit: false
  },
  {
    title: `<b>For our marketing purpose:</b> to offer our products and services to you`,
    doesApexShare: true,
    canYouLimit: false
  },
  {
    title: '<b>For joint marketing with other financial companies</b>',
    doesApexShare: true,
    canYouLimit: false
  },
  {
    title: "<b>For our affiliates'</b> everyday business purposes: information about your transactions and experiences",
    doesApexShare: true,
    canYouLimit: false
  },
  {
    title: '<b>For our affiliates to market to you:</b> information about your creditworthiness',
    doesApexShare: true,
    canYouLimit: {
      label: 'You must opt-out for us not to share',
      defaultValue: true
    }
  },
  {
    title: '<b>For non-affiliates to market to you</b>',
    doesApexShare: true,
    canYouLimit: {
      label: 'You must opt-in for us to share',
      defaultValue: false
    }
  }
];

const questions: IPrivacyQuestion[] = [
  {
    title: 'To start our sharing with non-affiliates Or to stop our sharing with affiliates for marketing purposes',
    content: [
      {
        list: [
          'Go to <a href="https://www.apexlend.com/customer/privacy">www.apexlend.com/customer/privacy</a> and change your options',
          'Change your options above',
          'Call us at (833) 4-APEXLEND or (833) 427-3953',
          'Write to us at <a href="mailto:privacy@apexlend.com">privacy@apexlend.com</a>',
          'Mail us one or both of the "Mail-in Forms" included with this notice (Click <a href="/assets/pdf/mailInForms/Texas-PrivacyNotice-ApexLend-mailer.pdf" download>here</a> to download the form). One form is to authorize our sharing information with non-affiliates; the second form is to stop us from sharing information with affiliates for marketing purposes.'
        ]
      },
      {
        header: 'Please note:',
        description:
          'If you are a new customer, we can begin sharing your information 30 days from the date we sent this notice. When you are no longer our customer, we can continue to share your information for up to 18 months after you are no longer our customer. However, you can contact us at any time to limit our sharing.'
      }
    ]
  },
  {
    title: 'Questions?',
    content: [
      {
        description:
          'Call our customer service department at toll-free number, (833) 427-3953, between the hours of 7:00 AM and 7:00 PM PT, Monday through Friday, except Federal Holidays.'
      }
    ]
  },
  {
    title: 'Who we are?',
    content: [
      {
        header: 'Who is providing this notice?',
        description: 'ApexLend LLC is your servicer.'
      }
    ]
  },
  {
    title: 'What we do?',
    content: [
      {
        header: 'How does ApexLend protect my personal information?',
        description:
          'To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings. ApexLend employees are trained in our privacy policy and procedures. Only employees who have a legitimate business reason to access your personal information may do so.'
      },
      {
        header: 'How does ApexLend collect my personal information?',
        description: 'We collect your personal information, for example, when you:',
        list: [
          'Apply for a loan or give us your income information.',
          "Provide account information or show your driver's license.",
          'Provide employment information.'
        ],
        listDescription:
          'We also collect your personal information from others, such as credit bureaus, affiliates, or other companies.'
      },
      {
        header: "Why can't I limit all sharing?",
        description: 'Federal law gives you the right to limit only:',
        list: [
          "sharing for affiliates' everyday business purposes - information about your creditworthiness.",
          'affiliates from using your information to market to you.',
          'sharing for non-affiliates to market to you.'
        ],
        listDescription: 'State laws and individual companies may give you additional rights to limit sharing.'
      },
      {
        header: 'What happens when I limit sharing for an account I hold jointly with someone else?',
        description: 'Your choices will apply to everyone on your account.'
      },
      {
        header: 'Does ApexLend share my personal information with affiliates, if I/We do not Opt-Out?',
        description:
          "ApexLend can begin sharing all borrowers' information with the affiliates, 30 days from the date we sent this notice. However, you can contact us at any time to limit our sharing."
      }
    ]
  },
  {
    title: 'Definition',
    content: [
      {
        header: 'Affiliates',
        description:
          'Companies related by common ownership or control. They can be financial and nonfinancial companies.',
        list: [
          'Our affiliates include companies with an XL Dynamics name; Sun West Financial Services International, Inc., a company that provides technology and back office support to mortgage and commercial banking and lending industry; PAG Financial International, LLC, a company that provides consulting services; Marco Management LLC, a company that provides personal investment rental property management; Sun West Property Management International, LLC, a company that provides personal investment rental property management; XLD Insurance Company, Inc., a commercial captive insurance company; XLD Brokerage Agency, LLC, a commercial insurance brokerage service company; XLD International Insurer Corporation, a commercial captive insurance company; Sun West Mortgage Company, Inc. NMLS ID 3277, a mortgage lender and servicer.'
        ]
      },
      {
        header: 'Non-affiliates',
        description:
          'Companies not related by common ownership or control. They can be financial and nonfinancial companies.',
        list: [
          'Non-affiliates we share with can include financial services companies such as mortgage brokers, lenders, and Servicers.'
        ]
      },
      {
        header: 'Joint Marketing',
        description:
          'A formal agreement between nonaffiliated financial companies that together market financial products or services to you.',
        list: [
          'Our joint marketing partners can include financial services companies such as mortgage brokers, lenders, and Servicers.'
        ]
      }
    ]
  },
  {
    title: 'Where can I get more information?',
    content: [
      {
        description: 'Call toll-free, (833) 4-APEXLEND or (833) 427-3953 and ask for the Compliance Manager.'
      }
    ]
  },
  {
    title: 'Complaints and Inquiries Notice',
    content: [
      {
        description:
          'ApexLend, LLC. is licensed and examined under the laws of the State of Texas and by state law is subject to regulatory oversight by the Office of Consumer Credit Commissioner. Any consumer wishing to file a complaint against the ApexLend, LLC. should contact the Office of Consumer Credit Commissioner through one of the means indicated below.'
      }
    ]
  }
];

const contacts: IPrivacyContact[] = [
  {
    title: 'In Person or U.S. Mail:',
    content: '2601 North Lamar Boulevard, Austin, Texas 78705-4207'
  },
  {
    title: 'Telephone No.:',
    content: '(800) 538-1579'
  },
  {
    title: 'Fax No.:',
    content: '(512) 936-7610'
  },
  {
    title: 'E-mail:',
    content: '<a href="consumer.complaints@occc.state.tx.us">consumer.complaints@occc.state.tx.us</a>'
  },
  {
    title: 'Website:',
    content: '<a href="www.occc.state.tx.us">www.occc.state.tx.us</a>'
  }
];

export const pagePdf = '/assets/pdf/Texas-PrivacyNotice-ApexLend.pdf';
export const mailInFormPdf = '/assets/pdf/mailInForms/Texas-PrivacyNotice-ApexLend-mailer.pdf';

export const texasPrivacyContent: IPrivacyContent = { facts, reasons, questions, contacts, mailInFormPdf, pagePdf };
