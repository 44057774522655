<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <div class="al-modal-title">
      New Bank Account
    </div>
    <form [formGroup]="form" class="form">
      <div class="auto-pay">
        <div class="auto-pay__apexpay apexpay">
          <img class="apexpay__image" src="assets/img/apex-pay/apexpay_logo.svg" alt="Apex-Pay">
          <mat-slide-toggle
            class="apexpay__toggle"
            [formControl]="turnOnAutoPay"
          ></mat-slide-toggle>
        </div>
        <div class="auto-pay__hint">
          We will be changing the auto-ACH to the new account.
        </div>
      </div><br>      
      <div
        class="controls new_bank_panel"
      >
        <a class="controls__manual">Add account manually </a>
        <p>(by choosing this option you will lose the benefits of <i>ApexPay</i>)</p>
      </div>
      <app-input
        class="input"
        formControlName="routing_number"
        errorMessage="Incorrect Bank Routing Number"
        ngxMask="000000000"
        label="Bank Routing Number"
        placeholder="9 digits"
        ngDefaultControl
        [id]="'new_routing_number'"
      ></app-input>
      <app-input
        class="input"
        formControlName="account_number"
        errorMessage="Incorrect Bank Account Number"
        ngxMask="000000000000"
        label="Bank Account Number"
        placeholder="10 to 12 digits"
        [validateErrors]="form.getError('reenter')"
        ngDefaultControl
        [id]="'new_account_number'"
      ></app-input>
      <app-input
        class="input"
        formControlName="account_number_reenter"
        ngxMask="000000000000"
        label="Re-enter Bank Account Number"
        placeholder="10 to 12 digits"
        [validateErrors]="form.getError('reenter')"
        ngDefaultControl
        [id]="'new_account_number_reenter'"
      ></app-input>
      <app-select
        class="input"
        label="Bank Account Type"
        formControlName="type"
        [options]="bankAccountTypeOptions"
        ngDefaultControl
        [id]="'new_bank_account_option'"
      ></app-select>
      <app-input
        class="input"
        formControlName="institution"
        label="Institution"
        [isDisabled]="true"
        ngDefaultControl
        [id]="'new_institution'"
      ></app-input>
    </form>
    <div class="form__bottom-controls">
      <app-button
        class="form__bottom-btn"
        color="white"
        (pressed)="dialogRef.close()"
        [id]="'new_bank_btn'"
      >Back</app-button>
      <app-button
        class="form__bottom-btn"
        (pressed)="onSubmit()"
        [id]="'new_submit_btn'"
      >Submit</app-button>
    </div>
  </div>
</div>
