<div class="bottom-controls">
  <app-button
    *ngFor="let control of controls"
    class="bottom-controls__btn"
    [isTableControl]="true"
    (click)="onControlClick(control)"
  >
    {{ control.label }}
  </app-button>
</div>

<table mat-table [dataSource]="data"
  class="loan-table"
  [class.highlight-first]="highlightFirst"
>
  <ng-container *ngFor="let schema of tableSchema" matColumnDef="{{ schema.key }}">
    <th class="thead" mat-header-cell *matHeaderCellDef>{{ schema.name }}</th>
    <td mat-cell *matCellDef="let element; let i = index" [ngClass]="schema.classCell">
      <span *ngIf="!schema.checkbox">{{ element[schema.key] | dynamic:schema.pipe:schema.pipeArgs }}</span>
      <mat-checkbox *ngIf="schema.checkbox" (change)="checkboxData[schema.key][i] = $event.checked"></mat-checkbox>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <mat-progress-bar mode="indeterminate" [ngStyle]="{'visibility': displayedColumns ? 'visible' : 'hidden'}"></mat-progress-bar>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

