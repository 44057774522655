export function formatObject(obj: Object, formatter: (val: any) => any): Object {
    const output = {};
    Object.entries(obj).forEach(([key, value]) => {
        output[key] = formatter(value);
    });
    return output;
}

export function formatObjectEmptyString(obj: Object): Object {
    return formatObject(obj, val => val ? String(val) : '');
}

export function formatObjectNumber(obj: Object): Object {
    return formatObject(obj, val => Number(val));
}