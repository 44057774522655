import { Component, OnInit } from '@angular/core';
import { HoldsService } from 'src/app/shared/services/holds/holds.service';
import { ActivatedRoute } from '@angular/router';

export interface IHolds {
  token?: string;
  loan?: number;
  user?: string;
}

@Component({
  selector: 'app-holds-accept',
  templateUrl: './holds-accept.component.html', 
  styleUrls: ['./holds-accept.component.scss']
})

export class HoldsAcceptComponent implements OnInit {
  public holdsData: IHolds;
  public response: any;

  constructor(private holdsService: HoldsService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.holdsData = {
        token: params['arg1'],
        loan: Number(params['arg2']),
        user: params['arg3']
      };
    }, 
    err => { console.log('http error', err)});
  }

  ngOnInit(): void {
    this.holdsService.setToken(this.holdsData.token);
    this.holdsService.acceptHolds(this.holdsData.loan).subscribe(
      res => {
        this.response = res;
      },
      error => {
        if (error.status === 401) {
          this.response = error.error.detail;
        }
      }
    );
  }
}
