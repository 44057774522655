import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from '../../../user/services/user/user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-reset-offer-code-modal',
  templateUrl: './reset-offer-code-modal.component.html',
  styleUrls: ['./reset-offer-code-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetOfferCodeModalComponent implements OnInit {
  public hasLoginErrors$ = new BehaviorSubject<boolean>(false);
  public setOfferTouched$ = new BehaviorSubject<boolean>(false);

  public form: FormGroup;
  private currentOfferCode: string;

  constructor(
    public dialogRef: MatDialogRef<ResetOfferCodeModalComponent>,
    private userService: UserService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      offerCode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), this.validateOfferCode.bind(this)]],
      ssn: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      zipcode: ['', [Validators.required]]
    });
  }

  public getOfferError() {
    const offer = this.form.controls.offerCode;
    if (offer.hasError('notEquivalent')) {
      return 'Offer Code doesn\'t exist';
    } else {
      return 'Offer Code should be 4 characters'
    }
  }

  onSubmit() {
    const { offerCode, ssn, zipcode } = this.form.value;
    this.userService.offerCode = offerCode;
    const request = { ssn, zipcode };
    this.userService.loginWithSSN(request)
      .subscribe(() => {
        this.hasLoginErrors$.next(false);
        this.resetToStep(3);
      }, () => {
        this.hasLoginErrors$.next(true);
      });
  }

  private validateOfferCode(control: FormControl) {
    const offer = control.value;
    if (offer && offer.length === 4 && offer !== this.currentOfferCode) {
      this.currentOfferCode = offer;
      this.userService.findMailOffer(false, offer)
        .subscribe((res) => {
          this.userService.customerId = res.customer;
          this.setOfferTouched$.next(false);
          return null;
        }, () => {
          control.setErrors({ notEquivalent: true });
          this.setOfferTouched$.next(true);
        });
    } else {
      this.setOfferTouched$.next(false);
      return null;
    }
  }

  private resetToStep(step: number) {
    this.userService.saveCurrentStepNumber(step, this.userService.applicationId)
      .subscribe((res) => {
        this.userService.customerId = res.customer;
        this.dialogRef.close({ step: res.step });
      });
  }
}
