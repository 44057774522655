<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<app-form-title [loading]="loading">

  <div class="description">
    Below is your monthly debt balance and payments to the best of our knowledge,<br />
    please change where appropriate
  </div>

  <div class="title">Monthly Debt Payments</div>

</app-form-title>
<form [formGroup]="form" class="form" *ngIf="form">
  <app-collapsed-group class="field-group" label="Auto" [loading]="loading">
    <app-input
      [formControl]="(form?.controls)['debtBalance']['controls'].auto"
      class="field-group__input input"
      type="tel"
      groupLabel="Balance"
      [collapsed]="true"
      label="Balance"
      ngxMask="comma_separator.2"
      prefix="$"
      [loading]="loading"
      [id]="'balance'"
      ngDefaultControl
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['debtMonthly']['controls'].auto"
      class="field-group__input input"
      type="tel"
      prefix="$"
      groupLabel="Monthly Payment"
      [collapsed]="true"
      label="Monthly Payment"
      ngxMask="comma_separator.2"
      errorMessage="Fill in Monthly Payment"
      [warning]="getWarning('auto')"
      [loading]="loading"
      [id]="'monthlyPayment'"
      ngDefaultControl
    ></app-input>
    <div
      *ngIf="+form.value.debtBalanceAuto || +form.value.debtMonthlyAuto"
      class="field-group__clear"
      (click)="onClearFields(['debtBalanceAuto', 'debtMonthlyAuto'])"
    ></div>
  </app-collapsed-group>

  <app-collapsed-group class="field-group" label="Home Debt" [loading]="loading">
    <app-input
      [formControl]="(form?.controls)['debtBalance']['controls'].home"
      class="field-group__input input"
      type="tel"
      prefix="$"
      [collapsed]="true"
      label="Balance"
      ngxMask="comma_separator.2"
      [loading]="loading"
      [id]="'homeBalance'"
      ngDefaultControl
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['debtMonthly']['controls'].home"
      class="field-group__input input"
      type="tel"
      prefix="$"
      [collapsed]="true"
      label="Monthly Payment"
      ngxMask="comma_separator.2"
      errorMessage="Fill in Monthly Payment"
      [warning]="getWarning('home')"
      [id]="'homePayment'"
      [loading]="loading"
      ngDefaultControl
    ></app-input>
    <div
      *ngIf="+form.value.debtBalanceHome || +form.value.debtMonthlyHome"
      class="field-group__clear"
      (click)="onClearFields(['debtBalanceHome', 'debtMonthlyHome'])"
    ></div>
  </app-collapsed-group>

  <app-collapsed-group class="field-group" label="Installment" [loading]="loading">
    <app-input
      [formControl]="(form?.controls)['debtBalance']['controls'].installment"
      class="field-group__input"
      type="tel"
      prefix="$"
      [collapsed]="true"
      label="Balance"
      ngxMask="comma_separator.2"
      [loading]="loading"
      [id]="'installment'"
      ngDefaultControl
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['debtMonthly']['controls'].installment"
      class="field-group__input"
      type="tel"
      prefix="$"
      [collapsed]="true"
      label="Monthly Payment"
      ngxMask="comma_separator.2"
      errorMessage="Fill in Monthly Payment"
      [warning]="getWarning('installment')"
      [loading]="loading"
      [id]="'installmentMonthly'"
      ngDefaultControl
    ></app-input>
    <div
      *ngIf="+form.value.debtBalanceInstallment || +form.value.debtMonthlyInstallment"
      class="field-group__clear"
      (click)="onClearFields(['debtBalanceInstallment', 'debtMonthlyInstallment'])"
    ></div>
  </app-collapsed-group>

  <app-collapsed-group class="field-group" label="Revolving (i.e. Credit Card)" [loading]="loading">
    <app-input
      [formControl]="(form?.controls)['debtBalance']['controls'].revolving"
      class="field-group__input"
      type="tel"
      prefix="$"
      [collapsed]="true"
      label="Balance"
      ngxMask="comma_separator.2"
      [loading]="loading"
      [id]="'revolving'"
      ngDefaultControl
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['debtMonthly']['controls'].revolving"
      class="field-group__input"
      type="tel"
      prefix="$"
      [collapsed]="true"
      label="Monthly Payment"
      ngxMask="comma_separator.2"
      errorMessage="Fill in Monthly Payment"
      [warning]="getWarning('revolving')"
      [loading]="loading"
      ngDefaultControl
      [id]="'revolvingMonthly'"
    ></app-input>
    <div
      *ngIf="+form.value.debtBalanceRevolving || +form.value.debtMonthlyRevolving"
      class="field-group__clear"
      (click)="onClearFields(['debtBalanceRevolving', 'debtMonthlyRevolving'])"
    ></div>
  </app-collapsed-group>

  <app-collapsed-group class="field-group" label="Student Loan" [loading]="loading">
    <app-input
      [formControl]="(form?.controls)['debtBalance']['controls'].student"
      class="field-group__input"
      type="tel"
      prefix="$"
      [collapsed]="true"
      label="Balance"
      ngxMask="comma_separator.2"
      [loading]="loading"
      [id]="'student'"
      ngDefaultControl
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['debtMonthly']['controls'].student"
      class="field-group__input"
      type="tel"
      prefix="$"
      [collapsed]="true"
      label="Monthly Payment"
      ngxMask="comma_separator.2"
      errorMessage="Fill in Monthly Payment"
      [warning]="getWarning('student')"
      [loading]="loading"
      [id]="'studentMonthly'"
      ngDefaultControl
    ></app-input>
    <div
      *ngIf="+form.value.debtBalanceStudent || +form.value.debtMonthlyStudent"
      class="field-group__clear"
      (click)="onClearFields(['debtBalanceStudent', 'debtMonthlyStudent'])"
    ></div>
  </app-collapsed-group>

  <app-collapsed-group class="field-group" label="Other Debts (i.e. Alimony)" [loading]="loading">
    <app-input
      [formControl]="(form?.controls)['debtBalance']['controls'].other"
      class="field-group__input"
      type="tel"
      prefix="$"
      [collapsed]="true"
      label="Balance"
      ngxMask="comma_separator.2"
      [loading]="loading"
      [id]="'otherDebt'"
      ngDefaultControl
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['debtMonthly']['controls'].other"
      class="field-group__input"
      type="tel"
      prefix="$"
      [collapsed]="true"
      label="Monthly Payment"
      ngxMask="comma_separator.2"
      errorMessage="Fill in Monthly Payment"
      [warning]="getWarning('other')"
      [loading]="loading"
      [id]="'otherDebtMonthly'"
      ngDefaultControl
    ></app-input>
    <div
      *ngIf="+form.value.debtBalanceOther || +form.value.debtMonthlyOther"
      class="field-group__clear"
      (click)="onClearFields(['debtBalanceOther', 'debtMonthlyOther'])"
    ></div>
  </app-collapsed-group>
</form>

<app-button 
styleType="form-submit" 
[isDisabled]="form?.invalid" 
(pressed)="onSubmit()" 
[loading]="loading"
[pending]="requestPending"
[spinner]="spinner"
[id]="'debt_submit'"
  >Submit</app-button
>
