import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor, AfterViewInit {
  @Input() loading: boolean;
  @Input() hidden: boolean;
  @Input() model: boolean;
  @Input() isDisabled: boolean;
  @Input() forceWarning: boolean;
  @Input() warningMessage: string;
  @Input() alignCheckbox: 'left' | 'right' | 'center' = 'center';
  @Output() changed = new EventEmitter<boolean>();
  private control: AbstractControl;
  public onTouched: () => void = () => {};
  public onChange: (value: boolean) => void = (value: boolean) => {
    this.changed.emit(value);
  };

  constructor(private injector: Injector) {}

  ngAfterViewInit() {
    this.initFormControl();
  }

  private initFormControl() {
    if (this.injector) {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      } else {
        console.warn('Component is missing form control binding');
      }
    }
  }

  public writeValue(value: boolean) {
    if (this.model !== value) {
      this.model = value;
      this.onChange(value);

      if (this.control) {
        this.control.setValue(value);
      }

      return value;
    }
  }

  public registerOnChange(fn: () => {}) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }
}
