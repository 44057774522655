import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxMaskModule } from 'ngx-mask';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { BaseFormLayoutComponent } from './components/base-form-layout/base-form-layout.component';
import { BaseInputComponent } from './components/base-input/base-input.component';
import { ButtonComponent } from './components/button/button.component';
import { ChangePasswordModalComponent } from './entry/change-password-modal/change-password-modal.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CloseButtonComponent } from './components/close-button/close-button.component';
import { CollapsedGroupComponent } from './components/collapsed-group/collapsed-group.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormDescriptionComponent } from './components/form-description/form-description.component';
import { FormTitleComponent } from './components/form-title/form-title.component';
import { HeaderComponent } from './components/header/header.component';
import { HintComponent } from './components/hint/hint.component';
import { InfoModalComponent } from './entry/info-modal/info-modal.component';
import { InformationConfirmFormComponent } from './components/information-confirm-form/information-confirm-form.component';
import { InputComponent } from './components/input/input.component';
import { LogotypeComponent } from './components/logotype/logotype.component';
import { PasswordRecoveryModalComponent } from './entry/password-recovery-modal/password-recovery-modal.component';
import { SecretInputComponent } from './components/secret-input/secret-input.component';
import { SecureLogotypesComponent } from './components/secure-logotypes/secure-logotypes.component';
import { SelectComponent } from './components/select/select.component';
import { SignInModalComponent } from './entry/sign-in-modal/sign-in-modal.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ValidateSSNModalComponent } from './entry/validate-ssn-modal/validate-ssn-modal.component';
import { RoundPipe } from './pipes/round/round.pipe';
import { SsnPipe } from './pipes/ssn/ssn.pipe';
import { ThousandsSeparatorsPipe } from './pipes/thousands-separators.pipe';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { AddBankAccountModalComponent } from './entry/add-bank-account-modal/add-bank-account-modal.component';
import { DelayPaymentModalComponent } from './entry/delay-payment-modal/delay-payment-modal.component';
import { ChangeAchModalComponent } from './entry/change-ach-modal/change-ach-modal.component';
import { ChangeCreditCardModalComponent } from './entry/change-credit-card-modal/change-credit-card-modal.component';
import { ResetOfferCodeModalComponent } from './entry/reset-offer-code-modal/reset-offer-code-modal.component';
import { ContactsModalComponent } from './entry/contacts-modal/contacts-modal.component';
import { AddCreditCardModalComponent } from './entry/add-credit-card-modal/add-credit-card-modal.component';
import { TwoFactorModalComponent } from './entry/two-factor-modal/two-factor-modal.component';
import { EditCreditCardModalComponent } from './entry/edit-credit-card-modal/edit-credit-card-modal.component';
import { ConfirmModalComponent } from './entry/confirm-modal/confirm-modal.component';
import { DynamicPipe } from './pipes/dynamic/dynamic.pipe';
import { IframeComponent } from './components/iframe/iframe.component';
import { FinalPaymentsManualModalComponent } from './entry/final-payments-manual-modal/final-payments-manual-modal.component';
import { FinalPaymentsPlaidAccountsModalComponent } from './entry/final-payments-plaid-accounts-modal/final-payments-plaid-accounts-modal.component';
import { SubjectAccordionComponent } from './components/subject-accordion/subject-accordion.component';
import { ApexpayTermsComponent } from './entry/apexpay-terms/apexpay-terms.component';
import { ConfirmManualLinkModalComponent } from './entry/confirm-manual-link-modal/confirm-manual-link-modal.component';
import { FaqCreditCardModalComponent } from './entry/faq-credit-card-modal/faq-credit-card-modal.component';
import { FaqDtiModalComponent } from './entry/faq-dti-modal/faq-dti-modal.component';
import { EditInputComponent } from './components/edit-input/edit-input.component';
import { FaqPaymentCycleModalComponent } from './entry/faq-payment-cycle-modal/faq-payment-cycle-modal.component';
import { ControlPipe } from './pipes/control/control.pipe';
import { CounterDirective } from './directive/counter.directive';

const declarations = [
  LogotypeComponent,
  InputComponent,
  ButtonComponent,
  DatepickerComponent,
  FooterComponent,
  SelectComponent,
  CollapsedGroupComponent,
  SsnPipe,
  RoundPipe,
  ThousandsSeparatorsPipe,
  DynamicPipe,
  HeaderComponent,
  InformationConfirmFormComponent,
  HintComponent,
  TabsComponent,
  StepperComponent,
  SecretInputComponent,
  BaseFormLayoutComponent,
  BaseInputComponent,
  FormTitleComponent,
  FormDescriptionComponent,
  CheckboxComponent,
  SecureLogotypesComponent,
  SelectInputComponent,
  RadioGroupComponent,
  IframeComponent,
  SubjectAccordionComponent,
  EditInputComponent,
  ControlPipe,
  CounterDirective
];

const entryComponents = [
  InfoModalComponent,
  SignInModalComponent,
  PasswordRecoveryModalComponent,
  CloseButtonComponent,
  ChangePasswordModalComponent,
  ValidateSSNModalComponent,
  DelayPaymentModalComponent,
  AddBankAccountModalComponent,
  ChangeAchModalComponent,
  ChangeCreditCardModalComponent,
  ResetOfferCodeModalComponent,
  ContactsModalComponent,
  AddCreditCardModalComponent,
  TwoFactorModalComponent,
  EditCreditCardModalComponent,
  ConfirmModalComponent,
  FinalPaymentsManualModalComponent,
  FinalPaymentsPlaidAccountsModalComponent,
  ApexpayTermsComponent,
  ConfirmManualLinkModalComponent,
  FaqCreditCardModalComponent,
  FaqDtiModalComponent,
  FaqPaymentCycleModalComponent
];

const materialModules = [
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatSnackBarModule
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ...materialModules,
    FormsModule,
    NgxMaskModule.forRoot(),
    NgxCaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    NgxPlaidLinkModule
  ],
  declarations: [...declarations, ...entryComponents],
  exports: [...declarations, ...materialModules, FormsModule, ReactiveFormsModule, NgxMaskModule, NgxCaptchaModule, NgxPlaidLinkModule],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    SsnPipe,
    RoundPipe,
    ThousandsSeparatorsPipe,
    DynamicPipe,
    DatePipe
  ],
  entryComponents
})
export class SharedModule {}
