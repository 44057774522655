import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { IPrivacyContent } from '../../models/privacy.model';
import { PrivacyPolicyRadioComponent } from './../privacy-policy-radio/privacy-policy-radio.component';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyComponent {
  @Input() content: IPrivacyContent;
  @Output() changedRadioButtons = new EventEmitter<boolean>();
  @ViewChildren(PrivacyPolicyRadioComponent) private radioButtons: QueryList<PrivacyPolicyRadioComponent>;

  constructor() {}

  public onChangedRadioButton() {
    const allChecked = this.radioButtons.filter(b => b.model === true).length === this.radioButtons.length;
    this.changedRadioButtons.emit(allChecked);
  }

  public isCheckboxConfiguration(value): boolean {
    return value !== null && typeof value === 'object';
  }
}
