import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PCI_DSS_LINK } from '../../constants/common.constants';

@Component({
  selector: 'app-secure-logotypes',
  templateUrl: './secure-logotypes.component.html',
  styleUrls: ['./secure-logotypes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecureLogotypesComponent {
  pciLink = PCI_DSS_LINK;
}
