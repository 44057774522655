<div class="title">
  {{ config.title }}
</div>
<div class="description">
  <div *ngFor="let row of descriptionList" class="description__row">
    {{ row }}
  </div>
</div>
<div class="buttons" [class.buttons--reversed]="config.reversed">
  <app-button 
    class="buttons__item"
    type="button" 
    [color]="!config.reversed ? 'white' : 'blue'"
    (pressed)="onCancel()"
    >{{ config.cancelText }}
  </app-button>
  <app-button
    class="buttons__item"
    type="button"
    [color]="!config.reversed ? 'blue' : 'white'"
    (pressed)="onConfirm()"
    >{{ config.confirmText }}
  </app-button>
</div>