<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<form [formGroup]="form" class="form">
  <app-collapsed-group
    class="field-group"
    label="Pay Off Credit Card Type"
    [labelOnlyForMobiles]="true"
    [loading]="loading"
  >
    <div class="left-column" [class.loading]="loading">
      <div class="left-column__title">
        Pay Off Credit Card Type
        <img 
          class="left-column__hint"
          src="assets/img/icons/info--blue.svg"
          (click)="onHintClick('pay-off-credit-card')"
        >
      </div>
      <div class="left-column__subtitle left-column__subtitle--info">Only cards issued in the United States</div>
      <ng-container formGroupName="payOffAccount">
        <app-select
          class="input"
          label="Credit Card Type"
          formControlName="card_type"
          errorMessage="Incorrect Credit Card Type"
          [options]="cardTypesPayOff"
          [loading]="loading"
          [id]="'ccType'"
          ngDefaultControl
        ></app-select>
        <app-input
          class="input"
          label="Amount to Pay Off"
          formControlName="amount"
          prefix="$"
          ngxMask="comma_separator.2"
          [loading]="loading"
          [isDisabled]="true"
          [id]="'amountPayoff'"
          ngDefaultControl
        ></app-input>
        <ng-container *ngIf="samePayOffCard$ | async">
          <app-input
            class="input"
            label="Total Credit Limit"
            prefix="$"
            ngxMask="comma_separator.2"
            [dropSpecialCharacters]="true"
            [formControl]="form.get('holdCardExtra.total_credit_limit')"
            [validateErrors]="form.get('holdCardExtra.total_credit_limit').errors?.responseErr"
            [loading]="loading"
            [id]="'creditLimit'"
            ngDefaultControl
          ></app-input>
          <app-input
            class="input"
            label="Cash Advance Limit"
            prefix="$"
            ngxMask="comma_separator.2"
            [dropSpecialCharacters]="true"
            [formControl]="form.get('holdCardExtra.cash_advance_limit')"
            [validateErrors]="form.get('holdCardExtra.cash_advance_limit').errors?.responseErr"
            [loading]="loading"
            [id]="'cash_advance_limit'"
            ngDefaultControl
          ></app-input>
          <app-input
            class="input"
            label="Available Cash Advance"
            prefix="$"
            ngxMask="comma_separator.2"
            [dropSpecialCharacters]="true"
            [formControl]="form.get('holdCardExtra.available_cash_advance')"
            [validateErrors]="form.get('holdCardExtra.available_cash_advance').errors?.responseErr"
            [loading]="loading"
            [id]="'available_cash_advance'"
            ngDefaultControl
          ></app-input>
          <app-input
            class="input"
            label="Name on Card"
            formControlName="name"
            errorMessage="Missing Name on Card"
            ngxMask="N*"
            [loading]="loading"
            [warning]="form.get('payOffAccount.name').warnings?.tooLong && 'Name on Card is too long.'"
            [id]="'finalName'"
            ngDefaultControl
          ></app-input>
          <div class="input card-number">
            <app-input
              class="card-number__input"
              label="Credit Card Number"
              formControlName="number"
              errorMessage="Incorrect Credit Card Number"
              [ngxMask]="(payOffAccountNumberDisabledDelayed$ | async) ? '' : '0000 0000 0000 0000'"
              [isDisabled]="(payOffAccountNumberDisabled$ | async)"
              [loading]="loading"
              [validateErrors]="form.get('payOffAccount').getError('creditCardTypeNumber')"
              [warning]="form.get('payOffAccount').warnings?.creditCardTypeNumber"
              [id]="'creditCardTypeNumber'"
              ngDefaultControl
            ></app-input>
            <app-button
              class="card-number__edit-btn"
              (pressed)="editPayOffAccountNumber()"
              [isDisabled]="!(payOffAccountNumberDisabled$ | async)"
              [loading]="loading"
              [id]="'finalEdit'"
            >Edit</app-button>
          </div>
          <app-input
            class="input"
            label="Expiration Date"
            formControlName="expiration_date"
            errorMessage="Incorrect Expiration Date"
            ngxMask="00/00"
            placeholder="MM/YY"
            [loading]="loading"
            ngDefaultControl
            [id]="'expiration_date'"
          ></app-input>
          <app-input
            class="input"
            label="Security Code"
            formControlName="security_code"
            errorMessage="Incorrect Security Code"
            ngxMask="0000"
            [loading]="loading"
            [id]="'security_code'"
            ngDefaultControl
          ></app-input>
        </ng-container>
      </ng-container>
    </div>
  </app-collapsed-group>
  <app-collapsed-group
    class="field-group"
    label="Hold Credit Card Type"
    [labelOnlyForMobiles]="true"
    [loading]="loading"
  >
    <div class="right-column" [class.loading]="loading">
      <div class="right-column__title">
        Hold Credit Card Type
        <img 
          class="right-column__hint"
          src="assets/img/icons/info--blue.svg"
          (click)="onHintClick('hold-credit-card')"
        >
      </div>
      <label
        class="right-column__subtitle right-column__subtitle--checkbox"
        [class.opacity]="isPayoffCardIncompatibleForHold"
      >
        <mat-checkbox
          class="checkbox"
          [class.checkbox_loading]="loading"
          [checked]="samePayOffCard$ | async"
          (change)="toggleSamePayOffCard($event.checked)"
          [disabled]="isPayoffCardIncompatibleForHold || loading"
          [id]="'isPayoffCardIncompatibleForHold'"
          rightColumn
        >
          <div class="checkbox__label"
          >Same as Pay Off Credit Card</div>
        </mat-checkbox>
      </label>
      <ng-container formGroupName="holdCard">
        <app-input
          formControlName="id"
          class="hidden-input"
          [id]="'holdCard'"
          ngDefaultControl
        ></app-input>
        <app-select
          class="input"
          label="Credit Card Type"
          formControlName="card_type"
          errorMessage="Incorrect Credit Card Type"
          [options]="cardTypesHold"
          [isDisabled]="samePayOffCard$ | async"
          [loading]="loading"
          [id]="'card_type'"
          ngDefaultControl
        ></app-select>
        <app-input
          class="input"
          label="Available Credit"
          formControlName="available_credit"
          [errorMessage]="getCardError('hold')"
          prefix="$"
          ngxMask="comma_separator.2"
          [isDisabled]="samePayOffCard$ | async"
          [loading]="loading"
          [id]="'available_credit'"
          ngDefaultControl
        ></app-input>
        <ng-container *ngIf="!(samePayOffCard$ | async)">
          <app-input
            class="input"
            label="Total Credit Limit"
            prefix="$"
            ngxMask="comma_separator.2"
            [dropSpecialCharacters]="true"
            [formControl]="form.get('holdCardExtra.total_credit_limit')"
            [validateErrors]="form.get('holdCardExtra.total_credit_limit').errors?.responseErr"
            [loading]="loading"
            [id]="'hold_total_credit_limit'"
            ngDefaultControl
          ></app-input>
          <app-input
            class="input"
            label="Cash Advance Limit"
            prefix="$"
            ngxMask="comma_separator.2"
            [dropSpecialCharacters]="true"
            [formControl]="form.get('holdCardExtra.cash_advance_limit')"
            [validateErrors]="form.get('holdCardExtra.cash_advance_limit').errors?.responseErr"
            [loading]="loading"
            [id]="'hold_cash_advance_limit'"
            ngDefaultControl
          ></app-input>
          <app-input
            class="input"
            label="Available Cash Advance"
            prefix="$"
            ngxMask="comma_separator.2"
            [dropSpecialCharacters]="true"
            [formControl]="form.get('holdCardExtra.available_cash_advance')"
            [validateErrors]="form.get('holdCardExtra.available_cash_advance').errors?.responseErr"
            [loading]="loading"
            [id]="'hold_vailable_cash_advance'"
            ngDefaultControl
          ></app-input>
          <app-input
            class="input"
            label="Name on Card"
            formControlName="name"
            errorMessage="Missing Name on Card"
            ngxMask="N*"
            [warning]="form.get('holdCard.name').warnings?.tooLong && 'Name on Card is too long.'"
            [isDisabled]="samePayOffCard$ | async"
            [loading]="loading"
            [id]="'hold_name'"
            ngDefaultControl
          ></app-input>
          <div class="input card-number">
            <app-input
              class="card-number__input"
              label="Credit Card Number"
              formControlName="number"
              errorMessage="Incorrect Credit Card Number"
              [ngxMask]="(holdCardNumberDisabled$ | async) ? '' : '0000 0000 0000 0000'"
              [isDisabled]="(samePayOffCard$ | async) || (holdCardNumberDisabled$ | async)"
              [loading]="loading"
              [validateErrors]="form.get('holdCard').getError('creditCardTypeNumber')"
              [warning]="form.get('holdCard').warnings?.creditCardTypeNumber"
              ngDefaultControl
              [id]="'hold_credit_number'"
            ></app-input>
            <app-button
              class="card-number__edit-btn"
              (pressed)="editHoldCardNumber()"
              [isDisabled]="(samePayOffCard$ | async) || !(holdCardNumberDisabled$ | async)"
              [loading]="loading"
              [id]="'hold_btn'"
            >Edit</app-button>
          </div>
          <app-input
            class="input"
            label="Expiration Date"
            formControlName="expiration_date"
            errorMessage="Incorrect Expiration Date"
            ngxMask="00/00"
            placeholder="MM/YY"
            [isDisabled]="samePayOffCard$ | async"
            [loading]="loading"
            [id]="'hold_expiration_date'"
            ngDefaultControl
          ></app-input>
          <app-input
            class="input"
            label="Security Code"
            formControlName="security_code"
            errorMessage="Incorrect Security Code"
            ngxMask="0000"
            [isDisabled]="samePayOffCard$ | async"
            [loading]="loading"
            [id]="'hold_ssn'"
            ngDefaultControl
          ></app-input>
        </ng-container>
        
      </ng-container>
    </div>
  </app-collapsed-group>
  <app-button
    class="submit-btn"
    type="submit"
    [loading]="loading"
    [pending]="requestPending"
    [spinner]="spinner"
    [id]="'hold_submit_btn'"
    (pressed)="onSubmit()"
  >Submit</app-button>
</form>