import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from '../../services/customer/customer.service';
import { BehaviorSubject } from 'rxjs';
import { IBankAccount } from '../../models/customer.model';
import { hideDataString } from '../../helpers/hide-data-string';
import { ISmartOption } from '../../components/select/select.component';

export interface IChangeAchModalOutput {
  action?: 'AddNew' | 'Submit',
  id?: string
}

@Component({
  selector: 'app-change-ach-modal',
  templateUrl: './change-ach-modal.component.html',
  styleUrls: ['./change-ach-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeAchModalComponent implements OnInit {
  public form: FormGroup;
  public options$ = new BehaviorSubject(null);
  public loading$ = new BehaviorSubject(true);

  constructor(
    public dialogRef: MatDialogRef<ChangeAchModalComponent, IChangeAchModalOutput>,
    private formBuilder: FormBuilder,
    private $api: CustomerService
  ) { }

  ngOnInit() {
    this.form = this.createForm();
    this.$api.getBankAccounts().subscribe(resp => {
      this.processBankAccounts(resp);
      this.loading$.next(false);
    });
  }

  onAddNewBank() {
    this.dialogRef.close({ action: 'AddNew' });
  }

  onSubmit() {
    this.dialogRef.close({ action: 'Submit', id: this.form.value.bankAccount })
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      bankAccount: [''],
    });
  }

  private processBankAccounts(accounts: IBankAccount[]) {
    // Create object for options and set it
    const options: ISmartOption[] = accounts.map(item => ({
      key: item.id,
      value: hideDataString(item.account_number, 4, 10)
    }))
    this.options$.next(options);

    // Find first item in accounts and set it's id to formControl
    const first = accounts.find(Boolean);
    this.form.get('bankAccount').setValue(first && first.id);
  }
}
