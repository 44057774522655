import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ILoan, IHoldCardResponse } from 'src/app/shared/models/customer.model';
import { CARD_TYPE_SHORT } from 'src/app/shared/constants/select-data.constants';
import { hideDataString } from 'src/app/shared/helpers/hide-data-string';
import { ModalService } from 'src/app/shared/services/modal/modal.service';

@Component({
  selector: 'app-credit-card-card',
  templateUrl: './credit-card-card.component.html',
  styleUrls: ['./credit-card-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditCardCardComponent implements OnChanges {
  @Input() card: IHoldCardResponse;
  @Input() loans: ILoan[];
  @Input() loading: boolean;

  @Output() change = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  public form: FormGroup;
  public loanId: number;
  public cardId: number;

  get isCardFoldable(): boolean {
    if (this.card) {
      return !this.card.active;
    }
  }

  get cardTitle(): string {
    const creditCardTypeTitle = CARD_TYPE_SHORT[this.card && this.card.credit_card ? this.card.credit_card.card_type : null];
    const creditCardCardNumber = hideDataString(this.card && this.card.credit_card ? this.card.credit_card.number : null, 4, 4);
    return `${creditCardTypeTitle} ${creditCardCardNumber}`;
  }

  constructor(private formBuilder: FormBuilder, private $modal: ModalService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      this.initForm();
    }

    if (changes.card && changes.card.currentValue) {
      const card = (<IHoldCardResponse>changes.card.currentValue).credit_card;
      this.cardId = card.id;
      const length = card.card_type === 'A' ? -3 : -4;
      this.form.patchValue({
        number: card.number ? `••••••••••••${card.number.slice(length)}` : '••••••••••••••••',
        expirationDate: card.expiration_date ? moment(card.expiration_date).format('MMYY') : ''
      });
    }

    if (changes.loans && changes.loans.currentValue) {
      const loans = <ILoan[]>changes.loans.currentValue;
      if (loans.length) {
        this.loanId = loans[0].id;
      }
    }
  }

  openEditCreditCardModal() {
    this.$modal.openEditCreditCard({
      card: this.card
    }).subscribe(updated => { 
      if (updated) { this.change.emit(); }
    });
  }

  onRemove() {
    this.remove.emit();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      number: [''],
      expirationDate: ['']
    });
  }
}
