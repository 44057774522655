import { Component, OnInit, ChangeDetectionStrategy, forwardRef, AfterViewInit, Injector, ChangeDetectorRef, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-edit-input',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: BaseInputComponent, useExisting: EditInputComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditInputComponent)
    }
  ]
})
export class EditInputComponent extends BaseInputComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() locked = true;
  @Input() id: string;
  @Output() lockedChange = new EventEmitter<boolean>();
  constructor(
    protected cdRef: ChangeDetectorRef,
    protected injector: Injector
  ) {
    super(injector, cdRef);
  }

  public ngxMaskDelayed$: Observable<string>;
  private ngxMaskSubject$ = new BehaviorSubject(this.ngxMask);

  ngOnInit() {
    // TODO: Figure out fix for workaround with dynamic mask change
    this.ngxMaskDelayed$ = this.ngxMaskSubject$.pipe(delay(1));
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnChanges({ ngxMask }: SimpleChanges) {
    if (ngxMask && this.ngxMask) {
      this.ngxMaskSubject$.next(this.ngxMask);
    }
  }

  onEditClick() {
    this.writeValue('');
    this.locked = false;
    this.lockedChange.emit(false);
  }
}
