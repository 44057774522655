<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<app-loan-warning
  [loading]="loading$ | async"
></app-loan-warning>
<div class="title">
  <div
    class="title__text"
    [class.title__text_loading]="loading$ | async"
  >My Loans</div>
  <app-button
    class="title__btn"
    [loading]="loading$ | async"
  >Apply for New Loan</app-button>
</div>

<div
  class="loans"
  [class.loans_loading]="loading$ | async"
>
  <app-loan-accordion
    class="loans__loan"
    *ngFor="let loan of (loans$ | async)"
    [loan]="loan"
    [bankAccounts]="bankAccounts$ | async"
    [holdCards]="holdCards$ | async"
    [status]="loanStatusEnum.success"
  ></app-loan-accordion>
</div>
