<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<div class="wrapper">
  <div class="column">
    <div class="title-wrapper">
      <div
        class="title"
        [class.title_loading]="(loading$ | async) || (banksLoading$ | async)"
      >Bank Accounts</div>
      <app-button
        color="white"
        class="add-button"
        (pressed)="openAddBankAccountModal()"
        [loading]="(loading$ | async) || (banksLoading$ | async)"
      >Add New</app-button>
    </div>
    <app-bank-account-card
      class="card"
      *ngFor="let bankAccount of (bankAccountsPrimary$ | async)"
      [loans]="loans$ | async"
      [loading]="(loading$ | async) || (banksLoading$ | async)"
      [bankAccount]="bankAccount"
    ></app-bank-account-card>
    <mat-accordion>
      <app-bank-account-card
        class="card"
        *ngFor="let bankAccount of (bankAccountsSecondary$ | async)"
        [loading]="(loading$ | async) || (banksLoading$ | async)"
        [bankAccount]="bankAccount"
      ></app-bank-account-card>
    </mat-accordion>

  </div>
  <div class="column">
    <div class="title-wrapper">
      <div
        class="title"
        [class.title_loading]="(loading$ | async) || (cardsLoading$ | async)"
      >Credit Cards</div>
      <app-button
        color="white"
        class="add-button"
        (pressed)="openAddCreditCardModal()"
        [loading]="(loading$ | async) || (cardsLoading$ | async)"
      >Add New</app-button>
    </div>
    <app-credit-card-card
      class="card"
      *ngFor="let card of (holdCardsActive$ | async)"
      [card]="card"
      [loans]="loans$ | async"
      [loading]="(loading$ | async) || (cardsLoading$ | async)"
      (change)="onHoldCardChange()"
    ></app-credit-card-card>
    <mat-accordion>
      <app-credit-card-card
        class="card"
        *ngFor="let card of (holdCardsInactive$ | async)"
        [card]="card"
        [loading]="(loading$ | async) || (cardsLoading$ | async)"
        (change)="onHoldCardChange()"
        (remove)="onCardRemove(card)"
      ></app-credit-card-card>
    </mat-accordion>
  </div>
</div>
