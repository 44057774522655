<div class="header-wrapper">
  <div
    class="header"
    [class.header_is-final-steps]="isFinalSteps"
  >
    <app-logotype class="header__logotype"></app-logotype>
    <!-- <app-button *ngIf="!isHiddenLogout" class="header__logout" icon="logout" (pressed)="onLoggedOut()"
      >Log Out</app-button
    > -->
    <div class="header__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
