<div class="line" link="pay-off-credit-card">
  <p class="line__question">What is a Pay Off Credit Card?</p>
  <p class="line__answer">
    The Pay Off Credit Card is the credit card you would like to pay off with an ApexLend loan.
    We can help you pay off all major U.S. credit card types, American Express, Discover, MasterCard, and Visa.
  </p>
</div>

<div class="line" link="hold-credit-card">
  <p class="line__question">What is a Hold Credit Card?</p>
  <p class="line__answer">
    The Hold Credit Card is the credit card you use to begin your debt diet.
    We will place authorization holds on your account to help you manage your debt.
    Currently, ApexLend only accepts Discover and MasterCard for Hold Credit Cards.
  </p>
</div>

<div class="line">
  <p class="line__question">What is an authorization hold?</p>
  <p class="line__answer">
    An authorization hold will reduce the available credit on your credit card by the amount on hold.
  </p>
</div>

<div class="line" link="available-credit">
  <p class="line__question">What is Available Credit?</p>
  <p class="line__answer">
    Available credit is the unused credit limit available to be charged to your credit card.
    It is the difference between your total credit limit and your balance, interest, new charges, pending charges and any authorization holds.
  </p>
</div>

<div class="line">
  <p class="line__question">Where can I find Available Credit?</p>
  <p class="line__answer">
    Your available credit can usually be found near your balance when you log into your online credit card portal or alternatively you can call your credit card company for help.
  </p>
  <img class="line__image" src="/assets/img/available-credit.png" alt="Available Credit">
</div>

<div class="line" link="available-cash-advance">
  <p class="line__question">What is Available Cash Advance?</p>
  <p class="line__answer">
    Available cash advance is the unused cash limit available to be charged to your credit card.
  </p>
</div>

<div class="line">
  <p class="line__question">Where can I find Available Cash Advance?</p>
  <p class="line__answer">
    Your available credit can usually be found near your credit limit when you log into your online credit card portal or alternatively you can call your credit card company for help.
  </p>
  <img class="line__image" src="/assets/img/available-cash-advance.png" alt="Available Cash Advance" />
</div>

<div class="line">
  <p class="line__question">How much authorization hold will ApexLend place?</p>
  <p class="line__answer">
    Just prior to completing your loan, ApexLend will request permission for the following holds:
  </p>
  <ul class="line__list">
    <li>The amount of your first payment (payment hold)</li>
    <li>The amount of available cash advance on your hold credit card up to the remaining balance after your first payment is made. (balance hold)</li>
  </ul>
</div>

<div class="line">
  <p class="line__question">When will you place the authorization hold?</p>
  <p class="line__answer">
    We will request your permission for authorization hold after you see your ApexLend rate and just prior to signing your loan document.
  </p>
  <ul class="line__list">
    <li>If your Pay Off Credit Card and Hold Credit Card are different, ApexLend will place both holds on your Hold Credit Card prior to finalizing the loan.</li>
    <li>If your Pay Off Credit Card and Hold Credit Card are the same ApexLend will place the payment hold prior to finalizing the loan and the balance hold after ApexLend pays down your loan with ApexDirect.</li>
  </ul>
</div>

<div class="line">
  <p class="line__question">How long will the holds last?</p>
  <p class="line__answer">
    The holds will remain in place until the next payment. Within 7 days of your next payment due date, we will ask you to authorize new holds for the next payment and balance after that next payment. Once you authorize us to place the new holds, we will cancel the prior holds and place the new authorization holds.
  </p>
  <p class="line__answer">If you pay off your loan, all remaining authorization holds will also be canceled.</p>
</div>

<div class="line">
  <p class="line__question">What is ApexDirect?</p>
  <p class="line__answer">
    With ApexDirect, we will send the money you borrow from ApexLend directly to your credit card company so you can begin to save money as quickly as possible. By sending the money directly to pay down your debt, we also mitigate fraudulent activity.
  </p>
</div>

<div class="line" link="abortLoan">
  <p class="line__question">What if I decide to change my mind and don’t want the loan after I give permission for the authorization hold?</p>
  <p class="line__answer">
    You can contact us to cancel the authorization hold or we will cancel the authorization hold automatically within 24 hours if you do not complete your loan.
  </p>
</div>
