import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/user/services/user/user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-verified-container',
  templateUrl: './user-verified-container.component.html',
  styleUrls: ['./user-verified-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserVerifiedContainerComponent implements OnInit {
  public message: string;
  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    const uidb64 = this.route.snapshot.paramMap.get('uidb64');
    const token = this.route.snapshot.paramMap.get('token');
    this.userService.activateUser(uidb64, token).subscribe(res => {
      this.message = res.message;
      this.loading$.next(false);
    });
  }

  onContinue() {
    this.router.navigateByUrl('/');
  }
}
