import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IInfoModalData {
  title?: string;
  description?: string | string[];
  buttonText?: string;
  imageUrl?: string;
}

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoModalComponent {
  constructor(
    public dialogRef: MatDialogRef<InfoModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IInfoModalData
  ) {}

  get description(): string[] {
    return [].concat(this.data.description);
  }

  onClose() {
    this.dialogRef.close();
  }
}
