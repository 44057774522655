import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserVerifiedContainerComponent } from 'src/app/user/containers/user-verified-container/user-verified-container.component';
import { AboutContainerComponent } from './containers/about-container/about-container.component';
import { ContactsContainerComponent } from './containers/contacts-container/contacts-container.component';
import { ContentPagesContainerComponent } from './containers/content-pages-container/content-pages-container.component';
import { PrivacyPolicyContainerComponent } from './containers/privacy-policy-container/privacy-policy-container.component';
import { ContentTermsContainerComponent } from './containers/content-terms-container/content-terms-container.component';
import { ContentTransactionsContainerComponent } from './containers/content-transactions-container/content-transactions-container.component';
import { FaqContainerComponent } from './containers/faq-container/faq-container.component';
import { LicenceContainerComponent } from './containers/licence-container/licence-container.component';
import { MainContainerComponent } from './containers/main-container/main-container.component';
import { Error500ContainerComponent } from './containers/status-codes/error500-container/error500-container.component';
import { NotFoundContainerComponent } from './containers/status-codes/not-found-container/not-found-container.component';
import { ResetPasswordContainerComponent } from './containers/reset-password-container/reset-password-container.component';
import { DocuSignWindowComponent } from './containers/docu-sign-window/docu-sign-window.component';
import { ApexPayTermsContainerComponent } from './containers/apex-pay-terms-container/apex-pay-terms-container.component';

export const routes: Routes = [
  {
    path: '',
    component: MainContainerComponent,
  },
  {
    path: 'about',
    component: AboutContainerComponent
  },
  {
    path: 'contacts',
    component: ContactsContainerComponent
  },
  {
    path: 'help',
    component: FaqContainerComponent
  },
  {
    path: 'verify-email/:uidb64/:token',
    component: UserVerifiedContainerComponent
  },
  {
    path: 'reset-password/:uidb64/:token',
    component: ResetPasswordContainerComponent,
  },
  {
    path: 'license',
    component: LicenceContainerComponent
  },
  {
    path: 'privacy',
    component: PrivacyPolicyContainerComponent
  },
  {
    path: 'content',
    component: ContentPagesContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'terms',
        pathMatch: 'full'
      },
      {
        path: 'terms',
        component: ContentTermsContainerComponent
      },
      {
        path: 'transactions',
        component: ContentTransactionsContainerComponent
      }
    ]
  },
  {
    path: 'docusign',
    component: DocuSignWindowComponent
  },
  {
    path: 'apex-terms',
    component: ApexPayTermsContainerComponent
  },
  {
    path: '404',
    component: NotFoundContainerComponent
  },
  {
    path: '500',
    component: Error500ContainerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {}
