<mat-progress-bar mode="indeterminate" [ngStyle]="{'visibility': !paymentHistoryStatus ? 'visible' : 'hidden'}"></mat-progress-bar>
<app-loans-table *ngIf='payments?.length > 0'
  [data]="payments"
  [controls]="controls"
  [tableSchema]="tableSchema"
  [loan]="loan"
></app-loans-table>

<p *ngIf="payments?.length < 0" class="center">Oops! payment history not available.</p>  

<div class="container statment-hide">
  <div class="row"> 
    <div class="col-md-8" id="htmlData" #htmlData>
      <app-download-statements
        [loan]="loan"
        [data]="selectedData"
        >
      </app-download-statements>
    </div>  
    <!-- <div class="col-md-4 text-right">
      <button class="btn btn-success btn-block" (click)="openPDF()">Download PDF</button>
    </div>   -->
  </div>
</div>
