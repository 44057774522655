import { ChangeDetectionStrategy, Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { UserService } from 'src/app/user/services/user/user.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordModalComponent implements OnInit {
  form: FormGroup;
  public error: string;
  public errorMessage: string;
  public user : string;
  public checkPassword: any;
  public loginData: any;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.initForm();
    this.getUserName();
  }

  initForm() {
    this.form = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      new_password1: ['', [Validators.required, RxwebValidators.minLength({value: 6})]],
      new_password2: ['', [Validators.required, RxwebValidators.compare({ fieldName: 'new_password1' })]]
    });
  }

  getUserName() {
    this.userService.getUsername()
    .subscribe(username => {
      this.user = username
    });
  }

  focusout(form: any): void {
    this.loginData = {
      username: this.user,
      password: form.currentPassword
    }

    if (form && form.currentPassword && form.currentPassword.length > 0) {
      this.userService.login(this.loginData)
      .subscribe(res => {
        if (res.token != null) {
          this.checkPassword = null
        }
      }, error => {
        if (error.error.token == null && error.status === 400) {
          this.checkPassword = "Incorrect Current password"
          this.form.get('currentPassword').invalid;
        }
      })
    }
  }

  onSubmit(form: any): void {
    if (this.form.valid) {
      this.userService
        .changePassword(form)
        // .subscribe(_ => this.dialogRef.close(true), _ => (this.errorMessage = 'This password is too common'));
        .subscribe(
          resp => {
            console.log(resp);
            this.dialogRef.close(true)
          },
          error => {
            if (error.status === 401) {
              this.errorMessage = error.error.detail;
            } else if ( error.status === 400) {
              this.errorMessage = error.error.new_password2[0];
            }
        }
      );
    }
  }
}
