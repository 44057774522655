<div class="stepper-wrapper">
  <div class="stepper-container">
    <div
      class="step"
      [ngClass]="isFinal ? null : 'step--number-' + (i + 1)"
      *ngFor="let step of steps; let i = index"
      [class.active]="i === current - 1"
      [class.completed]="i < current - 2 || (i === 1 && current === 3 && !isFinal)"
      [class.available]="i === current - 2 && (current !== 3 || isFinal)"
      [class.clickable]="!isLocked && i === current - 2"
      (click)="onSelectionChange(step, i)"
    >
      {{ i + 1 }}
    </div>
  </div>
</div>
