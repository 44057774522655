<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <div class="al-modal-title">
      Change Bank Account
    </div>
    <form [formGroup]="form" class="form">
      <app-select
        class="select"
        formControlName="bankAccount"
        label="Bank Account"
        [options]="options$ | async"
        [loading]="loading$ | async"
      ></app-select>
      <app-button
        class="add-button"
        color="white"
        icon="plus"
        iconPosition="left"
        noShadow="true"
        queryParamsHandling="preserve"
        (pressed)="onAddNewBank()"
      >Add New</app-button>
    </form>
    <div class="form__bottom-controls">
      <app-button
        class="form__bottom-btn"
        color="white"
        (pressed)="dialogRef.close()"
      >Back</app-button>
      <app-button
        class="form__bottom-btn"
        (click)="onSubmit()"
      >Submit</app-button>
    </div>
  </div>
</div>
