import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITab } from 'src/app/shared/components/tabs/tabs.component';

// TODO: move to own module
@Component({
  selector: 'app-content-pages-container',
  templateUrl: './content-pages-container.component.html',
  styleUrls: ['./content-pages-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentPagesContainerComponent implements OnInit {
  public tabs: ITab[] = this.createTabsLinks();
  private prevUrl: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.prevUrl = this.route.snapshot.queryParamMap.get('prevUrl');
    if (this.prevUrl) {
      this.tabs = this.createTabsLinks(this.prevUrl);
    }
  }

  private createTabsLinks(prevUrl?: string): ITab[] {
    return [
      { label: 'Terms of use', path: '/content/terms', queryParams: { prevUrl } },
      { label: 'Electronic Transactions and Disclosures', path: '/content/transactions', queryParams: { prevUrl } }
    ];
  }
}
