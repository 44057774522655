import { parseExpirationDate } from "../helpers/parse-expiration-date";
import { FormControl } from "@angular/forms";

export function validateExpirationDate(control: FormControl) {
  if (control.value) {
    const date = parseExpirationDate(control.value);
    if (date && date.getTime() > new Date().getTime()) {
      return null;
    } else {
      return { expirationDateError: true };
    }
  } else {
    return null;
  }
}