import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperComponent implements OnInit {
  public current = 0;
  @Input() steps: Route[];
  @Input() isFinal = false;
  @Input() isLocked = false;
  @Output() selected = new EventEmitter<Route>();

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.subscribeChangeRoute();
  }

  public onSelectionChange(selectedRoute: Route, index: number): void {
    if (!this.isLocked && index === this.current - 2 && (this.current !== 3 || this.isFinal)) {
      this.selected.emit(selectedRoute);
    }
  }

  private subscribeChangeRoute() {
    if (this.route.snapshot.firstChild && this.route.snapshot.firstChild.data){
      this.current = this.route.snapshot.firstChild.data['step'];
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(_ => {
      if (this.route.snapshot.firstChild && this.route.snapshot.firstChild.data){
        this.current = this.route.snapshot.firstChild.data['step'];
      }
    });
  }
}
