<app-header></app-header>
<div class="container page-container" *ngIf="(contact | json) != '{}'">
  <h1>Contacts</h1>
  <p>
    You can contact us via email at <a href="mailto:{{contact.helpEmail}}">{{contact.helpEmail}}</a> or by calling us at {{contact.ContactNumber}} or {{contact.tollFreeNumber}}.
  </p>
  <p>
    You may also reach us in writing at the following address:<br>
    {{ contact.name }}<br>
    {{ contact.address.address1 }}, {{ contact.address.address2 }}<br>
    {{ contact.address.city }}, {{ contact.address.state }}, {{ contact.address.zipCode }}, USA<br>
  </p>
</div>
<app-footer></app-footer>
