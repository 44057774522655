<div class="overview">
  <div class="control">
    <div class="control__label">Loan</div>
    <div class="control__value">#{{ loan?.id }}</div>
  </div>
  <div class="control">
    <div class="control__label">Loan Amount</div>
    <div class="control__value">$ {{ loan?.amount | number: '1.0-2' }}</div>
  </div>
  <div class="control">
    <div class="control__label">Current Balance</div>
    <div class="control__value">$ {{ loan?.current_balance | number: '1.0-2' }}</div>
  </div>
  <div class="control">
    <div class="control__label">Status</div>
    <div class="control__value">{{ loan?.status }}</div>
  </div>
  <div class="control">
    <div class="control__label">Closing Date</div>
    <div class="control__value">{{ loan?.closingDate | date: 'MM/dd/yyyy' : 'UTC' }}</div>
  </div>
  <div class="control">
    <div class="control__label">Current Term</div>
    <div class="control__value">{{ loan?.term }} months ({{ loan?.term / 12 }} years)</div>
  </div>
  <div class="control">
    <div class="control__label">Interest Rate</div>
    <div class="control__value">{{ loan?.interestRatePct | number: '1.0-2' }}%</div>
  </div>
  <div class="control">
    <div class="control__label">Remaining # of Payments</div>
    <div class="control__value">{{ paymentsSchedule?.length }}</div>
  </div>
  <div class="control">
    <div class="control__label">Bank Account #</div>
    <div class="control__value">{{ linkedBankAccountDigits }}</div>
  </div>
  <div class="control">
    <div class="control__label">Hold Card #</div>
    <div class="control__value">{{ linkedHoldCardDigits }}</div>
  </div>
  <div class="control">
    <div class="control__label">Original # of Payments</div>
    <div class="control__value">{{ paymentsSchedule?.length + paymentHistory?.length }}</div>
  </div>
  <div class="control" *ngIf="show" >
    <div class="control__label">
    <span>Hold Amount 1 & 2</span>
    <img 
      class="form-title--hint"
      src="assets/img/icons/info--blue.svg"
      (click)="onTitleHintClick()"
    > </div>
    <div class="control__value">$ {{TotalOfPendingHolds}}</div>
  </div>
  <div class="control">    
  </div>
</div>

<div class="bottom-controls">
  <app-button class="bottom-controls__btn" link="/profile/new-holds-authorization">Authorize Hold</app-button>
  <app-button class="bottom-controls__btn" link="/profile/make-payment" [queryParams]="{ 'loan': loan?.id }">Make Payment</app-button>
  <app-button 
    class="bottom-controls__btn"
    [isDisabled]="!canDelayPayment"
    (pressed)="openDelayPaymentModal()"
    >Delay Payment
  </app-button>
  <app-button class="bottom-controls__btn" (pressed)="openChangeAchModal()">Change Bank Account</app-button>
</div>

<div class="sliders">
  <app-control
    class="sliders__notification"
    label="Payment Notifications"
    type="radio"
    icon="/assets/img/icons/notifications.svg"
    [checked]="true"
    [short]="true"
  ></app-control>
  <div class="apexpay">
    <img class="apexpay__image" src="assets/img/apex-pay/apexpay_logo.svg" alt="Apex-Pay">
    <mat-slide-toggle
      class="apexpay__toggle"
      [formControl]="turnOnAutoPay"
    ></mat-slide-toggle>
  </div>
</div>

<div class="hint">
  Account ending in {{ lastAccountNumbers || '[xxxx]' }}
</div>
