import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICustomer, IHoldCard, IPayOffAccount } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { UserService } from 'src/app/user/services/user/user.service';
import { PCI_DSS_LINK } from 'src/app/shared/constants/common.constants';
import { BehaviorSubject, forkJoin, Observable, concat } from 'rxjs';
import { IApplication } from '../../../user/models/user.models';
import { shareReplay, toArray } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { formatObjectNumber } from 'src/app/shared/helpers/format-object';
import { HttpErrorResponse } from '@angular/common/http';
import { IInfoModalData } from 'src/app/shared/entry/info-modal/info-modal.component';

@Component({
  selector: 'app-final-credit-cards-container',
  templateUrl: './final-credit-cards-container.component.html',
  styleUrls: ['./final-credit-cards-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalCreditCardsContainerComponent implements OnInit {
  public customer: ICustomer;
  public pciLink = PCI_DSS_LINK;

  public holdCard$: Observable<IHoldCard>;
  public payOffAccount$: Observable<IPayOffAccount>;
  public application$: Observable<IApplication>;

  public loading$ = new BehaviorSubject<boolean>(true);
  public errors$ = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private userService: UserService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    const appId = this.userService.applicationId;
    this.holdCard$ = this.customerService.getHoldCard(appId).pipe(shareReplay(1));
    this.payOffAccount$ = this.customerService.getPayOffAccount(appId).pipe(shareReplay(1));
    forkJoin([
      this.holdCard$,
      this.payOffAccount$
    ]).subscribe(() => {
      this.getApplicationData();
    });
  }

  public onSubmitted({ application, holdCard, holdCardExtra }): void {
    const subscriptions: Observable<any>[] = [];
    const appId = this.userService.applicationId;

    subscriptions.push(this.customerService.patchApplication(appId, {
      ...application, 
      ...formatObjectNumber(holdCardExtra) 
    }));

    if (!holdCard.id) {
      delete holdCard.id;
      subscriptions.push(this.customerService.addHoldCard(appId, holdCard));
    } else {
      delete holdCard.id;
      subscriptions.push(this.customerService.patchHoldCard(appId, holdCard));
    }

    concat(...subscriptions).pipe(toArray()).subscribe(() => {
      this.router.navigateByUrl(`final/payments`);
      }, (resp: HttpErrorResponse) => {
        const info: IInfoModalData = { title: 'Error' };
        if (resp.error.amount) {
          info.description = resp.error.amount[0].replace('this value', 'Amount to Pay Off');
        } else if (resp.error.hold_card_limit) {
          info.description = resp.error.hold_card_limit[0].replace('this value', 'Available Credit');
        } else if (resp.error.non_field_errors) {
          info.description = resp.error.non_field_errors[0];
        } else {      
          info.description = 'Something went wrong'
        }
        info.buttonText = 'OK';
        this.modalService.openInfo(info);
        this.errors$.next(resp.error);
      });
  }

  private getApplicationData() {
    this.application$ = this.userService.getApplication().pipe(shareReplay(1));
    this.application$.subscribe(() => {
      this.loading$.next(false);
    });
  }
}
