import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlComponent {
  @Input() type: 'checkbox' | 'radio' = 'checkbox';
  @Input() label: string;
  @Input() short: boolean;
  @Input() icon: string;
  @Input() checked: boolean;
  @Output() changed = new EventEmitter<boolean>();

  public onChange(event: MatCheckboxChange) {
    this.changed.emit(event.checked);
  }
}
