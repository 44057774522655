<div class="support">
  <div class="support__info">
    <div class="title">
      <h2 class="title__text">Frequently Asked Questions</h2>
    </div>
    <app-select-input
      class="search"
      [formControl]="searchTerm"
      [options]="autocompleteOptions$ | async"
      (changed)="filterOptions($event)"
      placeholder="Type To Search"
      (selectedOption)="onSelectedQuestion()"
      ngDefaultControl
    ></app-select-input>
    <div class="subjects">
      <app-subject-accordion
        class="subjects__subject"
        subjectTitle="Offer Code"
        [forceExpand]="searchTerm.value"
      >
        <ul class="questions">
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How do I get an ApexLend personal loan?'"></span>
            <b class="questions__question__title">How do I get an ApexLend personal loan?</b>
            <p class="questions__question__text">
              You must have an offer code from ApexLend.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What is an offer code?'"></span>
            <b class="questions__question__title">What is an offer code?</b>
            <p class="questions__question__text">
              An offer code is your key to beginning your debt diet with ApexLend.
              If you received an offer code beginning with a “P” you have been pre-qualified for a loan with ApexLend.
              Offer codes are currently available for select clients of our affiliate Sun West Mortgage Company LLC.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Where do I find my offer code?'"></span>
            <b class="questions__question__title">Where do I find my offer code?</b>
            <p class="questions__question__text">
              You would have received an offer code by physical mail or e-mail
              and the offer code is found in the <a target="_blank" href="assets/img/offer_code_location.png">top right of the letter</a>.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How do I get an offer code if I did not receive a letter or e-mail?'"></span>
            <b class="questions__question__title">How do I get an offer code if I did not receive a letter or e-mail?</b>
            <p class="questions__question__text">
              Please sign up at <a routerLink="/">www.apexlend.com</a> at the bottom of the page and we will contact you by
              email when we are ready to begin the process with you.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Will the rate on the offer flyer be my rate if I apply?'"></span>
            <b class="questions__question__title">Will the rate on the offer flyer be my rate if I apply?</b>
            <p class="questions__question__text">
              If the income and liability information we have with our affiliate Sun West Mortgage Company is still accurate,
              the rate on the flyer should be the rate offered to you as long as you are still employed and meet all the
              qualifications for the loan. If the information has changed, you may receive a better or worse rate than the
              flyer rate.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How long does it take to get a loan?'"></span>
            <b class="questions__question__title">How long does it take to get a loan?</b>
            <p class="questions__question__text">
              Once the application is completed, the approval process should take no more than one business day and you may
              get funded within one business day of approval.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What if I entered the wrong Email address/Username?'"></span>
            <b class="questions__question__title">What if I entered the wrong Email address/Username?</b>
            <p class="questions__question__text">
              If you think you entered the wrong email address and cannot log in, you can <a (click)="openResetOfferCodeModal()">reset the offer code</a>
              and then go through the initial verification procedure again to change the email address associated with the Offer Code.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What does Reset Offer Code do?'"></span>
            <b class="questions__question__title">What does Reset Offer Code do?</b>
            <p class="questions__question__text">
              Reset Offer Code takes you back to the first step before you set up the User Name/Email address.
              Use this to change the User Name/Email address in case you typed in the wrong information.<br />
              <a (click)="openResetOfferCodeModal()">Reset Offer Code</a>
            </p>
          </li>
        </ul>
      </app-subject-accordion>
      <app-subject-accordion
        class="subjects__subject"
        subjectTitle="Household Income and Liabilities"
        [forceExpand]="searchTerm.value"
      >
        <ul class="questions">
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What are the income requirements to qualify for a loan?'"></span>
            <b class="questions__question__title">What are the income requirements to qualify for a loan?</b>
            <p class="questions__question__text">
              For your personal loan, Apexlend uses household income and liability to qualify you for the loan using the same
              set of borrowers for your mortgage with our affiliate Sun West Mortgage Company LLC.
            </p>
          </li>
        </ul>
      </app-subject-accordion>
      <app-subject-accordion
        class="subjects__subject"
        subjectTitle="Rates and Fees"
        [forceExpand]="searchTerm.value"
      >
        <ul class="questions">
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What are ApexLend personal loan rates?'"></span>
            <b class="questions__question__title">What are ApexLend personal loan rates?</b>
            <p class="questions__question__text">
              The ApexLend personal loan interest rates range from 5% to 29%.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What are origination fees for ApexLend personal loans?'"></span>
            <b class="questions__question__title">What are origination fees for ApexLend personal loans?</b>
            <p class="questions__question__text">
              The one time up front origination fees are 0% to 2%.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Do you have prepayment fees?'"></span>
            <b class="questions__question__title">Do you have prepayment fees?</b>
            <p class="questions__question__text">
              ApexLend will never charge you fees or penalties for prepayment of your loan.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Do you charge a fee to file an application?'"></span>
            <b class="questions__question__title">Do you charge a fee to file an application?</b>
            <p class="questions__question__text">
              No.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What is a Truth in Lending disclosure?'"></span>
            <b class="questions__question__title">What is a Truth in Lending disclosure?</b>
            <p class="questions__question__text">
              A Truth in Lending disclosure (TILA) is a standard form that borrowers receive about important terms of the
              loan. Please see <a target="_blank" href="http://bit.ly/CFPB_TILA">http://bit.ly/CFPB_TILA</a> for more information.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Will checking my rate affect my credit score?'"></span>
            <b class="questions__question__title">Will checking my rate affect my credit score?</b>
            <p class="questions__question__text">
              No. We will only use soft inquiries to offer you a rate. Please see
              <a target="_blank" href="http://bit.ly/Hard_vs_Soft">http://bit.ly/Hard_vs_Soft</a> for more information.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How will I receive my funds?'"></span>
            <b class="questions__question__title">How will I receive my funds?</b>
            <p class="questions__question__text">
              To help you begin your debt diet, we will send your loan funds directly to your credit card company to pay down
              your credit card so you can begin to save as soon as possible. Your origination fees are deducted from the funds
              disbursed to your credit card company. We charge interest beginning on the day the loan is issued.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How often are payments due?'"></span>
            <b class="questions__question__title">How often are payments due?</b>
            <p class="questions__question__text">
              Payments are due on a bi-weekly basis (every two weeks) or monthly. Please refer to
              <a target="_blank" href="http://bit.ly/Biweekly_calc">http://bit.ly/Biweekly_calc</a> to see how much you can save with a
              bi-weekly vs monthly loan.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Can I cancel my loan?'"></span>
            <b class="questions__question__title">Can I cancel my loan?</b>
            <p class="questions__question__text">
              Once your loan is funded, you may not cancel your loan but you can prepay the loan at any time.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Can I receive Military Lending Act (MLA) benefits?'"></span>
            <b class="questions__question__title">Can I receive Military Lending Act (MLA) benefits?</b>
            <p class="questions__question__text">
              MLA limits APR to below 36%. ApexLend loan APR is already below 36%. Please refer to
              <a target="_blank" href="http://bit.ly/Military_Lending_ACT">http://bit.ly/Military_Lending_ACT</a> for more information.
            </p>
          </li>
        </ul>
      </app-subject-accordion>
      <app-subject-accordion
        class="subjects__subject"
        subjectTitle="Existing Loans"
        [forceExpand]="searchTerm.value"
      >
        <ul class="questions">
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Can I take out an additional loans?'"></span>
            <b class="questions__question__title">Can I take out an additional loans?</b>
            <p class="questions__question__text">
              If you are already a borrower in good standing with ApexLend, you may take out
              additional loans at any time from your loan information portal. You can also click <a href="#">here</a> to apply.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How do I change my password?'"></span>
            <b class="questions__question__title">How do I change my password?</b>
            <p class="questions__question__text">
              Go to <a routerLink="/profile/information/personal">https://www.apexlend.com/profile/information/personal</a>
              and sign in to your account. Click "Change Password" at the bottom of the page to change the password.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What if I forgot my password?'"></span>
            <b class="questions__question__title">What if I forgot my password?</b>
            <p class="questions__question__text">
              Go to the <a routerLink="/">sign-in</a> and hit the “I forgot my password” link and follow the instructions.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Can I turn on two-step authentication?'"></span>
            <b class="questions__question__title">Can I turn on two-step authentication?</b>
            <p class="questions__question__text">
              Go to <a routerLink="/profile/information/personal">https://www.apexlend.com/profile/information/personal</a>
              and sign in to your account. Clink on the toggle button for 'Turn on Two-factor Authentication'.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How can I change my profile information (address, phone number, email, etc)?'"></span>
            <b class="questions__question__title">How can I change my profile information (address, phone number, email, etc)?</b>
            <p class="questions__question__text">
              Please go to <a routerLink="/profile/information/personal">https://www.apexlend.com/profile/information/personal</a>
              and sign in to your account. Clink the "Edit" button to change any information.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Changing your bank account?'"></span>
            <b class="questions__question__title">Changing your bank account?</b>
            <p class="questions__question__text">
              <a routerLink="/">Sign in to your account</a> and go to the <a routerLink="/profile/information/payment">payment information</a> or
              <a routerLink="/profile/make-payment" [queryParams]="queryParams">make payments</a> section and add a new bank account.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Changing your credit card?'"></span>
            <b class="questions__question__title">Changing your credit card?</b>
            <p class="questions__question__text">
              <a routerLink="/">Sign in to your account</a> and go to the
              <a routerLink="/profile/information/payment">payment information</a> and add a new bank account.
            </p>
          </li>
        </ul>
      </app-subject-accordion>
      <app-subject-accordion
        class="subjects__subject"
        subjectTitle="Making your Loan Payment"
        [forceExpand]="searchTerm.value"
      >
        <ul class="questions">
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Automated Payments'"></span>
            <b class="questions__question__title">Automated Payments</b>
            <p class="questions__question__text">
              If you opt-in to automatic ACH payments, your loan payments will be automatically deducted
              from your bank account on record on the due date. You will receive notification (unless you turned them off)
              at least 24 hours before we attempt to charge your bank account. If you do not have sufficient funds in your bank account,
              please go to the [make payment] section to schedule your regular payment for another date within 4 days of your due date.
              You can also make payments manually in advance of the automated payment or for additional principal paydowns.
              If you make a regular payment manually, the automated scheduled regular payment for that
              payment date will be canceled along with any associated notices.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Manual Payments - Regular Payments'"></span>
            <b class="questions__question__title">Manual Payments - Regular Payments</b>
            <p class="questions__question__text">
              <a routerLink="/">Sign in to your account</a> and go to the
              <a routerLink="/profile/make-payment" [queryParams]="queryParams">make payments</a>
              and make a regular payment or additional principal paydown.
              You will be sent a notification at least 24 hours before your due date.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How do I see how my loan will change if I make Additional Principal Paydown?'"></span>
            <b class="questions__question__title">How do I see how my loan will change if I make Additional Principal Paydown?</b>
            <p class="questions__question__text">
              Please click the “See How My Loan Will Change” button on the
              <a routerLink="/profile/make-payment" [queryParams]="queryParams">make payments</a> section.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'When will Additional Principal Paydowns be applied?'"></span>
            <b class="questions__question__title">When will Additional Principal Paydowns be applied?</b>
            <p class="questions__question__text">
              Additional Principal Paydowns will be applied after your regularly scheduled payment has been made.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How do I turn on or turn off notifications?'"></span>
            <b class="questions__question__title">How do I turn on or turn off notifications?</b>
            <p class="questions__question__text">
              Please go to <a routerLink="/profile/information/personal">Your Personal Info</a> page
              and click the “Notifications” button on the bottom left to toggle on or off.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Adding ApexLend to your address book'"></span>
            <b class="questions__question__title">Adding ApexLend to your address book</b>
            <p class="questions__question__text">
              If you are missing emails from ApexLend, you may need to whitelist our email address.
              Emails from apexlend will come from xxxx@apexlend.com.
              Please see your mail client instructions on how to whitelist apexlend.com.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How do I view my payment history?'"></span>
            <b class="questions__question__title">How do I view my payment history?</b>
            <p class="questions__question__text">
              Go to <a routerLink="/profile/loans">payment history</a>.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How do I view future payments due?'"></span>
            <b class="questions__question__title">How do I view future payments due?</b>
            <p class="questions__question__text">
              Go to <a routerLink="/profile/loans">future payments</a>.
            </p>
          </li>
        </ul>
      </app-subject-accordion>
      <app-subject-accordion
        class="subjects__subject"
        subjectTitle="How to Close your Account"
        [forceExpand]="searchTerm.value"
      >
        <ul class="questions">
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How to Close your Account'"></span>
            <b class="questions__question__title">How to Close your Account</b>
            <p class="questions__question__text">
              Once you pay off your loan, your account is no longer active and your
              official payoff letter can be found on the website under the
              <a href="#">loan information</a> tab. Federal and state laws require us to retain a record of your account
              information until the required record-retention period expires. For more information see our
              <a routerLink="/privacy">Privacy Policy</a>.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What happens if I enter active military duty?'"></span>
            <b class="questions__question__title">What happens if I enter active military duty?</b>
            <p class="questions__question__text">
              If you enter active military duty after taking out a loan with ApexLend,
              you will be covered under the Servicemembers Civil Relief Act (SCRA) and will be eligible to receive
              an interest rate of no more than 6% per year for loans closed before entering active duty or for reservists,
              before receiving notice of active duty. If you believe you qualify for SCRA benefits, first,
              ApexLend would like to thank you and your family for your service to the country.
              Second please send the following to help@apexlend.com:
            </p>
            <ul>
              <li>
                <p class="questions__question__text">
                  Your Full name
                </p>
              </li>
              <li>
                <p class="questions__question__text">
                  Loan Number
                </p>
              </li>
              <li>
                <p class="questions__question__text">
                  Best way to contact you
                </p>
              </li>
              <li>
                <p class="questions__question__text">
                  A copy of your active duty orders OR A letter from your executive or commanding officer
                  on official letterhead and it must include Your name, active duty start and end date,
                  branch of service, and the last four digits of your social security number
                </p>
              </li>
            </ul>
            <p class="questions__question__text">
              Please refer to <a href="http://bit.ly/SCRA_INFO">http://bit.ly/SCRA_INFO</a> for more information
            </p>
          </li>
        </ul>
      </app-subject-accordion>
    </div>
  </div>
  <!--<div class="support__controls">
    <div class="title">
      <h2 class="title__text">Contact Us</h2>
    </div>
    <div class="support__button-controls">
      <app-button
        class="support__btn-control"
      >Send Us an Email</app-button>
      <app-button
        class="support__btn-control"
      >Chat with Us</app-button>
    </div>
  </div>-->
</div>
