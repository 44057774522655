import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICustomer } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { UserService } from 'src/app/user/services/user/user.service';
import { IInformationForm } from '../../components/information-form/information-form.component';

@Component({
  selector: 'app-information-container',
  templateUrl: 'information-container.component.html',
  styleUrls: ['information-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationContainerComponent implements OnInit {
  public hasFormErrors$ = new BehaviorSubject<boolean>(false);
  public garbledCustomer$: Observable<ICustomer>;

  constructor(private customerService: CustomerService, private router: Router, private userService: UserService) {}

  ngOnInit() {
    this.garbledCustomer$ = this.customerService.get(this.userService.customerId);
  }

  public onSubmittedForm({ ssn, zipcode }: IInformationForm) {
    this.userService.loginWithSSN({ ssn, zipcode }).subscribe(
      ({ token }) => {
        if (!token) {
          this.hasFormErrors$.next(true);
          return;
        }
        this.router.navigateByUrl('/steps/confirm');
      },
      _ => this.hasFormErrors$.next(true)
    );
  }

  public onAuthFormChanged() {
    this.hasFormErrors$.next(false);
  }
}
