import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Route, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UserService } from 'src/app/user/services/user/user.service';
import finalSteps from '../../finalSteps';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-final-steps-container',
  templateUrl: './final-steps-container.component.html',
  styleUrls: ['./final-steps-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalStepsContainerComponent implements OnInit, OnDestroy {
  public customerId: number;
  public steps: Route[] = finalSteps;
  public username$: Observable<string>;

  private subscriptions = new Subscription();

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) {}

  get currentStep(): number {
    return this.userService.step;
  }

  ngOnInit() {
    this.username$ = this.userService.getUsername();
    this.subscriptions.add(this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((route: any) => {
      const step = this.steps.find(s => `/final/${s.path}` === route.url);
      if (step) {
        this.userService.saveCurrentStepNumber(step.data.step + 8, this.userService.applicationId).subscribe();
      }
    }, err => console.log('http error', err)));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onSelectedStep(step: Route) {
    this.router.navigateByUrl(`final/${step.path}`);
  }

  public goBackToPreviousSteps() {
    this.userService.saveCurrentStepNumber(8, this.userService.applicationId).subscribe(() => {
      this.router.navigateByUrl('steps/loan-options');
    }, err => console.log('http error', err));
  }

  public onLoggedOut() {
    this.userService.logout();
    this.router.navigateByUrl('/');
  }
}
