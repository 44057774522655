<app-header>
  <div
    class="header__username"
  >Welcome, {{ username$ | async }}</div>
  <app-button
    class="header__logout"
    icon="logout"
    (pressed)="onLoggedOut()"
  >
    Log Out
  </app-button>
</app-header>

<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<div class="container page-container">  

  
  <div class="border">
    <div class="title" [class.loading]="loading$ | async">
      Your loan Summary
    </div>
    
    <app-loan-info
      [payments]="paymentSchedule$ | async"
      [loan]="loan$ | async"
      [loading]="loading$ | async"
    ></app-loan-info>

    <div class="description" [class.loading]="loading$ | async">
      This is what your bi-weekly tentative loan schedule looks like. Scroll to the bottom to watch the video on how your
      loan works to proceed.
    </div>
    <app-loans-list
      [payments]="paymentSchedule$ | async"
      [loading]="loading$ | async"
    ></app-loans-list>
  </div>
  
  <embed *ngFor="let doc of documents$ | async; let i = index" class="pdf" [src]="documentUrls[i]">
  
  <div class="checkboxes">
    <app-checkbox
      *ngFor="let doc of documents$ | async; let i = index"
      [formControl]="formArray.controls[i]"
      [id]="doc.name"
    >I agree with {{ doc.name }}</app-checkbox>
  </div>
  <app-button
    [isDisabled]="!(documents$ | async).length || formArray.invalid"
    (pressed)="onSubmit()"
    [pending]="requestPending"
    [spinner]="spinner"
    [id]="'doc_submit_btn'"
  >Submit</app-button>

</div>
<app-footer></app-footer>
