import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  Injector,
  AfterViewInit,
  ChangeDetectorRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface IOptions {
  [key: string]: string;
}

export interface ISmartOption {
  key: any;
  value: string | number;
  disabled?: boolean;
}

export type ISmartOptionShort = [any, string];

@Component({
  selector: 'app-select',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent implements ControlValueAccessor, OnChanges, AfterViewInit, OnDestroy {
  @Input() isDisabled = false;
  @Input() isError: boolean;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() formControlName: string;
  @Input() errorMessage = 'Incorrect field';
  @Input() options: IOptions | (ISmartOptionShort | ISmartOption)[];
  @Input() correctValue: string;
  @Input() isPreview: boolean;
  @Input() loading: boolean;
  @Input() setTouched = false;
  @Input() initialValue: string;
  @Input() model: string;
  @Input() hasHint = false;
  @Input() isRequired = false;
  @Input() id: string;
  @Output() changed = new EventEmitter();
  @Output() hintClick = new EventEmitter();
  public control: AbstractControl;
  public optionsOutput: ISmartOption[] = [];
  private destroy$ = new Subject();

  public onTouched: () => void = () => {};
  public onChange: (value: string) => void = (value: string) => {
    this.changed.emit(value);
  };

  public get isOptionsArray(): boolean {
    return Array.isArray(this.options);
  }

  constructor(private injector: Injector, private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.initFormControl();
    this.setControlSubscriptions();
    this.monkeyPatchTouched(this.control);
  }

  ngOnChanges({ initialValue, options }: SimpleChanges) {
    if (initialValue && initialValue.currentValue) {
      this.writeValue(initialValue.currentValue);
    }

    if (options && this.options) {
      if (Array.isArray(this.options) && this.options.length > 0) {
        const first = this.options.find(Boolean);
        if (Array.isArray(first)) {
          this.optionsOutput = this.options.map((item) => {
            const [key, value] = item as ISmartOptionShort;
            return { key, value };
          });
        } else {
          this.optionsOutput = <ISmartOption[]>this.options;
        }
      } else {
        const entries = Object.entries(this.options);
        this.optionsOutput = entries.map(([key, value]) => ({ key, value }));
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: string) {
    if (this.model !== value) {
      this.model = value;
      this.onChange(value);

      if (this.control) {
        this.control.setValue(value);
      }
  
      return value;
    }
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouched = fn;
  }

  private initFormControl() {
    if (this.injector) {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      } else {
        console.warn('Component is missing form control binding');
      }
    }
  }

// Trigger change detection on control's value change
  private setControlSubscriptions() {
    if (!this.control) { return; }
    this.control.valueChanges.pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.cdRef.markForCheck();
    });
  }

  get isControlError(): boolean {
    if (!this.control) {
      return false;
    }

    if (this.correctValue) {
      return this.control.value !== this.correctValue;
    }

    return (this.control.touched || this.setTouched) && (!this.control.valid || this.isError);
  }

  private monkeyPatchTouched(control: AbstractControl) {
    if (!control) { return; }
    const self = this;
    const origFunc = control.markAsTouched;
    control.markAsTouched = function () {
      origFunc.apply(this, arguments);
      self.cdRef.markForCheck();
    }
  }
}
