<div class="block">
  <div class="bg-image bg-image_left"></div>
  <div class="content">
    <app-logotype class="content__logotype"></app-logotype>
    <div
      class="content__title"
      [class.content__title_loading]="loading$ | async"
    >{{ message }}</div>
    <div
      class="content__description"
      [class.content__description_loading]="loading$ | async"
    >You can now complete your application and take another step to becoming debt-free at ApexLend</div>
    <app-button
      class="content__button"
      (pressed)="onContinue()"
      [loading]="loading$ | async"
    >Continue with application</app-button>
  </div>
  <div class="bg-image bg-image_right"></div>
</div>
<app-footer></app-footer>
