<mat-progress-bar mode="indeterminate" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<app-form-title class="title" [loading]="loading">
  Make&nbsp;Payment&nbsp;for Loan&nbsp;#{{ loanId }}
</app-form-title>
<div
  class="payment"
  [class.payment_additional]="isAdditionalPayment$ | async"
>
  <form
    [formGroup]="form"
    class="form"
    [class.form_compressed]="isAdditionalPayment$ | async"
  >
    <div class="form__row">
      <app-select
        *ngIf="(paymentSource$ | async) === 'BankAccount'"
        [formControl]="form.controls['bankAccount']"
        label="Bank Account"
        class="form__bank-select"
        [options]="bankAccounts"
        [loading]="loading"
      ></app-select>
      <app-select
        *ngIf="(paymentSource$ | async) === 'CreditCard'"
        [formControl]="form.controls['creditCard']"
        label="Credit Card"
        class="form__bank-select"
        [options]="creditCardOptions"
        [loading]="loading"
      ></app-select>
      <div class="form__bank-buttons">
        <app-button
          class="form__bank-btn"
          color="white"
          queryParamsHandling="preserve"
          [loading]="loading"
          (pressed)="openAddNew()"
        >Add New</app-button>
        <ng-container *ngIf="(isRegularPayment$ | async) || (isPayoffPayment$ | async)">
          <app-button
            *ngIf="(paymentSource$ | async) === 'BankAccount'"
            class="form__bank-btn"
            color="white"
            queryParamsHandling="preserve"
            [loading]="loading"
            (pressed)="openChangeCardModal()"
          >Pay&nbsp;with Credit&nbsp;Card</app-button>
          <app-button
            *ngIf="(paymentSource$ | async) === 'CreditCard'"
            class="form__bank-btn"
            color="white"
            queryParamsHandling="preserve"
            [loading]="loading"
            (pressed)="openChangeBankModal()"
          >Pay&nbsp;with Bank&nbsp;Account</app-button>
        </ng-container>
      </div>
    </div>
    <div class="form__radio-group">
      <app-radio-group
        [formControl]="form.controls['type']"
        label="Type"
        [loading]="loading"
        [options]="paymentTypes"
        [direction]="(isAdditionalPayment$ | async) ? 'column' : 'row'"
      ></app-radio-group>
    </div>
    <div
      class="form__amount amount"
      [class.amount_loading]="loading"
    >
      <ng-container *ngIf="isRegularPayment$ | async">
        <div class="amount__label">Amount</div>
        <div class="amount__value">$ {{ firstNextPayment?.payment }}</div>
        <div class="amount__hint">Will be paid for regular payment due on {{ firstNextPayment?.due_date | date:'MM-dd-yyyy' : 'UTC' }}</div>
        <app-datepicker
          class="datepicker--regular"
          label="Payment Date"
          formControlName="date"
          [loading]="loading"
          [min]="minPaymentDate"
          [max]="maxPaymentDate"
          [warning]="datepickerWarning$ | async"
        ></app-datepicker>
      </ng-container>
      <ng-container *ngIf="isAdditionalPayment$ | async">
        <app-input
          class="amount__input"
          [formControl]="form.controls['amount']"
          label="Amount"
          [loading]="loading"
          ngxMask="comma_separator.2"
          prefix="$ "
          [dropSpecialCharacters]="true"
        ></app-input>
        <app-datepicker
          label="Payment Date"
          formControlName="date"
          [loading]="loading"
          [min]="minPaymentDate"
          [max]="maxPaymentDate"
        ></app-datepicker>
      </ng-container>
      <ng-container *ngIf="isPayoffPayment$ | async">
        <div class="amount__label">Amount</div>
        <div class="amount__value">$ {{ (payoffLoan$ | async)?.total_pending_balance | mask: 'comma_separator.2' }}</div>
        <app-datepicker
          class="datepicker--regular"
          label="Payment Date"
          formControlName="date"
          [loading]="loading"
          [min]="minPaymentDate"
          [max]="maxPaymentDate"
          [warning]="datepickerWarning$ | async"
        ></app-datepicker>
      </ng-container>
    </div>
    <div class="form__bottom-controls">
      <app-button
        class="form__bottom-btn"
        color="white"
        [loading]="loading"
        queryParamsHandling="preserve"
        (click)="onBack()"
      >Back</app-button>
      <app-button
        class="form__bottom-btn"
        queryParamsHandling="preserve"
        [loading]="loading"
        [isDisabled]="!(isRegularPayment$ | async) && form.invalid"
        [pending]="observer.isPending | async"
        (pressed)="onSubmit()"
      >Make Payment</app-button>
    </div>
  </form>

  <div
    *ngIf="isAdditionalPayment$ | async"
    class="payment__info"
  >
    <app-payment-info
      title="After&nbsp;making&nbsp;«Additional&nbsp;Principal Paydown&nbsp;of&nbsp;$&nbsp;{{ formAmount$ | async | mask: 'comma_separator.2' }}»"
      [payment]="(additionalPrincipalAddl$ | async)?.remaining_payment"
      [interest]="(additionalPrincipalAddl$ | async)?.total_interest"
      [payments]="(additionalPrincipalAddl$ | async)?.remaining_payments_term"
    ></app-payment-info>
    <app-payment-info
      title="Prior&nbsp;to&nbsp;change&nbsp;assuming payments&nbsp;mode&nbsp;on&nbsp;due&nbsp;date:"
      [payment]="(additionalPrincipalRegular$ | async)?.remaining_payment"
      [interest]="(additionalPrincipalRegular$ | async)?.total_interest"
      [payments]="(additionalPrincipalRegular$ | async)?.remaining_payments_term"
      [grey]="true"
    ></app-payment-info>
    <ng-container *ngIf="(savings$ | async) !== null">
      <ng-container *ngIf="(paymentsDiff$ | async) !== null">
        <div class="payment__savings">
          You will save
          «<span>$ {{savings$ | async | mask: 'comma_separator.2'}}</span>»
          in interest and have
          {{paymentsDiff$ | async | mask: 'comma_separator.2'}}
          less payments to make
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<p class="subtext">
  By making a payment here, you agree to the One Time Automated Payments <a (click)="openTermsOfUse()">terms of use</a>
</p>
