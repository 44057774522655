export enum EConsent {
  ESign = 'ESIGN CONSENT',
  Terms = 'TERMS OF USE',
  Privacy = 'PRIVACY',
  Plaid = 'PLAID CONSENT',
  Hold = 'HOLD CONSENT',
  UsaEpay = 'USA EPAY CONSENT',
  CreditPull = 'CREDIT PULL CONSENT',
  RecurringAch = 'RECURRING ACH',
  PlaidBalanceCheck = 'PLAID BALANCE CHECK'
}