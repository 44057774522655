import { ChangeDetectionStrategy, Component, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IBankAccount, IBankRouting, ILoan } from 'src/app/shared/models/customer.model';
import { CustomerService } from '../../../shared/services/customer/customer.service';
import { BehaviorSubject } from 'rxjs';
import { hideDataString } from 'src/app/shared/helpers/hide-data-string';

@Component({
  selector: 'app-bank-account-card',
  templateUrl: './bank-account-card.component.html',
  styleUrls: ['./bank-account-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankAccountCardComponent implements OnChanges {
  @Input() loans: ILoan[];
  @Input() bankAccount: IBankAccount;
  @Input() loading: boolean;
  public form: FormGroup;
  public loanId: number;
  public accountId: number;

  public fedwiresLoading$ = new BehaviorSubject<boolean>(true);

  get isCardFoldable(): boolean  {
    return this.bankAccount && this.bankAccount.account_priority ? this.bankAccount.account_priority === 'secondary' : false;
  }

  get endingDigits(): string {
    return hideDataString(this.bankAccount ? this.bankAccount.account_number : null, 4, 4);
  }

  private currentRoutingNumber: string;

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      this.initForm();
    }

    if (changes.bankAccount && changes.bankAccount.currentValue) {
      const account = <IBankAccount>changes.bankAccount.currentValue;
      this.accountId = account.id;
      this.form.patchValue({
        ...account,
        account_number: hideDataString(account && account.account_number ? account.account_number : null, 4, 12)
      });
      this.findBankRouting(account.routing_number);
    }

    if (changes.loans && changes.loans.currentValue) {
      const loans = <ILoan[]>changes.loans.currentValue;
      if (loans.length) {
        this.loanId = loans[0].id;
      }
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      routing_number: [''],
      account_number: [''],
      institution: [''],
    });
  }

  private findBankRouting(routingNumber: string) {
    if (routingNumber && routingNumber !== this.currentRoutingNumber) {
      this.customerService.findFedwireAccount(routingNumber).subscribe((res: IBankRouting) => {
        this.currentRoutingNumber = routingNumber;
        this.form.patchValue({
          institution: res.customerName.trim()
        });
        this.fedwiresLoading$.next(false);
      })
    } else {
      this.fedwiresLoading$.next(false);
    }
  }
}
