import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from '../../services/customer/customer.service';
import { UserService } from '../../../user/services/user/user.service';
import { BehaviorSubject } from 'rxjs';
import { ISmartOption } from '../../components/select/select.component';
import { hideDataString } from '../../helpers/hide-data-string';

export interface IChangeCreditCardModalOutput {
  action?: 'AddNew' | 'Submit',
  id?: string
}

@Component({
  selector: 'app-change-credit-card-modal',
  templateUrl: './change-credit-card-modal.component.html',
  styleUrls: ['./change-credit-card-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCreditCardModalComponent implements OnInit {
  public form: FormGroup;
  public error: boolean;
  public creditCardsOptions$ = new BehaviorSubject<ISmartOption[]>([]);
  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
      public dialogRef: MatDialogRef<ChangeCreditCardModalComponent, IChangeCreditCardModalOutput>,
      private customerService: CustomerService,
      private userService: UserService,
      private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.initForm();

    this.customerService.getHoldCardsList(this.userService.applicationId).subscribe(holdCards => {
      const options: ISmartOption[] = holdCards.map(card => {
        const isAmericanExpress = card.credit_card.card_type === 'A';
        return {
          key: card.credit_card.id,
          value: hideDataString(card.credit_card.number, isAmericanExpress ? 3 : 4)
        };
      });
      const firstActive = holdCards.find(card => card.active);
      if (firstActive) {
        this.form.get('card').setValue(String(firstActive.credit_card.id));
      }
      this.creditCardsOptions$.next(options);
      this.loading$.next(false);
    });
  }

  public onAddNew() {
    this.dialogRef.close({ action: 'AddNew' });
  }

  public onSubmitted() {
    this.dialogRef.close({ action: 'Submit', id: this.form.value.card });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      card: ['', [Validators.required]],
    });
  }
}
