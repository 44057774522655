<div class="tabs">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <a
      *ngIf="tab?.path"
      [routerLink]="tab?.path"
      [queryParams]="tab?.queryParams"
      class="tab"
      routerLinkActive="tab--active"
    >
      {{ tab?.label }}
    </a>

    <div *ngIf="!tab?.path" class="tab" [class.tab--active]="tab?.active" (click)="pressed.emit(i)">
      {{ tab?.label }}
    </div>
  </ng-container>
</div>
