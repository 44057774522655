import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from 'src/app/user/services/user/user.service';
import { environment } from 'src/environments/environment';
import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CustomerService} from "../services/customer/customer.service";
import {EApplicationStatus} from "../../user/models/enums/application-status.enum";

@Injectable({
  providedIn: 'root'
})
export class LoadGuard implements CanActivate {
  private readonly POSITIVE_APPLICATION_STATUSES = [
    EApplicationStatus.InProgress,
    EApplicationStatus.Open
  ];

  constructor(
    private userService: UserService,
    private customerService: CustomerService
  ) {}

  canActivate(): boolean | Observable<boolean> {
    if (environment.debug && environment.debug.disableGuards) { return true; }
    const application$ = this.userService.getApplication();
    return application$.pipe(
      map((application) => {
        const isApplicationStatusPositive = this.POSITIVE_APPLICATION_STATUSES.includes(application && application.status);
        return isApplicationStatusPositive;
      })
    );
  }
}
