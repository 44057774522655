import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PrivacyPolicyService } from 'src/app/user/services/privacy-policy/privacy-policy.service';
import { UserService } from '../../services/user/user.service';
import { Router } from '@angular/router';
import { EConsent } from 'src/app/shared/models/enums/consent.enum';

@Component({
  selector: 'app-content-terms-container',
  templateUrl: './content-terms-container.component.html',
  styleUrls: ['./content-terms-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentTermsContainerComponent {
  public consentsReady$ = this.privacyPolicyService.consentsReady$;
  
  private pdf = '/assets/pdf/TermsOfUse_ApexLend.pdf';
  private dtiRoute = ['steps', 'dti'];

  constructor(
    public userService: UserService,
    private privacyPolicyService: PrivacyPolicyService,
    private router: Router
  ) {}

  onSavePdf() {
    window.open(this.pdf);
  }

  public onAccepted() {
    this.privacyPolicyService.saveConsent(EConsent.Terms).subscribe(() => {
      this.router.navigate(this.dtiRoute);
    });
  }
}
