<app-header></app-header>
<div class="container page-container support">
  <div class="support__info">
    <div class="title">
      <h2 class="title__text">Frequently Asked Questions</h2>
    </div>
    <app-select-input
      class="search"
      [formControl]="searchTerm"
      [options]="autocompleteOptions$ | async"
      (changed)="filterOptions($event)"
      placeholder="Type To Search"
      (selectedOption)="onSelectedQuestion()"
    ></app-select-input>
    <div class="subjects">
      <app-subject-accordion
        class="subjects__subject"
        subjectTitle="Offer Code"
        [forceExpand]="searchTerm.value"
      >
        <ul class="questions">
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How do I get an ApexLend personal loan?'"></span>
            <b class="questions__question__title">How do I get an ApexLend personal loan?</b>
            <p class="questions__question__text">
              You must have an offer code from ApexLend.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What is an offer code?'"></span>
            <b class="questions__question__title">What is an offer code?</b>
            <p class="questions__question__text">
              An offer code is your key to beginning your debt diet with ApexLend.
              If you received an offer code beginning with a “P” you have been pre-qualified for a loan with ApexLend.
              Offer codes are currently available for select clients of our affiliate Sun West Mortgage Company LLC.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Where do I find my offer code?'"></span>
            <b class="questions__question__title">Where do I find my offer code?</b>
            <p class="questions__question__text">
              You would have received an offer code by physical mail or e-mail
              and the offer code is found in the top right of the letter – Click for image.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How do I get an offer code if I did not receive a letter or e-mail?'"></span>
            <b class="questions__question__title">How do I get an offer code if I did not receive a letter or e-mail?</b>
            <p class="questions__question__text">
              Please sign up at <a routerLink="/">www.apexlend.com</a> at the bottom of the page and we will contact you by
              email when we are ready to begin the process with you.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Will the rate on the offer flyer be my rate if I apply?'"></span>
            <b class="questions__question__title">Will the rate on the offer flyer be my rate if I apply?</b>
            <p class="questions__question__text">
              If the income and liability information we have with our affiliate Sun West Mortgage Company is still accurate,
              the rate on the flyer should be the rate offered to you as long as you are still employed and meet all the
              qualifications for the loan. If the information has changed, you may receive a better or worse rate than the
              flyer rate.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How long does it take to get a loan?'"></span>
            <b class="questions__question__title">How long does it take to get a loan?</b>
            <p class="questions__question__text">
              Once the application is completed, the approval process should take no more than one business day and you may
              get funded within one business day of approval.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What if I entered the wrong Email address/Username?'"></span>
            <b class="questions__question__title">What if I entered the wrong Email address/Username?</b>
            <p class="questions__question__text">
              If you think you entered the wrong email address and cannot log in, you can <a (click)="openResetOfferCodeModal()">reset the offer code</a>
              and then go through the initial verification procedure again to change the email address associated with the Offer Code.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What does Reset Offer Code do?'"></span>
            <b class="questions__question__title">What does Reset Offer Code do?</b>
            <p class="questions__question__text">
              Reset Offer Code takes you back to the first step before you set up the User Name/Email address.
              Use this to change the User Name/Email address in case you typed in the wrong information.<br />
              <a (click)="openResetOfferCodeModal()">Reset Offer Code</a>
            </p>
          </li>
        </ul>
      </app-subject-accordion>
      <app-subject-accordion
        class="subjects__subject"
        subjectTitle="Household Income and Liabilities"
        [forceExpand]="searchTerm.value"
      >
        <ul class="questions">
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Household Income and Liabilities'"></span>
            <b class="questions__question__title">Household Income and Liabilities</b>
            <p class="questions__question__text">
              For your personal loan, Apexlend uses household income and liability to qualify you for the loan using the same
              set of borrowers for your mortgage with our affiliate Sun West Mortgage Company LLC.
            </p>
          </li>
        </ul>
      </app-subject-accordion>
      <app-subject-accordion
        class="subjects__subject"
        subjectTitle="Rates and Fees"
        [forceExpand]="searchTerm.value"
      >
        <ul class="questions">
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What are ApexLend personal loan rates?'"></span>
            <b class="questions__question__title">What are ApexLend personal loan rates?</b>
            <p class="questions__question__text">
              The ApexLend personal loan interest rates range from 5% to 29%.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What are origination fees for ApexLend personal loans?'"></span>
            <b class="questions__question__title">What are origination fees for ApexLend personal loans?</b>
            <p class="questions__question__text">
              The one time up front origination fees are 1% to 2%.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Do you have prepayment fees?'"></span>
            <b class="questions__question__title">Do you have prepayment fees?</b>
            <p class="questions__question__text">
              ApexLend will never charge you fees or penalties for prepayment of your loan.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Do you charge a fee to file an application?'"></span>
            <b class="questions__question__title">Do you charge a fee to file an application?</b>
            <p class="questions__question__text">
              No.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'What is a Truth in Lending disclosure?'"></span>
            <b class="questions__question__title">What is a Truth in Lending disclosure?</b>
            <p class="questions__question__text">
              A Truth in Lending disclosure (TILA) is a standard form that borrowers receive about important terms of the
              loan. Please see <a target="_blank" href="http://bit.ly/CFPB_TILA">http://bit.ly/CFPB_TILA</a> for more information.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Will checking my rate affect my credit score?'"></span>
            <b class="questions__question__title">Will checking my rate affect my credit score?</b>
            <p class="questions__question__text">
              No. We will only use soft inquiries to offer you a rate. Please see
              <a target="_blank" href="http://bit.ly/Hard_vs_Soft">http://bit.ly/Hard_vs_Soft</a> for more information.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How will I receive my funds?'"></span>
            <b class="questions__question__title">How will I receive my funds?</b>
            <p class="questions__question__text">
              To help you begin your debt diet, we will send your loan funds directly to your credit card company to pay down
              your credit card so you can begin to save as soon as possible. Your origination fees are deducted from the funds
              disbursed to your credit card company. We charge interest beginning on the day the loan is issued.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'How often are payments due?'"></span>
            <b class="questions__question__title">How often are payments due?</b>
            <p class="questions__question__text">
              Payments are due on a bi-weekly basis (every two weeks) . Please refer to
              <a target="_blank" href="http://bit.ly/Biweekly_calc">http://bit.ly/Biweekly_calc</a> to see how much you can save with a
              bi-weekly vs monthly loan.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Can I cancel my loan?'"></span>
            <b class="questions__question__title">Can I cancel my loan?</b>
            <p class="questions__question__text">
              Once your loan is funded, you may not cancel your loan but you can prepay the loan at any time.
            </p>
          </li>
          <li class="questions__question">
            <span
              class="selected-question"
              #selectedQuestion
              *ngIf="(selectedQuestion$ | async) === 'Can I receive Military Lending Act (MLA) benefits?'"></span>
            <b class="questions__question__title">Can I receive Military Lending Act (MLA) benefits?</b>
            <p class="questions__question__text">
              MLA limits APR to below 36%. ApexLend loan APR is already below 36%. Please refer to
              <a target="_blank" href="http://bit.ly/Military_Lending_ACT">http://bit.ly/Military_Lending_ACT</a> for more information.
            </p>
          </li>
        </ul>
      </app-subject-accordion>
    </div>
  </div>
</div>
<app-footer></app-footer>
