<ng-template #tableHeadCell let-text="text">
  <div class="table-head-cell-content-outer">
    <div class="table-head-cell-content-inner">
      {{ text }}
    </div>
  </div>
</ng-template>

<ng-container *ngIf="payments?.length">
  <div class="table table--3cols" [class.loading]="loading" [class.table--expanded]="tableExpanded">
    <div class="table-row table-th">
      <div style="order:1;" class="table-cell">
        <ng-container *ngTemplateOutlet="tableHeadCell; context: { text: 'Due Date' }"></ng-container>
      </div>
      <div style="order:1;" class="table-cell">
        <ng-container *ngTemplateOutlet="tableHeadCell; context: { text: 'Balance' }"></ng-container>
      </div>
      <div style="order:1;" class="table-cell">
        <ng-container *ngTemplateOutlet="tableHeadCell; context: { text: 'Bi-weekly Payment' }"></ng-container>
      </div>
      <div style="order:1;" class="table-cell">
        <ng-container *ngTemplateOutlet="tableHeadCell; context: { text: 'Ending Balance' }"></ng-container>
      </div>
    </div>
    <ng-container *ngFor="let payment of (payments | paginate: paginationConfig | slice:0:tableExpanded ? undefined: visibleRows); let i = index">
      <div class="table-row table-tr">
        <div [style.order]="i + 2" class="table-cell">{{ payment['due_date'] | date:'yyyy-MM-dd' : 'UTC' }}</div>
        <div [style.order]="i + 2" class="table-cell">
          $ {{ payment['starting_balance'] ? (payment['starting_balance'] | thousandsSeparators) : 0 }}
        </div>
        <div [style.order]="i + 2" class="table-cell">
          $ {{ payment['payment'] ? (payment['payment'] | thousandsSeparators) : 0 }}
        </div>
        <div [style.order]="i + 2" class="table-cell">
          $ {{ payment['ending_balance'] ? (payment['ending_balance'] | thousandsSeparators) : 0 }}
        </div>
      </div>
    </ng-container>
    <button 
      class="expand-button"
      [ngClass]="{'expand-button--opened': tableExpanded}"
      (click)="tableExpanded = !tableExpanded"
    ></button>
  </div>

  <pagination-template
    #p="paginationApi"
    [id]="paginationConfig.id"
    (pageChange)="paginationConfig.currentPage = $event; tableExpanded = false"
    *ngIf="!loading"
  >
    <div class="pagination">
      <div class="pagination__previous" [class.disabled]="p.isFirstPage()">
        <a *ngIf="!p.isFirstPage()" (click)="p.previous()" class="link"></a>
      </div>

      <div *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value" class="pagination__page">
        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value" class="link">
          <span>{{ page.label }}</span>
        </a>
        <div *ngIf="p.getCurrent() === page.value">
          <span>{{ page.label }}</span>
        </div>
      </div>

      <div class="pagination__next" [class.disabled]="p.isLastPage()">
        <a *ngIf="!p.isLastPage()" (click)="p.next()" class="link"></a>
      </div>
    </div>
  </pagination-template>
</ng-container>
