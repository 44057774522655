import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {
  constructor(private http: HttpClient) {}

  findCityByStreet(query: string) {
    return this.http
      .get(
        `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${query}&inputtype=textquery&fields=formatted_address,name&key=AIzaSyDQqtEEABFnyfSj9Rs-wjXeM4nvii72zTI`
      )
      .pipe(
        switchMap((res: any) =>
          this.http.get(
            `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${
              res.status === 'OK' ? res.candidates[0].formatted_address : ''
            }&key=AIzaSyDQqtEEABFnyfSj9Rs-wjXeM4nvii72zTI&sessiontoken=1`
          )
        ),
        map((res: any) => (res.status === 'OK' ? res.predictions[0].terms[2].value : null))
      );
  }
}
