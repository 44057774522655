<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <div class="close" (click)="dialogRef.close()">
      <span aria-hidden="true">&times;</span>
    </div>
    <div class="al-modal-title">
      Change Password
    </div>
    <form [formGroup]="form" class="form">
      <app-input
        class="username-input"
        formControlName="currentPassword"
        label="Current password"
        type="password"
        [errorMessage]="checkPassword"
        [forceError]="checkPassword"
        (focusout)="focusout(form.value)"
        ngDefaultControl
      ></app-input>
      <app-input
        class="username-input"
        formControlName="new_password1"
        label="New password"
        [errorMessage]="errorMessage"
        [forceError]="errorMessage"
        type="password"
        ngDefaultControl
      ></app-input>
      <small class="form-text text-danger" *ngIf="form.controls.new_password1.errors && form.controls.new_password1.errors.minLength">
        {{ form.controls.new_password1.errors.minLength.message }}
      </small>
      <app-input
        class="username-input"
        formControlName="new_password2"
        label="Confirm password"
        [errorMessage]="errorMessage"
        [forceError]="errorMessage"
        type="password"
        ngDefaultControl
      ></app-input>
      <small class="form-text text-danger" *ngIf="form.controls.new_password2.errors && form.controls.new_password2.errors.compare">
        {{ form.controls.new_password2.errors.compare.message }}
      </small>
    </form>
    <app-button class="submit-btn" (pressed)="onSubmit(form.value)" [isDisabled]="form.invalid || checkPassword != null">Change</app-button>
  </div>
</div>
