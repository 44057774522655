import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IBankAccount, IBankRouting } from '../../models/customer.model';
import { BehaviorSubject, of, timer, forkJoin, Observable } from 'rxjs';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from '../../services/customer/customer.service';
import { map, catchError, concatMap, tap, filter } from 'rxjs/operators';
import { ModalService } from '../../services/modal/modal.service';
import { confirmSavingsBankAccountModalDescription, authorizationForOneTimeAutomatedPayments } from '../../constants/text.constants';
import { RequestObserver } from '../../helpers/request-observer.class';
import { bankAccountTypeOptions } from '../../models/bank-account-types';
import { EBankAccountType } from '../../models/enums/bank-account-type.enum';

export interface IFinalPaymentsManualModalData {
  bankAccounts: IBankAccount[];
  primaryBankRouting: IBankRouting;
  secondaryBankRouting: IBankRouting;
}

export interface IFormGroupBankInfo {
  id: number;
  account_priority: string;
  routing_number: string;
  account_number: string;
  account_number_reenter: string;
  type: EBankAccountType;
  institution?: string;
}

export interface IFormGroupBanks {
  primary: IFormGroupBankInfo;
  secondary: IFormGroupBankInfo;
}

@Component({
  selector: 'app-final-payments-manual-modal',
  templateUrl: './final-payments-manual-modal.component.html',
  styleUrls: ['./final-payments-manual-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalPaymentsManualModalComponent implements OnInit {
  public static readonly ACCOUNT_NUMBER_MIN_LENGTH: number = 3;
  public static readonly ACCOUNT_NUMBER_MAX_LENGTH: number = 18;

  public loading$ = new BehaviorSubject(true);
  public form: FormGroupTyped<IFormGroupBanks>;
  public bankAccountTypeOptions = bankAccountTypeOptions;
  public submitRequest = new RequestObserver();
  
  private bankAccounts$ = new BehaviorSubject<IBankAccount[]>([]);

  public get accountNumberMinLength() {
    return FinalPaymentsManualModalComponent.ACCOUNT_NUMBER_MIN_LENGTH;
  }

  public get accountNumberMaxLength() {
    return FinalPaymentsManualModalComponent.ACCOUNT_NUMBER_MAX_LENGTH;
  }

  private get primaryBankFormGroup() {
    return <FormGroupTyped<IFormGroupBankInfo>>this.form.controls.primary;
  }

  private get secondaryBankFormGroup() {
    return <FormGroupTyped<IFormGroupBankInfo>>this.form.controls.secondary;
  }

  constructor(
    public dialogRef: MatDialogRef<FinalPaymentsManualModalComponent>,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.initForm();
    this.customerService.getBankAccounts().subscribe(bankAccounts => {
      this.bankAccounts$.next(bankAccounts);
      this.patchFormWithBankAccounts(bankAccounts);
      this.loading$.next(false);
    });
  }

  public onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.confirmAccountType(this.form.value).pipe(
        filter(Boolean),
        concatMap(() => {
          const request = this.createRequest(this.form.value);
          return this.submitRequest.setSource(request);
        }),
        catchError(err => {
          const { error } = err;
          const badControlName = Object.keys(error)[0];
          return this.modalService.openInfo({
            title: `Error in ${badControlName}`,
            description: error[badControlName],
            buttonText: 'OK'
          });
        })
      ).subscribe();
    }
  }

  public onCancel() {
    this.dialogRef.close();
  }

  public openTermsOfUse() {
    this.modalService.openInfo({
      title: 'Authorization for One-Time Automated Payments',
      description: authorizationForOneTimeAutomatedPayments
    });
  }

  // If form values have 'id' field: patch bankAccount
  // Else: create bankAccount
  private createRequest(value: IFormGroupBanks): Observable<any> {
    const { primary, secondary } = value;
    const requests = [];
    delete primary.account_number_reenter;
    delete secondary.account_number_reenter;

    if (primary.id) {
      requests.push(this.customerService.patchBankAccount(primary));
    } else {
      requests.push(this.customerService.postBankAccount(primary));
    }

    if (secondary.routing_number && secondary.account_number) {
      if (secondary.id) {
        requests.push(this.customerService.patchBankAccount(secondary));
      } else {
        requests.push(this.customerService.postBankAccount(secondary));
      }
    }

    return forkJoin(requests);
  }

  // Patches form with IBankAccount objects
  // TODO: remove 'any' workaround caused by nested form groups
  private patchFormWithBankAccounts(accounts: IBankAccount[]) {
    const primary: any = accounts.find(account => account.account_priority === 'primary') || {};
    const secondary: any = accounts.find(account => account.account_priority === 'secondary') || {};
    this.form.patchValue({
      primary,
      secondary
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      primary: this.formBuilder.group({
        id: [''],
        account_priority: ['primary'],
        routing_number: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)], [this.asyncValidateRoutingNumber('primary')]],
        account_number: ['', [Validators.required, this.validateAccountNumberLength]],
        account_number_reenter: ['', [Validators.required, this.validateAccountNumberLength]],
        type: [EBankAccountType.Checking],
        institution: ['']
      }, {
        validators: [this.validateAccountNumberReenter]
      }),
      secondary: this.formBuilder.group({
        id: [''],
        account_priority: ['secondary'],
        routing_number: ['', [Validators.minLength(9), Validators.maxLength(9)], [this.asyncValidateRoutingNumber('secondary')]],
        account_number: ['', [this.validateAccountNumberLength]],
        account_number_reenter: ['', [this.validateAccountNumberLength]],
        type: [EBankAccountType.Checking],
        institution: ['']
      }, {
        validators: [this.validateAccountNumberReenter]
      })
    }, {
      validators: [this.validateSecondaryGroupNumbers]
    }) as FormGroupTyped<IFormGroupBanks>;
  }

  // Validate if secondary routing num not empty, then secondary account num required and vice versa
  private validateSecondaryGroupNumbers(group: FormGroupTyped<IFormGroupBanks>) {
    const secondaryGroup = group.get('secondary') as FormGroupTyped<IFormGroupBankInfo>;
    const routingNumber = secondaryGroup.controls.routing_number;
    const accountNumber = secondaryGroup.controls.account_number;
    if (routingNumber.value && !accountNumber.value) {
      return { linkAccountNumber: true };
    } else if (!routingNumber.value && accountNumber.value) {
      return { linkRoutingNumber: true }
    } else {
      return null;
    }
  }

  private validateAccountNumberLength(control: FormControlTyped<string>) {
    const min = FinalPaymentsManualModalComponent.ACCOUNT_NUMBER_MIN_LENGTH;
    const max = FinalPaymentsManualModalComponent.ACCOUNT_NUMBER_MAX_LENGTH;
    const number = control.value;
    if (number && ((number.length < min) || (number.length > max))) {
      return { length: `Ensure this field has ${min} to ${max} digits` };
    }
    else {
      return null;
    }
  }

  // Validate reenter account number
  private validateAccountNumberReenter(group: FormGroupTyped<IFormGroupBankInfo>) {
    const {account_number, account_number_reenter} = group.value;
    if (account_number && account_number !== account_number_reenter) {
      return { reenter: 'Account Numbers do not match.' };
    } else {
      return null;
    }
  }

  // Async validate routing number
  private asyncValidateRoutingNumber(target: 'primary' | 'secondary'): (control: FormControl) => Observable<object | null> {
    return control => {
      // Debounce time workaround
      return timer(300).pipe(
        map(() => control.value),
        concatMap(val => val ? this.checkRoutingNumber({[target]: val}) : of(false)),
        map(isError => isError ? {invalid: true} : null )
      );
    }
  }

  // Returns if routing number is okay
  // Side effect: sets 'institution' field based on response
  private checkRoutingNumber(args: { primary?: string, secondary?: string } = {}): Observable<boolean> {
    const { primary, secondary } = args;
    const targetGroup = (primary && this.primaryBankFormGroup) || (secondary && this.secondaryBankFormGroup);
    const value = primary || secondary;
    return this.customerService.findFedwireAccount(value).pipe(
      tap(routing => {
        targetGroup.get('institution').setValue(String(routing.customerName).trim());
      }),
      map(() => false),
      catchError(() => {
        targetGroup.get('institution').setValue('');
        return of(true);
      })
    );
  }

  // Show modal to confirm use of 'saving' account, if used
  private confirmAccountType(value: IFormGroupBanks): Observable<boolean> {
    const isSecondaryGroupFilled = value.secondary.account_number && value.secondary.routing_number;
    if ([
      value.primary.type,
      isSecondaryGroupFilled ? value.secondary.type : null
    ].includes(EBankAccountType.Savings)) {
      return this.modalService.openConfirm({
        description: confirmSavingsBankAccountModalDescription,
        cancelText: 'No',
        confirmText: 'Yes',
        reversed: true
      });
    } else {
      return of(true);
    } 
  }
}
