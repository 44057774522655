import { Component
  , ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contacts-modal',
  templateUrl: './contacts-modal.component.html',
  styleUrls: ['./contacts-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ContactsModalComponent>,
  ) {}
}
