import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, Output, EventEmitter, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeComponent implements AfterViewInit {
  
  @ViewChild('iframe') iframe: ElementRef;
  @Input() src: string;
  @Output() load = new EventEmitter<any>();

  get srcSanitized(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
  }

  private get iframeElement(): HTMLIFrameElement {
    return this.iframe.nativeElement;
  }

  constructor(private sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.cdRef.detach();
    this.iframeElement.addEventListener('load', event => this.onLoad(event));
  }

  private onLoad(e) {
    let link: string;
    try {
      link = this.iframeElement.contentWindow.location.href;
    } catch {}
    this.load.emit({e, link});
  }
}
