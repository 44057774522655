import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentInfoComponent {
  @Input() payment: number;
  @Input() interest: number;
  @Input() payments: number;
  @Input() title: string;
  @Input() grey = false;
}
