<form [formGroup]="form" class="form">
  <div class="left-column">
    <app-input
      label="Name on Сard"
      class="input"
      type="text"
      errorMessage="Missing Name on Сard"
      [formControl]="form.get('content_object.name')"
      [loading]="loading"
      [warning]="form.get('content_object.name').warnings?.tooLong && 'Name on Card is too long.'"
      ngDefaultControl
      [id]="'nameOnCard'"
    ></app-input>
    <app-edit-input
      label="Credit Card Number"
      class="input input--edit"
      type="tel"
      errorMessage="Incorrect Credit Card Number"
      [ngxMask]="creditCardLocked ? 'ZZZZ ZZZZ ZZZZ ZZZZZZ' : '0000 0000 0000 000000'"
      [formControl]="form.get('content_object.number')"
      [loading]="loading"
      [validateErrors]="[
        form.get('content_object').getError('creditCardTypeNumber'),
        form.get('content_object').getError('creditCardLength')
      ]"
      [warning]="form.get('content_object').warnings?.creditCardTypeNumber"
      [ngxDropSpecialCharacters]="true"
      [(locked)]="creditCardLocked"
      (lockedChange)="onCreditCardLockedChange($event)"
      ngDefaultControl
      [id]="'creditCardTypeNumber'"
    ></app-edit-input>
    <app-input
      label="Security Code"
      [class]="hidden ? 'd-none' : 'input'"
      type="text"
      errorMessage="Missing Security Code"
      [formControl]="form.get('content_object.security_code')"
      [loading]="loading"
      [hidden]="hidden"
      ngDefaultControl
      [id]="'finalSecurityCode'"
    ></app-input>
    <app-select-input
      label="Affinity"
      class="input"
      errorMessage="Missing Affinity"
      [formControl]="form.get('payee.affinity')"
      [loading]="loading"
      [options]="affinityOptions$ | async"
      (changed)="onAffinityInputChange($event)"
      (selectedOption)="onAffinityValueChange($event)"
      ngDefaultControl
      [id]="'affinity'"
    ></app-select-input>
    <app-input
      label="Address Line to Send Payment"
      class="input"
      type="text"
      errorMessage="Incorrect Address Line to Send Payment"
      [formControl]="form.get('payee.address.address1')"
      [loading]="loading"
      ngDefaultControl
      [id]="'finalAddress1'"
    ></app-input>
    <app-input
      label="Address Line to Send Payment 2"
      class="input"
      type="text"
      errorMessage="Incorrect Address Line to Send Payment 2"
      [formControl]="form.get('payee.address.address2')"
      [loading]="loading"
      ngDefaultControl
      [id]="'finalAddress2'"
    ></app-input>
    <app-input
      class="input"
      label="Credit Card Company Phone Number"
      type="tel"
      errorMessage="Missing Phone Number"
      ngxMask="000-000-0000"
      [formControl]="form.get('payee.phone_number')"
      [loading]="loading"
      ngDefaultControl
      [id]="'finalPhoneNumber'"
    ></app-input>
  </div>
  <div class="right-column">
    <app-select
      class="input"
      label="Credit Card Type"
      errorMessage="Missing Credit Card Type"
      [formControl]="form.get('content_object.card_type')"
      [options]="CARD_TYPE"
      [loading]="loading"
      ngDefaultControl
      [id]="'finalCardType'"
    ></app-select>
    <app-input
      class="input"
      label="Expiration Date"
      errorMessage="Invalid Expiration Date"
      ngxMask="00/00"
      placeholder="MM/YY"
      [formControl]="form.get('content_object.expiration_date')"
      [loading]="loading"
      ngDefaultControl
      [id]="'expiration_date'"
    ></app-input>
    <app-input
      label="Payable to (Credit Card Payee)"
      class="input"
      type="text"
      errorMessage="Missing Payee Name"
      [formControl]="form.get('payee.name')"
      [loading]="loading"
      ngDefaultControl
      [id]="'payeeName'"
    ></app-input>
    <app-input
      label="City"
      class="input"
      type="text"
      [errorMessage]="getErrorMessage('city')"
      [formControl]="form.get('payee.address.city')"
      [loading]="loading"
      ngDefaultControl
      [id]="'finalCity'"
    ></app-input>
    <app-select
      class="input"
      label="State"
      errorMessage="Missing State"
      [formControl]="form.get('payee.address.state')"
      [options]="STATES"
      [loading]="loading"
      ngDefaultControl
      [id]="'finalState'"
    ></app-select>
    <app-input
      label="Zip Code"
      class="input"
      type="tel"
      errorMessage="Incorrect Zip Code"
      ngxMask="00000"
      [formControl]="form.get('payee.address.zipCode')"
      [loading]="loading"
      ngDefaultControl
      [id]="'finalZipcode'"
    ></app-input>
  </div>
</form>

<app-button
  class="submit-btn"
  [loading]="loading"
  (pressed)="onSubmit()"
  [id]="'finalSubmit_btn'"
>Submit and Finish</app-button>
