import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { IInformationConfirmForm } from 'src/app/shared/components/information-confirm-form/information-confirm-form.component';
import { ICustomer, IUsersSetting } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { UserService } from 'src/app/user/services/user/user.service';
import { ModalService } from './../../../shared/services/modal/modal.service';

@Component({
  selector: 'app-personal-info-container',
  templateUrl: './personal-info-container.component.html',
  styleUrls: ['./personal-info-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalInfoContainerComponent implements OnInit {
  public customer$: Observable<ICustomer>;
  public loading = true;
  public isTwoFactorOn$ = new BehaviorSubject<boolean>(false);
  private customerNumber: string;
  public progressBar: boolean = true;
  public paymentNotificationFlag: boolean = false;
  public twoFactorAuthenticationFlag: boolean = false;
  public usersSetting;

  constructor(
    private customerService: CustomerService,
    private userService: UserService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.getUserSetting();
    this.customer$ = this.customerService.get(this.userService.customerId).pipe(
      shareReplay(1),
      tap(customer => {
        this.customerNumber = customer.cellPhone;
        this.progressBar = false;
      })
    );
  }

  public getUserSetting() {
    this.customerService.getUsersSetting(this.userService.customerId).subscribe(value => {
      if (value.length > 0){
        this.usersSetting = value;
        this.paymentNotificationFlag = value[0].paymentNotification;
        this.isTwoFactorOn$.next(value[0].twoFactorAuthentication);
      } else {
        let defaultUserSetting: IUsersSetting = {
          paymentNotification: false,
          twoFactorAuthentication: false
        }
        this.customerService.postUsersSetting(this.userService.customerId, defaultUserSetting).subscribe(response => {
          this.usersSetting = response;
        });
      }
     })
  }

  public setUserSetting(obj: any, key: string){
    if (this.usersSetting){
      this.customerService.patchUsersSetting(this.userService.customerId, this.usersSetting[0].id, obj)
      .subscribe(respose => {
        console.log(respose);
        this.modalService.openInfo({
          title: 'Information',
          description: obj.paymentNotification || obj.twoFactorAuthentication ? 'Activating ' + key + ' setting.' : 'Deactivating ' + key + ' setting.',
          buttonText: 'Ok'
        })
      })
    } 
  }

  public onSubmitted({ customer }: IInformationConfirmForm): void {
    this.customerService
      .patch(customer, this.userService.customerId)
      .subscribe(
        (res) => {
          this.customer$ = of(res);
        },
        ({ error }) => {
          const description = error[Object.keys(error)[0]][0];
          this.modalService.openInfo({
            title: 'Error',
            description: description || 'Error',
            buttonText: 'OK',
          });
        },
      );
  }

  public onChangedNotificationsControl(checked: boolean) {
    this.paymentNotificationFlag = checked;
    let user = {
      paymentNotification: checked
    }
    this.setUserSetting(user, 'Payment notification');
  }

  public onChangedTwoFactorAuthControl(checked: boolean) {
    this.isTwoFactorOn$.next(checked);
    let auth = {
      twoFactorAuthentication: checked
    }
    if (checked) {
      setTimeout(() => {
        this.isTwoFactorOn$.next(false);
      });
      this.modalService.openTwoFactor(this.customerNumber)
        .subscribe(res => {
          this.isTwoFactorOn$.next(!!res);
          this.setUserSetting(auth, 'Two Factor Authentication');
        });
    } else {
      this.setUserSetting(auth, 'Two Factor Authentication');
    }
  }

  public onPressedChangePassword() {
    this.modalService
      .openChangePassword()
      .pipe(
        switchMap(success =>
          success
            ? this.modalService.openInfo({
                title: 'Success',
                description: 'New password has been saved',
                buttonText: 'Ok'
              })
            : of()
        )
      )
      .subscribe();
  }
}
