import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }

    return value % 1 === 0 ? value : parseFloat(value).toFixed(2);
  }
}
