import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Route } from '@angular/router';
import { flattenDeep } from 'src/app/shared/helpers/flatten-array';
import { routes as finalStepRoutes } from 'src/app/final-steps/final-steps-routing.module';
import { routes as userRoutes } from 'src/app/user/user-routing.module';
import { routes as appRoutes } from 'src/app/app-routing.module';
import { routes as profileRoutes } from 'src/app/profile/profile-routing.module';
import { routes as stepsRoutes } from 'src/app/steps/steps-routing.module';

export interface IRouteItem {
  title: string;
  path: string;
}

@Component({
  selector: 'app-route-list',
  templateUrl: './route-list.component.html',
  styleUrls: ['./route-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteListComponent implements OnInit {

  visible = false;
  routes: IRouteItem[];

  private routeMap = [
    { routes: appRoutes, prefix: '' },
    { routes: userRoutes, prefix: '' },
    { routes: stepsRoutes, prefix: 'steps' },
    { routes: finalStepRoutes, prefix: 'final' },
    { routes: profileRoutes, prefix: 'profile' },
  ];

  ngOnInit() {
    this.routes = this.processRoutes();
  }

  toggle() {
    this.visible = !this.visible;
  }

  private processRoutes(): IRouteItem[] {
    const extract = (route: Route, prefix: string) => {
      if (route.loadChildren || route.redirectTo) { return; }
      if (route.children) {
        if (route.path) { prefix += `/${route.path}`; }
        return route.children.map(child => extract(child, prefix));
      } else {
        return {
          title: [prefix, route.path].join('/'),
          path: [prefix, route.path].join('/')
        }
      }
    }

    const output = flattenDeep(this.routeMap.map(item => {
      return item.routes.map(route => extract(route, item.prefix));
    }));

    return output.filter(Boolean);
  }
}
