<button
  class="button"
  [id]="id"
  (click)="onClick()"
  [disabled]="isDisabled"
  [routerLink]="link"
  [queryParams]="queryParams"
  [queryParamsHandling]="queryParamsHandling"
  [routerLinkActive]="link ? 'button_active-link' : ''"
  [type]="type"
  [class.button_is-form-submit]="styleType === 'form-submit'"
  [class.button_is-table-control]="isTableControl"
  [class.button_color-blue]="color === 'blue'"
  [class.button_color-white]="color === 'white'"
  [class.button_disabled]="isDisabled"
  [class.button_loading]="loading"
  [class.button_with-icon]="icon"
  [class.button_large-size]="size === 'large'"
  [class.button_left-icon-position]="iconPosition === 'left'"
  [class.button_hide-on-tablet]="hideTextOnTablet"
  [class.button_no-shadow]="noShadow"
  [class.button_faded-if-active]="fadedIfActive"
  [class.button_pending]="pending"
  [matTooltip]="matTooltip"
>
  <div [class.button_hide-on-tablet-content]="hideTextOnTablet">
    <ng-content></ng-content>
  </div>
  <span *ngIf="icon" class="button__icon" [style.backgroundImage]="'url(/assets/img/icons/' + icon + '.svg)'"></span>
  <mat-spinner *ngIf="spinner"
    class="progress-spinner"
    [diameter]=diameter
    [value]="value"
    [color]="procolor" 
    [mode]="mode"></mat-spinner>
</button>
