import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { BaseInputComponent } from '../base-input/base-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IOption {
  label: string;
  value: string | number;
  data?: any;
}

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: [
    { provide: InputComponent, useExisting: SelectInputComponent },
    { provide: BaseInputComponent, useExisting: InputComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseInputComponent),
      multi: true
    }
  ],
})
export class SelectInputComponent extends InputComponent {
  @Input() formControlName: string;
  @Input() options: string[] | IOption[];
  @Input() setTouched: boolean;
  @Output() selectedOption = new EventEmitter();
  @Input() id: string;

  public isOptionsVisible = false;

  public selectOption(option: string | IOption) {
    if (typeof option === 'string') {
      this.writeValue(option);
    } else {
      this.writeValue(String(option.value));
    }
    this.isOptionsVisible = false;
    this.selectedOption.emit(option);
  }

  public onFocused(event) {
    this.isOptionsVisible = true;
    this.focused.emit(event);
  }

  public onFocusedOut() {
    if (!document.hasFocus()) {
      this.isOptionsVisible = false;
    }
  }

  public onClickOutside() {
    this.isOptionsVisible = false;
  }
}
