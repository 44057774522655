<mat-accordion class="accordion">
  <mat-expansion-panel
    class="accordion__wrapper"
    [hideToggle]="true"
    (opened)="opened = true"
    (closed)="opened = false"
  >
    <mat-expansion-panel-header
      class="accordion__header"
      [class.accordion__header_expanded]="opened"
    >
      <mat-panel-title class="accordion__panel">
        <div class="accordion__left-panel">
          <div
            class="accordion__expand-icon"
            [class.accordion__expand-icon_expanded]="opened"
          ></div>
          <div
            class="accordion__status-icon"
            [class.accordion__status-icon--success]="status === loanStatusEnum.success"
            [class.accordion__status-icon--error]="status === loanStatusEnum.error"
            [class.accordion__status-icon--warning]="status === loanStatusEnum.warning"
            [class.accordion__status-icon--remark]="status === loanStatusEnum.remark"
          ></div>
          <div class="accordion__title">
            Loan #{{ loan?.id }}
          </div>
        </div>

        <div class="accordion__right-panel">
          <div class="accordion__panel-group">
            <div class="accordion__panel-item">
              <div class="accordion__panel-label">Status:</div>
              <div class="accordion__panel-value">{{ loan?.status }}</div>
            </div>
            <div class="accordion__panel-item">
              <div class="accordion__panel-label">Balance:</div>
              <div class="accordion__panel-value">$ {{ loan?.current_balance | number: '1.0-2' }}</div>
            </div>
          </div>
          <div class="accordion__panel-group">
            <div class="accordion__panel-item">
              <div class="accordion__panel-label">Next Payment:</div>
              <div class="accordion__panel-value">$ {{ loan?.payment | number: '1.0-2' }}</div>
            </div>
            <div class="accordion__panel-item">
              <div class="accordion__panel-label">Due:</div>
              <div class="accordion__panel-value">{{ (nextPayment$ | async)?.due_date | date: 'MM/dd/yyyy' : 'UTC' }}</div>
            </div>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-tabs [tabs]="tabs" (pressed)="onSelectedTab($event)" class="accordion__tabs"></app-tabs>
    <ng-container [ngSwitch]="selectedTabIndex">
      <app-loan-overview 
        *ngSwitchCase="0"
        [loan]="loan"
        [paymentsSchedule]="futurePayments$ | async"
        [paymentHistory]="paymentHistory$ | async"
        [linkedBankAccount]="linkedBankAccount"
        [linkedHoldCard]="linkedHoldCard"
        >0
      </app-loan-overview>
      <app-loan-payment-history 
        *ngSwitchCase="1"  
        [loan]="loan" 
        [payments]="paymentHistory$ | async" 
        [paymentHistoryStatus]="paymentHistoryStatus"
        >1
      </app-loan-payment-history>
      <app-loan-future-payments *ngSwitchCase="2" [paymentsSchedule]="futurePayments$ | async">2</app-loan-future-payments>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
