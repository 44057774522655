import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/app/user/services/user/user.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { IInfoModalData } from 'src/app/shared/entry/info-modal/info-modal.component';
import { IApplication } from '../../../user/models/user.models';
import { CustomerService } from '../../../shared/services/customer/customer.service';

@Component({
  selector: 'app-credit-card-information-container',
  templateUrl: './credit-card-information-container.component.html',
  styleUrls: ['./credit-card-information-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditCardInformationContainerComponent implements OnInit {
  public application$: Observable<IApplication>;

  public errors$ = new BehaviorSubject<any>(null);
  
  constructor(
    private router: Router,
    private customerService: CustomerService,
    private userService: UserService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.application$ = this.userService.getApplication(this.userService.applicationId);
  }

  public onSubmitted(cardData): void {
    this.customerService.patchApplication(this.userService.applicationId, cardData).subscribe(() => {
      this.router.navigateByUrl('steps/employment');
    }, (err) => {
      const info: IInfoModalData = { title: 'Error' };
      if (err.error.amount) {
        info.description = err.error.amount[0].replace('this value', 'Amount to Pay Off');
      } else if (err.error.hold_card_limit) {
        info.description = err.error.hold_card_limit[0].replace('this value', 'Available Credit');
      } else if (err.error.non_field_errors) {
        info.description = err.error.non_field_errors[0];
      } else {
        info.description = 'Something went wrong'
      }
      info.buttonText = 'OK';
      this.modalService.openInfo(info);
      this.errors$.next(err.error);
    });
  }
}
