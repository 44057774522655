<div class="block">
  <div class="bg-image bg-image--left"></div>
  <div class="content">
    <app-logotype class="content__logotype"></app-logotype>
    <div class="content__title">Reset password</div>
    <app-input
      [formControl]="(form?.controls).new_password1"
      label="New password"
      type="password"
      errorMessage="Password should contain at least 8 characters"
      class="content__input"
      ngDefaultControl
    ></app-input>
    <app-input
      [formControl]="(form?.controls).new_password2"
      label="Confirm password"
      type="password"
      errorMessage="Passwords do not match"
      class="content__input"
      ngDefaultControl
    ></app-input>
    <app-button
      [isDisabled]="form.invalid"
      (click)="onSubmit()"
      class="content__button"
    >Reset password</app-button>
  </div>
  <div class="bg-image bg-image--right"></div>
</div>
<app-footer></app-footer>
