import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-apexpay-terms',
  templateUrl: './apexpay-terms.component.html',
  styleUrls: ['./apexpay-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApexpayTermsComponent {

  constructor(
    public dialogRef: MatDialogRef<ApexpayTermsComponent>
  ) {}

}
