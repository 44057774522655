import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinalApprovedContainerComponent } from './containers/final-approved-container/final-approved-container.component';
import { FinalContainerComponent } from './containers/final-container/final-container.component';
import { FinalCreditCardsContainerComponent } from './containers/final-credit-cards-container/final-credit-cards-container.component';
import { FinalPaymentsMethodContainerComponent } from './containers/final-payments-method-container/final-payments-method-container.component';
import { FinalStepsContainerComponent } from './containers/final-steps-container/final-steps-container.component';
import { OfferCodeGuard } from '../shared/guards/offer-code.guard';
import { FinalDocuSignComponent } from './containers/final-docu-sign/final-docu-sign.component';

export const routes: Routes = [
  {
    path: '',
    component: FinalStepsContainerComponent,
    children: [
      {
        path: 'cards',
        component: FinalCreditCardsContainerComponent,
        data: { step: 1 }
      },
      {
        path: 'payments',
        component: FinalPaymentsMethodContainerComponent,
        data: { step: 2 }
      },
      {
        path: 'loans',
        component: FinalApprovedContainerComponent,
        data: { step: 3 }
      }
    ],
    // canActivate: [OfferCodeGuard]
  },
  {
    path: 'complete',
    component: FinalContainerComponent
  },
  {
    path: 'document',
    component: FinalDocuSignComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinalStepsRoutingModule {}
