import { Component, ChangeDetectionStrategy, Input, Pipe, OnChanges, SimpleChanges, ViewChild, ElementRef, Output } from '@angular/core';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { ILoan } from 'src/app/shared/models/customer.model';
export interface ITableControl {
  label: string;
  action: Function;
}

export interface ITableSchema {
  name: string;
  key: string;
  classCell?: string[];
  pipe?: Pipe;
  pipeArgs?: any[];
  checkbox?: boolean;
}

@Component({
  selector: 'app-loans-table',
  templateUrl: './loans-table.component.html',
  styleUrls: ['./loans-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoansTableComponent implements OnChanges {
  @Input() tableSchema: ITableSchema[] = [];
  @Input() controls: ITableControl[];
  @Input() data: any[];
  @Input() highlightFirst = false;
  @Input() loan: ILoan;
  public selectedData: any[];

  public checkboxData = {};

  public get displayedColumns() {
    return this.tableSchema.map(i => i.key);
  }

  ngOnChanges({ tableSchema }: SimpleChanges) {
    if (tableSchema && this.tableSchema) {
      const checkboxColumns = this.tableSchema.filter(item => item.checkbox);
      checkboxColumns.forEach(column => {
        const array = this.data.map(() => false);
        this.checkboxData[column.key] = array;
      });
    }
  }

  onControlClick(control: ITableControl) {
    if (control.label === 'Download Statements') {
      this.selectedData = this.data.filter((item, key) => {
        if (this.checkboxData['statement'][key] === true) {
          return this.data[key];
        }
      });
      let sortedData = []
      sortedData = this.selectedData.length > 0 ? this.selectedData :  this.data;
      control.action(sortedData, this.checkboxData);      
    } else {
      control.action(this.data, this.checkboxData);
    }
  }
}
