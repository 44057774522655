import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ModalService } from 'src/app/shared/services/modal/modal.service';

export interface IPasswordRecoveryError {
  token?: string[];
  new_password2: string[];
}

@Component({
  selector: 'app-reset-password-container',
  templateUrl: './reset-password-container.component.html',
  styleUrls: ['./reset-password-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordContainerComponent implements OnInit {
  private token: string;
  private uidb64: string;
  public form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.uidb64 = this.route.snapshot.paramMap.get('uidb64');
    this.token = this.route.snapshot.paramMap.get('token');
    this.initForm();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      new_password1: ['', [Validators.required, Validators.minLength(8)]],
      new_password2: ['', [Validators.required, RxwebValidators.compare({ fieldName: 'new_password1' })]]
    });

    this.form.controls['new_password1'].valueChanges.subscribe(() => {
      this.form.controls['new_password2'].updateValueAndValidity();
    });
  }

  public onSubmit(): void {
    this.userService.confirmPassword(this.form.controls['new_password1'].value, this.uidb64, this.token)
      .subscribe(() => {
        this.openInfoModal('Success');
      }, ({ error }) => {
        this.openInfoModal('Error', error);
      });
  }

  private openInfoModal(type: string, error: IPasswordRecoveryError = null) {
    let errorText = 'Something went wrong';
    if (error) {
      if (error.token && error.token.length) {
        errorText = 'Your Password Reset link has expired';
      } else if (error.new_password2 && error.new_password2.length) {
        errorText = error.new_password2[0];
      }
    }
    this.modalService.openInfo({
      title: type,
      description: type === 'Success' ? 'Your Password has been successfully changed' : errorText,
      buttonText: 'OK'
    }).subscribe(() => {
      if (type === 'Success' || error.token) {
        this.router.navigateByUrl('/');
      }
    });
  }
}
