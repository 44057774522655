import { CardTypeKeys, CardTypeShortKeys } from "../constants/select-data.constants";

export function validateCreditCardType(
  cardNumber: string, cardType: CardTypeKeys | CardTypeShortKeys, error?: string): { creditCardTypeNumber: string | boolean } 
{
  if (!cardNumber || !cardType) { return null; }
  cardNumber = cardNumber.replace(/\s/g, '');
  const output = { creditCardTypeNumber: error ? error : true };
  let regexp: RegExp;
  switch (cardType) {
    case 'Visa' || 'V':
      regexp = /^4[0-9]{12}(?:[0-9]{3})?$/;
      break;
    case 'AmericanExpress' || 'A':
      regexp = /^3[47][0-9]{13}$/;
      break;
    case 'MasterCard' || 'M':
      regexp = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}/;
      break;
    case 'Discover' || 'D':
      regexp = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
      break;
  }
  return regexp.test(cardNumber) ? null : output;
}
