<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <div class="close" (click)="dialogRef.close()">
      <span aria-hidden="true">&times;</span>
    </div>
    <div class="al-modal-title">
      Password Recovery
    </div>
    <ng-container *ngIf="!(submitRequest.isSuccess | async)">
      <form [formGroup]="form" class="form" (keyup.enter)="onSubmit()">
        <app-input
          class="username-input"
          formControlName="email"
          label="Email"
          [errorMessage]="errorMessage$ | async"
          [forceError]="forceError"
          ngDefaultControl
        ></app-input>
      </form>
      <app-button 
        class="submit-btn"
        [pending]="submitRequest.isPending | async"
        (pressed)="onSubmit()"
        >Send Email
      </app-button>
    </ng-container>
    <ng-container *ngIf="submitRequest.isSuccess | async">
      <div class="message">Password recovery link has been sent</div>
      <app-button 
        class="submit-btn"
        (pressed)="dialogRef.close(true)"
        >OK
      </app-button>
    </ng-container>
  </div>
</div>
