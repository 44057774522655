import { Injectable } from '@angular/core';
import { ISignInResult, ModalService } from '../../shared/services/modal/modal.service';
import { UserService } from './user/user.service';
import { CustomerService } from '../../shared/services/customer/customer.service';
import { Router } from '@angular/router';
import { StepsService } from '../../steps/services/steps/steps.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignInService {
  public signInComplete$ = new Subject<ISignInResult>();

  constructor(
    private modalService: ModalService,
    private userService: UserService,
    private customerService: CustomerService,
    private router: Router,
    private stepsService: StepsService
  ) { }

  initSignIn() {
    this.modalService.openSignIn(environment.withRecaptcha)
      .subscribe((authRes: ISignInResult) => {
        if (authRes && authRes.token) {
          this.customerService.getApplications(this.userService.customerId).subscribe(applications => {
            const application = applications[applications.length - 1];
            const { step, id, mailOffers, status, term } = application;

            // TODO: hardcode
            const offerCodeId = mailOffers ? mailOffers[0] : 2;
            // hardcode ends

            if (environment.debug && environment.debug.saveLoginTokenOnSteps) {
              this.userService.setToken(authRes.token);
            }

            if (['OPEN', 'IN_PROGRESS'].includes(status)) {
              this.userService.offerCodeId = offerCodeId;
              this.userService.applicationId = id;
              this.userService.selectedTerm = term;
              if (step === 99) {
                this.userService.setToken(authRes.token);
              }
              this.goToStep(step, offerCodeId);
            } else if ('ACCEPTED' === status) {
              this.userService.setToken(authRes.token);
              this.router.navigateByUrl('/profile');
            } else {
              // TODO: smth wrong with application, show error
              this.router.navigateByUrl('/');
            }
          })
        } else if (authRes) {
          if (authRes.action === 'passwordRecovery') {
            this.modalService.openRecoveryPassword();
          } else if (authRes.action === 'resetOffer') {
            this.modalService.openResetOfferCode().subscribe(res => {
              if (!res) {
                return;
              }
              this.stepsService._goToStep(3);
            });
          }
        }
        if (authRes) { this.signInComplete$.next(authRes); }
      })
  }

  private goToStep(step, offerCodeId: number = this.userService.offerCodeId) {
    this.userService.getMailOffer(offerCodeId).subscribe((offer) => {
      this.userService.offerCode = offer.code;
      this.stepsService._goToStep(step <= 3 ? 2 : step);
    });
  }
}
