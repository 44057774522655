import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, Subject, timer } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

const STORAGE_KEY = 'last-activity';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idle$: Observable<any>;
  private timer$;
  private timeOutMilliSeconds: number;
  private idleSubscription;

  private get lastActivity(): number {
    return +localStorage[STORAGE_KEY] || 0;
  }

  private set lastActivity(value: number) {
    localStorage[STORAGE_KEY] = value;
  }

  public expired$: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  public startWatching(timeOutSeconds): Observable<any> {
    this.idle$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize')
    );

    this.timeOutMilliSeconds = timeOutSeconds * 1000;
    this.lastActivity = Date.now();

    this.idleSubscription = this.idle$.pipe(throttleTime(1500)).subscribe(() => {
      this.lastActivity = Date.now();
      this.resetTimer();
    });

    this.startTimer();

    return this.expired$;
  }

  private startTimer(delay: number = this.timeOutMilliSeconds) {
    this.timer$ = timer(delay, this.timeOutMilliSeconds).subscribe(res => {
      const now = Date.now();

      if ((now - this.lastActivity) < this.timeOutMilliSeconds) {
        this.resetTimer(this.timeOutMilliSeconds - (now - this.lastActivity));
      } else {
        this.expired$.next(true);
      }
    });
  }

  public resetTimer(delay: number = this.timeOutMilliSeconds) {
    this.timer$.unsubscribe();
    this.startTimer(delay);
  }

  public stopTimer() {
    this.timer$.unsubscribe();
    this.idleSubscription.unsubscribe();
  }
}
