<div
  class="datepicker"
  [class.datepicker_has-error]="isControlError"
  [class.datepicker_loading]="loading"
  (click)="onOpenPicker()"
>
  <div class="datepicker__label" *ngIf="label">
    <span>{{ label }}</span>
    <span *ngIf="isRequired && !isPreview" class="datepicker__required">*</span>
  </div>
  <div class="datepicker__input-wrapper">
    <input
      class="datepicker__input"
      [hidden]="isPreview"
      (dateChange)="handleChange($event.target.value)"
      [matDatepicker]="pickerRef"
      [matDatepickerFilter]="datepickerFilter"
      (keydown)="$event.preventDefault()"
      [disabled]="isDisabled || isPreview"
      [max]="max"
      [min]="min"
      (focus)="$event.target.blur()"
      [value]="control?.value"
      [id]="id"
    />
  </div>
  <span
    *ngIf="isPreview"
    class="datepicker__preview"
    [class.datepicker__preview_loading]="loading"
  >{{ previewValue }}</span>
  <mat-datepicker #pickerRef (closed)="onTouched()"></mat-datepicker>

  <div *ngIf="isControlError" class="datepicker__error-message">
    {{ errorMessage }}
  </div>

  <div *ngIf="!isControlError && warning" class="datepicker__warning-message">{{ warning }}</div>
</div>
