import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import {ThemePalette} from '@angular/material/core';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner'

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements AfterContentChecked {
  @Input() isDisabled = false;
  @Input() color: 'blue' | 'white' = 'blue';
  @Input() styleType: 'default' | 'form-submit' = 'default';
  @Input() size: 'large' | 'default' = 'default';
  @Input() loading: boolean;
  @Input() icon: string;
  @Input() iconPosition: 'left' | 'right' = 'right';
  @Input() link: string;
  @Input() queryParams: string;
  @Input() queryParamsHandling: 'merge' | 'preserve' | '' = '';
  @Input() hideTextOnTablet: boolean;
  @Input() noShadow: boolean;
  @Input() fadedIfActive = false;
  @Input() isTableControl = false;
  @Input() pending = false;
  @Input() type = 'button';
  @Input() spinner: boolean;
  @Input() id: string;
  @Input() matTooltip: string;
  @Output() pressed = new EventEmitter();

  procolor: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  diameter = 30;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  onClick() {
    if (this.loading || this.pending || this.isDisabled) { return; }
    this.pressed.emit();
  }
}
