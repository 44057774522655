import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IInformationConfirmForm } from 'src/app/shared/components/information-confirm-form/information-confirm-form.component';
import { ICustomer } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { UserService } from 'src/app/user/services/user/user.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';

@Component({
  selector: 'app-information-confirm-container',
  templateUrl: './information-confirm-container.component.html',
  styleUrls: ['./information-confirm-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationConfirmContainerComponent implements OnInit {
  public customer$: Observable<ICustomer>;
  public username$: Observable<string>;
  
  public errors$ = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private customerService: CustomerService,
    public userService: UserService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.customer$ = this.customerService.get(this.userService.customerId);
    this.username$ = this.userService.getUsername();
  }

  public onSubmitted({ customer, auth }: IInformationConfirmForm): void {
    this.customerService
      .patch(customer, this.userService.customerId)
      .pipe(
        switchMap(_ => {
          if (!this.userService.isProfileFilled && auth) {
            return this.userService.change(auth);
          } else {
            return of({});
          }
        })
      )
      .subscribe(
        () => {
          this.userService.isProfileFilled = true;
          this.router.navigateByUrl('steps/card');
        },
        ({ error }) => {
          const key = Object.keys(error)[0];
          let description = '';
          let title = 'Error';
          if (key === 'address') {
            const addressKey = Object.keys(error[key])[0];
            title = 'Address you are trying to store is already Exists';
            description = error[key][addressKey][0];
          } else {
            description = error[Object.keys(error)[0]];
          }
          this.modalService.openInfo({
            title: title,
            description: description || 'Error',
            buttonText: 'OK',
          });
          this.errors$.next(error);
        },
      );
  }
}
