import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  Injector
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-datepicker',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent implements ControlValueAccessor, AfterViewInit {
  @Input() isDisabled = false;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() maxLength: number;
  @Input() formControlName: string;
  @Input() forceError: boolean;
  @Input() errorMessage = 'Incorrect field';
  @Input() warning: string;
  @Input() max: Date;
  @Input() min: Date;
  @Input() isPreview: boolean;
  @Input() id: string;
  @Input() loading: boolean;
  @Input() datepickerFilter: (date: moment.Moment) => boolean;
  @Input() isRequired = false;
  @ViewChild('pickerRef', { static: true }) pickerRef: MatDatepicker<{}>;
  public control: AbstractControl;
  public previewValue: string;
  public onChange: (_) => void = () => {};
  public onTouched: () => void = () => {};

  constructor(
    private cdRef: ChangeDetectorRef,
    private injector: Injector
  ) {}

  ngAfterViewInit() {
    this.initFormControl();
    this.cdRef.detectChanges();
  }

  initFormControl() {
    if (this.injector) {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
        this.control.statusChanges.subscribe(() => this.cdRef.markForCheck());
        this.monkeyPatchTouched(this.control);
      } else {
        console.warn('Component is missing form control binding');
      }
    }
  }

  public onOpenPicker() {
    this.pickerRef.open();
  }

  handleChange(value: moment.Moment) {
    if (value && !this.previewValue ) {
      this.previewValue = moment(value).format('MM-DD-YYYY');
      this.writeValue(value);
    }
    this.onTouched();
  }

  writeValue(value: any) {
    if (value) {
      this.pickerRef.select(this.isMoment(value) ? value : moment(value));
    }
    this.onChange(this.isMoment(value) ? value.toDate() : value);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  get isControlError(): boolean {
    if (!this.control) { return false; }
    return this.control.touched && this.forceError;
  }

  private isMoment(value: any): boolean {
    return Boolean(value && value.toDate && value.clone);
  }

  private monkeyPatchTouched(control: AbstractControl) {
    if (!control) { return; }
    const self = this;
    const origFunc = control.markAsTouched;
    control.markAsTouched = function () {
      origFunc.apply(this, arguments);
      self.cdRef.markForCheck();
    }
  }
}
