<div
  class="control"
  [class.control_short]="short"
>
  <div class="control__icon" [style.backgroundImage]="'url(' + icon + ')'"></div>
  <div class="control__label">{{ label }}</div>
  <mat-checkbox
    *ngIf="type === 'checkbox'; else radio"
    [checked]="checked"
    (change)="onChange($event)"
    class="control__input"
  ></mat-checkbox>
</div>

<ng-template #radio>
  <mat-slide-toggle class="control__input" [checked]="checked" (change)="onChange($event)"></mat-slide-toggle>
</ng-template>
