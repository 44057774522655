import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILoanPayment } from '../../../shared/services/customer/customer.service';
import { parse } from 'json2csv';
import * as FileSaver from 'file-saver';
import * as ical2json from 'ical2json';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ThousandsSeparatorsPipe } from 'src/app/shared/pipes/thousands-separators.pipe';
import { ITableSchema } from '../loans-table/loans-table.component';

@Component({
  selector: 'app-loan-future-payments',
  templateUrl: './loan-future-payments.component.html',
  styleUrls: ['./loan-future-payments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanFuturePaymentsComponent {
  @Input() paymentsSchedule: ILoanPayment[];

  public tableSchema: ITableSchema[] = [
    { name: 'Starting Balance ($)', key: 'starting_balance', pipe: ThousandsSeparatorsPipe },
    { name: 'Payment Due Date', key: 'due_date', pipe: DatePipe, pipeArgs: ['MM/dd/yyyy'] },
    { name: 'Consent Due Date', key: 'consent_date', pipe: DatePipe, pipeArgs: ['MM/dd/yyyy'] },
    { name: 'Payment Amount ($)', key: 'payment', pipe: ThousandsSeparatorsPipe },
    { name: 'Interest ($)', key: 'interest', pipe: ThousandsSeparatorsPipe },
    { name: 'Principal ($)', key: 'principal', pipe: ThousandsSeparatorsPipe },
    { name: '	Ending Balance ($)', key: 'ending_balance', pipe: ThousandsSeparatorsPipe },
  ];

  public controls = [
    {
      label: 'Download Payments in CSV',
      action: () => {
        const fields = this.tableSchema.map(item => ({
          label: item.name,
          value: item.key,
          default: 'NULL'
        }));
        if (this.paymentsSchedule && this.paymentsSchedule.length) {
          const csv = parse(this.paymentsSchedule, { fields });
          const blob = new Blob([csv], {type: 'text/csv;charset=utf-8' });
          FileSaver.saveAs(blob, "payment-schedule.csv");
        }
      },
    }, {
      label: 'Import to Calendar',
      action: () => {
        const calendar = {
          "VCALENDAR": [
            {
              "PRODID": "-//Calendar Labs//Calendar 1.0//EN",
              "VERSION": "2.0",
              "CALSCALE": "GREGORIAN",
              "METHOD": "PUBLISH",
              "X-WR-CALNAME": "Payment Schedule",
              "X-WR-TIMEZONE": "America/New_York",
              "VEVENT": this.paymentsSchedule.map(payment => ({
                "DTSTART;VALUE=DATE": `${moment(payment.due_date).format('YYYYMMDD')}`,
                "DTEND;VALUE=DATE": `${moment(payment.consent_date).format('YYYYMMDD')}`,
                "SUMMARY": "ApexLend Payment Schedule",
                "DESCRIPTION": `Payment $${payment.payment}\\nConsent Due ${moment(payment.consent_date).format('MM/DD/YYYY')}`,
              }))
            }
          ]
        };
        const ical = ical2json.revert(calendar);
        const blob = new Blob([ical], {type: 'text/calendar;charset=utf-8' });
        FileSaver.saveAs(blob, "payment-schedule.ics");
      },
    },
  ];
}
