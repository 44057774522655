import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { steps } from './steps-routes';
import { StepsComponent } from './steps.component';

export const routes: Routes = [
  {
    path: '',
    component: StepsComponent,
    children: steps
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StepsRoutingModule {}
