import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from 'src/app/user/services/user/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,  
  ) {}

  canActivate(): boolean {
    if (environment.debug && environment.debug.disableGuards) { return true; }
    if (this.userService.isLoggedIn) {
      return true;
    } else {
      // TODO: show modal???
      this.router.navigate(['']);
      return false;
    }
  }
}
