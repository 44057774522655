import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { SharedModule } from './shared/shared.module';
import { StepsModule } from './steps/steps.module';
import { IdleService } from './idle.service';
import { RateLimitingInterceptor } from './shared/interceptors/rate-limiting.interceptor';
import { DebugModule } from './debug/debug.module';
import { environment } from 'src/environments/environment';
import { DebugService } from './debug/services/debug.service';
import { HoldsAcceptComponent } from './independent/holds-accept/holds-accept.component';
import { GoogleAnalyticsService } from './shared/services/google-api/google-analytics.service'

export class DebugServiceMock {}

@NgModule({
  declarations: [AppComponent, HoldsAcceptComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    StepsModule,
    environment.debug ? DebugModule : []
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RateLimitingInterceptor,
      multi: true,
    },
    IdleService,
    GoogleAnalyticsService,
    {
      provide: DebugService,
      useClass: environment.debug ? DebugService : DebugServiceMock
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
