import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, forkJoin } from 'rxjs';
import { IEmployment, IEmploymentType } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { UserService } from 'src/app/user/services/user/user.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-employment-information-container',
  templateUrl: './employment-information-container.component.html',
  styleUrls: ['./employment-information-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmploymentInformationContainerComponent implements OnInit {
  public employment$: Observable<IEmployment>;
  public incomes$: Observable<any>;
  public employmentTypesOptions$: Subject<IEmploymentType[]> = new Subject();

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.employment$ = this.customerService.getEmployments(this.userService.customerId).pipe(
      map((employments: IEmployment[]) => {
        const found = employments && employments.find(Boolean);
        return found || {
          status: null,
          employer: null,
          _type: null,
          yearsExperience: null,
        };
      }),
    );
    this.incomes$ = this.customerService.getIncomes(this.userService.customerId);
  }

  public onSubmitted({ employment, ...incomes }): void {
    const subscriptions: Observable<any>[] = [];
    if (employment) {
      subscriptions.push(this.customerService.postEmployment(this.userService.customerId, employment));
    }

    Object.entries(incomes).forEach(([key, value]) => {
      subscriptions.push(this.customerService.postIncome(this.userService.customerId, {
        periodicity: 'YEARLY',
        _type: key.replace('income', '').toUpperCase(),
        value,
      }));
    });

    if (subscriptions.length) {
      forkJoin(subscriptions).subscribe(() => {
        this.router.navigateByUrl('steps/payments');
      });
    } else {
      this.router.navigateByUrl('steps/payments');
    }
  }

  public onChangedEmploymentType(title: string) {
    this.customerService.findEmploymentType(title).subscribe(res => {
      this.employmentTypesOptions$.next(res);
    });
  }
}
