import { Injectable } from '@angular/core';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { map, shareReplay, tap, switchMap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { SubscribeRequest } from '../../models/api/request/subscribe-request';
import { IOffer } from '../../models/api/response/login-response';
import { SubscribeResponse } from '../../models/api/response/subscribe-response';
import { IApplication, ILoginWithSSNRequest, IProfiles } from '../../models/user.models';
import { ILoginRequest, ILoginResponse } from './../../models/user.models';
import { NavigationService } from '../navigation.service';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { PlaidService } from 'src/app/shared/services/plaid/plaid.service';
import { ChatService } from 'src/app/shared/services/chat/chat.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private mailOffersCache: { [offer: string]: IOffer } = {};
  private currentStep: number;

  public token: string = null;
  public isProfileFilled = false;
  public maxStep = 0;

  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  public username$ = new BehaviorSubject<string>(null);
  public logoutEvent: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private navigationService: NavigationService,
    private $storage: StorageService,
    private $plaid: PlaidService,
    private $chat: ChatService
  ) {
    this.navigationService.shouldLogOut$.subscribe(() => {
      this.logout();
    });

    this.token = localStorage.getItem('token');
  }

  subscribe(request: SubscribeRequest): Observable<SubscribeResponse> {
    return this.apiService.post<SubscribeResponse>(`subscribers/checkExists`, request);
  }

  public setToken(token: string = this.token) {
    token ? localStorage.setItem('token', token) : localStorage.removeItem('token');
  }

  public getToken() {
    return localStorage.getItem('token');
  }

  set step(step: number) {
    this.currentStep = step;
    if (this.maxStep < step) { this.maxStep = step; }
  }

  get step(): number {
    return this.currentStep;
  }

  set applicationId(id: number) {
    id ? localStorage.setItem('application-id', `${id}`) : localStorage.removeItem('application-id');
  }

  get applicationId(): number {
    const id = localStorage.getItem('application-id');
    return id ? +id : null;
  }

  set offerCodeId(id: number) {
    id ? localStorage.setItem('offer-code-id', `${id}`) : localStorage.removeItem('offer-code-id');
  }

  get offerCodeId(): number {
    const id = localStorage.getItem('offer-code-id');
    return id ? +id : null;
  }

  set customerId(customerId: number) {
    customerId ? localStorage.setItem('customer-id', `${customerId}`) : localStorage.removeItem('customer-id');
  }

  get customerId(): number {
    const customerId = localStorage.getItem('customer-id');
    return customerId ? parseInt(customerId, 10) : null;
  }

  get offerCode(): string {
    const offerCode = localStorage.getItem('offer-code');
    return offerCode ? offerCode : null;
  }

  set offerCode(offerCode: string) {
    offerCode ? localStorage.setItem('offer-code', `${offerCode}`) : localStorage.removeItem('offer-code');
  }

  set selectedTerm(term: number) {
    term ? localStorage.setItem('selectedTerm', `${term}`) : localStorage.removeItem('selectedTerm');
  }

  get selectedTerm(): number {
    const term = localStorage.getItem('selectedTerm');
    return term ? parseInt(term, 10) : null;
  }

  get username(): string {
    return this.username$.getValue();
  }

  set username(value: string) {
    this.username$.next(value);
  }

  loginWithSSN(request: ILoginWithSSNRequest): Observable<ILoginResponse> {
    return this.apiService
      .post<ILoginResponse>('auth/login', { ...request, code: this.offerCode })
      .pipe(tap(r => {
        this.setToken(r.token);
        this.storeRefreshToken(r.refresh_token);
        r.token ? this.token = r.token : null
      }));
  }

  login(request: ILoginRequest): Observable<ILoginResponse> {
    this.navigationService.saveLoginNavigationEvent();
    return this.apiService
      .post<ILoginResponse>('auth/login', request)
      .pipe(tap(r => {
        this.username = request.username;
        this.setToken(r.token);
        this.storeRefreshToken(r.refresh_token);
        return r.token ? (this.token = r.token) : null;
      }));
  }

  verifyEmailOTP(request: any): Observable<any> {
    return this.apiService
      .post('auth/verify_email_otp', request)
      .pipe(tap(r => {
        return r;
      }));
  }

  resendOTP(request: any): Observable<any> {
    return this.apiService
      .patch('auth/resend_otp', request)
      .pipe(tap(r => {
        return r;
      }))
  }

  refreshToken(): Observable<any> {
    this.setToken(null);
    this.navigationService.saveLoginNavigationEvent();
    return this.apiService
      .post<ILoginResponse>('auth/refresh-token', {'refresh_token': this.getRefreshToken()})
      .pipe(tap((r) => {
        this.setToken(r.token);
        return r.token ? (this.token = r.token) : null;
      }));
  }

  private storeRefreshToken(refreshToken: string) {
    localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private removeRefreshToken() {
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  profiles(): Observable<IProfiles> {
    return this.apiService
      .get<IProfiles>('auth/user')
      .pipe(tap(r => ((r.profiles && r.profiles.customer) ? (this.customerId = r.profiles.customer) : null)));
  }

  getUsername(): Observable<string> {
    const request$ = this.apiService.get<IProfiles>('auth/user').pipe(
      map(({ username }) => username),
      tap(username => this.username = username)
    );
    
    return this.username$.pipe(switchMap(value => value ? of(value) : request$));
  }

  logout() {
    this.token = null;
    this.username = null;
    this.mailOffersCache = {};
    this.customerId = null;
    this.offerCode = null;
    this.offerCodeId = null;
    this.applicationId = null;
    this.selectedTerm = null;
    this.setToken(null);
    this.isProfileFilled = false;
    this.$storage.clearAll();
    this.$plaid.logout();
    this.logoutEvent.next();
    this.removeRefreshToken();
  }

  saveCurrentStepNumber(step: number, applicationId: number): Observable<IApplication> {
    this.step = step;
    return this.apiService.patch(`applications/${applicationId}`, { step });
  }

  resetSteps() {
    this.currentStep = null;
    this.maxStep = 0;
  }

  getApplication(applicationId: number = this.applicationId): Observable<IApplication> {
    return this.apiService.get(`applications/${applicationId}`)
      .pipe(
        tap((application: IApplication) => {
          if (application.term) {
            this.selectedTerm = application.term;
          }
        }),
      );
  }

  processApplication(application: IApplication): Observable<any> {
    return this.apiService.post(`applications/${application.id}/process`, application);
  }

  change(user: ILoginRequest) {
    return this.apiService.patch('auth/user', user).pipe(
      tap(() => {
        if (user.username) { this.username = user.username; }
      })
    );
  }

  get isLoggedIn(): boolean {
    return Boolean(localStorage.getItem('token'));
  }

  get isLoggedInForSteps(): boolean {
    return Boolean(!!this.token);
  }

  findMailOffer(force = false, offerCode = this.offerCode): Observable<IOffer> {
    const token = this.token;
    if (!this.mailOffersCache[offerCode] || force) {
      return this.apiService.get(`mailoffers/search?code=${offerCode}`, false).pipe(
        shareReplay(1),
        tap((offer: IOffer) => {
          if (token) {
            this.mailOffersCache[offerCode] = offer;
          }
          if (offer && offer.customer && offer.application) {
            this.offerCode = offerCode;
            this.applicationId = offer.application;
            this.getApplication(this.applicationId);
          }
        })
      );
    }
    return of(this.mailOffersCache[offerCode]);
  }

  getMailOffer(offerCodeId: number): Observable<IOffer> {
    return this.apiService.get(`mailoffers/${offerCodeId}`).pipe(
      tap((offer: IOffer) => {
        if (offer && offer.code) {
          this.mailOffersCache[offer.code] = offer;
        }
      }),
    );
  }

  create(user: { email: string; password: string }): Observable<void> {
    return this.apiService.post('users', user);
  }

  changePassword(form: { new_password1: string; new_password2: string }): Observable<void> {
    return this.apiService.post('auth/password/change', form);
  }

  resetPassword(email: string): Observable<void> {
    return this.apiService.post('auth/password/reset', { email });
  }

  confirmPassword(password: string, uid: string, token: string) {
    return this.apiService.post('auth/password/reset/confirm', { new_password1: password, new_password2: password, token, uid });
  }

  activateUser(uid: string, token: string): Observable<any> {
    return this.apiService.post('auth/email-verification', { token, uid });
  }
}
