import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Subject, merge, interval, BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil, startWith, switchMapTo, mapTo, filter, delay } from 'rxjs/operators';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      state('prev', style({transform: 'translateX(0%)'})),
      state('next', style({transform: 'translateX(0%)'})),
      state('first', style({transform: 'translateX(0%)'})),
      transition('void => next', [
        style({transform: 'translateX(-100%)'}),
        animate('2000ms ease-in-out', style({transform: 'translateX(0%)'}))
      ]),
      transition('void => prev', [
        style({transform: 'translateX(100%)'}),
        animate('2000ms ease-in-out', style({transform: 'translateX(0%)'}))
      ]),
      transition('next => void', [
        animate('2000ms ease-in-out', style({transform: 'translateX(100%)'}))
      ]),
      transition('first => void', [
        animate('2000ms ease-in-out', style({transform: 'translateX(100%)'}))
      ]),
      transition('prev => void', [
        animate('2000ms ease-in-out', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class SlideshowComponent implements OnInit {

  @Input() disableAutoScroll = false;

  next$ = new Subject<void>();
  prev$ = new Subject<void>();
  source$: Observable<{ src: SafeStyle, first: boolean }[]>;
  direction$ = new BehaviorSubject<string>('first');
  
  private imageIndex = 0;
  private destroy$ = new Subject();
  private readonly interval = 12000;
  private readonly images = [
    'assets/img/main-background/8.jpg',
    'assets/img/main-background/9.jpg',
    'assets/img/main-background/10.jpg',
    'assets/img/main-background/11.jpg',
    'assets/img/main-background/12.jpg',
    'assets/img/main-background/13.jpg',
    'assets/img/main-background/14.jpg',
    'assets/img/main-background/1.jpg',
    'assets/img/main-background/2.jpg',
    'assets/img/main-background/3.jpg',
    'assets/img/main-background/4.jpg',
    'assets/img/main-background/5.jpg',
    'assets/img/main-background/6.jpg',
    'assets/img/main-background/7.jpg',
  ];

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    const manualChange$ = merge(this.next$, this.prev$);
    const interval$ = interval(this.interval);
    const timer$ = manualChange$.pipe(
      startWith(null),
      switchMapTo(interval$),
      filter(() => !this.disableAutoScroll)
    );
    const change$ = merge(
      timer$.pipe(mapTo('next')),
      this.next$.pipe(mapTo('next')),
      this.prev$.pipe(mapTo('prev'))
    );
    merge(this.next$, timer$).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.imageIndex = (this.imageIndex + 1) % this.images.length
    });
    this.prev$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.direction$.next('prev');
      this.imageIndex = this.imageIndex - 1 >= 0 ? this.imageIndex - 1 : this.images.length - 1
    });
    this.source$ = change$.pipe(
      startWith(null),
      map(direction => {
        this.direction$.next(direction);
        const index = this.imageIndex % this.images.length;
        const src = this.sanitizer.bypassSecurityTrustStyle(`url(${this.images[index]})`);
        return [{ src, first: !direction }];
      }),
      delay(50)
    );
  }
}