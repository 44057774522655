<div class="container">
    <div class="row">
        <table style="margin-top: 50px; font-size: 10;">
            <tr style="border: 1px solid #e3e3e3;">
                <td>Loan: #{{loan?.id}}</td>
                <td>Current Balance $ {{ loan?.current_balance | number: '1.0-2' }}</td>
                <td>Status: {{ loan?.status }}</td>
            </tr>
            <tr style="border: 1px solid #e3e3e3;">
                <td>Closing Date: {{loan?.closingDate | date: 'MM/dd/yyyy' : 'UTC' }}</td>
                <td>Current Term: {{loan?.term }} months ({{ loan?.term / 12 }} years)</td>
                <td>Interest Rate: {{ loan?.interestRatePct | number: '1.0-2' }}%</td>
            </tr>
        </table>
    </div>

    <div class="row" >
        <table mat-table [dataSource]="data" style="margin-top: 30px; font-size: 10;" class="loan-table" >
            <strong>Payment History</strong>
            <ng-container *ngFor="let schema of tableSchema" matColumnDef="{{ schema.key }}">
                <th class="thead" mat-header-cell *matHeaderCellDef>{{ schema.name }}</th>
                <td mat-cell *matCellDef="let element; let i = index" [ngClass]="schema.classCell">
                    <span *ngIf="!schema.checkbox">{{ element[schema.key] | dynamic:schema.pipe:schema.pipeArgs }}</span>
                    <mat-checkbox *ngIf="schema.checkbox" (change)="checkboxData[schema.key][i] = $event.checked"></mat-checkbox>
                </td>
            </ng-container>

            <tr style="border: 1px solid #e3e3e3; padding: 5px;" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <mat-progress-bar mode="indeterminate" [ngStyle]="{'visibility': displayedColumns ? 'visible' : 'hidden'}">
            </mat-progress-bar>
            <tr style="border: 1px solid #e3e3e3;" margin="5px" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>