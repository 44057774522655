import { Component, OnInit } from '@angular/core';
import { UserService } from './user/services/user/user.service';
import { IdleService } from './idle.service';
import { ModalService } from './shared/services/modal/modal.service';
import { Router, NavigationEnd } from '@angular/router';
import { SignInService } from './user/services/sign-in.service';
import { takeWhile, concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChatService } from './shared/services/chat/chat.service';
import { DebugService } from './debug/services/debug.service';
import { CustomerService } from './shared/services/customer/customer.service';
import { of } from 'rxjs';
import { ICustomer } from './shared/models/customer.model';
import { ReactiveFormConfig } from '@rxweb/reactive-form-validators';

declare let ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  
  constructor(
    private userService: UserService,
    private idle: IdleService,
    private modalService: ModalService,
    private signInService: SignInService,
    private router: Router,
    private $chat: ChatService,
    private $debug: DebugService, // Don't delete!
    private $customer: CustomerService
  ) {
    this.idle.startWatching(300).pipe(
      takeWhile(() => !environment.debug || !environment.debug.disableLogoutOnTimeOut)
    ).subscribe(() => {
      if (this.userService.isLoggedIn || this.userService.isLoggedInForSteps) {
        this.userService.logout();
        this.modalService
          .openInfo({
            description: 'You are logged out',
            buttonText: 'Log In'
          })
          .subscribe(() => {
            this.router.navigateByUrl('/')
              .then(() => {
                setTimeout(() => {
                  this.signInService.initSignIn();
                })
              });
          });
      }
    });
    this.$chat.init();
    this.signInService.signInComplete$.pipe(
      concatMap(resp => resp.customer ? this.$customer.get(resp.customer) : of(null))
    )
    .subscribe((customer: ICustomer) => {
      if (!customer) { return; }
      this.$chat.setUserInfo({
        email: customer.email,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phoneNumber: customer.cellPhone
      });
    });

    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  ngOnInit (){
    //if you want to apply global configuration then use below code. 
    ReactiveFormConfig.set({
        "validationMessage":
          {
            "compare": "Your confirmation password do not match.",            
            "minLength": "Enter minimum length of 6 characters."
          }
      });
  }
}
