<mat-accordion>
  <mat-expansion-panel #panel
    class="accordion"
    [hideToggle]="true"
    (expandedChange)="switchExpansionPanel()"
  >
    <mat-expansion-panel-header
      class="accordion__header"
      [class.accordion__header_expanded]="opened$ | async"
    >
      <mat-panel-title class="accordion__panel">
        <div class="accordion__left-panel">
          <div class="accordion__title">
            {{ subjectTitle | uppercase }}
          </div>
        </div>

        <div class="accordion__right-panel">
          <div
            class="accordion__expand-icon"
            [class.accordion__expand-icon_expanded]="opened$ | async"
          ></div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
</mat-accordion>
