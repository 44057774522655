import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICustomerCard, ILoan } from 'src/app/shared/models/customer.model';
import { ILoanPayment } from '../../../shared/services/customer/customer.service';

@Component({
  selector: 'app-loan-info',
  templateUrl: './loan-info.component.html',
  styleUrls: ['./loan-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanInfoComponent {
  @Input() loan: ILoan;
  @Input() payments: ILoanPayment[];
  @Input() payoffCard: ICustomerCard;
  @Input() loading: boolean;

  public get proceeds(): number {
    if (!this.loan) {
      return null;
    }
    return this.loan.amount * (1 - this.loan.originationFeePct / 100);
  }

  public get firstPaymentDate(): string {
    if (!this.payments || !this.payments.length) {
      return null;
    }
    return this.payments[0].due_date;
  }

  public get lastPaymentDate(): string {
    if (!this.payments || !this.payments.length) {
      return null;
    }
    return this.payments[this.payments.length - 1].due_date;
  }
}
