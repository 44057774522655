<div
  class="input-wrapper"
  [class.input-wrapper_has-error]="!loading && (isTouched && (forceError || hasValidateErrors || invalid))"
  [class.input-wrapper_loading]="loading"
>
  <div class="input-wrapper__label" *ngIf="label">
    <span class="input-wrapper__title">{{ label }}</span>
    <span *ngIf="isRequired && !isPreview" class="input-wrapper__required">*</span>
    <img 
      *ngIf="hasHint"
      class="input-wrapper__hint"
      src="assets/img/icons/info--blue.svg"
      (click)="hintClick.emit()"
    >
  </div>
  <div class="input-wrapper__control-wrapper" (clickOutside)="focusOuted.emit()">  <!-- [clickOutsideEvents]="'mousedown'" -->
    <input
      class="input-wrapper__control"
      [hidden]="hidden"
      #inputRef
      [ngModel]="model"
      (ngModelChange)="writeValue($event)"
      (focusout)="focusOuted.emit()"
      (keypress)="keyPressed.emit($event)"
      [placeholder]="placeholder || ''"
      [type]="type"
      [disabled]="isDisabled"
      [hiddenInput]="hasHiddenInput"
      [mask]="ngxMask"
      [dropSpecialCharacters]="ngxDropSpecialCharacters"
      [prefix]="prefix || ''"
      [suffix]="suffix"
      [maxLength]="maxLength ? maxLength : 1000"
      [autocomplete]="autocomplete"
      [patterns]="customPatterns"
      (blur)="onTouched()"
      (focus)="focused.emit($event)"
      [id]="id"
    />
  </div>
  <div *ngIf="!loading && (isTouched && (forceError || hasValidateErrors || invalid)) && !hidden" class="input-wrapper__error-message">
    {{ validateErrorOutput }}
  </div>
  <div *ngIf="!loading && !(forceError || hasValidateErrors || invalid) && !hidden && isTouched && warning" class="input-wrapper__warning-message">
    {{ warning }}
  </div>
</div>
