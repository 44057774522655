import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IConfirmModalData {
  title?: string;
  description?: string | string[];
  cancelText?: string;
  confirmText?: string;
  reversed?: boolean;
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmModalComponent implements OnInit {

  public config: IConfirmModalData = {
    title: 'Confirm',
    description: ['Are you sure?'],
    cancelText: 'Cancel',
    confirmText: 'OK',
    reversed: false
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IConfirmModalData,
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
  ) {}

  get descriptionList(): string[] {
    return [].concat(this.data.description);
  }

  ngOnInit() {
    this.config = { ...this.config, ...this.data };
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(true);
  }
}