import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {
  @Input() title: string;
  @Input() priority: string;
  @Input() loading: boolean;
  @Input() description: string;
  @Input() foldable = false;
  @Input() expanded = true;
  @Input() hasRemoveBtn = false;

  @Output() removeBtnClick = new EventEmitter<void>();

  onRemoveBtnClick(e: Event) {
    e.stopPropagation();
    this.removeBtnClick.emit();
  }
}
