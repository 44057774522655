import { ValidatorFn, AbstractControl } from "@angular/forms";

export function parseMin(min: number): ValidatorFn {
  return (control: AbstractControl) => {
    return Number(control.value) < min ? {parseMin: true} : null;
  }
}

export function parseMax(max: number): ValidatorFn {
  return (control: AbstractControl) => {
    return Number(control.value) > max ? {parseMax: true} : null;
  }
}