import { Component, ChangeDetectionStrategy, Input, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ILoan } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { HoldsService, IPendingHolds } from 'src/app/shared/services/holds/holds.service';
import { UserService } from 'src/app/user/services/user/user.service';

@Component({
  selector: 'app-loan-warning',
  templateUrl: './loan-warning.component.html',
  styleUrls: ['./loan-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanWarningComponent implements OnInit{
  @Input() loading: boolean;
  public show: boolean = false;
  public pendingHolds: any = []; 
  public loans$ = new BehaviorSubject<ILoan[]>([]);

  constructor(
    private holdsService: HoldsService,
    private customerService: CustomerService,
    private userService: UserService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getPendingHolds();
  }

  getPendingHolds() {
    forkJoin([
    this.holdsService.getPendingHolds(),
    this.customerService.getLoans(this.userService.customerId),
  ]).subscribe(([holds, loans]) => {
      holds.length > 0 ? this.show = true : this.show = false;
      this.pendingHolds = holds;
      this.loans$.next(loans);
      this.cd.markForCheck();
    })
  }

  calculateDiff(data: Date){
    let date = new Date(data);
    let currentDate = new Date();

    let days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }
}
