<form [formGroup]="form" (keyup.enter)="onSubmitButtonClick()">
  <div class="component">
    <div *ngIf="showLabel" class="component-label">
      {{ labelText }}
    </div>
    <div class="component-controls-block">
      <div class="component-input-container">
        <app-input
          formControlName="textValue"
          type="text"
          [id]="inputPlaceholder == 'E-mail' ? 'email_id' : inputPlaceholder == 'offer code' ? 'mobile_offer_code' : 'offer_code'"
          [placeholder]="inputPlaceholder"
          [pattern]="pattern"
          [maxLength]="maxLength"
          #inputComponent
          ngDefaultControl
        ></app-input>
      </div>
      <button
        type="button"
        class="component-submit-btn al-button"
        [id]="inputPlaceholder == 'offer code' ? 'mobile_continue' : submitButtonText"
        [ngClass]="{
          'al-button-blue': submitButtonMode === sendInputFormSubmitButtonMode.Blue,
          'al-button-white': submitButtonMode === sendInputFormSubmitButtonMode.White
        }"
        (click)="onSubmitButtonClick()"
      >
        {{ submitButtonText }}
      </button>
    </div>
    <a *ngIf="showHelpLink" class="component-help-link" [attr.href]="helpLinkUrl" (click)="onHelpLinkClick($event)">
      {{ helpLinkText }}
    </a>
  </div>
</form>
