import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ICustomer } from 'src/app/shared/models/customer.model';
import { ModalService } from './../../../shared/services/modal/modal.service';

export interface IInformationForm {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  alternateEmail: string;
  address1: string;
  address2: string;
  homePhone: string;
  cellPhone: string;
  ssn: string;
  zipcode: string;
}

@Component({
  selector: 'app-information-form',
  templateUrl: './information-form.component.html',
  styleUrls: ['./information-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() customer: ICustomer;
  @Input() hasErrors: boolean;
  public progressBar: boolean = true;
  @Output() submitted = new EventEmitter();
  @Output() authFormChanged = new EventEmitter();
  public isDisabled = true;
  public loading: boolean;
  public form: FormGroupTyped<IInformationForm>;
  public lastRequestTimestamp: number;
  public lastRequestsCounter = 0;
  private subscriptions = new Subscription();

  constructor(private formBuilder: FormBuilder, private modalService: ModalService) {}

  ngOnInit() {
    this.loading = true;
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customer && changes.customer.currentValue) {
      this.loading = false;
      delete changes.customer.currentValue.ssn;
      this.form.patchValue({ ...changes.customer.currentValue, monthlyPayment: '' });
      this.progressBar = false;
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      dateOfBirth: [''],
      email: [''],
      alternateEmail: [''],
      address1: [''],
      address2: [''],
      homePhone: [''],
      cellPhone: [''],
      ssn: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      zipcode: ['', [Validators.required]]
    }) as FormGroupTyped<IInformationForm>;

    this.subscriptions.add(this.form.statusChanges.subscribe(_ => this.authFormChanged.emit()));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onSubmit() {
    if (!this.form.valid) { return; }
    if (this.form.valid) {
      const exceededLimit = this.checkTimeLimitIsExceeded();
      if (exceededLimit) {
        this.modalService.openInfo({
          title: 'We are sorry',
          description: 'Your account has been locked for 1 min, wait to try again',
          buttonText: 'Ok'
        });
      } else {
        const form: IInformationForm = {
          ...this.form.value,
          ssn: this.form.value.ssn,
          zipcode: this.form.value.zipcode
        };
        this.submitted.emit(form);
      }
    }
  }

  private checkTimeLimitIsExceeded(): boolean {
    const currentTimestamp = +new Date();
    if (this.lastRequestTimestamp) {
      if (currentTimestamp - this.lastRequestTimestamp < 60000) {
        if (this.lastRequestsCounter < 4) {
          this.lastRequestsCounter += 1;
          return false;
        } else {
          return true;
        }
      } else {
        this.lastRequestTimestamp = +new Date();
        this.lastRequestsCounter = 0;
        return false;
      }
    } else {
      this.lastRequestTimestamp = +new Date();
      this.lastRequestsCounter += 1;
      return false;
    }
  }
}
