import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserService } from 'src/app/user/services/user/user.service';

@Component({
  selector: 'app-logotype',
  templateUrl: './logotype.component.html',
  styleUrls: ['./logotype.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogotypeComponent {
  @Input() mode: 'default' | 'no-spacing' = 'default';

  constructor(
    private userService: UserService,
  ) {}

  get route(): string[] {
    return this.userService.isLoggedIn ? ['/profile'] : ['/'];
  }

  public onClick() {
    if (this.route[0] === '/') {
      this.userService.logout();
    }
  }
}
