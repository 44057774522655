import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../user/services/user/user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-two-factor-modal',
  templateUrl: './two-factor-modal.component.html',
  styleUrls: ['./two-factor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoFactorModalComponent implements OnInit {
  public form: FormGroup;
  public options = [];
  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    public dialogRef: MatDialogRef<TwoFactorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { phoneNumber: string },
    private userService: UserService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.userService.getUsername()
      .subscribe(username => {
        this.options = [
          ['email', `Email Address: ${username}`],
          ['phone', `Phone Number: ${this.data.phoneNumber}`]
        ];
        this.loading$.next(false);
      });
  }

  public onSubmit() {
    this.dialogRef.close(this.form.value.option);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      option: ['', [Validators.required]]
    });
  }
}
