import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/user/services/user/user.service';
import { Subject } from 'rxjs';
import { RequestObserver } from '../../helpers/request-observer.class';

@Component({
  selector: 'app-password-recovery-modal',
  templateUrl: './password-recovery-modal.component.html',
  styleUrls: ['./password-recovery-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordRecoveryModalComponent implements OnInit {
  form: FormGroup;
  public forceError = false;
  public errorMessage$ = new Subject<string>();
  public submitRequest = new RequestObserver();

  constructor(
    public dialogRef: MatDialogRef<PasswordRecoveryModalComponent>,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.errorMessage$.next('Wrong email');
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]]
    });

    this.form.controls['email'].valueChanges.subscribe(() => {
      this.forceError = false;
      this.errorMessage$.next('Wrong email');
    });
  }

  onSubmit(): void {
    this.forceError = false;
    if (this.form.valid) {
      const { email } = this.form.value;
      const request = this.userService.resetPassword(email);
      this.submitRequest.setSource(request).subscribe({
        error: ({ error }) => {
          this.forceError = true;
          this.errorMessage$.next((error && error.message) || 'Error');
        }
      });
    }
  }
}
