import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../user/services/user/user.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-validate-ssn-modal',
  templateUrl: './validate-ssn-modal.component.html',
  styleUrls: ['./validate-ssn-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidateSSNModalComponent implements OnInit {
  form: FormGroup;
  public loginError$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ValidateSSNModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customerId: number },
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      ssn: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      zipcode: ['', [Validators.required]]
    });

    this.form.valueChanges.subscribe(() => {
      this.loginError$.next(false);
    });
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    const { ssn, zipcode } = this.form.value;
    const request = { ssn, zipcode };

    this.userService.loginWithSSN(request).subscribe(
      ({ token }) => {
        if (!token) {
          return;
        }
        this.dialogRef.close(true);
      },
      () => {
        this.loginError$.next(true);
      }
    );
  }
}
