import { DatePipe } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { ILoan } from 'src/app/shared/models/customer.model';
import { ThousandsSeparatorsPipe } from 'src/app/shared/pipes/thousands-separators.pipe';
import { ITableSchema } from '../loans-table/loans-table.component';

@Component({
  selector: 'app-download-statements',
  templateUrl: './download-statements.html',
  styleUrls: ['./download-statements.scss', '../../../shared/components/logotype/logotype.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadStatementsComponent {
    @Input() loan: ILoan;
    @Input() data: any;
    @Input() mode: 'default' | 'no-spacing' = 'default';

    public tableSchema: ITableSchema[] = [
        { name: 'Payment Date', key: 'created', pipe: DatePipe, pipeArgs: ['MM/dd/yyyy'] },
        { name: 'Type', key: 'type' },
        { name: 'Paid Amount ($)', key: 'payment', pipe: ThousandsSeparatorsPipe },
        { name: 'Interest ($)', key: 'interest', pipe: ThousandsSeparatorsPipe },
        { name: 'Principal ($)', key: 'principal', pipe: ThousandsSeparatorsPipe },
        { name: 'Payment Notes', key: 'notes' },
        { name: 'Status', key: 'status' },
      ];

    public get displayedColumns() {
        return this.tableSchema.map(i => i.key);
    }
}
