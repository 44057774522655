import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { CARD_TYPE, createCardTypesForHold, disabledCardTypesHold } from 'src/app/shared/constants/select-data.constants';
import { IHoldCard, IPayOffAccount } from 'src/app/shared/models/customer.model';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { IApplication } from '../../../user/models/user.models';
import { ISmartOption } from 'src/app/shared/components/select/select.component';
import { FaqCreditCardModalComponent } from 'src/app/shared/entry/faq-credit-card-modal/faq-credit-card-modal.component';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { validateCreditCardType } from 'src/app/shared/validators/credit-card-type.validator';
import { takeUntil, delay } from 'rxjs/operators';
import { AbstractControlWarnings } from 'src/app/shared/models/abstract-control-warnings.model';
import { validateCreditCardLuhn } from 'src/app/shared/validators/credit-card-luhn.validator';
import { formatObjectEmptyString } from 'src/app/shared/helpers/format-object';

@Component({
  selector: 'app-final-credit-cards-form',
  templateUrl: './final-credit-cards-form.component.html',
  styleUrls: ['./final-credit-cards-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalCreditCardsFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() holdCard: IHoldCard;
  @Input() payOffAccount: IPayOffAccount;
  @Input() loading: boolean;
  @Input() application: IApplication;
  @Input() requestErrors: any;
  @Input() spinner: boolean;
  @Output() submitted = new EventEmitter<any>();
  public form: AbstractControlWarnings & FormGroup;
  public CARD_TYPE = CARD_TYPE;
  public minExpirationDate: Date;
  public isPayOffAccountEmpty: boolean;
  public cardTypesPayOff = CARD_TYPE;
  public cardTypesHold: ISmartOption[];
  public requestPending = false;
  public progressBar: boolean = true;

  public samePayOffCard$ = new BehaviorSubject<boolean>(false);
  public availableCreditTouched$ = new BehaviorSubject<boolean>(false);
  public payOffTypeChanged$ = new BehaviorSubject<boolean>(false);
  public holdTypeChanged$ = new BehaviorSubject<boolean>(false);
  public holdCardNumberDisabled$ = new BehaviorSubject<boolean>(true);
  public payOffAccountNumberDisabled$ = new BehaviorSubject<boolean>(true);
  public payOffAccountNumberDisabledDelayed$: Observable<boolean>;

  public get isPayoffCardIncompatibleForHold(): boolean {
    return disabledCardTypesHold.includes(this.form.get('payOffAccount.card_type').value);
  }

  private destroy$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.cardTypesHold = createCardTypesForHold();
    this.minExpirationDate = new Date();
    this.holdCardNumberDisabled$.pipe(takeUntil(this.destroy$)).subscribe(bool => {
      const control = this.form.get('holdCard.number');
      bool ? control.disable() : control.enable();
    });
    this.payOffAccountNumberDisabled$.pipe(takeUntil(this.destroy$)).subscribe(bool => {
      const control = this.form.get('payOffAccount.number');
      bool ? control.disable() : control.enable();
    });
    this.payOffAccountNumberDisabledDelayed$ = this.payOffAccountNumberDisabled$.pipe(delay(0));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      this.initForm();
    }

    if (changes.requestErrors && this.requestErrors) {
      this.requestPending = false;
      this.spinner = false;
      this.applyErrors(this.requestErrors);
    }

    if (changes.holdCard && changes.holdCard.currentValue) {
      const holdCard = <IHoldCard>changes.holdCard.currentValue;
      this.holdCardNumberDisabled$.next(!!holdCard.number);
      this.form.controls.holdCard.patchValue({
        id: holdCard.id || '',
        name: holdCard.name || '',
        number: holdCard.number.replace('*********', '*** **** '),
        card_type: this.findCardType(holdCard.card_type),
        available_credit: holdCard.available_credit ? `${holdCard.available_credit}` : '',
        expiration_date: holdCard.expiration_date ? moment(holdCard.expiration_date).format('MMYY') : '',
        security_code: holdCard.security_code
      });
      this.toggleCardNumberValidators('hold', !holdCard.number);
    }

    if (changes.payOffAccount && changes.payOffAccount.currentValue) {
      const payOffAccount = <IPayOffAccount>changes.payOffAccount.currentValue;
      if (payOffAccount.content_object) {
        this.form.controls.payOffAccount.patchValue({
          card_type: this.findCardType(payOffAccount.content_object.card_type)
        });
      }
    }

    if (changes.application && changes.application.currentValue) {
      const application = <IApplication>changes.application.currentValue;

      this.form.controls.payOffAccount.patchValue({
        amount: application.amount ? `${application.amount}` : '',
      });

      if (!this.form.value.holdCard.card_type) {
        this.form.controls.holdCard.patchValue({
          card_type: this.findCardType(application.desired_hold_card),
        });
      }

      if (!this.form.value.holdCard.available_credit) {
        this.form.controls.holdCard.patchValue({
          available_credit: application.hold_card_limit ? `${application.hold_card_limit}` : '',
        });
      }

      if (!this.form.value.payOffAccount.card_type) {
        this.isPayOffAccountEmpty = true;
        this.form.controls.payOffAccount.patchValue({
          card_type: this.findCardType(application.desired_payoff_account),
        });
      }

      this.progressBar = false;
      this.form.get('holdCardExtra').patchValue(formatObjectEmptyString(this.application));
      this.toggleSamePayOffCard(application.same_payoff_hold_account || false);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const form = this.form.value;

      form.application = {
        same_payoff_hold_account: this.samePayOffCard$.value
      };

      if (this.isPayOffAccountEmpty) {
        form.application.desired_payoff_account = form.payOffAccount.card_type.substring(0, 1);
      } else {
        form.payOffAccount.content_type = 'credit card';
        form.payOffAccount.content_object = {
          card_type: form.payOffAccount.card_type.substring(0, 1)
        };
      }

      const holdCard = { ...form.holdCard };
      if (!this.samePayOffCard$.value) {
        holdCard.expiration_date = this.formatExpirationDate(form.holdCard.expiration_date).format('YYYY-MM-DD');
        holdCard.card_type = form.holdCard.card_type.substring(0, 1);
        holdCard.available_credit = parseFloat(form.holdCard.available_credit.replace(/,/g, ''));
      } else {
        delete holdCard.available_credit;
        holdCard.card_type = form.payOffAccount.card_type.substring(0, 1);
        holdCard.number = form.payOffAccount.number;
        holdCard.name = form.payOffAccount.name;
        holdCard.expiration_date = this.formatExpirationDate(form.payOffAccount.expiration_date).format('YYYY-MM-DD');
        holdCard.security_code = form.payOffAccount.security_code;
      }

      this.requestPending = true;
      this.spinner = true;
      this.submitted.emit({
        ...form,
        holdCard
      });
    }
  }

  public toggleSamePayOffCard(value: boolean): void {
    this.samePayOffCard$.next(value);
    this.toggleHoldCardValidators(value);
    this.togglePayoffCardValidators(value);

    const { holdCard, payOffAccount } = this.form.getRawValue();
    if (value && payOffAccount.card_type === holdCard.card_type) {
      this.payOffAccountNumberDisabled$.next(this.holdCardNumberDisabled$.getValue());
      this.toggleCardNumberValidators('payoff', !this.holdCardNumberDisabled$.getValue());
      this.form.controls.payOffAccount.patchValue({
        name: holdCard.name,
        number: holdCard.number,
        expiration_date: holdCard.expiration_date,
        security_code: holdCard.security_code
      });
    } else if (value && payOffAccount.card_type !== holdCard.card_type) {
      this.payOffAccountNumberDisabled$.next(false);
      this.toggleCardNumberValidators('payoff', true);
      this.form.controls.payOffAccount.patchValue({
        name: '',
        number: '',
        expiration_date: '',
        security_code: ''
      });
    }
  }

  public editHoldCardNumber() {
    this.holdCardNumberDisabled$.next(false);
    this.toggleCardNumberValidators('hold', true);
    setTimeout(() => {
      this.form.controls.holdCard.patchValue({
        number: ''
      });
    });
  }

  public editPayOffAccountNumber() {
    this.payOffAccountNumberDisabled$.next(false);
    this.toggleCardNumberValidators('payoff', true);
    setTimeout(() => {
      this.form.controls.payOffAccount.patchValue({
        number: ''
      });
    });
  }

  public getCardError(cardType: string) {
    if (cardType === 'payoff') {
      const control = this.form.controls.payOffAccount['controls'].amount;
      if (control.getError('tooBig')) {
        return 'Maximum Amount to Pay Off is $40,000.';
      } else if (control.getError('tooSmall')) {
        return 'Minimum Amount to Pay Off is $500.';
      } else if (control.getError('required')) {
        return 'This field is required.';
      }
    } else if (cardType === 'hold') {
      const control = this.form.controls.holdCard['controls'].available_credit;
      if (control.getError('tooBig')) {
        return 'Available Credit has to be $1,000,000 or less';
      } else if (control.getError('tooSmall')) {
        return 'Available Credit has to be equal or more than the Amount to Pay Off';
      } else if (control.getError('required')) {
        return 'This field is required.';
      }
    }
    return 'Invalid Value';
  }

  public onHintClick(linkId?: string) {
    this.modalService.open(FaqCreditCardModalComponent, linkId);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      payOffAccount: this.formBuilder.group(
        {
          card_type: ['', [Validators.required]],
          amount: ['', [Validators.required]],
          name: ['', [this.validateNameOnCard]],
          number: [''],
          expiration_date: [''],
          security_code: ['']
        },
        { validators: [this.validateCardTypeNumber]}
      ),
      holdCard: this.formBuilder.group(
        {
          id: [''],
          card_type: ['', [Validators.required]],
          available_credit: ['', [Validators.required, this.validateAvailableCredit.bind(this)]],
          name: ['', [Validators.required, this.validateNameOnCard]],
          number: ['', [Validators.required, Validators.minLength(19), Validators.maxLength(19)]],
          expiration_date: ['', [Validators.required, this.validateExpirationDate.bind(this)]],
          security_code: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
        },
        { validators: [this.validateCardTypeNumber]}
      ),
      holdCardExtra: this.formBuilder.group({
        total_credit_limit: [''],
        cash_advance_limit: [''],
        available_cash_advance: ['']
      })
    });

    this.form.controls.payOffAccount['controls'].card_type.valueChanges.subscribe((cardType: string) => {
      this.payOffTypeChanged$.next(true);
      const { number, security_code } = this.form.controls.payOffAccount['controls'];
      this.setCreditCardValidators(security_code, number, cardType, 'payoff');
      if (this.isPayoffCardIncompatibleForHold) {
        this.toggleSamePayOffCard(false);
      }
    });

    this.form.controls.holdCard['controls'].card_type.valueChanges.subscribe((cardType) => {
      if (!this.samePayOffCard$.getValue()) {
        this.holdTypeChanged$.next(true);
        const { number, security_code } = this.form.controls.holdCard['controls'];
        this.setCreditCardValidators(security_code, number, cardType, 'hold');
      }
    });
  }

  private applyErrors(obj: Object) {
    const sameCard = this.samePayOffCard$.value;
    const controls = {
      ...(sameCard ? (this.form.get('payOffAccount') as FormGroup).controls : {}),
      ...(!sameCard ? (this.form.get('holdCard') as FormGroup).controls : {}),
      ...(this.form.get('holdCardExtra') as FormGroup).controls,
    };
    
    for (const key of Object.keys(obj)) {
      const control = controls[key];
      if (!control) { continue; }
      const error = obj[key];
      control.setErrors({
        responseErr: [].concat(error).find(Boolean)
      });
    }
  }

  private setCreditCardValidators(securityCodeControl: AbstractControl, cardNumberControl: AbstractControl, cardType: string, usageType: 'hold' | 'payoff') {
    const securityCodeLength = cardType === 'AmericanExpress' ? 4 : 3;
    const numberLength = cardType === 'AmericanExpress' ? 18 : 19;

    securityCodeControl.clearValidators();
    cardNumberControl.clearValidators();

    if (usageType === 'hold' || this.samePayOffCard$.value) {
      securityCodeControl.setValidators([
        Validators.required,
        Validators.minLength(securityCodeLength),
        Validators.maxLength(securityCodeLength)
      ]);

      if (usageType === 'hold' && !this.holdCardNumberDisabled$.getValue()) {
        cardNumberControl.setValidators([
          Validators.required,
          Validators.minLength(numberLength),
          Validators.maxLength(numberLength),
          validateCreditCardLuhn
        ]);
      }

      if (usageType === 'payoff' && !this.payOffAccountNumberDisabled$.getValue()) {
        cardNumberControl.setValidators([
          Validators.required,
          Validators.minLength(numberLength),
          Validators.maxLength(numberLength),
          validateCreditCardLuhn
        ]);
      }
    }

    securityCodeControl.updateValueAndValidity();
    cardNumberControl.updateValueAndValidity();
  }

  private findCardType(firstLetter: string) {
    return Object.keys(CARD_TYPE).find(key => CARD_TYPE[key].substring(0, 1) === firstLetter) || '';
  }

  private validateAvailableCredit(control: FormControl) {
    if (control.value) {
      const availableCredit = parseFloat(control.value.replace(/[^\d\.]/gi, ''));
      const payoffAmount = this.form.controls.payOffAccount['controls'].amount;
      const payoffAmountValue = parseFloat(payoffAmount.value.replace(/[^\d\.]/gi, ''));
      if (availableCredit) {
        if (availableCredit > 1000000) {
          this.availableCreditTouched$.next(true);
          return { tooBig: true };
        } else if (availableCredit < payoffAmountValue) {
          this.availableCreditTouched$.next(true);
          return { tooSmall: true }
        }
        this.availableCreditTouched$.next(false);
        return null;
      }
    } else {
      this.availableCreditTouched$.next(false);
      return null;
    }
  }

  private validateExpirationDate(control: FormControl) {
    if (control.value) {
      const date = this.formatExpirationDate(control.value);
      if (date && date.isAfter(new Date())) {
        return null;
      } else {
        return { expirationDateError: true };
      }
    } else {
      return null;
    }
  }

  private formatExpirationDate(expiration: string): moment.Moment {
    const reg = /^(0[1-9]|1[0-2])\/([1-9]{4}|[0-9]{2})$/;
    const matches = expiration.match(reg);

    if (matches) {
      const [value, month, year] = matches;
      return moment(`${month}-01-20${year}`, 'MM-DD-YYYY');
    } else {
      return null;
    }
  }

  private toggleCardNumberValidators(type: 'hold' | 'payoff', value: boolean) {
    const { card_type, number } = type === 'hold'
      ? this.form.controls.holdCard['controls']
      : this.form.controls.payOffAccount['controls'];

    if (value) {
      const numberLength = (card_type.value && card_type.value.substring(0, 1) === 'A') ? 18 : 19;
      number.setValidators([Validators.required, Validators.minLength(numberLength), Validators.maxLength(numberLength), validateCreditCardLuhn]);
    } else {
      number.clearValidators();
    }
    number.updateValueAndValidity();
  }

  private toggleHoldCardValidators(sameCard: boolean) {
    const holdCardGroup = this.form.get('holdCard') as FormGroup;
    const { card_type, available_credit, number, name, expiration_date, security_code } = holdCardGroup.controls;
    if (sameCard) {
      card_type.clearValidators();
      available_credit.clearValidators();
      number.clearValidators();
      name.clearValidators();
      expiration_date.clearValidators();
      security_code.clearValidators();
      holdCardGroup.clearValidators();
    } else {
      const securityCodeLength = (card_type.value && card_type.value.substring(0, 1) === 'A') ? 4 : 3;
      const numberLength = (card_type.value && card_type.value.substring(0, 1) === 'A') ? 18 : 19;
      card_type.setValidators([Validators.required]);
      available_credit.setValidators([Validators.required, this.validateAvailableCredit.bind(this)]);
      name.setValidators([Validators.required, this.validateNameOnCard]);
      number.setValidators([Validators.required, Validators.minLength(numberLength), Validators.maxLength(numberLength), validateCreditCardLuhn]);
      expiration_date.setValidators([Validators.required, this.validateExpirationDate.bind(this)]);
      security_code.setValidators([Validators.required, Validators.minLength(securityCodeLength), Validators.maxLength(securityCodeLength)]);
      holdCardGroup.setValidators([this.validateCardTypeNumber]);
    }
    card_type.updateValueAndValidity();
    available_credit.updateValueAndValidity();
    name.updateValueAndValidity();
    number.updateValueAndValidity();
    expiration_date.updateValueAndValidity();
    security_code.updateValueAndValidity();
  }

  private togglePayoffCardValidators(sameCard: boolean) {
    const payoffGroup = this.form.get('payOffAccount') as FormGroup;
    const { name, number, card_type, expiration_date, security_code } = payoffGroup.controls;
    if (!sameCard) {
      name.clearValidators();
      number.clearValidators();
      expiration_date.clearValidators();
      security_code.clearValidators();
      payoffGroup.clearValidators();
    } else {
      const securityCodeLength = (card_type.value && card_type.value.substring(0, 1) === 'A') ? 4 : 3;
      const numberLength = (card_type.value && card_type.value.substring(0, 1) === 'A') ? 18 : 19;
      name.setValidators([Validators.required, this.validateNameOnCard]);
      number.setValidators([Validators.required, Validators.minLength(numberLength), Validators.maxLength(numberLength), validateCreditCardLuhn]);
      expiration_date.setValidators([Validators.required, this.validateExpirationDate.bind(this)]);
      security_code.setValidators([Validators.required, Validators.minLength(securityCodeLength), Validators.maxLength(securityCodeLength)]);
      payoffGroup.setValidators([this.validateCardTypeNumber]);
    }
    name.updateValueAndValidity();
    number.updateValueAndValidity();
    expiration_date.updateValueAndValidity();
    security_code.updateValueAndValidity();
  }

  private validateCardTypeNumber(control: AbstractControlWarnings) {
    const { number, card_type } = control.value;
    const error = validateCreditCardType(number, card_type, `Card Type and Card Number don't match, are you sure?`);
    control.warnings = { ...error };
    return null;
  }

  private validateNameOnCard(control: AbstractControlWarnings) {
    const value: string = control.value;
    const warning = value.length > 26 ? { tooLong: true } : null;
    control.warnings = { ...warning };
    return null;
  }
}
