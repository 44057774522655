import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../../app/shared/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private dialog: MatDialog, private apiService: ApiService) {}

  getContactInfo() {
    return this.apiService.get(`company`);
  }
}
