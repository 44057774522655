import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-licence-container',
  templateUrl: './licence-container.component.html',
  styleUrls: ['./licence-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenceContainerComponent {
  constructor() {}

  onSavePdf() {
    window.open('/assets/pdf/ApexLend_Licenses.pdf');
  }
}
