import { Pipe, PipeTransform, Injector, InjectionToken } from '@angular/core';

@Pipe({
  name: 'dynamic'
})
export class DynamicPipe implements PipeTransform {

  constructor(private injector: Injector) {}

  transform(value: any, token: InjectionToken<PipeTransform>, pipeArgs: any[]): any {
    if (!token) { return value; }
    const pipe = this.injector.get(token);
    if (pipeArgs) {
      return pipe.transform(value, ...pipeArgs);
    }
    return pipe.transform(value);
  }
}
