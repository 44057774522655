import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICustomer, IBankAccount, IPeriodicDebt, IBalanceDebt } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { IOffer } from 'src/app/user/models/api/response/login-response';
import { UserService } from 'src/app/user/services/user/user.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-monthly-payments-information-container',
  templateUrl: './monthly-payments-information-container.component.html',
  styleUrls: ['./monthly-payments-information-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthlyPaymentsInformationContainerComponent implements OnInit {
  public balanceDebts$ = new Observable<IBalanceDebt[]>();
  public periodicDebts$ = new Observable<IPeriodicDebt[]>();

  constructor(private router: Router, private customerService: CustomerService, private userService: UserService) {}

  ngOnInit() {
    this.balanceDebts$ = this.customerService.getBalanceDebts(this.userService.customerId);
    this.periodicDebts$ = this.customerService.getPeriodicDebts(this.userService.customerId);
  }

  public onSubmitted({ debtMonthly, debtBalance }): void {
    const subscribtions: Observable<any>[] = [];
    Object.entries(debtMonthly).forEach(([key, value]) => {
      subscribtions.push(this.customerService.postPeriodicDebt(this.userService.customerId, {
        _type: key.toUpperCase(),
        value: Number(value).toFixed(2),
        periodicity: 'MONTHLY',
      }));
    });

    Object.entries(debtBalance).forEach(([key, value]) => {
      subscribtions.push(this.customerService.postBalanceDebt(this.userService.customerId, {
        _type: key.toUpperCase(),
        value: Number(value).toFixed(2),
      }));
    });
    if (subscribtions.length) {
      forkJoin(subscribtions).subscribe(() => {
        this.router.navigateByUrl('steps/dti');
      });
    } else {
      this.router.navigateByUrl('steps/dti');
    }
  }
}
