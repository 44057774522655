import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../shared/services/modal/modal.service';
import { Router } from '@angular/router';
import { ILoanPayment, CustomerService } from 'src/app/shared/services/customer/customer.service';
import {Observable, of, BehaviorSubject, combineLatest} from 'rxjs';
import {map, concatMap, filter, switchMap, startWith, shareReplay, debounceTime} from 'rxjs/operators';
import { ELoanPaymentRequestType } from 'src/app/shared/models/enums/loan-payment-request.enum';
import { ILoanPaymentRequest } from 'src/app/shared/models/customer.model';
import { ISmartOption } from 'src/app/shared/components/select/select.component';
import { parseDate } from 'src/app/shared/helpers/parse-date';
import { PlaidService } from 'src/app/shared/services/plaid/plaid.service';
import { thousandsSeparators } from 'src/app/shared/helpers/thousands-separators';
import { authorizationForOneTimeAutomatedPayments } from 'src/app/shared/constants/text.constants';
import { RequestObserver } from 'src/app/shared/helpers/request-observer.class';
import { AddBankAccountModalComponent } from 'src/app/shared/entry/add-bank-account-modal/add-bank-account-modal.component';
import {ApiService} from "../../../shared/services/api/api.service";
import * as moment from "moment";
import {UserService} from "../../../user/services/user/user.service";

enum EPaymentTypes {
  Regular, AdditionalPrincipal, PayoffLoan
}

enum EPaymentSource {
  BankAccount = 'BankAccount',
  CreditCard = 'CreditCard'
}

interface IAdditionalPrincipalPaydownRequest {
  id: number;
  amount: any;
  date: string;
}

interface IAdditionalPrincipalPaydownItem {
  total_interest: number;
  remaining_payment: number;
  remaining_payments_term: number;
}

interface IAdditionalPrincipalPaydown {
  addl_payment: IAdditionalPrincipalPaydownItem;
  regular: IAdditionalPrincipalPaydownItem;
}

interface IPayoffLoanRequest {
  id: number;
  date: string;
}

interface IPayoffLoan {
  total_principal: number;
  total_interest: number;
  total_pending_balance: number;
}

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MakePaymentComponent implements OnInit, OnChanges {
  @Input() loanId: number;
  @Input() bankAccounts: ISmartOption[] = [];
  @Input() creditCardOptions: ISmartOption[] = [];
  @Input() nextPayments: ILoanPayment[] = [];
  @Input() loading: boolean;

  @Output() reload = new EventEmitter<void>();
  public form: FormGroup;
  public paymentTypes = [
    [EPaymentTypes.Regular, 'Regular Payment'],
    [EPaymentTypes.AdditionalPrincipal, 'Additional Principal Paydown'],
    [EPaymentTypes.PayoffLoan, 'Payoff Loan']
  ];
  public firstNextPayment: ILoanPayment;
  public sumNextPayment: number;
  public sumInterestNextPayment: number;
  public minPaymentDate = new Date();
  public maxPaymentDate: Date;

  public observer = new RequestObserver();
  public formAmount$: Observable<number>;
  public additionalPrincipalPaydown$: Observable<IAdditionalPrincipalPaydown>;
  public additionalPrincipalRegular$: Observable<IAdditionalPrincipalPaydownItem>;
  public additionalPrincipalAddl$: Observable<IAdditionalPrincipalPaydownItem>;
  public savings$: Observable<number>;
  public paymentsDiff$: Observable<number>;
  public payoffLoan$: Observable<IPayoffLoan>;
  public paymentType$ = new BehaviorSubject<EPaymentTypes>(EPaymentTypes.Regular);
  public paymentSource$ = new BehaviorSubject<EPaymentSource>(EPaymentSource.BankAccount);
  public progressBar: boolean = true;

  // Observable `getters`
  public isRegularPayment$: Observable<boolean> = this.paymentType$.pipe(map(type => {
    return type === EPaymentTypes.Regular;
  }));
  public isAdditionalPayment$: Observable<boolean> = this.paymentType$.pipe(map(type => {
    return type === EPaymentTypes.AdditionalPrincipal;
  }));
  public isPayoffPayment$: Observable<boolean> = this.paymentType$.pipe(map(type => {
    return type === EPaymentTypes.PayoffLoan;
  }));
  public datepickerWarning$: Observable<string> = this.isPayoffPayment$.pipe(map(bool => {
    if (bool) {
      return `
        If you scheduled your payment on a weekend or a holiday,
        your payment will be made on the next business day
        and your Estimated Payoff Amount may be different.
      `;
    } else {
      return `
        If you scheduled your payment on a weekend or a holiday,
        your payment will be made on the next business day.
      `;
    }
  }));

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private $customerApi: CustomerService,
    private $api: ApiService,
    private $plaid: PlaidService,
    private $user: UserService
  ) {}

  ngOnInit() {
    this.initForm();
    this.formAmount$ = this.isAdditionalPayment$.pipe(
      filter(Boolean),
      switchMap(() => this.form.get('amount').valueChanges.pipe(startWith(this.form.get('amount').value)))
    );
    this.form.get('type').valueChanges.subscribe(val => this.handleTypeChange(val));
    this.additionalPrincipalPaydown$ = this.isAdditionalPayment$.pipe(
      filter(Boolean),
      switchMap(() => {
        return combineLatest([
          this.formAmount$.pipe(filter(Boolean)),
          this.form.get('date').valueChanges.pipe(startWith(this.form.get('date').value), filter(Boolean))
        ]).pipe(
          debounceTime(300),
          switchMap(([ amount, date ]) => {
            const request: IAdditionalPrincipalPaydownRequest = {
              id: this.$user.applicationId,
              amount,
              date: moment(date).format('YYYY-MM-DD')
            };
            return this.$api.post('additional-payment', request) as Observable<IAdditionalPrincipalPaydown>;
        }));
      }),
      shareReplay(1),
    );
    this.additionalPrincipalRegular$ = this.additionalPrincipalPaydown$.pipe(map(paydown => paydown.regular));
    this.additionalPrincipalAddl$ = this.additionalPrincipalPaydown$.pipe(map(paydown => paydown.addl_payment));
    this.savings$ = combineLatest([
      this.additionalPrincipalRegular$,
      this.additionalPrincipalAddl$
    ]).pipe(map(([ regular, addl ]) => regular.total_interest - addl.total_interest));
    this.paymentsDiff$ = combineLatest([
      this.additionalPrincipalRegular$,
      this.additionalPrincipalAddl$
    ]).pipe(map(([ regular, addl ]) => regular.remaining_payments_term - addl.remaining_payments_term));
    this.payoffLoan$ = this.isPayoffPayment$.pipe(
      filter(Boolean),
      switchMap(() => this.form.get('date').valueChanges.pipe(startWith(this.form.get('date').value), filter(Boolean))),
      switchMap(date => {
        const request: IPayoffLoanRequest = {
          id: this.$user.applicationId,
          date: moment(date).format('YYYY-MM-DD')
        };
        return this.$api.post('payoff-loan', request) as Observable<IPayoffLoan>;
      }),
      shareReplay(1)
    );
  }

  ngOnChanges({ loading }: SimpleChanges): void {
    // On @Input loading === true
    if (!loading.currentValue) {
      // Set form fields from inputs
      const firstBankAccount = this.bankAccounts.find(Boolean);
      const fristCreditCard = this.creditCardOptions.find(Boolean);
      this.form.patchValue({
        bankAccount: firstBankAccount && firstBankAccount.key,
        creditCard: fristCreditCard && fristCreditCard.key
      });

      // Set first next payment property
      this.firstNextPayment = this.nextPayments.find(Boolean);
      this.maxPaymentDate = new Date(this.firstNextPayment.due_date);

      // Set sum of payments and payments interest
      const [sumPayment, sumInterest] = this.nextPayments.reduce(([p, i], next) => {
        return [p + next.payment, i + next.interest]
      }, [0, 0]);
      this.sumNextPayment = sumPayment;
      this.sumInterestNextPayment = sumInterest;
      this.progressBar = false;
    }
  }

  public onSubmit(): void {
    const values = this.form.value;
    const date = parseDate(values.date, 'yyyy-MM-dd');
    let request: Observable<ILoanPaymentRequest>;
    let amountTitle: string;
    let amount: number;
    let modalConfirmation: Observable<boolean> = of(true);

    // Set variables based on payment type
    if (values.type === EPaymentTypes.Regular) {
      amount = this.firstNextPayment.payment;
      amountTitle = 'Regular Payment Amount';
      request = this.$customerApi.createPayment(this.loanId, ELoanPaymentRequestType.Payment, amount, date);
      // Open confirmation modal if Auto-Pay is on and 'Regular' payment type
      if (this.$plaid.isAutoPayOn) {
        modalConfirmation = this.openConfirmModal();
      }
    } else if (values.type === EPaymentTypes.AdditionalPrincipal) {
      amount = values.amount;
      amountTitle = 'Additional Principal Paydown';
      request = this.$customerApi.createPayment(this.loanId, ELoanPaymentRequestType.Principal, amount, date);
    } else if (values.type === EPaymentTypes.PayoffLoan) {
      this.payoffLoan$.subscribe((value) => {
        amount = value.total_pending_balance;
      });
      amountTitle = 'Payoff Amount';
      request = this.$customerApi.createPayment(this.loanId, ELoanPaymentRequestType.Principal, amount, date);
    }

    const mergedRequest = modalConfirmation.pipe(concatMap(bool => {
      if (bool) { return this.observer.setSource(request); }
      else { return of(null); }
    }));
    // Make request and open modal window
    mergedRequest.subscribe(resp => {
      if (!resp) { return; }
      const firstDate = parseDate(this.firstNextPayment.due_date);
      const secondDate = this.form.value.date && parseDate(this.form.value.date);
      this.modalService.openInfo({
        title: 'Success',
        description: [
          `Your ${amountTitle} of $ ${thousandsSeparators(amount)} due on ${firstDate}`,
          `has been successfully scheduled for payment on ${secondDate}`
        ],
        buttonText: 'OK'
      }).subscribe(() => {
        this.onBack();
      });
    }, error => { 
      if (error.status === 401){
        this.modalService.openConfirm({
          title: 'Unauthorized',
          description: [
            error.error.message
          ],
          cancelText: 'Cancel',
          confirmText: 'Authorized Holds',
          reversed: true,
        }).subscribe(resp => {
          if (resp) { 
            if (resp === true){
              this.router.navigate(['profile', 'new-holds-authorization']);
            }
          }
        });
      } else if (error.status === 500){
        this.router.navigate(['500']);
      }      
    });
  }

  public onBack(): void {
    this.router.navigate(['profile', 'loans']);
  }

  // Open modal for adding credit card/bank account
  public openAddNew() {
    let modal: Observable<any>;
    if (this.paymentSource$.getValue() === EPaymentSource.CreditCard) {
      modal = this.modalService.openAddNewCreditCard();
    } else if (this.paymentSource$.getValue() === EPaymentSource.BankAccount) {
      modal = this.modalService.open(AddBankAccountModalComponent);
    }
    modal.subscribe(resp => {
      if (resp) { this.reload.emit(); }
    })
  }

  public openChangeCardModal() {
    this.modalService.openChangeCreditCard().subscribe(output => {
      if (!output) { return; }

      if (output.action === 'AddNew') {
        this.modalService.openAddNewCreditCard().subscribe(resp => {
          if (resp) { this.reload.emit(); }
        });
      }

      if (output.action === 'Submit') {
        this.paymentSource$.next(EPaymentSource.CreditCard);
        this.form.get('creditCard').setValue(output.id);
      }
    });
  }

  public openChangeBankModal() {
    this.modalService.openChangeAch().subscribe(output => {
      if (!output) { return; }

      if (output.action === 'AddNew') {
        this.modalService.open(AddBankAccountModalComponent).subscribe(updated => {
          if (updated) { this.reload.emit(); }
        });
      }

      if (output.action === 'Submit') {
        this.paymentSource$.next(EPaymentSource.BankAccount);
        this.form.get('bankAccount').setValue(output.id);
      }
    });
  }

  public openTermsOfUse() {
    this.modalService.openInfo({
      title: 'Authorization for One-Time Automated Payments',
      description: authorizationForOneTimeAutomatedPayments
    }).subscribe();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      creditCard: [''],
      bankAccount: [''],
      type: [this.paymentType$.getValue(), [Validators.required]],
      amount: ['0', [Validators.required]],
      payoffAmount: [''],
      date: [new Date(), [Validators.required]],
    });
  }
  private handleTypeChange(type: EPaymentTypes) {
    this.paymentType$.next(type);
    if (type === EPaymentTypes.AdditionalPrincipal) {
      this.paymentSource$.next(EPaymentSource.BankAccount);
    }
  }

  private openConfirmModal() {
    return this.modalService.openConfirm({
      confirmText: 'Submit',
      description: [
        `Your next payment is scheduled on Apex-Pay for ${parseDate(this.firstNextPayment.due_date)} for ${this.firstNextPayment.payment}`,
        'Are you sure you want to make a manual payment?'
      ]
    });
  }
}
