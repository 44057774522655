import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

export interface IHolds {
  loan?: number,
  token?: string
}

export interface IPendingHolds {
  amount?: number,
  period?: number,
  submitted: string,
  status: string,
  notes?: string,
  type: string,
  loan?: number
}

@Injectable({
  providedIn: 'root'
})

export class HoldsService {
  public token: string = null;
 
  constructor(private apiService: ApiService) {
    this.token = localStorage.getItem('token');
  }

  public setToken(token: string) {
    token ? localStorage.setItem('token', token) : localStorage.removeItem('token');
  }
  
  acceptHolds(loan: number) {
    return this.apiService.post(`holds/accept`, {loan});
  }
  getPendingHolds(){
    return this.apiService.get<IPendingHolds[]>('holds')
  }
}
