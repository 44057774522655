import { Routes } from '@angular/router';
import { CreditCardInformationContainerComponent } from './containers/credit-card-information-container/credit-card-information-container.component';
import { DebtToIncomeComparisonContainerComponent } from './containers/debt-to-income-comparison-container/debt-to-income-comparison-container.component';
import { EmploymentInformationContainerComponent } from './containers/employment-information-container/employment-information-container.component';
import { FicoComparisonContainerComponent } from './containers/fico-comparison-container/fico-comparison-container.component';
import { InformationConfirmContainerComponent } from './containers/information-confirm-container/information-confirm-container.component';
import { InformationContainerComponent } from './containers/information-container/information-container.component';
import { MonthlyPaymentsInformationContainerComponent } from './containers/monthly-payments-information-container/monthly-payments-information-container.component';
import { AuthForStepsGuard } from '../shared/guards/auth-for-steps.guard';
import { StepsGuard } from '../shared/guards/steps.guard';

export const steps: Routes = [
  {
    path: '',
    redirectTo: 'validate',
    pathMatch: 'full'
  },
  {
    path: 'validate',
    component: InformationContainerComponent,
    data: { step: 2 }
  },
  {
    path: 'confirm',
    component: InformationConfirmContainerComponent,
    data: { step: 3 },
    canActivate: [AuthForStepsGuard, StepsGuard]
  },
  {
    path: 'card',
    component: CreditCardInformationContainerComponent,
    data: { step: 4 },
    canActivate: [AuthForStepsGuard, StepsGuard]
  },
  {
    path: 'employment',
    component: EmploymentInformationContainerComponent,
    data: { step: 5 },
    canActivate: [AuthForStepsGuard, StepsGuard]
  },
  {
    path: 'payments',
    component: MonthlyPaymentsInformationContainerComponent,
    data: { step: 6 },
    canActivate: [AuthForStepsGuard, StepsGuard]
  },
  {
    path: 'dti',
    component: DebtToIncomeComparisonContainerComponent,
    data: { step: 7 },
    canActivate: [AuthForStepsGuard, StepsGuard]
  },
  {
    path: 'loan-options',
    component: FicoComparisonContainerComponent,
    data: { step: 8, lockStepper: true },
    canActivate: [AuthForStepsGuard, StepsGuard]
  }
];
