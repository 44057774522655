import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { CARD_TYPE, createCardTypesForHold, disabledCardTypesHold } from 'src/app/shared/constants/select-data.constants';
import { BehaviorSubject } from 'rxjs';
import { IApplication } from '../../../user/models/user.models';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { FaqCreditCardModalComponent } from 'src/app/shared/entry/faq-credit-card-modal/faq-credit-card-modal.component';
import { parseMin, parseMax } from 'src/app/shared/validators/parse-number.validator';

@Component({
  selector: 'app-credit-card-information-form',
  templateUrl: './credit-card-information-form.component.html',
  styleUrls: ['./credit-card-information-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditCardInformationFormComponent implements OnChanges {
  @Input() application: IApplication;
  @Output() submitted = new EventEmitter<any>();
  @Input() spinner: boolean;
  @Input() requestErrors: any;
  public requestPending = false;
  public progressBar: boolean = true;
  public form: FormGroup;
  public cardTypesPayOff = CARD_TYPE;
  public cardTypesHold = createCardTypesForHold();
  public payoffAmountLessFiveDigits: boolean;

  public samePayOffCard$ = new BehaviorSubject<boolean>(false);
  public availableCreditTouched$ = new BehaviorSubject<boolean>(false);
  public payoffAmountTouched$ = new BehaviorSubject<boolean>(false);
  public loading$ = new BehaviorSubject<boolean>(true);

  public get isPayoffCardIncompatibleForHold(): boolean {
    return disabledCardTypesHold.includes(this.form.get('desired_payoff_account').value);
  }

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      this.initForm();
    }

    if (changes.requestErrors && this.requestErrors) {
      this.requestPending = false;
      this.spinner = false;
    }

    if (changes.application && changes.application.currentValue) {
      this.loading$.next(false);
      const application = <IApplication>changes.application.currentValue;
      this.toggleSamePayOffCard(application.same_payoff_hold_account || false);

      this.form.patchValue({
        desired_payoff_account: this.findCardType(application.desired_payoff_account),
        amount: application.amount ? `${application.amount}` : '',
        desired_hold_card: this.findCardType(application.desired_hold_card),
        hold_card_limit: application.hold_card_limit ? `${application.hold_card_limit}` : '',
        total_credit_limit: application.total_credit_limit ? `${application.total_credit_limit}` : '0',
        cash_advance_limit: application.cash_advance_limit ? `${application.cash_advance_limit}` : '0',
        available_cash_advance: application.available_cash_advance ? `${application.available_cash_advance}` : '0'
      });
      this.progressBar = false;
    }
  }

  public onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) { return; }
    
    this.requestPending = true;
    this.spinner = true;
    const form = { ...this.form.value };

    form.same_payoff_hold_account = this.samePayOffCard$.value;
    form.desired_payoff_account = form.desired_payoff_account.substring(0, 1);
    form.amount = parseFloat(form.amount.replace(/,/g, ''));
    form.total_credit_limit = Number(form.total_credit_limit);
    form.cash_advance_limit = Number(form.cash_advance_limit);
    form.available_cash_advance = Number(form.available_cash_advance);

    if (!form.same_payoff_hold_account) {
      form.desired_hold_card = form.desired_hold_card.substring(0, 1);
      form.hold_card_limit = parseFloat(form.hold_card_limit.replace(/,/g, ''));
    } else {
      form.desired_hold_card = form.desired_payoff_account;
      delete form.hold_card_limit;
    }

    this.submitted.emit(form);
  }

  private initForm() {
    this.form = this.formBuilder.group(
      {
        desired_payoff_account: ['', [Validators.required]],
        amount: ['', [Validators.required, this.validatePayoffAmount.bind(this)]],
        desired_hold_card: ['', [Validators.required]],
        hold_card_limit: ['', [Validators.required, this.validateAvailableCredit.bind(this)]],
        total_credit_limit: ['', [parseMax(1000000)]],
        cash_advance_limit: ['', [parseMax(1000000)]],
        available_cash_advance: ['', [parseMax(1000000)]]
      },
      {validators: [this.validateForm.bind(this)]}
    );

    this.form.controls.amount.valueChanges.subscribe(
      (amount: string) => {
        this.payoffAmountLessFiveDigits = amount.length < 5;
        if (!this.samePayOffCard$.value) {
          this.availableCreditTouched$.next(true);
          this.form.controls.hold_card_limit.updateValueAndValidity();
        }
      }
    );

    this.form.controls.desired_payoff_account.valueChanges.subscribe(() => {
      if (this.isPayoffCardIncompatibleForHold) { this.toggleSamePayOffCard(false); }
      if (this.samePayOffCard$.value) {
        const payOffCardType = this.form.get('desired_payoff_account').value;
        this.form.get('desired_hold_card').setValue(payOffCardType);
      }
    });
  }

  public toggleSamePayOffCard(value: boolean): void {
    this.samePayOffCard$.next(value);
    if (value) {
      const payOffCardType = this.form.get('desired_payoff_account').value;
      this.form.get('desired_hold_card').setValue(payOffCardType);
    }
    this.toggleHoldCardValidators(value);
  }

  public getCardError(usageType: string) {
    if (usageType === 'payoff') {
      const control = this.form.controls.amount;
      if (control.getError('tooBig')) {
        return 'Maximum Amount to Pay Off is $40,000.';
      } else if (control.getError('tooSmall')) {
        return 'Minimum Amount to Pay Off is $500.';
      } else if (control.getError('required')) {
        return 'This field is required.';
      }
    } else if (usageType === 'hold') {
      const control = this.form.controls.hold_card_limit;
      if (control.getError('tooBig')) {
        return 'Available Credit has to be $1,000,000 or less.';
      } else if (control.getError('tooSmall')) {
        return 'Available Credit should be equal or more than Amount to Pay Off.';
      } else if (control.getError('required')) {
        return 'This field is required.';
      }
    }
    return 'Invalid Value';
  }

  public onHintClick(linkId?: string) {
    this.modalService.open(FaqCreditCardModalComponent, linkId);
  }

  private findCardType(firstLetter: string) {
    return Object.keys(CARD_TYPE).find(key => CARD_TYPE[key].substring(0, 1) === firstLetter);
  }

  private validatePayoffAmount(control: FormControl) {
    if (control.value) {
      const payoffAmount = parseFloat(control.value.replace(/[^\d\.]/gi, ''));
      if (payoffAmount) {
        if (payoffAmount < 500) {
          this.payoffAmountTouched$.next(true);
          return { tooSmall: true };
        } else if (payoffAmount > 40000) {
          this.payoffAmountTouched$.next(true);
          return { tooBig: true };
        }
        this.payoffAmountTouched$.next(false);
        return null;
      }
    } else {
      this.payoffAmountTouched$.next(false);
      return null;
    }
  }

  private validateAvailableCredit(control: FormControl) {
    if (control.value) {
      const availableCredit = Number(control.value);
      const payoffAmount = this.form.controls.amount;
      const payoffAmountValue = Number(payoffAmount.value);
      if (availableCredit) {
        if (availableCredit > 1000000) {
          this.availableCreditTouched$.next(true);
          return { tooBig: true };
        } else if (availableCredit < payoffAmountValue) {
          this.availableCreditTouched$.next(true);
          return { tooSmall: true }
        }
        this.availableCreditTouched$.next(false);
        return null;
      }
    } else {
      this.availableCreditTouched$.next(false);
      return null;
    }
  }

  private toggleHoldCardValidators(sameCard: boolean) {
    const { desired_hold_card, hold_card_limit } = this.form.controls;
    if (sameCard) {
      desired_hold_card.clearValidators();
      hold_card_limit.clearValidators();
    } else {
      desired_hold_card.setValidators([Validators.required]);
      hold_card_limit.setValidators([Validators.required, this.validateAvailableCredit.bind(this)]);
    }
    desired_hold_card.updateValueAndValidity();
    hold_card_limit.updateValueAndValidity();
  }

  private validateForm(group: FormGroup): ValidationErrors {
    const amount = Number(group.value.amount);
    const cashAdvanceLimit = Number(group.value.cash_advance_limit);
    const totalCreditLimit = Number(group.value.total_credit_limit);
    const availableCashAdvance = Number(group.value.available_cash_advance);
    const holdCardLimit = Number(group.value.hold_card_limit);
    const samePayOffCard = this.samePayOffCard$.value;
    const errors: ValidationErrors = {
      totalCreditLimitLessThenPayoff: totalCreditLimit < amount,
      cashAdvanceLimit20Percent: samePayOffCard && cashAdvanceLimit / 0.2 < amount,
      availableCash20Percent: !samePayOffCard && availableCashAdvance / 0.2 < amount,
      cashAdvanceLimitLessThenAvailableCash: cashAdvanceLimit < availableCashAdvance,
      totalCreditLimitLessThenHoldCardLimit: !samePayOffCard && totalCreditLimit < holdCardLimit,
      cashAdvanceLimitLessThenTotalCreditLimit: cashAdvanceLimit > totalCreditLimit,
      availableCashAdvanceLessThenTotalCreditLimit: availableCashAdvance > totalCreditLimit
    };
    Object.entries(errors).forEach(([key, value]) => {
      if (!value) { delete errors[key]; }
    });
    return errors;
  }
}
