<div class="titled-modal-content">
  <div class="al-modal-title">New Credit Card</div>
  <div class="modal-content" mat-dialog-content>
    <form [formGroup]="form" class="form">
      <app-select
        class="input"
        label="Credit Card Type"
        formControlName="card_type"
        errorMessage="Incorrect Credit Card Type"
        [options]="cardTypes"
        [id]="'new_card_type'"
      ></app-select>
      <app-input
        class="input"
        label="Available Credit"
        formControlName="available_credit"
        [errorMessage]="getAvailableCreditError()"
        prefix="$"
        ngxMask="comma_separator.2"
        [id]="'new_available_credit'"
      ></app-input>
      <app-input
        class="input"
        label="Name on Card"
        formControlName="name"
        errorMessage="Missing Name on Card"
        [warning]="form.get('name').warnings?.tooLong && 'Name on Card is too long.'"
        [id]="'new_name'"
      ></app-input>
      <app-input
        class="input"
        label="Credit Card Number"
        formControlName="number"
        errorMessage="Incorrect Credit Card Number"
        ngxMask="0000 0000 0000 0000"
        [validateErrors]="form.getError('creditCardTypeNumber')"
        [warning]="form.warnings.creditCardTypeNumber"
        [id]="'new_credit_card'"
      ></app-input>
      <app-input
        class="input"
        label="Expiration Date"
        formControlName="expiration_date"
        errorMessage="Incorrect Expiration Date"
        ngxMask="00/00"
        placeholder="MM/YY"
        [id]="'new_expiration_date'"
      ></app-input>
      <app-input
        class="input"
        label="Security Code"
        formControlName="security_code"
        errorMessage="Incorrect Security Code"
        ngxMask="0000"
        [id]="'new_security_code'"
      ></app-input>
    </form>
    <div class="form__bottom-controls">
      <app-button
        class="form__bottom-btn"
        color="white"
        (pressed)="dialogRef.close()"
        [id]="'new_credit_bank'"
      >Back</app-button>
      <app-button
        class="form__bottom-btn"
        [isDisabled]="form.invalid"
        (pressed)="onSubmit()"
        [id]="'new_credit_submit'"
      >Submit</app-button>
    </div>
  </div>
</div>
