import { Injectable } from '@angular/core';
import { PrivacyPolicyService, IConsent } from 'src/app/user/services/privacy-policy/privacy-policy.service';
import { EConsent } from '../../models/enums/consent.enum';
import { map, toArray, concatMap } from 'rxjs/operators';
import { ILinkToken, IPlaidSuccessCallbackArguments, PlaidService } from '../plaid/plaid.service';
import { ModalService } from '../modal/modal.service';
import { PlaidAccountObject } from 'ngx-plaid-link/lib/interfaces';
import { FinalPaymentsPlaidAccountsModalComponent } from '../../entry/final-payments-plaid-accounts-modal/final-payments-plaid-accounts-modal.component';
import { EBankAccountType } from '../../models/enums/bank-account-type.enum';
import { EPlaidAccountObjectSubtype } from '../../models/enums/plaid.enum';
import { ExcludeReadonly } from '../../types/exclude-readonly.type';
import { IBankAccount } from '../../models/customer.model';
import { forkJoin, concat, Observable } from 'rxjs';
import { CustomerService } from '../customer/customer.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApexPayService {

  private plaidSuccessData: IPlaidSuccessCallbackArguments;
  private consentsSwitchable = [EConsent.RecurringAch, EConsent.PlaidBalanceCheck];
  private consentsUnswitchable = [EConsent.Plaid];
  public link_token: ILinkToken;

  private get allConsentTypes(): EConsent[] {
    return [...this.consentsSwitchable, ...this.consentsUnswitchable];
  }

  constructor(
    private privacyPolicyService: PrivacyPolicyService,
    private plaidService: PlaidService,
    private modalService: ModalService,
    private customerService: CustomerService,
    private $storage: StorageService
  ) {}

  public isApexPayOn(): Observable<boolean> {
    return this.getConsents().pipe(map(arr => {
      const found = this.allConsentTypes.map(type => arr.find(i => i.consent.name === type));
      return found.every(i => i && i.accepted);
    }));
  }

  public wasApexPayOn(): Observable<boolean> {
    return this.getConsents().pipe(map(arr => {
      const found = this.consentsUnswitchable.map(type => arr.find(i => i.consent.name === type));
      return found.every(i => i && i.accepted);
    }));
  }

  public setApexPay(value: boolean): Observable<unknown> { // TODO: remove unknown
    const consents$ = this.consentsSwitchable.map(t => this.privacyPolicyService.saveConsent(t, value));
    return forkJoin(consents$);
  }

  public startApexPaySignUp() {
    if (this.plaidSuccessData) {
      return this.openPlaidAccountSelection(this.plaidSuccessData);
    }

    return new Observable(observer => {
      this.plaidService.createAndOpen(args => {
        this.plaidSuccessData = args;
        this.openPlaidAccountSelection(args).subscribe({
          next: () => { observer.next(); observer.complete(); },
          error: () => observer.error()
        });
      }, () => observer.error());
    });
  }

  public deletePlaidCache() {
    this.plaidSuccessData = undefined;
  }

  private openPlaidAccountSelection(args: IPlaidSuccessCallbackArguments) {
    const { token, metadata } = args;
    const modal$ = this.modalService.open<PlaidAccountObject>(FinalPaymentsPlaidAccountsModalComponent, metadata.accounts);
    return modal$.pipe(concatMap(account => {
      if (!account) { throw new Error(); }
      let type: EBankAccountType;
      switch (account.subtype as EPlaidAccountObjectSubtype) {
        case EPlaidAccountObjectSubtype.Checking: { type = EBankAccountType.Checking; break; }
        case EPlaidAccountObjectSubtype.Savings: { type = EBankAccountType.Savings; break; }
      }
      const request: ExcludeReadonly<IBankAccount> = {
        public_token: token,
        account_id: account.id,
        account_number: account.mask,
        type
      };
      const consents$ = this.allConsentTypes.map(t => this.privacyPolicyService.saveConsent(t));
      return concat(
        forkJoin(consents$),
        this.customerService.postBankAccount(request)
      ).pipe(toArray());
    }));
  }

  private getConsents(): Observable<IConsent[]> {
    return this.privacyPolicyService.getConsent();
  }

  public plaidLinkToken() {
    const now = new Date()
    const currentTime = now.getTime();
    const linkToken = this.$storage.get('plaid_link_token');
    if (linkToken == null || (linkToken && currentTime > linkToken.expiry)){
      this.$storage.remove('plaid_link_token');
      this.customerService.getLinkToken().subscribe((token) => {
        const key = 'plaid_link_token';
        this.link_token = token;
        const item = {
					value: this.link_token.link_token,
					expiry: now.getTime() + 900000
				}
        this.$storage.set(key, item);
      })
    }    
  }
}
