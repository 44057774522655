<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<app-form-title
  [loading]="loading$ | async"
  *ngIf="!isProfilePage else profileInfoHeader"
>Please confirm your information</app-form-title>

<ng-template #profileInfoHeader>
  <app-form-title
    [loading]="loading$ | async"
  >Your Personal Information</app-form-title>
</ng-template>

<form [formGroup]="form" class="form" *ngIf="form">
  <app-base-form-layout>
    <app-input
      [formControl]="(form?.controls)['customer'].controls.firstName"
      class="input"
      label="First Name"
      [errorMessage]="getErrorMessage('firstName')"
      [warning]="getWarningMessage('firstName')"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"
      [id]="'fName'"
      leftColumn
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['customer'].controls.lastName"
      class="input"
      label="Last Name"
      [errorMessage]="getErrorMessage('lastName')"
      [warning]="getWarningMessage('lastName')"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"
      [id]="'lName'"
      leftColumn
    ></app-input>
    <app-datepicker
      [formControl]="(form?.controls)['customer'].controls.dateOfBirth"
      class="input"
      label="Date of Birth"
      errorMessage="Missing Birth Date"
      [forceError]="(form?.controls)['customer'].controls.dateOfBirth.invalid"
      [warning]="getWarningMessage('dateOfBirth')"
      [min]="minDate"
      [max]="eighteenYearsAgoDate"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="!isProfilePage"
      [isDisabled]="isProfilePage"
      [id]="'dateOfBirth'"
      leftColumn
    ></app-datepicker>
    <app-input
      [formControl]="(form?.controls)['customer'].controls.email"
      class="input"
      label="Email Address"
      [errorMessage]="getErrorMessage('email')"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"
      [id]="'customerEmail'"
      leftColumn
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['customer'].controls.alternateEmail"
      class="input"
      label="Alternate Email Address"
      errorMessage="Invalid Email Address format"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [id]="'customeralternativeEmail'"
      leftColumn
    ></app-input>
    <app-select
      [formControl]="(form?.controls)['customer'].controls.citizenship"
      [options]="CITIZENSHIP"
      class="input"
      errorMessage="Missing Citizenship"
      label="Citizenship"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"  
      [id]="'citizenship'"  
      leftColumn
    ></app-select>
    <app-secret-input
      class="input"
      label="Social Security Number"
      ngxMask="000-00-0000"
      [hasHiddenInput]="true"
      [formControl]="(form?.controls)['customer'].controls.ssn"
      [errorMessage]="getErrorMessage('ssn')"
      [hidden]="withoutAuth"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isDisabled]="true"
      leftColumn
      [id]="'SocialSecurityNumber'"
    ><span>If you believe your SSN is incorrect, please <a class="open-contacts-link" (click)="openContactsModal()">CONTACT US</a></span>
    </app-secret-input>
    <app-input
      [formControl]="(form?.controls)['customer'].controls['address'].controls.address1"
      class="input"
      label="Residence Address 1"
      errorMessage="Missing Address"
      [warning]="getWarningMessage('address1')"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"
      [id]="'address1'"
      rightColumn
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['customer'].controls['address'].controls.address2"
      class="input"
      label="Residence Address 2"
      [warning]="getWarningMessage('address2')"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [id]="'address2'"
      rightColumn
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['customer'].controls['address'].controls.city"
      class="input"
      label="City"
      [errorMessage]="getErrorMessage('city')"
      [warning]="getWarningMessage('city')"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"
      rightColumn
      [id]="'city'"
    ></app-input>
    <app-select
      [formControl]="(form?.controls)['customer'].controls['address'].controls.state"
      class="input"
      [options]="STATES"
      label="State"
      errorMessage="Missing State"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"
      [id]="'state'"
      rightColumn
    ></app-select>
    <app-input
      [formControl]="(form?.controls)['customer'].controls['address'].controls.zipCode"
      class="input"
      label="Residence Zip Code"
      type="tel"
      [errorMessage]="getErrorMessage('zipCode')"
      [warning]="(displayZipCodeWarnings$ | async) ? getWarningMessage('state') : ''"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"
      [id]="'zipCode'"
      ngxMask="00000"
      rightColumn
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['customer'].controls.homePhone"
      class="input"
      label="Home Phone"
      type="tel"
      [errorMessage]="getErrorMessage('homePhone')"
      [warning]="(displayZipCodeWarnings$ | async) ? getWarningMessage('homePhone') : ''"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"
      ngxMask="000-000-0000"
      [id]="'homePhone'"
      rightColumn
    ></app-input>
    <app-input
      [formControl]="(form?.controls)['customer'].controls.cellPhone"
      class="input"
      label="Cell Phone"
      type="tel"
      [errorMessage]="getErrorMessage('cellPhone')"
      [warning]="(displayZipCodeWarnings$ | async) ? getWarningMessage('cellPhone') : ''"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"
      ngxMask="000-000-0000"
      [id]="'cellPhone'"
      rightColumn
    ></app-input>
    <app-select
      [formControl]="(form?.controls)['customer'].controls.homeOwnership"
      class="input"
      label="Home Ownership"
      errorMessage="Missing Home Ownership"
      [options]="HOME_OWNERSHIP"
      [isPreview]="isProfilePage && isPreview"
      [loading]="loading$ | async"
      [isRequired]="true"
      [id]="'homeOwnership'"
      rightColumn
    ></app-select>
    <p 
      *ngIf="!isPreview && !(loading$ | async)"
      class="info-required" 
      leftColumn  
    ><span class="info-required__star">*</span> Required</p>
  </app-base-form-layout>

  <ng-container *ngIf="!withoutAuth">
    <app-form-description [loading]="loading$ | async">
      Please create your account using your email address as your user name and a password to save your progress.
    </app-form-description>
    <app-base-form-layout>
      <app-input
        [formControl]="(form?.controls)['auth'].controls.email"
        label="Email Address"
        class="input"
        errorMessage="Incorrect Email Address"
        [isDisabled]="authenticated"        
        [isPreview]="isProfilePage && isPreview"
        [loading]="loading$ | async"
        [isRequired]="true"
        [id]="'userEmail'"
        leftColumn
      ></app-input>
      <!-- [warning]="(form?.controls)['auth'].controls.email.value !== (form?.controls)['customer'].controls.email.value ? 'Are you sure?' : ''" -->
      <app-input
        [formControl]="(form?.controls)['auth'].controls.password"
        label="Password"
        class="input"
        type="password"
        [placeholder]="authenticated ? '******' : ''"
        errorMessage="Password should contain at least 8 characters"
        [isDisabled]="authenticated"
        [isPreview]="isProfilePage && isPreview"
        [loading]="loading$ | async"
        [isRequired]="true"
        [id]="'password'"
        rightColumn
      ></app-input>
      <app-input
        [formControl]="(form?.controls)['auth'].controls.repeatPassword"
        label="Confirm password"
        class="input"
        type="password"
        [placeholder]="authenticated ? '******' : ''"
        errorMessage="Passwords do not match"
        [isDisabled]="authenticated"
        [isPreview]="isProfilePage && isPreview"
        [loading]="loading$ | async"
        [isRequired]="true"
        rightColumn
        [id]="'repeatPassword'"
      ></app-input>
    </app-base-form-layout>
  </ng-container>
</form>

<ng-container *ngIf="isProfilePage && isPreview">
  <app-button styleType="form-submit" (pressed)="isPreview = !isPreview" [loading]="loading$ | async">Edit</app-button>
</ng-container>

<div *ngIf="isProfilePage && isPreview === false" class="controls">
  <app-button class="controls__btn" (pressed)="onCancel()" [id]="'Cancel_btn'" color="white">Cancel</app-button>
  <app-button
    class="controls__btn"
    styleType="form-submit"
    (pressed)="onSubmit()"
    [loading]="loading$ | async"
    [pending]="requestPending"
    [spinner]="spinner"
    [id]="'save_btn'"
    >Save</app-button>
</div>

<app-button
  *ngIf="!isProfilePage"
  styleType="form-submit"
  (pressed)="onSubmit()"
  [loading]="loading$ | async"
  [pending]="requestPending"
  [spinner]="spinner"
  [id]="'save_btn1'"
  >Save</app-button>

<app-form-description [loading]="loading$ | async" class="bottom-description" *ngIf="!isProfilePage">
  You will receive a confirmation email to verify your account.
</app-form-description>
