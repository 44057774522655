<div class="controls">
  <app-button
    class="controls__button"
    fadedIfActive="true"
    (pressed)="onAccepted()"
    *ngIf="(consentsReady$ | async) && (userService.isLoggedInForSteps || userService.isLoggedIn)"
  >Accept</app-button>
  <app-button class="controls__button" (pressed)="onSavePdf()">Save in PDF</app-button>
</div>
<div id="pdf-wrapper">
  <h1>CONSENT TO ELECTRONIC TRANSACTIONS AND DISCLOSURES</h1>
  <p>
    ApexLend LLC d/b/a ApexLend is an Internet-only lender operating at
    <a href="https://www.apexlend.com">www.apexlend.com</a> and any sub-domain thereof (collectively the “Site”).
    Therefore, to conduct business with ApexLend, you need to agree to do business with us online and electronically.
    You also need to agree to receive all communications, disclosures, documents, agreements, contracts, and notices
    (collectively, “Disclosures”) electronically, either via our Site or to the email address you provide to us. By
    agreeing to the Terms of Use, you agree to receive all Disclosures electronically arising from or relating to your
    use of the Site and Service, including any loans you may request or receive and the loan servicing as a borrower of
    ApexLend,whether we are acting in the capacity as trustee, servicer or otherwise. The decision to do business with
    us electronically is yours. This document informs you of your rights concerning Disclosures.
  </p>

  <h2>Communications.</h2>
  <p>
    To conduct business with ApexLend, you must agree to the Terms of Use and by agreeing, you expressly agree that
    ApexLend, our agents, employees, affiliates, representatives, or anyone else on our behalf (collectively, “ApexLend
    Agents”) may communicate with you for any and all purposes, at any telephone number, physical address or email
    address which you provided to us or we can reasonably associate with your account (through skip trace, caller ID
    capture or other means). You expressly consent to us contacting you in any way, including SMS message, prerecorded
    message, artificial voice, or person to person calls, auto telephone dialing system call sand automatic texting
    system messages. When the telephone is answered by you or anyone else, automated messages may be played. ApexLend
    Agents may also leave a message on your answering machine, voice mail, or send one via text.
  </p>
  <p>
    You represent, certify and warrant that you are permitted to receive calls at each of the telephone numbers you have
    provided to us and that the numbers are yours. You agree to alert us whenever you stop using a particular telephone
    number.
  </p>
  <p>
    You agree that the cellular or mobile or landline telephone provider may charge you according to the plan for any
    calls or texts to that phone number.
  </p>

  <h2>Call Recording and Monitoring.</h2>
  <p>
    For any calls or chats between ApexLend Agents and you, you agree to being recorded and monitored, for quality
    assurance, training, risk management and/or collection purposes.
  </p>

  <h2>Electronic Communications.</h2>
  <p>
    All Disclosures will be sent to you electronically through our Site or to the email address you provided. For paper
    copies of Disclosures, you may print the documents by logging in to your account on our Site or you may write to us
    at the mailing address provided below and we can provide you a paper copy if requested. A paper copy request of any
    Disclosure will not be considered a withdrawal of your consent to receive electronic Disclosures. We may discontinue
    electronic Disclosures at any time in our sole discretion.
  </p>

  <h2>Scope of Consent.</h2>
  <p>
    You consent to receive all Disclosures and transact all business with ApexLend electronically. Your consent will
    remain in effect for so long as you are a customer and, even if you stop being a customer, the consent will continue
    until the end of all Disclosures relevant to transactions that occurred while you were a customer.
  </p>

  <h2>Consenting to Do Business Electronically.</h2>
  <p>
    You agree that all Disclosures from ApexLend relating to the use of the Site will be delivered electronically
    through the Site or to the email address you have provided. Before doing business electronically with ApexLend,
    please take into consideration the hardware and software requirements below.
  </p>

  <h2>Hardware and Software Requirements.</h2>
  <p>
    Hardware and Software Requirements. To access and retain Disclosures electronically, you should meet the following
    minimum computer hardware and software requirements or better: Internet access; email account and related software;
    supported Web browsing software (like Chrome version 69.0 or higher, Firefox version 63.0 or higher, Internet
    Explorer version 11.0 or higher, Microsoft Edge or Safari version 11.0 or higher); and capable hardware. To ensure
    access and ability to print your loan documents in PDF format, you should also have Adobe Reader or equivalent
    software. To install the free version of Adobe Reader, click here. You hereby acknowledge that you have the
    requisite hardware and software and therefore can access the electronic Disclosures in the designated formats
    described.
  </p>

  <h2>Mobile Technology Requirements.</h2>
  <p>
    If you are accessing our Site and the Disclosures electronically via a mobile device (for example a smart phone or a
    tablet), you must make sure that you meet the requirements above and also have mobile device software to print and
    save the Disclosures. Such applications may be available in the device's "app store". You must access our Site
    through a device that has these capabilities.
  </p>

  <h2>Assignment.</h2>
  <p>
    In addition, you further acknowledge that your consent to have all Disclosures electronically and to do business on
    the Site is assignable to any entity that succeeds ApexLend in servicing your loan, and/or purchases any part of the
    agreement evidencing a loan you obtained through ApexLend.
  </p>

  <h2>Contact Us.</h2>
  <p>
    You can contact us with any questions regarding Electronic Disclosures via email at
    <a href="mailto:compliance@apexlend.com">compliance@apexlend.com</a> with the subject “E Disclosure” or by calling
    us at (833) 4-APEXLEND or (833) 427-3953. You may also reach us in writing to us at the following address: ApexLend
    LLC, 16192 Coastal Highway, Lewes, Delaware 19958 USA, Attention: E Disclosure.
  </p>

  <h2>Change in Your Contact Information.</h2>
  <p>
    You promise to keep us informed of any change in your email or home mailing address such that all Disclosures will
    reach you in a timely fashion. If the email address you registered on the Site changes, you must notify us of the
    change by sending an email to <a href="mailto:tohelp@apexlend.com">help@apexlend.com</a> or calling (833) 4-APEXLEND
    or (833) 427-3953 or by logging into your user account at the Site and changing your email address in the personal
    information section. You also promise to update your residence address and telephone numbers on the Site if they
    change.
  </p>

  <h2>Miscellaneous.</h2>
  <p>
    Print and/or save a copy of this consent for your records. You also agree and confirm that you can access, receive
    and retain all Disclosures electronically sent via email, SMS messages, or posted on the Site. You acknowledge that
    we may amend this policy at any time by posting a revised version on our website. If we make a material change to
    this policy, we will also send a notice to your email address with ApexLend.
  </p>

  <h2>Consent Withdrawal.</h2>
  <p>
    You may withdraw your consent by contacting us via email at
    <a href="mailto:compliance@apexlend.com">compliance@apexlend.com</a> with the subject “E CONSENT” or by calling us
    at (833) 4-APEXLEND or (833) 427-3953 on Monday through Friday that are not holidays during 9:00 AM PST to 5:00 PM
    PST. You may also write to us at the following address: ApexLend LLC, 16192 Coastal Highway, Lewes, Delaware 19958
    USA, Attention: E CONSENT. However, once you have withdrawn your consent you will not be able to borrow on our Site
    anymore. Any of your pending loans on our Site will be terminated and removed from our system. Any loan you received
    prior to the Consent Withdrawal will adhere to all previously agreed to terms and conditions and we will send
    Disclosures to your home address provided during registration.
  </p>
</div>
