import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlaidAccountObject } from 'ngx-plaid-link/lib/interfaces';
import { FormBuilder,  } from '@angular/forms';
import { ISmartOption } from '../../components/select/select.component';
import { EPlaidAccountObjectSubtype } from '../../models/enums/plaid.enum';
import { ModalService } from '../../services/modal/modal.service';
import { confirmSavingsBankAccountModalDescription } from '../../constants/text.constants';

@Component({
  selector: 'app-final-payments-plaid-accounts-modal',
  templateUrl: './final-payments-plaid-accounts-modal.component.html',
  styleUrls: ['./final-payments-plaid-accounts-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalPaymentsPlaidAccountsModalComponent implements OnInit {

  public formControl: FormControlTyped<PlaidAccountObject>;
  public options: ISmartOption[] = [];

  public get hasOptions(): boolean {
    return Boolean(this.options && this.options.length);
  }

  private plaidAcceptedSubtypes = [
    EPlaidAccountObjectSubtype.Checking,
    EPlaidAccountObjectSubtype.Savings
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PlaidAccountObject[],
    private dialogRef: MatDialogRef<FinalPaymentsPlaidAccountsModalComponent>,
    private fb: FormBuilder,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.formControl = this.fb.control(null);
    if (this.data && this.data.length > 0) {
      const filteredAccounts = this.data.filter(item => {
        return this.plaidAcceptedSubtypes.includes(item.subtype as EPlaidAccountObjectSubtype);
      });
      this.options = filteredAccounts.map(item => ({
        key: item, value: `${item.name} (${item.mask}, ${item.subtype})`
      }));
    }
  }

  onSubmit() {
    const value = this.formControl.value;
    if (value.subtype === EPlaidAccountObjectSubtype.Savings) {
      this.modalService.openConfirm({
        description: confirmSavingsBankAccountModalDescription,
        cancelText: 'No',
        confirmText: 'Yes',
        reversed: true
      }).subscribe(resp => {
        if (resp) { this.dialogRef.close(this.formControl.value); }
      });
    } else {
      this.dialogRef.close(this.formControl.value);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
