import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapsed-group',
  templateUrl: './collapsed-group.component.html',
  styleUrls: ['./collapsed-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsedGroupComponent implements OnInit {
  @Input() label: string;
  @Input() labelOnlyForMobiles = false;
  @Input() loading: boolean;
  public opened = false;

  constructor() {}

  ngOnInit() {}
}
