import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IBankAccount } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { PCI_DSS_LINK } from 'src/app/shared/constants/common.constants';
import { Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { FinalPaymentsManualModalComponent } from 'src/app/shared/entry/final-payments-manual-modal/final-payments-manual-modal.component';
import { FormControl } from '@angular/forms';
import { ApexPayService } from 'src/app/shared/services/apexpay/apexpay.service';

@Component({
  selector: 'app-final-payments-method-container',
  templateUrl: './final-payments-method-container.component.html',
  styleUrls: ['./final-payments-method-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalPaymentsMethodContainerComponent implements OnInit, OnDestroy {
  @Input() spinner: boolean;
  public progressBar: boolean = true;
  public loading$ = new BehaviorSubject<boolean>(true);
  public pciLink = PCI_DSS_LINK;
  public bankAccounts$: Observable<IBankAccount[]>;
  public apexPayControl = new FormControl(false);
  
  private wasApexPayOn: boolean;

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private modalService: ModalService,
    private apexPayService: ApexPayService
  ) {
    apexPayService.plaidLinkToken();
  }

  ngOnInit() {
    this.bankAccounts$ = this.customerService.getBankAccounts().pipe(shareReplay(1));
    forkJoin({
      bankAccount: this.bankAccounts$,
      isApexPayOn: this.apexPayService.isApexPayOn(),
      wasApexPayOn: this.apexPayService.wasApexPayOn()
    }).subscribe(({ isApexPayOn, wasApexPayOn }) => {
      this.wasApexPayOn = wasApexPayOn;
      this.apexPayControl.setValue(isApexPayOn);
      this.apexPayControl.valueChanges.subscribe(val => this.handleApexPayControlChange(val));
      this.progressBar = false;
      this.loading$.next(false);
    }, err => console.log('http error', err));
  }

  ngOnDestroy() {
    this.apexPayService.deletePlaidCache();
  }

  public onManualSignUpClick() {
    this.modalService.openConfirmManualPayments().subscribe(resp => {
      if (resp) { this.openManualSignUp(); }
    }, err => console.log('http error', err));
  }

  // Open manual signup modal
  public openManualSignUp() {
    this.modalService.open<boolean>(FinalPaymentsManualModalComponent, null, {
      autoFocus: false,
    }).subscribe(form => {
      if (form) { this.handleManualSignUp(form); }
    }, err => console.log('http error', err));
  }

  public openApexpayTermsModal() {
    this.modalService.openApexpayTerms();
  }

  public onSubmit() {
    this.spinner = true;
    this.router.navigateByUrl(`final/loans`);
  }

  // If didn't have apexPay and set to true, start apexPay signup
  // Else set apexPay normally
  private handleApexPayControlChange(val: boolean) {
    if (val && !this.wasApexPayOn) {
      this.apexPayService.startApexPaySignUp().subscribe({
        next: () => this.router.navigateByUrl(`final/loans`),
        error: () => this.apexPayControl.setValue(false, { emitEvent: false })
      });
    } else {
      this.apexPayService.setApexPay(val).subscribe();
    }
  }

  // Go to next step if success
  private handleManualSignUp(bool: boolean): void {
    if (bool) {
      this.router.navigateByUrl(`final/loans`);
    }
  }
}
