import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CreditCardInformationFormComponent } from './components/credit-card-information-form/credit-card-information-form.component';
import { EmploymentInformationFormComponent } from './components/employment-information-form/employment-information-form.component';
import { InformationFormComponent } from './components/information-form/information-form.component';
import { MonthlyPaymentsInformationFormComponent } from './components/monthly-payments-information-form/monthly-payments-information-form.component';
import { CreditCardInformationContainerComponent } from './containers/credit-card-information-container/credit-card-information-container.component';
import { DebtToIncomeComparisonContainerComponent } from './containers/debt-to-income-comparison-container/debt-to-income-comparison-container.component';
import { EmploymentInformationContainerComponent } from './containers/employment-information-container/employment-information-container.component';
import { FicoComparisonContainerComponent } from './containers/fico-comparison-container/fico-comparison-container.component';
import { InformationConfirmContainerComponent } from './containers/information-confirm-container/information-confirm-container.component';
import { InformationContainerComponent } from './containers/information-container/information-container.component';
import { MonthlyPaymentsInformationContainerComponent } from './containers/monthly-payments-information-container/monthly-payments-information-container.component';
import { StepsRoutingModule } from './steps-routing.module';
import { StepsComponent } from './steps.component';
import { TermsTableComponent } from './components/terms-table/terms-table.component';

@NgModule({
  declarations: [
    StepsComponent,
    CreditCardInformationContainerComponent,
    DebtToIncomeComparisonContainerComponent,
    EmploymentInformationContainerComponent,
    FicoComparisonContainerComponent,
    InformationConfirmContainerComponent,
    InformationContainerComponent,
    MonthlyPaymentsInformationContainerComponent,
    CreditCardInformationFormComponent,
    InformationFormComponent,
    MonthlyPaymentsInformationFormComponent,
    EmploymentInformationFormComponent,
    TermsTableComponent
  ],
  imports: [CommonModule, StepsRoutingModule, SharedModule]
})
export class StepsModule {}
