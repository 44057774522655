<app-base-input
  [hidden]="isPreview || hidden"
  [label]="label"
  [errorMessage]="errorMessage"
  [model]="model"
  [placeholder]="placeholder"
  [type]="type"
  [id]="id"
  [isDisabled]="loading || isDisabled"
  [loading]="loading"
  [hasHiddenInput]="hasHiddenInput"
  [ngxMask]="ngxMask"
  [prefix]="prefix"
  [suffix]="suffix"
  [maxLength]="maxLength"
  [setTouched]="setTouched"
  [forceError]="forceError"
  [warning]="warning"
  [ngxDropSpecialCharacters]="dropSpecialCharacters"
  [validateErrors]="validateErrors"
  [hasHint]="hasHint"
  [isRequired]="isRequired"
  [isPreview]="isPreview"
  (changed)="changeModel($event)"
  (focused)="focused.emit($event)"
  (focusOuted)="focusOuted.emit($event)"
  (keyPressed)="keyPressed.emit($event)"
  (hintClick)="hintClick.emit($event)"
></app-base-input>

<span
  *ngIf="isPreview"
  class="input-wrapper__control-preview"
  [class.input-wrapper__control-preview_faded]="!model"
  [class.input-wrapper__control-preview_loading]="loading"
>{{ model || '________________' }}</span>
