<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <div class="al-modal-title">
      Delay Payment for Loan #{{ data.loan?.id }}
    </div>
    <div class="amount">
      <div class="amount__label">Amount</div>
      <div class="amount__value">$ {{ data.nextPayment?.payment }}</div>
      <div class="amount__hint">For regular payment as of {{ data.nextPayment?.due_date | date:'MM/dd/yyyy' : 'UTC' }}</div>
    </div>
    <form [formGroup]="form" class="form">
      <app-datepicker
        [datepickerFilter]="workDayFilter"
        [min]="minPaymentDate"
        [max]="maxPaymentDelayDate"
        formControlName="date"
        label="New Payment Date"
        ngDefaultControl
      ></app-datepicker>
    </form>
    <div class="form__bottom-controls">
      <app-button
        class="form__bottom-btn"
        color="white"
        (pressed)="dialogRef.close()"
      >Back</app-button>
      <app-button
        class="form__bottom-btn"
        (pressed)="onSubmit(form.value)"
      >Delay</app-button>
    </div>
  </div>
</div>
