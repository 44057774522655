<ng-container *ngIf="show">
<div
  class="warning hide"
  [class.warning_loading]="loading"
>
  <div class="warning__icon"></div>
  <p class="warning__text" *ngFor="let loan of (loans$ | async)">
    Loan #{{loan?.id}}: Need to renew your hold authorization by midnight on {{pendingHolds[0]?.payment_date | date: 'MM/dd/yyyy' : 'UTC'}} ({{calculateDiff(pendingHolds[0]?.payment_date)}})
    or your balance of ${{pendingHolds[0]?.amount}} and ${{pendingHolds[1]?.amount}} interest will be accelerated and charged to your
    credit card on {{pendingHolds[0]?.payment_date | date: 'MM/dd/yyyy' : 'UTC' }}. Please go to <a routerLink="/profile/new-holds-authorization">Authorize Hold.</a>
    to authorize a new hold.
  </p>
</div>
</ng-container>
