import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { ILoanPayment } from '../../../shared/services/customer/customer.service';

@Component({
  selector: 'app-loans-list',
  templateUrl: './loans-list.component.html',
  styleUrls: ['./loans-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoansListComponent {
  @Input() payments: ILoanPayment[] = [];
  @Input() loading: boolean;
  public tableExpanded = false;
  public visibleRows = 3;
  public paginationConfig: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1
  };
}
