import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { IProfiles } from 'src/app/user/models/user.models';
import { UserService } from 'src/app/user/services/user/user.service';

export const steps: Routes = [
  {
    path: 'validate',
    data: { step: 2 }
  },
  {
    path: 'confirm',
    data: { step: 3 }
  },
  {
    path: 'card',
    data: { step: 4 }
  },
  {
    path: 'employment',
    data: { step: 5 }
  },
  {
    path: 'payments',
    data: { step: 6 }
  },
  {
    path: 'dti',
    data: { step: 7 }
  },
  {
    path: 'loan-options',
    data: { step: 8 }
  },
  {
    path: 'cards',
    data: { step: 9 }
  },
  {
    path: 'payments',
    data: { step: 10 }
  },
  {
    path: 'loans',
    data: { step: 11 }
  },
  {
    path: 'document',
    data: { step: 12 }
  },
  {
    path: 'complete',
    data: { step: 13 }
  },
  {
    path: 'profile',
    data: { step: 99 }
  }
];

@Injectable({
  providedIn: 'root'
})
export class StepsService {
  public steps: Routes = [...steps];

  constructor(private router: Router, private modalService: ModalService, private userService: UserService) {}

  _goToStep(stepNumber: number) {
    this.userService.step = stepNumber;
    const step = this.steps.find(s => s.data.step === stepNumber);
    if (step) {
      if (stepNumber > 3) {
        this.userService.isProfileFilled = true;
      }
      const path = stepNumber === 99
        ? `/${step.path}`
        : stepNumber > 8
          ? `/final/${step.path}`
          : `/steps/${step.path}`;
      this.router.navigateByUrl(path);
    }
  }
}
