import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IBankAccount, IHoldCard, IHoldCardResponse, ILoan } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { ModalService } from '../../../shared/services/modal/modal.service';
import { UserService } from 'src/app/user/services/user/user.service';
import { BehaviorSubject, forkJoin, Observable, concat } from 'rxjs';
import { map, concatMap, filter } from 'rxjs/operators';
import { hideDataString } from 'src/app/shared/helpers/hide-data-string';
import { AddBankAccountModalComponent } from 'src/app/shared/entry/add-bank-account-modal/add-bank-account-modal.component';

@Component({
  selector: 'app-payment-info-container',
  templateUrl: './payment-info-container.component.html',
  styleUrls: ['./payment-info-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentInfoContainerComponent implements OnInit {
  public holdCards$ = new BehaviorSubject<IHoldCardResponse[]>([]);
  public bankAccounts$ = new BehaviorSubject<IBankAccount[]>([]);
  public loans$ = new BehaviorSubject<ILoan[]>([]);

  public loading$ = new BehaviorSubject<boolean>(true);
  public cardsLoading$ = new BehaviorSubject<boolean>(false);
  public banksLoading$ = new BehaviorSubject<boolean>(false);

  public bankAccountsPrimary$: Observable<IBankAccount[]>;
  public bankAccountsSecondary$: Observable<IBankAccount[]>;

  public holdCardsActive$: Observable<IHoldCardResponse[]>;
  public holdCardsInactive$: Observable<IHoldCardResponse[]>;
  public progressBar: boolean = true;

  constructor(
    private customerService: CustomerService,
    private modalService: ModalService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    // Set data pipe observables from request subjects
    this.holdCardsActive$ = this.holdCards$.pipe(map(arr => arr.filter(i => i.active)));
    this.holdCardsInactive$ = this.holdCards$.pipe(map(arr => arr.filter(i => !i.active)));
    this.bankAccountsPrimary$ = this.bankAccounts$.pipe(
      map(arr => arr.filter(i => i.account_priority === 'primary'))
    );
    this.bankAccountsSecondary$ = this.bankAccounts$.pipe(
      map(arr => arr.filter(i => i.account_priority === 'secondary'))
    );

    // Run requests, then set loading to false
    forkJoin([
      this.customerService.getLoans(this.userService.customerId),
      this.customerService.getHoldCardsList(this.userService.applicationId),
      this.customerService.getBankAccounts()
    ]).subscribe(([loans, holdCards, bankAccounts]) => {
      this.loans$.next(loans);
      this.holdCards$.next(holdCards);
      this.bankAccounts$.next(bankAccounts);
      this.loading$.next(false);
      this.progressBar = false;
    })
  }

  public openAddBankAccountModal() {
    this.modalService
      .open(AddBankAccountModalComponent)
      .subscribe((updated: boolean) => {
        if (updated) {
          this.updateBankAccounts();
        }
      });
  }

  public openAddCreditCardModal() {
    this.modalService
      .openAddNewCreditCard()
      .subscribe((res: IHoldCard) => {
        if (res) {
          this.updateHoldCards();
        }
      });
  }

  public onHoldCardChange() {
    this.updateHoldCards();
  }

  public onCardRemove(card: IHoldCardResponse) {
    this.modalService.openConfirm({
      title: 'Delete Credit Card',
      description: `Are you sure you want to delete Credit Card #${hideDataString(card.credit_card.number, 4, 4)}?`
    }).pipe(
      filter(resp => resp),
      concatMap(() => this.customerService.deleteHoldCard(card.application, card.id))
    ).subscribe(() => {
      this.updateHoldCards();
    });
  }

  private updateHoldCards() {
    this.cardsLoading$.next(true);
    this.customerService.getHoldCardsList(this.userService.applicationId)
    .subscribe(res => {
      this.holdCards$.next(res);
      this.cardsLoading$.next(false);
    });
  }

  private updateBankAccounts() {
    this.banksLoading$.next(true);
    this.customerService.getBankAccounts()
      .subscribe(res => {
      this.bankAccounts$.next(res);
      this.banksLoading$.next(false);
    });
  }
}
