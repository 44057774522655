import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-description',
  templateUrl: './form-description.component.html',
  styleUrls: ['./form-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormDescriptionComponent implements OnInit {
  @Input() loading: boolean;

  constructor() {}

  ngOnInit() {}
}
