import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from './../base-input/base-input.component';

@Component({
  selector: 'app-secret-input',
  templateUrl: './secret-input.component.html',
  styleUrls: ['./secret-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: BaseInputComponent, useExisting: SecretInputComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SecretInputComponent)
    }
  ]
})
export class SecretInputComponent extends BaseInputComponent {
  @Input() isPreview: boolean;
  @Input() forceError: boolean;
  @Input() id: string;
  @Input() isDisabled = false;
  public secret = true;

  public onToggleSecret(event: MouseEvent) {
    event.stopPropagation();
    this.secret = !this.secret;
  }
}
