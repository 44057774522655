import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { IOffer } from 'src/app/user/models/api/response/login-response';
import { UserService } from 'src/app/user/services/user/user.service';
import { Observable, forkJoin, BehaviorSubject, of, combineLatest } from 'rxjs';
import { IApplicationTerm, IApplication } from 'src/app/user/models/user.models';
import { EApplicationStatus } from 'src/app/user/models/enums/application-status.enum';
import { map } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/services/modal/modal.service';

@Component({
  selector: 'app-fico-comparison-container',
  templateUrl: './fico-comparison-container.component.html',
  styleUrls: ['./fico-comparison-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FicoComparisonContainerComponent implements OnInit {
  @Input() spinner: boolean;
  public requestPending = false;
  public selectedTerm = 3;
  public progressBar: boolean = true;  
  public loading$ = new BehaviorSubject<boolean>(true);
  public loanOptionsAvailable$ = new BehaviorSubject<boolean>(true);

  public offer$ = new BehaviorSubject<IOffer>(null);
  public terms$ = new BehaviorSubject<IApplicationTerm[]>(null);
  public application$ = new BehaviorSubject<IApplication>(null);

  public isLoanAvailable$: Observable<boolean>;
  public isLoanRejected$: Observable<boolean>;
  public isLoanUnderReview$: Observable<boolean>;

  public payoffAmount: number;

  private positiveApplicationStatuses = [
    EApplicationStatus.InProgress,
    EApplicationStatus.Open
  ];

  constructor(
    private router: Router,
    private userService: UserService,
    private customerService: CustomerService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.isLoanAvailable$ = combineLatest([this.terms$, this.application$]).pipe(
      map(([terms, application]) => {
        const isApplicationStatusPositive = this.positiveApplicationStatuses.includes(application && application.status)
        return Boolean((!terms || terms.length) && isApplicationStatusPositive);
      })
    );
    this.isLoanRejected$ = combineLatest([this.terms$, this.application$]).pipe(
      map(([terms, application]) => {
        return Boolean((terms && !terms.length) || application && application.status === EApplicationStatus.Rejected)
      })
    );
    this.isLoanUnderReview$ = of(false); // TODO when status 'under review' in api will be done

    forkJoin({
      application: this.userService.getApplication(),
      terms: this.customerService.getApplicationTerms(this.userService.applicationId),
      offer: this.userService.findMailOffer(false, this.userService.offerCode)
    })
    .subscribe(({application, terms, offer}) => {
      this.terms$.next(terms);
      this.application$.next(application);
      this.offer$.next(offer);
      this.loading$.next(false);
      this.progressBar = false;
    }, error => {
      if (error.status === 400 || error.status === 500) {
        this.modalService.openInfo({
          title: 'Error',
          description: 'Error while loading terms. ​Navigating back to the previous step​',
          buttonText: 'Ok'
        }).subscribe(() => {
          this.router.navigateByUrl('/steps/dti');
        });
      }
    });
  }

  public onSubmitted() {
    this.requestPending = true;
    this.spinner = true;
    this.userService.selectedTerm = this.selectedTerm;
    this.customerService.postApplicationTerms(this.userService.applicationId, this.selectedTerm)
    .subscribe((response) => {
      console.log(response)
    })
    this.customerService
      .patchApplication(this.userService.applicationId, { term: this.selectedTerm, step: 9 })
      .subscribe(() => this.router.navigateByUrl(`final/cards`));
  }

  public onTermSelect(term: number) {
    this.selectedTerm = term;
  }
}
