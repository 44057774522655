<span *ngIf="isNonResponsive">
  {{ text.text }}
</span>
<ng-template *ngIf="isResponsive">
  <span class="display-inline-mob-only">
    {{ text.mobileText }}
  </span>
  <span class="display-inline-tab-only">
    {{ text.tabletText }}
  </span>
  <span class="display-inline-des-only">
    {{ text.desktopText }}
  </span>
</ng-template>
