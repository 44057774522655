import { Pipe, PipeTransform } from '@angular/core';
import { thousandsSeparators } from '../helpers/thousands-separators';

@Pipe({
  name: 'thousandsSeparators'
})
export class ThousandsSeparatorsPipe implements PipeTransform {
  transform(value: any): any {
    return thousandsSeparators(value);
  }
}
