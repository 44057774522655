import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user/services/user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  constructor(private userService: UserService, private router: Router) {}

  public onLoggedOut() {
    this.userService.logout();
    this.router.navigateByUrl('/');
  }
}
