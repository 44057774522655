<div class="controls">
  <app-button
    class="controls__button"
    fadedIfActive="true"
    (pressed)="onAccepted()"
    *ngIf="(consentsReady$ | async) && (userService.isLoggedInForSteps || userService.isLoggedIn)"
  >Accept</app-button>
  <app-button class="controls__button" (pressed)="onSavePdf()">Save in PDF</app-button>
</div>
<div id="pdf-wrapper">
  <h1>Terms of Use</h1>
  <p>
    ApexLend is an Internet only lender operating at <a href="https://www.apexlend.com">www.apexlend.com</a> and any
    sub-domain thereof (collectively the “Site”). The terms of use ("Terms of Use") are for ApexLend LLC, a Delaware
    corporation (“ApexLend”, "the Company," "we," or "us"), and its affiliates. Please review these Terms of Use before
    using the Site. If you do not agree to these terms without limitation or exclusion, please exit this site. If you
    continue to use the site, you (the “User”) affirm that you have read, understand, and agree with the Terms of Use.
    The Terms of Use are effective immediately for any and all users of the Site. In case of any contradiction between
    the Terms of Use and any other agreement you the User enter into with us, the other agreement will take precedence
    as it applies to that specific product or service.
  </p>

  <h2>Changes to these Terms of Use and Site</h2>
  <p>
    From time to time, the Terms of Use may be amended and the new Terms of Use will be posted on the Site with the last
    revision date. By continuing to use the Site after the amendments, you are agreeing to acceptance of the new Terms
    of Use. We may also change or discontinue the Site at any time temporarily or permanently for any reason without
    notice to you.
  </p>

  <h2>Eligibility</h2>
  <p>
    All users of the Site must be 18 years of age or older. Any person under the age of 18 using the Site is
    unauthorized, unlicensed and in violation of the Terms of Use and must exit the Site immediately. Any user using
    Site represents and warrants that you are 18 or older and that you consent to the Terms of Use and will accept and
    adhere to the Terms of Use. Violations of the Terms of Use, or any other agreement with us, may result in a
    termination of your user account and deletion and denial of any loan request on the Site at our sole discretion.
  </p>

  <h2>Intellectual Property</h2>
  <p>
    You agree that all content on the Site, including but not limited to logos, headers, graphics, button icons,
    scripts, designs, text, pictures, video, information, software, music, sound and other files, and their selection
    and arrangement, belong to ApexLend. You are not permitted to copy, imitate, distribute, reproduce, republish, post,
    transmit, sell, or use any content on the Site in any form or by any means, in whole or in part, without ApexLend's
    prior written permission, except to download or print a copy of any portion of the contents of the Site solely for
    use of the Site and transact business with ApexLend. Any unauthorized use will be deemed a violation of the Terms of
    Use and such unauthorized will result in a termination of your license to use the site and may also violate
    applicable laws including without limitation copyright and trademark laws.
  </p>

  <h2>Privacy</h2>
  <p>
    Your privacy is very important to us. Our Privacy Policy is designed to help you understand how we use your personal
    information and protect your privacy when you use our Site and also opt in or opt out of certain policies. We will
    only share information with third parties as described in the Privacy Policy and by using the Site and agreeing to
    the Terms of Use, you are agreeing to the Privacy Policy. When you call, chat, or otherwise communicate
    (“Communications”) with any of our representatives, we may listen and record those Communications with or without
    notice to you.
  </p>

  <h2>User Representations</h2>
  <p>
    You represent and warrant that any information you give to us is yours to share and does not violate or infringe
    upon any third party rights including but not limited to copyright, trademark, privacy or other personal rights and
    that you are not violating any laws. You also agree not to collect information from the site not related to you as a
    user. You also agree to register one and only one account for yourself and use the Site only to conduct business
    between yourself and ApexLend. You also agree that by sharing any information or content, you explicitly grant us a
    transferable, non-exclusive, sub-licensable, royalty-free, worldwide license to use any information you give to us
    subject to our Privacy Policy. We may review, edit, modify, reformat, or otherwise change any information you
    provide to us.
  </p>
</div>
