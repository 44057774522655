import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Route, Router, Routes, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CustomerService } from '../shared/services/customer/customer.service';
import { UserService } from '../user/services/user/user.service';
import { ModalService } from './../shared/services/modal/modal.service';
import { steps } from './steps-routes';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsComponent implements OnInit, OnDestroy {
  public steps: Routes = [...steps];
  public username$: Observable<string>;
  public currentStepRoute: ActivatedRouteSnapshot;
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    public userService: UserService,
    public customerService: CustomerService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute
  ) {}

  get currentStep(): number {
    return this.currentStepRoute && this.currentStepRoute.data.step;
  }

  ngOnInit() {
    this.username$ = this.userService.getUsername();
    this.currentStepRoute = this.getActivatedStep();
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.currentStepRoute = this.getActivatedStep();
      if (this.currentStep) {
        this.userService.saveCurrentStepNumber(this.currentStep, this.userService.applicationId).subscribe();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSelectedStep(step: Route) {
    if (step && step.data) {
      switch (step.data.step) {
        case 3:
          this.openValidateSSNDialog();
          break;
        default:
          this.router.navigateByUrl(step.path ? `steps/${step.path}` : '/');
      }
    } else {
      this.router.navigateByUrl('/');
      this.userService.logout();
    }
  }

  public onLoggedOut() {
    this.userService.logout();
    this.router.navigateByUrl('/');
  }

  public goBack() {
    this.onSelectedStep(this.steps.find(s => s.data && s.data.step === this.currentStep - 1));
  }

  private openValidateSSNDialog() {
    this.modalService.openValidateSsnModal(this.userService.customerId).subscribe(success => {
      if (success) {
        this.router.navigateByUrl('steps/confirm');
      }
    });
  }

  private getActivatedStep(): ActivatedRouteSnapshot {
    return this.activatedRoute.snapshot.firstChild;
  }
}
