import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { CustomerService, ILoanPayment } from '../../../shared/services/customer/customer.service';
import { IBankAccount, IHoldCardResponse } from '../../../shared/models/customer.model';
import { map } from 'rxjs/operators';
import { hideDataString } from 'src/app/shared/helpers/hide-data-string';
import { ISmartOption } from 'src/app/shared/components/select/select.component';
import { UserService } from 'src/app/user/services/user/user.service';

@Component({
  selector: 'app-make-payment-container',
  templateUrl: './make-payment-container.component.html',
  styleUrls: ['./make-payment-container.component.scss']
})
export class MakePaymentContainerComponent implements OnInit {
  public loanId: number;
  public loading$ = new BehaviorSubject<boolean>(true);

  // Observables for template
  public bankAccountsOptions$: Observable<ISmartOption[]>;
  public creditCardOptions$: Observable<ISmartOption[]>;
  
  // Subjects for requests
  public futurePayments$ = new BehaviorSubject<ILoanPayment[]>([]);
  private creditCards$ = new BehaviorSubject<IHoldCardResponse[]>([]);
  private bankAccounts$ = new BehaviorSubject<IBankAccount[]>([]);

  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private router: Router,
    private $user: UserService
  ) {}

  ngOnInit() {
    // Check loand id, if invalid show 404
    this.loanId = +this.route.snapshot.queryParams.loan;
    if (isNaN(this.loanId)) {
      this.router.navigateByUrl('/404');
    }

    // Set observables, then make requests
    this.setObservables();
    this.makeRequests();
  }

  public onReload() {
    this.makeRequests();
  }

  // Set data changing observables
  private setObservables() {
    this.bankAccountsOptions$ = this.bankAccounts$
      .pipe(map(accounts => this.processBankAccounts(accounts)));
    this.creditCardOptions$ = this.creditCards$
      .pipe(map(cards => this.processCreditCards(cards)));
  }

  private makeRequests() {
    this.loading$.next(true);
    forkJoin([
      this.customerService.getBankAccounts(),
      this.customerService.getLoanFuturePayments(this.loanId),
      this.customerService.getHoldCardsList(this.$user.applicationId)
    ]).subscribe(([bankAccounts, futurePayments, creditCards]) => {
      this.bankAccounts$.next(bankAccounts);
      this.futurePayments$.next(futurePayments);
      this.creditCards$.next(creditCards);
      this.loading$.next(false);
    });
  }

  // Create options from IBankAccount list
  private processBankAccounts(list: IBankAccount[]): ISmartOption[] {
    return list.map(account => ({
      key: account.id,
      value: hideDataString(account.account_number, 4, 10) 
    }));
  }

  // Create options from IHoldCardResponse list
  private processCreditCards(list: IHoldCardResponse[]): ISmartOption[] {
    return list.map(card => ({
      key: card.credit_card.id,
      value: hideDataString(card.credit_card.number, 4, 16)
    }));
  }
}
