import { FinalApprovedContainerComponent } from './containers/final-approved-container/final-approved-container.component';
import { FinalCreditCardsContainerComponent } from './containers/final-credit-cards-container/final-credit-cards-container.component';
import { FinalPaymentsMethodContainerComponent } from './containers/final-payments-method-container/final-payments-method-container.component';

export default [
  {
    path: 'cards',
    component: FinalCreditCardsContainerComponent,
    data: { step: 1 }
  },
  {
    path: 'payments',
    component: FinalPaymentsMethodContainerComponent,
    data: { step: 2 }
  },
  {
    path: 'loans',
    component: FinalApprovedContainerComponent,
    data: { step: 3 }
  }
];
