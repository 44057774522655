import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { IBalanceDebt, IPeriodicDebt } from 'src/app/shared/models/customer.model';
import { DEBT_TYPES } from 'src/app/shared/constants/select-data.constants';

@Component({
  selector: 'app-monthly-payments-information-form',
  templateUrl: './monthly-payments-information-form.component.html',
  styleUrls: ['./monthly-payments-information-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthlyPaymentsInformationFormComponent implements OnChanges {
  @Input() balanceDebts: IBalanceDebt[];
  @Input() periodicDebts: IPeriodicDebt[];
  @Output() submitted = new EventEmitter<any>();
  @Input() spinner: boolean;
  public requestPending = false;
  public progressBar: boolean = true;
  public form: FormGroup;
  
  private filteredPeriodicDebts: IPeriodicDebt[];
  private filteredBalanceDebts: IBalanceDebt[];
  
  public balanceLoading = true;
  public periodicLoading = true;

  get loading(): boolean {
    return this.balanceLoading || this.periodicLoading;
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      this.initForm();
    }
    if (changes.balanceDebts && changes.balanceDebts.currentValue) {
      this.filteredBalanceDebts = this.filterDebts(changes.balanceDebts.currentValue);
      this.form.controls.debtBalance.patchValue(this.formatDebts(this.filteredBalanceDebts));
      this.balanceLoading = false;
    }

    if (changes.periodicDebts && changes.periodicDebts.currentValue) {
      this.filteredPeriodicDebts = this.filterDebts(changes.periodicDebts.currentValue);
      this.form.controls.debtMonthly.patchValue(this.formatDebts(this.filteredPeriodicDebts));
      this.periodicLoading = false;
      this.progressBar = false;
    }
  }

  private formatDebts(debts: IPeriodicDebt[] | IBalanceDebt[]): any {
    const values = {};
    
    debts.forEach(({ _type, value }) => {
      values[_type.toLowerCase()] = `${value}`
    });

    return values;
  }

  private filterDebts(debts: IPeriodicDebt[] | IBalanceDebt[]): any {
    if (!debts) return [];
    const filtered = [];
    DEBT_TYPES.forEach((type) => {
      const debt = debts.find((d) => d._type === type);
      if (debt) {
        filtered.push(debt);
      }
    });
    return filtered;
  }

  public onSubmit() {
    if (this.form.valid) {
      this.requestPending = true;
      this.spinner = true;
      const debtBalance = { ...this.form.value.debtBalance };
      const debtMonthly = { ...this.form.value.debtMonthly };

      const formatter = (form, debts: IPeriodicDebt[] | IBalanceDebt[]) => {
        Object.keys(form).forEach((key) => {
          const value = this.parseNumber(form[key]);
          const debt = debts.find((d) => d._type === key.toUpperCase());
          if ((!debt && !value) || (debt && Number(debt.value) === Number(value))) {
            delete form[key];
          } else {
            form[key] = value;
          }
        });
      };

      formatter(debtBalance, this.filteredBalanceDebts);
      formatter(debtMonthly, this.filteredPeriodicDebts);

      this.submitted.emit({ debtBalance, debtMonthly });
    }
  }

  private parseNumber(value: string): string {
    return parseFloat(value.replace(/[^0-9/.]/g, '')).toFixed(2);
  }

  public getWarning(key: string) {
    const balanceValue = this.parseNumber(this.form.controls.debtBalance['controls'][key].value);
    const paymentValue = this.parseNumber(this.form.controls.debtMonthly['controls'][key].value);

    if (Number(balanceValue) < Number(paymentValue) * 10) return 'Check your balance and payment';
    return '';
  }

  public onClearFields(fieldTitlesArray: string[]): void {
    if (this.form) {
      fieldTitlesArray.forEach((filedTitle: string) => {
        if (this.form.controls[filedTitle]) {
          this.form.controls[filedTitle].setValue('0');
        }
      });
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      debtBalance: this.formBuilder.group({
        auto: ['0', [RxwebValidators.required()]],
        home: ['0', [RxwebValidators.required()]],
        installment: ['0', [RxwebValidators.required()]],
        revolving: ['0', [RxwebValidators.required()]],
        student: ['0', [RxwebValidators.required()]],
        other: ['0', [RxwebValidators.required()]]
      }),
      debtMonthly: this.formBuilder.group({
        auto: ['0', [RxwebValidators.required()]],
        home: ['0', [RxwebValidators.required()]],
        installment: ['0', [RxwebValidators.required()]],
        revolving: ['0', [RxwebValidators.required()]],
        student: ['0', [RxwebValidators.required()]],
        other: ['0', [RxwebValidators.required()]]
      })
    });
  }
}
