import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputComponent } from 'src/app/shared/components/input/input.component';
import { MessageService } from '../../services/message.service';

export enum SendInputFormSubmitButtonMode {
  Blue = 'blue',
  White = 'white'
}

export class SendInputFormData {
  textInputValue: string;
}

@Component({
  selector: 'app-send-input-form',
  templateUrl: './send-input-form.component.html',
  styleUrls: ['./send-input-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendInputFormComponent implements OnInit {
  @Input() showLabel: boolean;
  @Input() labelText: string;
  @Input() inputPlaceholder: string;
  @Input() submitButtonMode: SendInputFormSubmitButtonMode = SendInputFormSubmitButtonMode.Blue;
  @Input() submitButtonText: string;
  @Input() showHelpLink: boolean;
  @Input() helpLinkText: string;
  @Input() helpLinkUrl: string;
  @Input() pattern: string;
  @Input() maxLength = 100;
  @Input() minLength = 0;
  @Input() type: 'email' | 'tel' = 'tel';
  @Output() helpLinkClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitted = new EventEmitter();
  @ViewChild('inputComponent', { static: true }) inputComponent: InputComponent;
  public form: FormGroup;
  public sendInputFormSubmitButtonMode = SendInputFormSubmitButtonMode;

  constructor(private messageService: MessageService, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm(): void {
    const pattern = this.type === 'email' ? /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ : /^[0-9a-zA-Z]+$/;
    this.form = this.formBuilder.group({
      textValue: ['', [Validators.required, Validators.minLength(this.minLength), Validators.pattern(pattern)]]
    });
  }

  onSubmitButtonClick(): void {
    if (this.form.valid) {
      this.submitted.emit(this.form.value.textValue);
    } else {
      // TODO:
      // this.inputComponent.inputRef.nativeElement.focus();
    }
  }

  onHelpLinkClick(event: Event): boolean {
    try {
      this.helpLinkClick.emit();
    } catch (e) {
      this.messageService.codeError(e, 'Send input form help link click');
    }
    event.preventDefault();
    return false;
  }
}
