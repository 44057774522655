import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseInputComponent } from './../base-input/base-input.component';

@Component({
  selector: 'app-input',
  providers: [
    { provide: BaseInputComponent, useExisting: InputComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseInputComponent),
      multi: true
    }
  ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends BaseInputComponent implements OnInit {
  @Input() formControlName: string;
  @Input() isDisabled = false;
  @Input() forceError: boolean;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() maxLength: number;
  @Input() errorMessage: string;
  @Input() prefix: string;
  @Input() hasHiddenInput: boolean;
  @Input() ngxMask: string;
  @Input() type: 'text' | 'password' | 'tel' | 'number' | 'ssn' = 'text';
  @Input() id: string;
  @Input() suffix: string;
  @Input() withIndicator: boolean;
  @Input() withToggleSecret: boolean;
  @Input() groupLabel: string;
  @Input() collapsed = false;
  @Input() warning: string;
  @Input() isPreview: boolean;
  @Input() isSecretPreview: boolean;
  @Input() loading: boolean;
  @Input() isSecurityCodeInfo: boolean;
  @Input() setTouched = false;
  @Input() hidden: boolean;
  @Input() dropSpecialCharacters = false;
  @Output() keyPressed = new EventEmitter<KeyboardEvent>();
  @Output() hintClick = new EventEmitter();
  @ViewChild('inputRef', { static: true }) inputRef: ElementRef;
  @ViewChild('optionsRef', { static: true }) optionsRef: ElementRef;
  private modelChanged: Subject<string> = new Subject<string>();
  public secret = true;
  public model: string;

  ngOnInit() {
    this.secret = this.withToggleSecret;

    this.modelChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(t => this.changed.emit(t));
  }

  public changeModel(value) {
    this.modelChanged.next(value);
  }

  public onToggleSecret() {
    this.secret = !this.secret;
    if (!this.secret) {
      setTimeout(() => this.inputRef.nativeElement.focus());
    }
  }

  public onClickInput() {
    if (this.withToggleSecret && this.secret) {
      this.onToggleSecret();
    }
  }

  public onFocusOut() {
    if (this.withToggleSecret && !this.secret) {
      this.onToggleSecret();
    }
  }
}
