import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CITIZENSHIP, HOME_OWNERSHIP, STATES } from 'src/app/shared/constants/select-data.constants';
import { ICustomer, IZipCode } from '../../models/customer.model';
import { CustomerService } from '../../services/customer/customer.service';
import { GoogleApiService } from '../../services/google-api/google-api.service';
import { ILoginRequest } from './../../../user/models/user.models';
import { ModalService } from '../../services/modal/modal.service';
import { BehaviorSubject } from 'rxjs';
import { parseDate } from '../../helpers/parse-date';
import { hideDataString } from '../../helpers/hide-data-string';
import { Router } from '@angular/router';

export interface IInformationConfirmForm {
  customer: ICustomer;
  auth: ILoginRequest;
}

@Component({
  selector: 'app-information-confirm-form',
  templateUrl: './information-confirm-form.component.html',
  styleUrls: ['./information-confirm-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationConfirmFormComponent implements OnInit, OnChanges {
  @Input() customer: ICustomer;
  @Input() username: string;
  @Input() withoutAuth = false;
  @Input() authenticated: boolean;
  @Input() isProfilePage = false;
  public progressBar: boolean = true;  
  public requestPending = false;
  @Input() spinner: boolean;
  @Output() submitted = new EventEmitter<IInformationConfirmForm>();
  public zipCodeInformation: IZipCode;
  public form: FormGroup;
  public HOME_OWNERSHIP = HOME_OWNERSHIP;
  public STATES = STATES;
  public CITIZENSHIP = CITIZENSHIP;
  public eighteenYearsAgoDate: Date;
  public minDate: Date;
  public isPreview = true;
  public setTouched = false;
  public loading$ = new BehaviorSubject<boolean>(true);
  public displayZipCodeWarnings$ = new BehaviorSubject<boolean>(false);
  private currentZipCode: string;
  @Input() requestErrors: any;

  constructor(
    private formBuilder: FormBuilder,
    private googleApiService: GoogleApiService,
    private customerService: CustomerService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.isPreview = this.isProfilePage;

    const dateNow = new Date();
    this.eighteenYearsAgoDate = new Date(dateNow.setFullYear(dateNow.getFullYear() - 18));
    this.minDate = new Date(1940, 0, 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      this.initForms();
    }

    if (changes.requestErrors && this.requestErrors) {
      this.requestPending = false;
      this.spinner = false;
    }

    if (changes.customer && changes.customer.currentValue) {
      this.loading$.next(false);
      this.patchCustomerData(this.customer);
      this.progressBar = false;
    }

    if (changes.username && this.username) {
      const emailControl = this.form.get('auth.email');
      if (emailControl) { emailControl.setValue(this.username); }
    }
  }

  private initForms() {
    this.form = this.formBuilder.group(
      {
        customer: this.formBuilder.group({
          dateOfBirth: ['', [Validators.required]],
          firstName: [
            '',
            [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]
          ],
          lastName: [
            '',
            [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]
          ],
          email: ['',
            [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]
          ],
          alternateEmail: ['', [Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
          address: this.formBuilder.group({
            address1: ['', [Validators.required]],
            address2: [''],
            city: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
            state: ['', [Validators.required]],
            zipCode: ['', [Validators.required, Validators.minLength(5), this.validateZipCode.bind(this)]]
          }),
          homePhone: ['', [Validators.required, Validators.minLength(12)]],
          cellPhone: ['', [Validators.required, Validators.minLength(12)]],
          ssn: [''],
          citizenship: ['', [Validators.required]],
          homeOwnership: ['', [Validators.required]]
        })
      },
    );

    if (!this.withoutAuth) {
      this.form.addControl(
        'auth',
        this.formBuilder.group({
          email: [
            '',
            this.authenticated
              ? []
              : [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]
          ],
          password: [
            '',
            this.authenticated ? [] : [Validators.required, Validators.minLength(8), Validators.maxLength(30)]
          ],
          repeatPassword: [
            '',
            this.authenticated ? [] : [Validators.required, RxwebValidators.compare({ fieldName: 'password' })]
          ]
        })
      );

      this.form.controls.auth['controls'].password.valueChanges.subscribe(() => {
        this.form.controls.auth['controls'].repeatPassword.updateValueAndValidity();
      });
    }
    if (this.isProfilePage) {
      this.form.get('customer.dateOfBirth').disable();
    }
  }

  public getErrorMessage(controlName: string): string {
    let control: AbstractControl;
    switch (controlName) {
      case 'firstName':
        control = this.form.controls.customer['controls'].firstName;
        if (control.getError('required')) {
          return 'Missing First Name'
        } else if (control.getError('pattern')) {
          return 'Only letters in First Name';
        }
        break;
      case 'lastName':
        control = this.form.controls.customer['controls'].lastName;
        if (control.getError('required')) {
          return 'Missing Last Name'
        } else if (control.getError('pattern')) {
          return 'Only letters in Last Name';
        }
        break;
      case 'email':
        control = this.form.controls.customer['controls'].email;
        if (control.getError('required')) {
          return 'Missing Email Address'
        } else if (control.getError('pattern')) {
          return 'Invalid Email Address format';
        }
        break;
      case 'ssn':
        control = this.form.controls.customer['controls'].ssn;
        if (control.getError('required')) {
          return 'Missing Social Security Number'
        } else if (control.getError('minlength')) {
          return 'xxx-xx-xxxx format required';
        }
        break;
      case 'zipCode':
        control = this.form.controls.customer['controls'].address['controls'].zipCode;
        if (control.getError('required')) {
          return 'Missing Zip Code'
        } else if (control.getError('minlength')) {
          return '5 digits required';
        } else if (control.getError('notEquivalent')) {
          return 'Zip Code doesn\'t exist';
        }
        break;
      case 'homePhone':
        control = this.form.controls.customer['controls'].homePhone;
        if (control.getError('required')) {
          return 'Missing Home Phone Number'
        } else if (control.getError('minlength')) {
          return 'xxx-xxx-xxxx format required';
        }
        break;
      case 'cellPhone':
        control = this.form.controls.customer['controls'].cellPhone;
        if (control.getError('required')) {
          return 'Missing Cell Phone Number'
        } else if (control.getError('minlength')) {
          return 'xxx-xxx-xxxx format required';
        }
        break;
      case 'city':
        control = this.form.controls.customer['controls'].address['controls'].city;
        if (control.getError('required')) {
          return 'Missing City';
        } else if (control.getError('pattern')) {
          return 'Only letters in City';
        }
        break;
      default:
        return 'Invalid field value';
    }
  }

  public getWarningMessage(fieldName: string): string {
    switch (fieldName) {
      case 'state':
        const state = this.form.controls.customer['controls'].address['controls'].state;
        const correctState = this.zipCodeInformation ? this.zipCodeInformation.state : '';
        if (correctState && correctState !== state.value) {
          return 'Zip Code does not match State';
        }
        break;
      case 'homePhone': {
        const homePhone = this.form.controls.customer['controls'].homePhone;
        const correctPhoneCodes = this.zipCodeInformation ? this.zipCodeInformation.area_codes : [];
        if (correctPhoneCodes.length && correctPhoneCodes.findIndex(c => homePhone.value.startsWith(c)) === -1) {
          return 'Area Code does not match Zip Code'
        }
        break;
      }
      case 'cellPhone': {
        const cellPhone = this.form.controls.customer['controls'].cellPhone;
        const correctPhoneCodes = this.zipCodeInformation ? this.zipCodeInformation.area_codes : [];
        if (correctPhoneCodes.length && correctPhoneCodes.findIndex(c => cellPhone.value.startsWith(c)) === -1) {
          return 'Area Code does not match Zip Code'
        }
        break;
      }
      case 'firstName':
      case 'lastName':
        const nameValue = this.form.controls.customer['controls'][fieldName].value;
        if (nameValue && nameValue.length > 30) {
          return 'Up to 30 chars';
        }
        break;
      case 'dateOfBirth':
        const dateOfBirth = this.form.controls.customer['controls'].dateOfBirth;
        const dateOfBirthValue = dateOfBirth.value && dateOfBirth.value._d || dateOfBirth.value;
        if (dateOfBirthValue && dateOfBirthValue.getFullYear() < 1940) {
          return 'Are you sure?';
        }
        break;
      case 'address1':
      case 'address2':
      case 'city': {
        const addressValue = this.form.controls.customer['controls'].address['controls'][fieldName].value;
        if (addressValue && addressValue.length > 50) {
          return 'Up to 50 chars';
        }
        break;
      }
    }

    return '';
  }

  public onSubmit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) { return; }
    
    this.requestPending = true;
    this.isPreview = true;
    const { customer, auth } = this.form.value;
    const dateOfBirth = customer.dateOfBirth && parseDate(customer.dateOfBirth, 'yyyy-MM-dd');
    const formattedForm: IInformationConfirmForm = {
      auth: auth ? { 
        username: auth.email, 
        password: auth.password, 
        first_name: customer.firstName,
        last_name: customer.lastName } : null,
      customer: { ...customer, dateOfBirth }
    };
    if (this.isProfilePage) {
      delete formattedForm.customer.dateOfBirth;
    }
    if (customer.email !== auth.email){
      this.modalService.openConfirm({
        title: 'Warning',
        description: 'Profile Email and Login Email Address not matching, Are you sure to continue!',
        cancelText: 'Cancel',
        confirmText: 'Continue',
        reversed: true,
      }).subscribe(resp => {
        if (resp) { 
          if (resp === true){
            this.spinner = true;
            this.submitted.emit(formattedForm)
          } 
        } else {
          this.spinner = this.requestPending = false
        }
      });
    } else {
      this.spinner = true;
      this.submitted.emit(formattedForm);
    }
  }

  public onCancel() {
    this.patchCustomerData(this.customer);
    this.isPreview = true;
  }

  openContactsModal() {
    this.modalService.openContacts();
  }

  private patchCustomerData(customer: ICustomer) {
    if (customer) {
      this.form.controls.customer.patchValue({
        ...customer,
        address: customer.address || {},
        homePhone: customer.homePhone ? customer.homePhone.replace(/-/g, '') : '',
        cellPhone: customer.cellPhone ? customer.cellPhone.replace(/-/g, '') : '',
        dateOfBirth: customer.dateOfBirth ? new Date(customer.dateOfBirth) : '',
        ssn: customer.ssn.replace(/-/g, '')
      });
    }
  }

  private validateZipCode(control: FormControl) {
    const code = control.value || '';
    if (code.length === 5 && code !== this.currentZipCode) {
      this.currentZipCode = code;
      this.customerService.getZipCode(code).subscribe(
        res => {
          this.zipCodeInformation = res;
          this.displayZipCodeWarnings$.next(true);
        },
        () => {
          control.setErrors({ notEquivalent: true });
        }
      );
    }
  }
}
