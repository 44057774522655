import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CARD_TYPE, STATES, CardTypeKeys } from '../../../shared/constants/select-data.constants';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { IHoldCard, IPayOffAccount, IPayee } from 'src/app/shared/models/customer.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { IApplication } from '../../../user/models/user.models';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { IOption } from 'src/app/shared/components/select-input/select-input.component';
import { validateCreditCardType } from 'src/app/shared/validators/credit-card-type.validator';
import { AbstractControlWarnings } from 'src/app/shared/models/abstract-control-warnings.model';
import { validateCreditCardLuhn } from 'src/app/shared/validators/credit-card-luhn.validator';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { validateExpirationDate } from 'src/app/shared/validators/expiration-date.validator';
import { parseDate } from 'src/app/shared/helpers/parse-date';
import { parseExpirationDate } from 'src/app/shared/helpers/parse-expiration-date';

@Component({
  selector: 'app-final-form',
  templateUrl: './final-form.component.html',
  styleUrls: ['./final-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalFormComponent implements OnChanges {
  @Input() payOffAccount: IPayOffAccount;
  @Input() application: IApplication;
  @Input() holdCard: IHoldCard;
  @Input() loading: boolean;
  @Input() hidden: boolean = false;
  public requestPending = false;
  @Input() spinner: boolean;
  @Output() submitted = new EventEmitter<any>();
  public form: AbstractControlWarnings & FormGroup;
  public CARD_TYPE = CARD_TYPE;
  public STATES = STATES;
  public creditCardLocked = false;  
  public affinityOptions$ = new BehaviorSubject<IOption[]>([]);

  private discoverKey: CardTypeKeys = 'Discover';

  constructor(private formBuilder: FormBuilder, private $customerApi: CustomerService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      this.initForm();
    }

    if (changes.payOffAccount && changes.payOffAccount.currentValue) {
      const { payee, content_object } = <IPayOffAccount>changes.payOffAccount.currentValue;

      if (payee) {
        const payeeGroup = this.form.get('payee');
        payeeGroup.patchValue(payee);
      }

      if (content_object) {
        this.form.get('content_object').patchValue({
          ...content_object,
          // number: content_object.number.replace('*********', '*** ****** '),
          card_type: this.findCardType(content_object.card_type),
          expiration_date: content_object.expiration_date ? parseDate(content_object.expiration_date, 'MMyy') : ''
        });

        if (this.form.get('content_object.number').value) {
          this.creditCardLocked = true;
          this.onCreditCardLockedChange(true);
        }
      }
    }

    if (changes.application && changes.application.currentValue) {
      const applicationValue = <IApplication>changes.application.currentValue;
      this.form.get('content_object').patchValue({
        card_type: this.findCardType(applicationValue.desired_payoff_account),
      });
    }

    if (changes.holdCard && changes.holdCard.currentValue) {
      const holdCardValue = <IHoldCard>changes.holdCard.currentValue;
      this.form.get('content_object').patchValue({
        ...holdCardValue,
        // number: holdCardValue.number.replace('*********', '*** ****** '),
        card_type: this.findCardType(holdCardValue.card_type),
        expiration_date: holdCardValue.expiration_date ? parseDate(holdCardValue.expiration_date, 'MMyy') : ''
      });
      if (this.form.get('content_object.number').value) {
        this.creditCardLocked = true;
        this.onCreditCardLockedChange(true);
      }
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      payee: this.formBuilder.group({
        name: ['', [Validators.required]],
        phone_number: ['', [Validators.required]],
        affinity: ['', Validators.required],
        address: this.formBuilder.group({
          address1: ['', [Validators.required]],
          address2: [''],
          city: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
          state: ['', [Validators.required]],
          zipCode: ['', [Validators.required, Validators.minLength(5)]]
        }),
      }),
      content_object: this.formBuilder.group(
        {
          name: ['', [Validators.required, this.validateNameOnCard]],
          number: ['', [Validators.required, Validators.minLength(15)]], //validateCreditCardLuhn
          card_type: ['', [Validators.required]],
          expiration_date: ['', [Validators.required, validateExpirationDate]],
          security_code: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
        }, 
        // { validators: [this.validateCreditCardTypeNumber, this.validateCreditCardLength] } 
      ),
    });

    this.form.get('content_object.card_type').valueChanges.pipe(
      distinctUntilChanged(),
      filter(type => type === this.discoverKey),
    ).subscribe(() => this.handleDiscoverCardType());
  }

  public onSubmit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) { return; }    
    // this.requestPending = true;
    // this.spinner = true;
    const values: IPayOffAccount = { ...this.form.getRawValue() };
    const expirationDate = parseExpirationDate(values.content_object.expiration_date);
    values.content_object.card_type = values.content_object.card_type.substring(0, 1);
    values.content_object.number = values.content_object.number.replace(/^(.{4})(.*)$/, "$1 $2");
    values.content_object.expiration_date = values.content_object.expiration_date ? parseDate(expirationDate, 'yyyy-MM-dd') : '';
    values.content_type = 'credit card';
    this.submitted.emit(values);
  }

  public onAffinityInputChange(label: string) {
    this.createOptionsForAffinity(label).subscribe(options => {
      this.affinityOptions$.next(options);
    });
  }

// Set other fields based on affinity data
  public onAffinityValueChange(option: IOption) {
    const data: IPayee = option.data;
    const payeeGroup = this.form.get('payee');
    const payeeAddressGroup = this.form.get('payee.address')['controls'];
    payeeGroup.patchValue(data);
    payeeAddressGroup.patchValue(data.address);
  }

  public onCreditCardLockedChange(val: boolean) {
    const control = this.form.get('content_object.number')['controls'];
    const security_code = this.form.get('content_object.security_code')['controls'];
    if (val) {
      control.disable();
      security_code.disable();
      this.hidden = true;
    } else {
      control.enable();
      security_code.enable();
      this.hidden = false;
      this.form.get('content_object')['controls'].setValidators([this.validateCreditCardTypeNumber, this.validateCreditCardLength]);
      this.form.get('content_object')['controls'].updateValueAndValidity();
    }   
  }

  private createOptionsForAffinity(label: string): Observable<IOption[]> {
    return this.$customerApi.findPayees(label).pipe(map(resp => {
      return resp.map(i => ({
        label: i.affinity,
        value: i.affinity,
        data: i
      }));
    }));
  }

  private findCardType(firstLetter: string): CardTypeKeys {
    return Object.keys(CARD_TYPE).find(key => CARD_TYPE[key].substring(0, 1) === firstLetter) as CardTypeKeys;
  }
  
  private handleDiscoverCardType() {
    const options$ = this.createOptionsForAffinity(this.discoverKey);
    options$.subscribe(options => {
      const discoverOption = options.find(i => i.label.toLowerCase() === this.discoverKey.toLowerCase());
      if (!discoverOption) { return; }
      this.affinityOptions$.next(options);
      this.form.get('payee.affinity')['controls'].setValue(this.discoverKey);
      this.onAffinityValueChange(discoverOption);
    });
  }

  private validateCreditCardTypeNumber(control: AbstractControlWarnings) {
    if (control.get('number').disabled) { return null; }
    const { number, card_type } = control.value;    
    const error = validateCreditCardType(number, card_type, `Card Type and Card Number don't match, are you sure?`);
    control.warnings = { ...error };
    return null;
  }

  private validateCreditCardLength(control: FormGroup) {
    if (control.get('number').disabled) { return null; }
    const { number, card_type } = control.value;
    const numberLength = card_type === 'AmericanExpress' ? 15 : 16;
    return number && number.length === numberLength ? null : { creditCardLength: true };
  }

  private validateNameOnCard(control: AbstractControlWarnings) {
    const value: string = control.value;
    const warning = value.length > 26 ? { tooLong: true } : null;
    control.warnings = { ...warning };
    return null;
  }

  public getErrorMessage(controlName: string): string {
    let control: AbstractControl;
    switch (controlName) {
      case 'city':
        control = this.form.get('payee.address.city') as FormGroup;
        if (control.getError('required')) {
          return 'Missing City';
        } else if (control.getError('pattern')) {
          return 'Only letters in City';
        }
        break;
        default:
        return 'Invalid field value';
    }
  }
}
