<div class="title">
  Choose bank account
</div>

<div class="no-data" *ngIf="!hasOptions">
  <p class="no-data__line">Your Bank Accounts do not meet the requirements.</p>
  <p class="no-data__line">Please link your account manually.</p>
</div>

<app-radio-group
  *ngIf="hasOptions"
  class="radio-group"
  [formControl]="formControl"
  [options]="options"
  [direction]="true ? 'column' : 'row'"
></app-radio-group>
<div class="button-list">
  <app-button
    class="button-list__button"
    [color]="'white'"
    (pressed)="onCancel()"
    >Cancel
  </app-button>
  <app-button
    *ngIf="hasOptions"
    class="button-list__button"
    [isDisabled]="!formControl.value"
    (pressed)="onSubmit()"
    >Submit
  </app-button>
</div>