import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { createCardTypesForHold } from '../../constants/select-data.constants';
import { CustomerService } from '../../services/customer/customer.service';
import { UserService } from '../../../user/services/user/user.service';
import { validateCreditCardType } from '../../validators/credit-card-type.validator';
import { AbstractControlWarnings } from '../../models/abstract-control-warnings.model';
import { validateCreditCardLuhn } from '../../validators/credit-card-luhn.validator';

@Component({
  selector: 'app-add-credit-card-modal',
  templateUrl: './add-credit-card-modal.component.html',
  styleUrls: ['./add-credit-card-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddCreditCardModalComponent implements OnInit {
  public form: AbstractControlWarnings & FormGroup;
  public cardTypes = createCardTypesForHold();

  constructor(
    public dialogRef: MatDialogRef<AddCreditCardModalComponent>,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      card_type: ['', [Validators.required]],
      available_credit: ['', [Validators.required, this.validateAvailableCredit.bind(this)]],
      name: ['', [Validators.required, this.validateNameOnCard]],
      number: ['', [Validators.required, Validators.minLength(19), Validators.maxLength(19), validateCreditCardLuhn]],
      expiration_date: ['', [Validators.required, this.validateExpirationDate.bind(this)]],
      security_code: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
    }, {
      validators: [this.validateCreditCardTypeNumber]
    });

    this.form.controls.card_type.valueChanges.subscribe((cardType: string) => {
      const { number, security_code } = this.form.controls;
      this.setCreditCardValidators(security_code, number, cardType);
    });
  }

  public onSubmit() {
    const holdCard = { ...this.form.value };
    holdCard.card_type = holdCard.card_type.substring(0, 1);
    holdCard.available_credit = parseFloat(holdCard.available_credit.replace(/,/g, ''));
    holdCard.expiration_date = this.formatExpirationDate(holdCard.expiration_date).format('YYYY-MM-DD');
    holdCard.number = holdCard.number.replaceAll(' ', '');
    this.customerService.addHoldCard(this.userService.applicationId, holdCard)
      .subscribe((res) => {
        this.dialogRef.close(res);
      });
  }

  public getAvailableCreditError() {
    const control = this.form.controls.available_credit;
    if (control.getError('tooBig')) {
      return 'Available Credit has to be $50000 or less';
    } else if (control.getError('tooSmall')) {
      return 'Available Credit has to be at least $2,000';
    } else if (control.getError('required')) {
      return 'This field is required.';
    }
  }

  private validateAvailableCredit(control: FormControl) {
    if (control.value) {
      const availableCredit = parseFloat(control.value.replace(/[^\d\.]/gi, ''));
      if (availableCredit) {
        if (availableCredit > 1000000) {
          return { tooBig: true };
        } else if (availableCredit <= 2000) {
          return { tooSmall: true }
        }
        return null;
      }
    } else {
      return null;
    }
  }

  private validateExpirationDate(control: FormControl) {
    if (control.value) {
      const date = this.formatExpirationDate(control.value);
      if (date && date.isAfter(new Date())) {
        return null;
      } else {
        return { expirationDateError: true };
      }
    } else {
      return null;
    }
  }

  private setCreditCardValidators(securityCodeControl: AbstractControl, cardNumberControl: AbstractControl, cardType: string) {
    const securityCodeLength = cardType === 'AmericanExpress' ? 4 : 3;
    const numberLength = cardType === 'AmericanExpress' ? 18 : 19;

    securityCodeControl.clearValidators();
    cardNumberControl.clearValidators();

    securityCodeControl.setValidators([
      Validators.required,
      Validators.minLength(securityCodeLength),
      Validators.maxLength(securityCodeLength)
    ]);

    cardNumberControl.setValidators([
      Validators.required,
      Validators.minLength(numberLength),
      Validators.maxLength(numberLength),
      validateCreditCardLuhn
    ]);

    securityCodeControl.updateValueAndValidity();
    cardNumberControl.updateValueAndValidity();
  }

  private formatExpirationDate(expiration: string): moment.Moment {
    const reg = /^(0[1-9]|1[0-2])\/([1-9]{4}|[0-9]{2})$/;
    const matches = expiration.match(reg);

    if (matches) {
      const [value, month, year] = matches;
      return moment(`${month}-01-20${year}`, 'MM-DD-YYYY');
    } else {
      return null;
    }
  }

  private validateCreditCardTypeNumber(control: AbstractControlWarnings) {
    const { number, card_type } = control.value;
    const error = validateCreditCardType(number, card_type, `Card Type and Card Number don't match, are you sure?`);
    control.warnings = { ...error };
    return null;
  }

  private validateNameOnCard(control: AbstractControlWarnings) {
    const value: string = control.value;
    const warning = value.length > 26 ? { tooLong: true } : null;
    control.warnings = { ...warning };
    return null;
  }
}
