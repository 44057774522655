<app-card
  [title]="cardTitle"
  [description]="loanId && 'Hold Authorization for Loan #' + loanId"
  [loading]="loading"
  [foldable]="isCardFoldable"
  [expanded]="!isCardFoldable"
  [hasRemoveBtn]="isCardFoldable"
  (removeBtnClick)="onRemove()"
>
  <form [formGroup]="form" class="form">
    <app-secret-input
      formControlName="number"
      label="Number"
      class="form-field"
      type="tel"
      [loading]="loading"
      [isDisabled]="true"
      ngDefaultControl
    ></app-secret-input>
    <app-input
      formControlName="expirationDate"
      label="Expiration Date"
      class="form-field"
      ngxMask="00/00"
      placeholder="MM/YY"
      [loading]="loading"
      [isDisabled]="true"
      ngDefaultControl
    ></app-input>
  </form>
  <app-button
    class="edit-btn"
    (pressed)="openEditCreditCardModal()"
    >Edit
  </app-button>
</app-card>
