<div class="form-group" [class.has-error]="isControlError" [class.loading]="loading">

  <div class="label" *ngIf="label">{{ label }}</div>

  <div class="input">
    <mat-radio-group
      [class.column]="direction === 'column'"
      [ngModel]="model"
      (ngModelChange)="writeValue($event)"
    >
      <mat-radio-button
        *ngFor="let option of optionsOutput"
        [value]="option.key"
      >
        {{ option.value }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <span *ngIf="isPreview">{{ model }}</span>
</div>