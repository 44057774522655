<mat-progress-bar mode="indeterminate" class="mat-custome-progress-bar" [ngStyle]="{'visibility': progressBar ? 'visible' : 'hidden'}"></mat-progress-bar>
<div
  class="title"
  [class.title_loading]="(loading$ | async)"
>
  Bank account information
</div>

<div
  class="controls"
  [class.controls_loading]="loading$ | async"
>
  <!-- <div class="controls__apexpay" (click)="onPlaidSignUpClick()">
    <img class="controls__apexpay__logo" src="/assets/img/apex-pay/apexpay_button.svg" />
  </div> -->
  <div class="controls__apexpay apexpay">
    <img class="apexpay__image" src="assets/img/apex-pay/apexpay_logo.svg" alt="Apex-Pay">
    <mat-slide-toggle
      class="apexpay__toggle"
      [formControl]="apexPayControl"
      id="apexpay__toggle"
    ></mat-slide-toggle>
  </div>
  <p class="controls__tooltip">By signing up for <i>ApexPay</i>, you agree to the <i>ApexPay</i> / Plaid <a [routerLink]="['/apex-terms']">terms of use</a></p>
</div>

<div
  class="information"
  [class.information_loading]="loading$ | async"
>
  <h4 class="information__header">The features and benefits of <i>ApexPay</i>:</h4>
  <ul class="list_outer">
    <li>
      As long as <i>ApexPay</i> is on for your loan:
      <ul class="list_inner">
        <li>a 0.25 % interest rate discount *</li>
        <li>All Late fees waived</li>
        <li>All Returned payment fees waived</li>
        <li>Automatic payment of regularly scheduled payments</li>
        <li>Auto Balance Check one business day prior to debiting your payment</li>
        <ul class="list_inner">
          <li>No cost to you</li>
          <li>Avoids debiting payment if the balance is insufficient the day before payment due date</li>
          <li>Helps you avoid your bank’s NSF (Insufficient Funds) fees and/or overdraft charges **</li>
        </ul>
      </ul>
    </li>
    <!-- <li>
      We know you are busy, so, we automatically check for sufficient fund in your account prior to debiting
      your account for payment (The sufficient funds check will be at no cost to you and will generally happen
      within a couple of hours of the attempted automated debit payment)
    </li>
    <li>
      By checking for sufficient funds we help you avoid your bank's NSF (Insufficient Funds) fees
      and/or overdraft charges (if the bank balance declines between the time we check and the time
      when we debit your account, the payment may still be declined and you may still be subject to fees
      from your bank, but we still walve any ApexLend returned payment fees and late fees)
    </li>
    <li>
      We save you time by automatically debiting the payments.
    </li> -->
  </ul>
  <p class="subtext">
    * Interest rate discount is applied as a credit against your outstanding principal loan balance. 
    Please see the <i>ApexPay</i>/Plaid <a [routerLink]="['/apex-terms']">terms of use</a> above for more information
  </p>
  <p class="subtext">
    ** If the bank account available balance declines between the time we check and the time when we debit your account, 
    the payment may still be declined and you may still be subject to fees from your bank, 
    but we will still waive any ApexLend returned payment fees and late fees
  </p>
</div>

<div
  class="controls"
  [class.controls_loading]="loading$ | async"
>
  <a class="controls__manual" (click)="onManualSignUpClick()">Link account manually</a>
  <p class="controls__tooltip">(by choosing this option you will lose the benefits of <i>ApexPay</i>)</p>
</div>

<div class="submit" [class.submit--loading]="loading$ | async">
  <app-button class="submit__btn" [spinner]="spinner" [id]="'bank_submit_btn'" (pressed)="onSubmit()">Submit</app-button>
</div>

<div class="description" [class.loading]="loading$ | async">
  Please be assured that your information is being sent over a secure SSL server. We are in full compliance with
  <a target="_blank" [href]="pciLink">PCI DSS</a>. Your privacy is important to us and we will not share your information with anyone.

  <app-secure-logotypes></app-secure-logotypes>
</div>
