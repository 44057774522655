import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { UserService } from '../user/user.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, map, shareReplay, switchMap } from 'rxjs/operators';
import { EConsent } from 'src/app/shared/models/enums/consent.enum';

export interface IConsentForm {
  id: number;
  name: EConsent;
  version: number;
  active: boolean;
  created: string;
}

export interface IConsent {
  created: string;
  accepted: boolean;
  consent: IConsentForm;
}

@Injectable({
  providedIn: 'root'
})
export class PrivacyPolicyService {

  public consentsReady$: Observable<boolean>;

  private consents$ = new BehaviorSubject<IConsent[]>(null);
  private consentForms$: Observable<IConsentForm[]>;
  private request$: Observable<IConsent[]>;

  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {
    this.userService.logoutEvent.subscribe(() => {
      this.consents$.next(null);
    });
    this.consentsReady$ = this.consents$.pipe(map(Boolean));
    this.consentForms$ = this.apiService.get<IConsentForm[]>('consents').pipe(shareReplay(1));
  }

  public getConsent(): Observable<IConsent[]>;
  public getConsent(consentType: EConsent): Observable<IConsent>;
  public getConsent(consentType?: EConsent): Observable<any> {
    const consents = this.requestConsents();
    return consents.pipe(map(resp => { 
      const arr = resp.slice().reverse();
      return consentType ? arr.find(i => i.consent.name === consentType) : arr;
    }));
  }

  public saveConsent(consentType: EConsent, accepted = true): Observable<unknown> {
    return this.consentForms$.pipe(
      map(arr => arr.find(i => i.name === consentType)),
      switchMap(i => i ? this.apiService.post(`applications/${this.userService.applicationId}/consents`, {
        accepted,
        consent_id: i.id
      }) : of(null))
    );
  }

  public requestConsents(): Observable<IConsent[]> {
    if (!this.request$) {
      this.request$ = this.apiService.get(`applications/${this.userService.applicationId}/consents`).pipe(
        tap((resp: IConsent[]) => {
          this.consents$.next(resp);
          this.request$ = null;
        }),
        shareReplay(1)
      );
    }
    return this.request$;
  }
}
