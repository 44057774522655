import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-base-form-layout',
  templateUrl: './base-form-layout.component.html',
  styleUrls: ['./base-form-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BaseFormLayoutComponent {}
