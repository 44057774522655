<app-final-credit-cards-form
  [holdCard]="holdCard$ | async"
  [payOffAccount]="payOffAccount$ | async"
  [loading]="loading$ | async"
  [application]="application$ | async"
  [requestErrors]="errors$ | async"
  (submitted)="onSubmitted($event)"
></app-final-credit-cards-form>
<app-form-description class="description">
  Please be assured that your information is being sent over a secure SSL server. We are in full compliance with
  <a target="_blank" [href]="pciLink">PCI DSS</a>. Your privacy is important to us and we will not share your information with anyone.
</app-form-description>
<app-secure-logotypes></app-secure-logotypes>
