import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { LoanStatusEnum } from './../../components/loan-accordion/loan-accordion.component';
import { UserService } from '../../../user/services/user/user.service';
import { IBankAccount, ILoan, IHoldCardResponse } from '../../../shared/models/customer.model';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Component({
  selector: 'app-my-loans-container',
  templateUrl: './my-loans-container.component.html',
  styleUrls: ['./my-loans-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyLoansContainerComponent implements OnInit {
  public loanStatusEnum = LoanStatusEnum;
  public progressBar: boolean = true;

  public loans$ = new BehaviorSubject<ILoan[]>([]);
  public bankAccounts$ = new BehaviorSubject<IBankAccount[]>([]);
  public holdCards$ = new BehaviorSubject<IHoldCardResponse[]>([]);

  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private customerService: CustomerService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    forkJoin([
      this.customerService.getLoans(this.userService.customerId),
      this.customerService.getBankAccounts(),
      this.customerService.getHoldCardsList(this.userService.applicationId)
    ]).subscribe(([loans, banks, cards]) => {
      this.loans$.next(loans);
      this.bankAccounts$.next(banks);
      this.holdCards$.next(cards);
      this.loading$.next(false);
      this.progressBar = false;
    })
  }
}
