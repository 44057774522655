import { AbstractControl } from "@angular/forms";

export function validateCreditCardLuhn(control: AbstractControl) {
  const value = control.value;
  return luhnAlgorithmCheck(value) ? null : { luhn: true };
}

// https://en.wikipedia.org/wiki/Luhn_algorithm
function luhnAlgorithmCheck(value: string): boolean {
  let nCheck = 0, bEven = false;
  value = String(value).replace(/\D/g, "");

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

    nCheck += nDigit;
    bEven = !bEven;
  }

  return (nCheck % 10) === 0;
}