import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { CustomerService } from '../../services/customer/customer.service';
import { shareReplay, first } from 'rxjs/operators';
import { bankAccountTypeOptions } from '../../models/bank-account-types';
import { EBankAccountType } from '../../models/enums/bank-account-type.enum';
import { forkJoin } from 'rxjs';
import { ApexPayService } from '../../services/apexpay/apexpay.service';

export interface IFormGroupBankAccountValues {
  routing_number: string;
  account_number: string;
  account_number_reenter: string;
  type: EBankAccountType;
  institution: string;
}

@Component({
  selector: 'app-add-bank-account-modal',
  templateUrl: './add-bank-account-modal.component.html',
  styleUrls: ['./add-bank-account-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddBankAccountModalComponent implements OnInit {
  public form: FormGroupTyped<IFormGroupBankAccountValues>;
  public turnOnAutoPay: FormControl = new FormControl(false);
  public bankAccountTypeOptions = bankAccountTypeOptions;
  
  private wasApexPayOn: boolean;
  private currentRoutingNumber: string;

  constructor(
    public dialogRef: MatDialogRef<AddBankAccountModalComponent>,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private apexPayService: ApexPayService
  ) {
    apexPayService.plaidLinkToken();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      routing_number: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9),
          RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false })
        ]
      ],
      account_number: [
        '',
        [
          Validators.required,
          RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false })
        ]
      ],
      account_number_reenter: [''],
      type: [EBankAccountType.Checking],
      institution: ['']
    }, {
      validators: [this.validateAccountNumberReenter]
    }) as FormGroupTyped<IFormGroupBankAccountValues>;

    this.form.valueChanges.subscribe(() => {
      if (this.form.controls.routing_number.valid) {
        this.findRouting(this.form.value.routing_number);
      }
    });

    this.form.get('account_number').valueChanges.pipe(first())
      .subscribe(() => this.form.get('account_number_reenter').markAsTouched());

    forkJoin({
      isApexPayOn: this.apexPayService.isApexPayOn(),
      wasApexPayOn: this.apexPayService.wasApexPayOn()
    }).subscribe(({ isApexPayOn, wasApexPayOn }) => {
      this.wasApexPayOn = !wasApexPayOn;
      this.turnOnAutoPay.setValue(!isApexPayOn);
      this.turnOnAutoPay.valueChanges.subscribe(val => this.handleAutoPayChange(val));
    });

    this.dialogRef && this.dialogRef.afterClosed().subscribe(() => this.apexPayService.deletePlaidCache());
  }

  public onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) { return; }
    this.customerService.postBankAccount(this.form.value)
      .subscribe(res => this.dialogRef.close(res));
  }

  private findRouting(routingNumber: string) {
    if (routingNumber !== this.currentRoutingNumber) {
      this.currentRoutingNumber = routingNumber;
      this.customerService.findFedwireAccount(routingNumber)
        .pipe(
          shareReplay(1)
        )
        .subscribe(res => {
          this.form.patchValue({
            institution: res.customerName ? res.customerName.trim() : '',
          });
          this.form.controls.routing_number.setErrors(null);
        }, () => {
          this.form.controls.routing_number.setErrors({ invalid: true });
          this.form.patchValue({
            institution: '',
          })
        });
    }
  }

  private validateAccountNumberReenter(group: FormGroupTyped<IFormGroupBankAccountValues>) {
    const {account_number, account_number_reenter} = group.value;
    if (account_number && account_number !== account_number_reenter) {
      return { reenter: 'Account Numbers do not match.' };
    } else {
      return null;
    }
  }

  private handleAutoPayChange(val: boolean) {
    if (val && !this.wasApexPayOn) {
      this.apexPayService.startApexPaySignUp().subscribe({
        next: () => this.dialogRef.close(true),
        error: () => this.turnOnAutoPay.setValue(false, { emitEvent: false })
      });
    } else {
      this.apexPayService.setApexPay(val).subscribe();
    }
  }
}
