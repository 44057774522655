import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Pipe({
  name: 'control'
})
export class ControlPipe implements PipeTransform {

  transform(value: FormGroup, path: string): AbstractControl {
    if (!value) { return null; }
    return value.get(path);
  }

}
