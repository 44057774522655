<app-header>
  <div
    class="header__username"
  >Welcome, {{ username$ | async }}</div>
  <app-button class="header__logout" icon="logout" (pressed)="onLoggedOut()">Log Out</app-button>
</app-header>
<div class="container page-container">
  <div class="form-title">
    Congratulations on beginning your Debt Diet with ApexLend
  </div>
  <div class="form-description">
    To help you begin immediately, we need the following information<br />
    for the card you would like ApexLend to pay off
  </div>
  <app-final-form
    class="form"
    [loading]="loading$ | async"
    [payOffAccount]="payOffAccount$ | async"
    [application]="application$ | async"
    [holdCard]="holdCard$ | async"
    (submitted)="onSubmitted($event)"
  ></app-final-form>
</div>
<app-footer></app-footer>
