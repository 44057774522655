import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { FormControl } from '@angular/forms';
import { IBankAccount, ILoan, IHoldCardResponse } from '../../../shared/models/customer.model';
import { PlaidService } from 'src/app/shared/services/plaid/plaid.service';
import { ILoanPayment, CustomerService } from 'src/app/shared/services/customer/customer.service';
import { Router } from '@angular/router';
import { parseDate } from 'src/app/shared/helpers/parse-date';
import { forkJoin } from 'rxjs';
import { ApexPayService } from 'src/app/shared/services/apexpay/apexpay.service';
import { AddBankAccountModalComponent } from 'src/app/shared/entry/add-bank-account-modal/add-bank-account-modal.component';
import { HoldsService } from 'src/app/shared/services/holds/holds.service';

@Component({
  selector: 'app-loan-overview',
  templateUrl: './loan-overview.component.html',
  styleUrls: ['./loan-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanOverviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() loan: ILoan;
  @Input() paymentsSchedule: ILoanPayment[];
  @Input() paymentHistory: ILoanPayment[];
  @Input() linkedBankAccount: IBankAccount;
  @Input() linkedHoldCard: IHoldCardResponse;

  public turnOnAutoPay = new FormControl(false);
  public lastAccountNumbers: string;
  public nextPayment: ILoanPayment;
  public pendingHolds: any = []; 
  public show: boolean = false;
  public TotalOfPendingHolds: Number;

  private wasApexPayOn: boolean;

  constructor(
    private modalService: ModalService,
    private plaidService: PlaidService,
    private $api: CustomerService,
    private router: Router,
    private apexPayService: ApexPayService,
    private holdsService: HoldsService,
    private readonly cd: ChangeDetectorRef
  ) {
    apexPayService.plaidLinkToken();
  }

  public get canDelayPayment(): boolean {
    return this.plaidService.isLoggedIn && this.turnOnAutoPay.value;
  }

  public get linkedBankAccountDigits(): string {
    return String(this.linkedBankAccount ? (this.linkedBankAccount.account_number).slice(-4) : 'Not Available');
  }

  public get linkedHoldCardDigits(): string {
    return String(this.linkedHoldCard && this.linkedHoldCard.credit_card.number).slice(-4);
  }

  ngOnInit() {
    this.getPendingHolds();
    forkJoin({
      isApexPayOn: this.apexPayService.isApexPayOn(),
      wasApexPayOn: this.apexPayService.wasApexPayOn()
    }).subscribe(({ isApexPayOn, wasApexPayOn }) => {
      this.wasApexPayOn = wasApexPayOn;
      this.turnOnAutoPay.setValue(isApexPayOn);
      this.turnOnAutoPay.valueChanges.subscribe(val => this.handleAutoPayChange(val));
    });
  }

  ngOnChanges({ bankAccounts, paymentsSchedule }: SimpleChanges) {
    if (bankAccounts && bankAccounts.currentValue) {
      const accounts = <IBankAccount[]>bankAccounts.currentValue;
      const primaryAccount = accounts.find(acc => acc.account_priority === 'primary');
      if (primaryAccount) {
        this.lastAccountNumbers = primaryAccount.account_number.slice(-4);
      }
    }

    if (paymentsSchedule && this.paymentsSchedule) {
      this.nextPayment = this.paymentsSchedule.find(Boolean);
    }
  }

  ngOnDestroy() {
    this.apexPayService.deletePlaidCache();
  }

  public onTitleHintClick() {
    this.getPendingHolds();
    this.modalService.openInfo({
      title: 'Hold Amount 1 & 2!!',
      description: `Hold Amount $${this.pendingHolds[0]?.amount} + $${this.pendingHolds[1]?.amount}`,
      buttonText: 'Ok'
    }).subscribe(() => {
          // this.router.navigateByUrl('/profile/information/personal');
    });
  }

  getPendingHolds() {
    this.holdsService.getPendingHolds()
      .subscribe((holds) => {
        holds.length > 0 ? this.show = true : this.show = false;
        this.pendingHolds = holds;
        this.TotalOfPendingHolds = Number(this.pendingHolds[0]?.amount) + Number(this.pendingHolds[1]?.amount)
        this.cd.markForCheck();
      })
  }

  public openDelayPaymentModal() {
    this.modalService.openDelayPayment({
      loan: this.loan,
      nextPayment: this.nextPayment
    }).subscribe();
  }

  public openChangeAchModal() {
    this.modalService.openChangeAch().subscribe(({ action, id }) => {
      if (action === 'AddNew') { 
        this.modalService.open(AddBankAccountModalComponent);
        return;
      }
      if (action === 'Submit') {
        this.$api.patchBankAccount({
          id: Number(id),
          account_priority: 'primary'
        }).subscribe();
      }
    });
  }

  public onMakePaymentClick() {
    const navigate = () => this.router.navigate(
      ['profile', 'make-payment'],
      { queryParams: { loan: this.loan.id }
    });
    if (this.turnOnAutoPay.value) {
      this.modalService.openConfirm({
        confirmText: 'Submit',
        description: [
          `Your next payment is scheduled on Apex-Pay for ${parseDate(this.nextPayment.due_date)} for ${this.nextPayment.payment}`,
          'Are you sure you want to make a manual payment?'
        ]
      })
      .subscribe(resp => resp && navigate());
    } else {
      navigate();
    }
  }

  private handleAutoPayChange(val: boolean) {
    if (val && !this.wasApexPayOn) {
      this.apexPayService.startApexPaySignUp().subscribe({
        error: () => this.turnOnAutoPay.setValue(false, { emitEvent: false })
      });
    } else {
      this.apexPayService.setApexPay(val).subscribe();
    }
  }
}
