<div class="titled-modal-content">
  <div class="titled-modal-content-inner">
    <div class="al-modal-title">
      Reset Offer Code
    </div>
    <form [formGroup]="form" class="form" (keyup.enter)="onSubmit()">
      <app-input
        class="input"
        formControlName="offerCode"
        ngxMask="AAAA"
        label="Enter your Offer Code"
        [setTouched]="setOfferTouched$ | async"
        [errorMessage]="getOfferError()"
        ngDefaultControl
      ></app-input>
      <app-secret-input
        formControlName="ssn"
        class="input"
        label="Last 4 Digits of SSN"
        [forceError]="hasLoginErrors$ | async"
        ngxMask="0000"
        ngDefaultControl
      ></app-secret-input>
      <app-input
        formControlName="zipcode"
        class="input"
        label="Zip Code"
        [forceError]="hasLoginErrors$ | async"
        ngxMask="00000"
        ngDefaultControl
      ></app-input>
    </form>
    <div class="form__bottom-controls">
      <app-button
        class="form__bottom-btn"
        [isDisabled]="form.invalid"
        (pressed)="onSubmit()"
      >Submit</app-button>
      <span
        *ngIf="hasLoginErrors$ | async"
        class="form__bottom-btn__error"
      >Incorrect SSN or Zip Code</span>
    </div>
  </div>
</div>
